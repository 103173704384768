import { GoogleApiWrapper, Marker } from "google-maps-react";
import moment from "moment";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import styled from "styled-components";
import Button from "../../Component/Form/button";
import Image from "../../Component/Image";
import Loading from "../../Component/Loading";
import CurrentLocation from "../../Component/MapContainer";
import Search from "../../Component/Search";
import TableStyle from "../../Component/Styles/Table";
import TitleContainer from "../../Component/TitleContainer";
import { colors } from "../../Themes/Color";
import Images from "../../Themes/Images";
import { sendNotification } from "../../utils";
import {
  GET_INSTITUTE_DASHBOARD_DATA,
  UPDATE_INSTITUTE_LIMIT_ADMIN,
} from "../graphql";

let UpdateSubscription;
const DashboardContainer = styled.div``;
const SchoolDetailsContainer = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  background: ${(props) =>
    props.isActive ? colors.gradientColor : colors.inactiveGradientColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .schoolTextLarge {
    font-family: "Muli", sans-serif;
    font-size: 21;
    color: ${colors.colorWhite};
    margin-bottom: 10px;
  }
  .semiBold {
    font-weight: 600;
  }

  & .schoolTextSmall {
    font-family: "Muli", sans-serif;
    font-size: 19;
    color: ${colors.colorWhite};
  }
  .regular {
    font-weight: 500;
  }
`;
const AlertContainer = styled.div`
  background: #fff;
  padding: 10px 20px;
  border-radius: 11px;
  & .heading {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 19px;
    color: #343434;
  }
  & .counter {
    border-radius: 50%;
    background: #dd455d;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    color: #fff;
    display: inline;
    padding: 0px 5px;
  }
  .close {
    font-size: 13px;
    font-weight: 600;
    color: #dd455d;
    line-height: 16px;
  }
`;
const DeleteModalContainer = styled.div`
  background: ${(props) => (props.transparent ? "#fff" : "#eaf2fc")};
  padding: 10px;
  border-radius: 5px;
  .title {
    font-size: 17px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
  .timer {
    font-size: 13px;
    font-weight: 600;
    color: #343434;
    line-height: 16px;
    opacity: 0.43;
  }
  .close {
    font-size: 13px;
    font-weight: 600;
    color: #dd455d;
    line-height: 16px;
  }
`;
export class SchoolDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [],
      activeItem: "active",
      stops: [{}, {}],
      instituteId: "",
      birdViews: [],
      notifications: [],
      studentsAbsent: [],
      onGoingTrips: [],
      onUpcomingTrips: [],
      onGoingPosition: [],
      institute: {},
      limitRequest: {},
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    this.setState({ instituteId });
  }
  render() {
    return (
      <DashboardContainer className="col-12">
        {this.state.instituteId !== "" && (
          <Query
            variables={{ instituteId: this.state.instituteId, limit: 10 }}
            query={GET_INSTITUTE_DASHBOARD_DATA}
            fetchPolicy="network-only"
            onCompleted={(data) => {
              if (data.getInstituteDashboardData) {
                let institute = {},
                  limitRequest = {},
                  notifications = [],
                  studentsAbsent = [],
                  onUpcomingTrips = [],
                  ongoing = [],
                  onGoingPosition = [];
                if (data.getInstituteDashboardData.institute) {
                  institute = data.getInstituteDashboardData.institute;
                }
                if (data.getInstituteDashboardData.limitRequest) {
                  limitRequest = data.getInstituteDashboardData.limitRequest;
                }
                if (data.getInstituteDashboardData.notifications) {
                  notifications = data.getInstituteDashboardData.notifications;
                }
                if (data.getInstituteDashboardData.studentsAbsent) {
                  studentsAbsent =
                    data.getInstituteDashboardData.studentsAbsent;
                }
                if (data.getInstituteDashboardData.ongoing) {
                  ongoing = data.getInstituteDashboardData.ongoing;
                  onGoingPosition = ongoing.map((data) => {
                    let [lat, lng] = data.currentLocation.split(",");
                    return {
                      lat,
                      lng,
                    };
                  });
                }
                if (data.getInstituteDashboardData.upcoming) {
                  onUpcomingTrips = data.getInstituteDashboardData.upcoming;
                }
                this.setState({
                  institute,
                  limitRequest,
                  notifications,
                  studentsAbsent,
                  onUpcomingTrips,
                  ongoing,
                  onGoingPosition,
                });
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <Mutation
          mutation={UPDATE_INSTITUTE_LIMIT_ADMIN}
          onError={(error) => {
            if (!error || !error.message) return null;

            sendNotification({
              message: error.message.replace("GraphQL error: ", ""),
              type: "danger",
            });
            // this.showNotificationError(
            //   error.message.replace("GraphQL error: ", "")
            // );
          }}
          onCompleted={(data) => {
            if (data.updateInstituteLimitRequest) {
              let instituteId = this.state.instituteId;
              this.setState({ isPendingLimit: false, instituteId: "" }, () => {
                this.setState({ isPendingLimit: true, instituteId });
              });
              // this.showNotification(
              //   "👋 Welcome to our group!",
              //   ``
              // );
              // setTimeout(() => {
              //   // localStorage.setItem("token", data.adminSignIn.token);
              //   this.props.history.push(`/dashboard/admins`);
              //   // this.props.history.push("/events/me");
              // }, 500);
              // message.success("Contact Created!!");
            }
          }}
        >
          {(UpdateAdmin, { data, loading, error }) => {
            UpdateSubscription = UpdateAdmin;
            return (
              <React.Fragment>
                <Loading is_loading={loading}></Loading>
              </React.Fragment>
            );
          }}
        </Mutation>
        {this.state.limitRequest.id && (
          <AlertContainer className="d-inline-block col-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="heading d-flex justify-content-center align-items-center">
                Alert
              </div>
              <Image src={Images.arrowIcon}></Image>
            </div>
            <div className=" mt-4">
              <div className=" mb-3">
                <DeleteModalContainer>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="timer">
                      {moment(this.state.limitRequest.updatedAt).fromNow()}
                    </div>
                    <div
                      className="close pointer-cursor"
                      onClick={() => {
                        UpdateSubscription({
                          variables: {
                            requestId: this.state.limitRequest.id,
                            isNotified: true,
                          },
                        });
                      }}
                    >
                      Close
                    </div>
                  </div>
                  <div className="d-flex  align-items-center">
                    <div className="col-2 p-0">
                      <Image
                        src={
                          (this.state.limitRequest.institute || {}).coverImage
                        }
                      />
                    </div>
                    <div className="ml-2 desc">
                      <div>{`Your school limit has been ${
                        this.state.limitRequest.status !== "ACCEPTED"
                          ? "rejected"
                          : "updated"
                      }`}</div>
                      <div className="semi-bold">
                        Extra limit requested: {this.state.limitRequest.limit}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-4 justify-content-end">
                    <Button
                      rounded
                      primary
                      title={"Done"}
                      onClick={(e) => {
                        e.preventDefault();
                        UpdateSubscription({
                          variables: {
                            requestId: this.state.limitRequest.id,
                            isNotified: true,
                          },
                        });
                      }}
                    ></Button>
                  </div>
                </DeleteModalContainer>
              </div>
            </div>
          </AlertContainer>
        )}
        <SchoolDetailsContainer
          className="mt-4"
          isActive={this.state.institute.isActive}
        >
          <div className="d-flex align-items-center">
            <div style={{ width: "50px" }}>
              <Image src={this.state.institute.coverImage}></Image>
            </div>
            <div className="ml-3">
              <div className="schoolTextLarge semibold">
                {this.state.institute.name}
              </div>
              <div className="schoolTextSmall">
                No of Students:
                <span className="regular">
                  {" "}
                  {`${this.state.institute.totalStudents || 0} Students`}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="schoolTextLarge semibold">
              Subscription:{" "}
              <span className="regular">
                {this.state.institute.subscription}
              </span>
            </div>
            <div className="schoolTextSmall">
              Valid upto:
              <span className="regular">
                {" "}
                {moment(this.state.institute.validUpto).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </SchoolDetailsContainer>
        <div className="row">
          <div className="col-7">
            <TitleContainer
              className="mt-4 mb-3 color-blue"
              title="Birds view"
            ></TitleContainer>
          </div>
          <div className="col-5">
            <TitleContainer
              className="mt-4 mb-3 color-blue"
              title="Upcoming schedule"
            ></TitleContainer>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-7">
            {/*<iframe
              src={`https://maps.google.com/maps?q=[10.8160054,78.6189869,9.8160054,78.6189869]&z=15&output=embed`}
              height="400"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
              className="w-100"
              title="bird View Google maps"
            />*/}
            <div
              className="position-relative"
              style={{
                height: 500,
                borderRadius: 10,
                overflow: "hidden",
                // height: "100vh",
                width: "100%",
              }}
            >
              <div
                className="position-absolute col-12 p-0 h-100"
                style={{ zIndex: 99 }}
              >
                <CurrentLocation
                  google={this.props.google}
                  addMarker={() => {}}
                  currentLocation={
                    !this.state.onGoingPosition.length
                      ? { lat: 12.972442, lng: 77.580643 }
                      : {
                          lat: this.state.onGoingPosition[0].lat,
                          lng: this.state.onGoingPosition[0].lng,
                        }
                  }
                  initialCenter={
                    !this.state.onGoingPosition.length
                      ? { lat: 12.972442, lng: 77.580643 }
                      : {
                          lat: this.state.onGoingPosition[0].lat,
                          lng: this.state.onGoingPosition[0].lng,
                        }
                  }
                >
                  {this.state.onGoingPosition.map((data, index) => {
                    return (
                      <Marker
                        key={`marker-${index}`}
                        onClick={() => {}}
                        name={"current location"}
                        position={{
                          lat: data.lat,
                          lng: data.lng,
                        }}
                      />
                    );
                  })}
                </CurrentLocation>
              </div>
            </div>
          </div>
          <div className="col-5">
            <TableStyle className="">
              <div className="tableHeader row m-0">
                <div className="col-3">Bus</div>

                <div className="col-3">Route</div>
                <div className="col-3">Time</div>
                <div className="col-3">Driver</div>
              </div>
              <div style={{ maxHeight: 330, overflow: "scroll" }}>
                {this.state.onUpcomingTrips.length === 0 && (
                  <div className="tableBody row m-0 alternate text-center justify-content-center">
                    No Ongoing trips found
                  </div>
                )}
                {this.state.onUpcomingTrips.map((data, index) => {
                  return (
                    <div
                      className={`tableBody row m-0 ${
                        index % 2 ? "alternate" : ""
                      }`}
                      key={`onUpcomingTrips-${index}`}
                    >
                      <div className="col-3 word-break-all">
                        {(data.vehicle || {}).vehicleId}
                      </div>

                      <div className="col-3 word-break-all">
                        {(data.route || {}).routeId}
                      </div>
                      <div className="col-3 word-break-all">
                        {data.scheduledTime
                          ? moment(data.scheduledTime).format("hh:mm A")
                          : ""}
                      </div>
                      <div className="col-3 word-break-all">{`${
                        (data.driver || {}).firstName || ""
                      } ${(data.driver || {}).lastName || ""} `}</div>
                    </div>
                  );
                })}
              </div>
            </TableStyle>
          </div>
        </div>
        <div className="row d-flex align-items-center mb-3">
          <div className="col-7">
            <div className="d-flex align-items-center justify-content-between">
              <TitleContainer
                className="color-blue"
                title="Students who missed bus"
              ></TitleContainer>
              <div className="col-7 p-0 d-none">
                <Search></Search>
              </div>
            </div>
          </div>
          <div className="col-5  ">
            <TitleContainer
              className="color-blue"
              title="Notifications"
            ></TitleContainer>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <TableStyle className="mt-3 pb-2 mb-4">
              <div className="tableHeader row m-0">
                <div className="col-2">Name</div>

                <div className="col-2">Standard</div>
                <div className="col-1">Stop</div>
                <div className="col-1">Route</div>
                <div className="col-2">Bus</div>
                <div className="col-2">Contact</div>
                <div className="col-2"></div>
              </div>
              <div style={{ maxHeight: 400, overflow: "scroll" }}>
                {this.state.studentsAbsent.length === 0 && (
                  <div className="tableBody alternate justify-content-center">
                    No Students found
                  </div>
                )}
                {this.state.studentsAbsent.map((data, index) => {
                  let student = data.student || {};
                  return (
                    <div
                      className={`tableBody row m-0 ${
                        index % 2 ? "alternate" : ""
                      }`}
                    >
                      <div className="col-2 word-break-all">{`${
                        student.firstName || ""
                      } ${student.lastName || ""}`}</div>

                      <div className="col-2 word-break-all">{`${student.class} ${student.section}`}</div>
                      <div className="col-1 word-break-all">{`${
                        (data.stop || {}).stopId
                      }`}</div>
                      <div className="col-1 word-break-all">{`${
                        ((data.schedule || {}).route || {}).routeId || ""
                      }`}</div>
                      <div className="col-2 word-break-all">{`${
                        ((data.schedule || {}).vehicle || {}).vehicleId || ""
                      }`}</div>
                      <div className="col-2 word-break-all">
                        <div>{`${student.fatherName || ""}`}</div>
                        <div>{`${student.motherName || ""}`}</div>
                      </div>
                      <div className="col-2 word-break-all">
                        <div>{`${student.fatherMobileNo || ""}`}</div>
                        <div>{`${student.motherMobileNo || ""}`}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TableStyle>
          </div>
          <div className="col-5 ">
            <TableStyle className="pb-2">
              {this.state.notifications.length === 0 && (
                <div className={`tableBody row m-0 alternate text-center`}>
                  <div className="pl-4 w-100 pr-4">No Notifications found</div>
                </div>
              )}
              {this.state.notifications.map((data, index) => {
                return (
                  <div
                    className={`tableBody row m-0 ${
                      index % 2 ? "" : "alternate"
                    }`}
                    key={`Notifications-${index}`}
                  >
                    <div className="pl-4 pr-4">{data.message}</div>
                  </div>
                );
              })}
            </TableStyle>
          </div>
        </div>
      </DashboardContainer>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBuR4HOfDQUy2xqT4dhwkoRcVy9nvbYstE",
})(SchoolDashboard);
// export default SchoolDashboard;
