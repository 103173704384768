import React, { Component } from "react";
import { Mutation } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import ReactInput from "../../../Component/Form/input";
import Loading from "../../../Component/Loading";
import ModalMsg from "../../../Component/ModalMsg";
import TitleContainer from "../../../Component/TitleContainer";
import Images from "../../../Themes/Images";
import { sendNotification } from "../../../utils";
import { UPDATE_ADMIN } from "../../graphql";
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .color-gray {
    color: #919191;
  }
`;
let FormRef;

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      retypepwd: "",
      password: "",
      formSubmit: false,
      successModal: false,
    };
  }
  // "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };
  handleSubmit = (func) => {
    this.setState({ formSubmit: true, isError: true }, () => {
      FormRef.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            if (this.state.password === this.state.retypepwd) {
              let variables = {
                password: this.state.password,
                oldPassword: this.state.oldpassword,
                id: this.props.match.params.id,
              };
              this.setState({ variables }, () => {
                func();
              });
            } else {
              sendNotification({
                message: "Password mismatch",
                type: "danger",
              });
            }
          }
        }
      }, 1);
    });
  };

  render() {
    return (
      <div className="col-12">
        <TitleContainer
          className="mt-5 pl-5 color-blue"
          title="Change password"
        ></TitleContainer>
        <TitleContainer
          className="mt-1 pl-5 very-small color-gray semi-bold"
          title="(All Fields are mandatory)"
        ></TitleContainer>
        <Form
          className={`col-7 mt-5 pt-4 ${
            this.state.formSubmit ? "invalid-form" : ""
          }`}
          ref={(ref) => {
            FormRef = ref;
          }}
        >
          <InputContainer className="row m-0 mt-3">
            <div className="col-5 pl-0 color-gray">Current Password</div>
            <div className="col-7">
              <ReactInput
                placeholder=""
                required
                white
                type="password"
                value={this.state.oldpassword}
                className={"w-100"}
                isError={this.state.formSubmit && this.state.password === ""}
                ErrorMessage="Password incorrect"
                onChange={(value) => {
                  this.onChange("oldpassword", value);
                }}
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-5 pl-0">New password</div>
            <div className="col-7">
              <ReactInput
                placeholder=""
                required
                white
                type="password"
                value={this.state.password}
                className={"w-100"}
                isError={this.state.formSubmit && this.state.password === ""}
                ErrorMessage="Password incorrect"
                onChange={(value) => {
                  this.onChange("password", value);
                }}
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-5 pl-0">Confirm new password</div>
            <div className="col-7">
              <ReactInput
                placeholder=""
                required
                white
                type="password"
                value={this.state.retypepwd}
                className={"w-100"}
                isError={this.state.formSubmit && this.state.password === ""}
                ErrorMessage={
                  this.state.retypepwd === ""
                    ? "Password incorrect"
                    : this.state.password !== this.state.retypepwd
                    ? "Password does not match"
                    : ""
                }
                onChange={(value) => {
                  this.onChange("retypepwd", value);
                }}
              ></ReactInput>
            </div>
          </InputContainer>
          <div className="mt-5 mb-5 pb-5 d-flex justify-content-end">
            <Button
              primaryTransparent
              danger
              title={"Cancel"}
              onClick={() => {
                this.props.history.push(
                  this.props.match.url.replace("/changepassword", "")
                );
              }}
            ></Button>
            <Mutation
              variables={{
                // id: this.props.query.id
                data: { ...this.state.variables },
              }}
              mutation={UPDATE_ADMIN}
              onError={(error) => {
                if (!error || !error.message) return null;

                sendNotification({
                  message: error.message.replace("GraphQL error: ", ""),
                  type: "danger",
                });
                // this.showNotificationError(
                //   error.message.replace("GraphQL error: ", "")
                // );
              }}
              onCompleted={(data) => {
                if (data.updateAdminUser) {
                  // this.showNotification(
                  //   "👋 Welcome to our group!",
                  //   ``
                  // );
                  this.setState({
                    oldpassword: "",
                    password: "",
                    retypepwd: "",
                    formSubmit: false,
                  });
                  this.onSuccessModal();
                  // setTimeout(() => {
                  //   this.props.history.push(`/dashboard/admins`);
                  //   // this.props.history.push("/events/me");
                  // }, 500);
                  // message.success("Contact Created!!");
                }
              }}
            >
              {(UpdateAdmin, { data, loading, error }) => {
                return (
                  <React.Fragment>
                    <Loading is_loading={loading}></Loading>
                    <Button
                      rounded
                      primary
                      title={"Change Password"}
                      onClick={() => {
                        this.handleSubmit(UpdateAdmin);
                      }}
                    ></Button>
                  </React.Fragment>
                );
              }}
            </Mutation>
          </div>
          <ModalMsg
            image={Images.ModalSuccess}
            isOpen={this.state.successModal}
            toggleModal={this.onSuccessModal}
            message="YOUR PASSWORD HAS BEEN SUCCESSFULLY CHANGED."
          ></ModalMsg>
        </Form>
      </div>
    );
  }
}

export default ChangePassword;
