import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import Button from "./Form/button";

export class ModalMsg extends Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggleModal}
          centered
          size="md"
        >
          <ModalBody className="text-center">
            <img className="img-fluid" src={this.props.image}></img>
            <div className="mt-4 mb-4">{this.props.message}</div>
            <div className="d-flex align-items-center justify-content-center mb-2">
              <Button
                rounded
                title={"Done"}
                primary
                onClick={this.props.toggleModal}
              ></Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ModalMsg.protoTypes = {
  image: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool
};
export default ModalMsg;
