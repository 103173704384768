import PropTypes from "prop-types";
import React from "react";
import Avatar from "react-avatar";

const ReactAvatar = props => {
  return (
    <div>
      <Avatar {...props} />
    </div>
  );
};

ReactAvatar.protoTypes = {
  name: PropTypes.string,
  size: PropTypes.string
};

export default ReactAvatar;
