import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import ReactFile from "../../../Component/Form/file";
import ReactInput from "../../../Component/Form/input";
import ReactTextarea from "../../../Component/Form/textarea";
import Loading from "../../../Component/Loading";
import { sendNotification } from "../../../utils";
import { GET_INSTITUTE_DETAILS_ID, UPDATE_SCHOOL } from "../../graphql";
let FormRef;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export class AboutSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      createSchool: {},
      fileInfo: {
        coverImage: "",
      },
      instituteId: "",
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    // refer
    this.setState({ instituteId });
  }

  onChange = (key, value) => {
    const createSchool = { ...this.state.createSchool };
    createSchool[key] = value;

    this.setState({ createSchool });
  };
  fileInfo = (key, value) => {
    const fileInfo = { ...this.state.fileInfo };
    fileInfo[key] = value;
    this.setState({ fileInfo });
  };

  render() {
    return (
      <div className="col-12 mt-5 pt-3">
        {this.state.instituteId !== "" && (
          <Query
            variables={{ id: this.state.instituteId }}
            query={GET_INSTITUTE_DETAILS_ID}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              // console.log(data);
              if (data.getInstituteById) {
                let createSchool = {
                  address: data.getInstituteById.address,
                  billingName: data.getInstituteById.billingName,
                  businessEmail: data.getInstituteById.emailId,
                  contactNo: data.getInstituteById.contactNo,
                  lname: data.getInstituteById.userName,
                  noOfStudents: data.getInstituteById.noOfStudents,
                  pname: data.getInstituteById.principalName,
                  schoolContactNo: data.getInstituteById.principalContact,
                  sname: data.getInstituteById.name,
                  stream: data.getInstituteById.syllabus,
                  subsType: data.getInstituteById.subscription,
                  tName: data.getInstituteById.transportOfficer,
                  website: data.getInstituteById.website,
                  dateOfValid: new Date(
                    data.getInstituteById.validFrom
                  ).getDate(),
                  monthOfValid:
                    new Date(data.getInstituteById.validFrom).getMonth() + 1,
                  yearOfValid: new Date(
                    data.getInstituteById.validFrom
                  ).getFullYear(),
                  insToken: data.getInstituteById.insToken,
                  imageURL: data.getInstituteById.logo,
                  validUpto: data.getInstituteById.validUpto,
                  isActive: data.getInstituteById.isActive === "true",
                  alternateEmail: data.getInstituteById.alternateEmail,
                  alternatePhone: data.getInstituteById.alternatePhone,
                };
                let fileInfo = data.getInstituteById.logo
                  ? { profilePicture: { name: "logo.png" } }
                  : {};
                this.setState({ createSchool, fileInfo });

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}

        <Form
          ref={(ref) => {
            FormRef = ref;
          }}
          className={`col-8 ${this.state.formSubmit ? "invalid-form" : ""}`}
        >
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">Cover Image</div>
            <div className="col-9">
              <ReactFile
                placeholder=""
                white
                header="Cover Image"
                multiple={false}
                required
                uploadFor={"Institutes"}
                value={this.state.fileInfo.coverImage}
                imageURL={this.state.createSchool.coverImage}
                token={{
                  userToken: this.state.createSchool.userToken,
                  insToken: this.state.instituteId,
                }}
                onTokenChange={(insToken, userToken) => {
                  this.onChange("userToken", userToken);
                }}
                className={"w-100"}
                onSuccess={(value) => {
                  this.onChange("coverImage", value);
                }}
                onDelete={() => {
                  this.onChange("coverImage", "");
                  this.fileInfo("coverImage", { name: "" });
                }}
                onChange={(value) => {
                  this.fileInfo(
                    "coverImage",
                    value ? value[value.length - 1] : ""
                  );
                }}
              ></ReactFile>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">School name</div>
            <div className="col-9">
              <ReactInput
                placeholder=""
                white
                required
                value={this.state.createSchool.sname}
                className={"w-100"}
                onChange={(value) => {
                  this.onChange("sname", value);
                }}
                isError={
                  this.state.formSubmit && this.state.createSchool.sname === ""
                }
                ErrorMessage="School name cannot be empty"
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">Contact number</div>
            <div className="col-9">
              <ReactInput
                placeholder=""
                white
                required
                value={this.state.createSchool.contactNo}
                className={"w-100"}
                onChange={(value) => {
                  this.onChange("contactNo", value);
                }}
                isError={
                  this.state.formSubmit &&
                  this.state.createSchool.contactNo === ""
                }
                ErrorMessage="contact number cannot be empty"
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">Alternate Contact number</div>
            <div className="col-9">
              <ReactInput
                placeholder=""
                white
                required
                value={this.state.createSchool.alternatePhone}
                className={"w-100"}
                onChange={(value) => {
                  this.onChange("alternatePhone", value);
                }}
                isError={
                  this.state.formSubmit &&
                  this.state.createSchool.alternatePhone === ""
                }
                ErrorMessage="contact number cannot be empty"
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">Email ID</div>
            <div className="col-9">
              <ReactInput
                placeholder=""
                white
                required
                disabled
                value={this.state.createSchool.businessEmail}
                className={"w-100"}
                onChange={(value) => {
                  this.onChange("businessEmail", value);
                }}
                isError={
                  this.state.formSubmit &&
                  this.state.createSchool.businessEmail === ""
                }
                ErrorMessage="Email ID cannot be empty"
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">Alternate Email ID</div>
            <div className="col-9">
              <ReactInput
                placeholder=""
                white
                required
                value={this.state.createSchool.alternateEmail}
                className={"w-100"}
                onChange={(value) => {
                  this.onChange("alternateEmail", value);
                }}
                isError={
                  this.state.formSubmit &&
                  this.state.createSchool.alternateEmail === ""
                }
                ErrorMessage="Email ID cannot be empty"
              ></ReactInput>
            </div>
          </InputContainer>
          <InputContainer className="row m-0 mt-3">
            <div className="col-3 pl-0">Description</div>
            <div className="col-9">
              <ReactTextarea
                placeholder=""
                required
                white
                value={this.state.description}
                className={"w-100"}
                isError={this.state.formSubmit && this.state.description === ""}
                ErrorMessage="Description incorrect"
                onChange={(value) => {
                  this.onChange("description", value);
                }}
              ></ReactTextarea>
            </div>
          </InputContainer>

          <div className="d-flex justify-content-end mt-5 mb-5 pb-5">
            <Button
              primaryTransparent
              title={"Cancel"}
              onClick={() => {}}
            ></Button>
            <div className="ml-2">
              <Mutation
                variables={{
                  // id: this.props.query.id
                  data: {
                    id: this.state.instituteId,
                    coverImage: this.state.createSchool.coverImage,
                    name: this.state.createSchool.sname,

                    contactNo: this.state.createSchool.contactNo,
                    description: this.state.createSchool.description,
                    // emailId: this.state.createSchool.businessEmail,
                    alternateEmail: this.state.createSchool.alternateEmail,
                    alternatePhone: this.state.createSchool.alternatePhone,
                  },
                }}
                mutation={UPDATE_SCHOOL}
                onError={(error) => {
                  if (!error || !error.message) return null;

                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                  // this.showNotificationError(
                  //   error.message.replace("GraphQL error: ", "")
                  // );
                }}
                onCompleted={(data) => {
                  if (data.updateInstitute) {
                    // this.showNotification(
                    //   "👋 Welcome to our group!",
                    //   ``
                    // );
                    sendNotification({
                      title: "",
                      message: "School Updated Successfully",
                    });

                    // message.success("Contact Created!!");
                  }
                }}
              >
                {(UpdateSchool, { data, loading, error }) => {
                  return (
                    <React.Fragment>
                      <Button
                        primary
                        rounded
                        title={"Confirm"}
                        onClick={() => {
                          UpdateSchool();
                        }}
                      ></Button>
                    </React.Fragment>
                  );
                }}
              </Mutation>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default AboutSchool;
