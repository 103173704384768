import "rc-slider/assets/index.css";
import Table from "rc-table";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import SliderRange from "../../../Component/Form/range";
import Image from "../../../Component/Image";
import Loading from "../../../Component/Loading";
import ModalMsg from "../../../Component/ModalMsg";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import Images from "../../../Themes/Images";
import { DownloadCSV, sendNotification } from "../../../utils";
import {
  DELETE_SCHOOL,
  DOWNLOAD_INSTITUTE_DETAILS,
  GET_INSTITUTE_DETAILS,
  GET_INSTITUTE_FILTERS,
} from "../../graphql";

const DeleteModalContainer = styled.div`
  .title {
    font-size: 17px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
`;

export class SchoolList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instituteDetails: [],
      schoolsCSV: [],
      filterData: [],
      currFilter: [],
      downloadInstituteDetails: [],
      selectedIndex: null,
      refetch: true,
      searchTerm: "",
      sort: "ascending",
      sortCol: "name",
      sortStr: "ASC",
      role: "",
      roleSet: "",
      forDownload: false,
      selectedSchool: {
        name: "",
        logo: "",
      },
    };
  }
  componentDidMount() {
    // if(this.props.)
    const roleSet =
      (this.props.user || {}).rolePriority === "PRIMARY" ? "1" : "0";
    this.setState({ roleSet });
  }
  selectSchool = (index) => {
    const instituteDetails = [...this.state.instituteDetails];
    instituteDetails.map((data, dataIndex) => {
      data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = true;
      }
      return data;
    });
    this.setState({
      selectedSchool: instituteDetails[index],
      instituteDetails,
      selectedIndex: index + 1,
    });
  };
  editSchools = () => {
    // alert(this.state.selectedIndex);
    if (this.state.selectedIndex) {
      this.props.history.push(
        `/dashboard/school/edit/${
          this.state.instituteDetails[this.state.selectedIndex - 1].id
        }`
      );
    } else {
      sendNotification({ message: "Please select a school", type: "danger" });
    }
  };
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };
  deleteSchool = () => {
    if (this.state.selectedIndex) {
      this.setState(
        {
          selectedSchool: this.state.instituteDetails[
            this.state.selectedIndex - 1
          ],
        },
        () => {
          this.toggleDeleteModal();
        }
      );
    } else {
      sendNotification({ message: "Please select a school", type: "danger" });
    }
  };
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  onSortChange = () => {};

  onDownloadClick = () => {
    const CSV = [];
    CSV.push([
      "School Name",
      "Principal Name",
      "Principal Contact Number",
      "Address",
      "Email",
      "Number of Students",
      "Total Students",
      "Total Bus",
      "Total Drivers",
      "Website",
    ]);
    this.state.downloadInstituteDetails.map((data) => {
      CSV.push([
        data.name,
        data.principalName,
        data.principalContact,
        `"${data.address}"`,
        data.emailId,
        data.noOfStudents,
        data.totalStundents || 0,
        data.totalBus || 0,
        data.totalDrivers || 0,
        `${data.website}`,
      ]);
    });
    this.setState({ schoolsCSV: CSV, forDownload: false }, () => {
      DownloadCSV("Schools", CSV);
    });
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;
            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  sliderChange = (dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[dataIndex].minValue = value[0];
    filterData[dataIndex].maxValue = value[1];

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  resetFilter = () => {
    const currFilter = [...this.state.filterData];
    // filterData[dataIndex].minValue = value[0];
    // filterData[dataIndex].maxValue = value[1];
    const filterData = currFilter.map((data) => {
      if ((data.fieldValues || []).length) {
        data.fieldValues = data.fieldValues.map((field) => {
          field.isChecked = false;
          return field;
        });
      } else {
        data.minValue = data.defaultMinValue;
        data.maxValue = data.defaultMaxValue;
      }
      return data;
    });

    this.setState({ filterData, currFilter: [] });
  };
  render() {
    const columns = [
      {
        title: "School name",
        dataIndex: "name",
        key: "name",
        width: 250,
        fixed: "left",
      },

      {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
        width: 180,
        render: (text, data, index) => {
          return (
            <div className=" m-0">
              <div className="">{data.emailId}</div>
              <div>{data.contactNo}</div>
            </div>
          );
        },
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        width: 300,
      },
      {
        title: "No of Students",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
      },
      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.setState({ forDownload: true });
              //this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 160,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className="col-6">
                <img
                  src={
                    !data.isSelected
                      ? Images.circleIcon
                      : Images.circleIconSelected
                  }
                  className="img-fluid "
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectSchool(index);
                  }}
                />
              </div>
              <div className="col-4 p-0">
                <img src={Images.nextIcon} className="img-fluid " alt="" />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="row m-0 mt-4">
          <div className="col-5  pt-3">
            <Search
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              onReset={this.resetFilter}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {data.defaultMinValue && data.defaultMinValue && (
                          <div className="pl-1 pr-1">
                            <SliderRange
                              min={Math.ceil(data.defaultMinValue)}
                              max={Math.ceil(data.defaultMaxValue)}
                              valueMin={Math.ceil(data.minValue)}
                              valueMax={Math.ceil(data.maxValue)}
                              onSliderChange={(e) => {
                                this.sliderChange(index, e);
                              }}
                            ></SliderRange>
                          </div>
                        )}
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "name",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "name",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    title="Student Limit"
                    subTitle="(High to Low)"
                    active={this.state.sort === "studentLimitLow"}
                    stateKey="studentLimitLow"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "noOfStudents",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    title="Student Limit"
                    subTitle="(Low to High)"
                    active={this.state.sort === "studentLimitHigh"}
                    stateKey="studentLimitHigh"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "noOfStudents",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            {this.state.roleSet == "1" && (
              <div>
                <Button
                  title="Create new school"
                  primary
                  rounded
                  onClick={() => {
                    this.props.history.push("/dashboard/school/create");
                  }}
                ></Button>
              </div>
            )}
            <div className="ml-3">
              <Button
                title="Edit"
                white
                rounded
                onClick={this.editSchools}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                title="Delete"
                danger
                rounded
                onClick={this.deleteSchool}
              ></Button>
            </div>
          </div>
        </div>
        {this.state.forDownload && (
          <Query
            variables={{
              searchTerm: this.state.searchTerm,
              sortCol: this.state.sortCol,
              sort: this.state.sortStr,
              filter: this.state.currFilter,
            }}
            query={DOWNLOAD_INSTITUTE_DETAILS}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (this.state.forDownload) {
                if (data.getInstituteDetailsList) {
                  let downloadInstituteDetails = [];

                  downloadInstituteDetails = data.getInstituteDetailsList.map(
                    (data) => {
                      data.isSelected = false;
                      return data;
                    }
                  );

                  this.setState({ downloadInstituteDetails }, () => {
                    this.onDownloadClick();
                  });
                }
              } else {
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <Query
          query={GET_INSTITUTE_FILTERS}
          fetchPolicy={"network-only"}
          onCompleted={(data) => {
            if (data.createUniqueFiltersForInstitute) {
              let filterData = data.createUniqueFiltersForInstitute.data.map(
                (data) => {
                  if (data.minValue && data.maxValue) {
                    data.defaultMinValue = data.minValue;
                    data.defaultMaxValue = data.maxValue;
                  }
                  if ((data.fieldValues || []).length) {
                    data.fieldValues = data.fieldValues.map((field) => {
                      return {
                        label: field,
                        isChecked: false,
                      };
                    });
                  }
                  return data;
                }
              );
              //console.log(filterData);
              this.setState({
                filterData: filterData, //data.createUniqueFiltersForInstitute.data,
              });
            }
          }}
        >
          {({ data, loading, error }) => {
            return <Loading is_loading={loading} />;
          }}
        </Query>
        {this.state.refetch && (
          <Query
            variables={{
              searchTerm: this.state.searchTerm,
              filter: this.state.currFilter,
              sortCol: this.state.sortCol,
              sort: this.state.sortStr,
            }}
            query={GET_INSTITUTE_DETAILS}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (data.getInstituteDetailsList) {
                let instituteDetails = [];

                instituteDetails = data.getInstituteDetailsList.map((data) => {
                  data.isSelected = false;
                  return data;
                });
                this.setState({
                  instituteDetails,
                });
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <div className="col-12 mt-5">
          <div>
            <Table
              useFixedHeader
              onRow={(data, index) => ({
                onClick: () => {
                  localStorage.setItem("institueId", data.id);
                  setTimeout(() => {
                    this.props.history.push("/school/dashboard");
                  }, 1);
                },
              })}
              rowClassName={(record, i) => `pointer-cursor`}
              columns={columns}
              data={this.state.instituteDetails}
              scroll={{
                x: 1200,
                y: "calc(100vh - 140px - 75px - 64px )",
              }}
              emptyText="No Institute Found!!"
              //title={setTableTitle}
            />
          </div>
        </div>
        <ModalMsg
          image={Images.ModalFailure}
          isOpen={this.state.successModal}
          toggleModal={this.onSuccessModal}
          message={`${this.state.selectedSchool.name.toUpperCase()} HAS BEEN REMOVED FROM THE PLATFORM`}
        ></ModalMsg>
        <Modal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          centered
          size="md"
        >
          {this.state.selectedSchool !== "" && (
            <ModalBody>
              <DeleteModalContainer>
                <div className="title">Delete School</div>
                <div className="desc mt-2 mb-3">
                  {`Are you sure you want to remove ${this.state.selectedSchool.name} from the Scabbr
                platform?`}
                </div>
                <div className="d-flex  align-items-center">
                  <div className="col-2 p-0">
                    <Image src={this.state.selectedSchool.logo} />
                  </div>
                  <div className="ml-2 desc">
                    <div className="semi-bold">{`${this.state.selectedSchool.name}`}</div>
                    <div>{`No of students: ${
                      this.state.selectedSchool.noOfStudents
                    } Student${
                      this.state.selectedSchool.noOfStudents !== 0 ? "s" : ""
                    }`}</div>
                  </div>
                </div>
                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    primaryTransparent
                    title={"Cancel"}
                    onClick={() => {
                      this.toggleDeleteModal();
                    }}
                  ></Button>
                  <Mutation
                    variables={{
                      // id: this.props.query.id
                      instituteId: this.state.selectedSchool.id,
                    }}
                    refetchQueries={[
                      {
                        query: GET_INSTITUTE_DETAILS,
                      },
                    ]}
                    mutation={DELETE_SCHOOL}
                    onError={(error) => {
                      if (!error || !error.message) return null;

                      sendNotification({
                        message: error.message.replace("GraphQL error: ", ""),
                        type: "danger",
                      });
                      // this.showNotificationError(
                      //   error.message.replace("GraphQL error: ", "")
                      // );
                    }}
                    onCompleted={(data) => {
                      if (data.deleteInstitute) {
                        // this.toggle
                        this.toggleDeleteModal();
                        this.setState({ refetch: false }, () => {
                          this.setState({ refetch: true });
                        });
                        this.onSuccessModal();
                      }
                    }}
                  >
                    {(DeleteAdmin, { data, loading, error }) => {
                      return (
                        <Button
                          rounded
                          danger
                          title={"Remove School"}
                          onClick={() => {
                            DeleteAdmin();
                          }}
                        ></Button>
                      );
                    }}
                  </Mutation>
                </div>
              </DeleteModalContainer>
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}

export default SchoolList;
