import React, { Component } from "react";
import { Mutation } from "react-apollo";
import Loading from "../../Component/Loading";
import { sendNotification } from "../../utils";
import { VERIFY_EMAIL } from "../graphql";
let verifyEmailG = null;
export class VerifyEmail extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (verifyEmailG) {
        verifyEmailG();
      }
    }, 500);
  }
  render() {
    return (
      <div>
        <Mutation
          mutation={VERIFY_EMAIL}
          variables={{
            token: this.props.match.params.id,
            // id: this.props.query.id
            //userId: this.state.selectedAdmin.id,
          }}
          onCompleted={(data) => {
            if (data.verifyEmail) {
              sendNotification({
                message: "Email Verified",
                type: "success",
              });
            }
            setTimeout(() => {
              this.props.history.push("/");
            }, 10);
            // if (data.getOnboardingEmailId) {
            //   this.setState({ email: data.getOnboardingEmailId.email });
            // }
          }}
          onError={(error) => {
            if (!error || !error.message) return null;
            this.setState({ hasErr: true });
            sendNotification({
              message: error.message.replace("GraphQL error: ", ""),
              type: "danger",
            });
            setTimeout(() => {
              this.props.history.push("/");
            }, 10);
          }}
        >
          {(verifyEmail, { data, loading, error }) => {
            verifyEmailG = verifyEmail;
            return <Loading is_loading={loading} />;
          }}
        </Mutation>
      </div>
    );
  }
}

export default VerifyEmail;
