import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .color-gray {
    color: #919191;
  }
`;
export default InputContainer;
