import moment from "moment";
import "rc-slider/assets/index.css";
import Table from "rc-table";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import ReactInput from "../../../Component/Form/input";
import SliderRange from "../../../Component/Form/range";
import InputContainer from "../../../Component/Form/style/InputContainer";
import Image from "../../../Component/Image";
import Loading from "../../../Component/Loading";
import ModalMsg from "../../../Component/ModalMsg";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import Images from "../../../Themes/Images";
import { DownloadCSV, sendNotification } from "../../../utils";
import {
  DOWNLOAD_SUBSCRIPTION_DETAILS,
  GET_INSTITUTE_DETAILS,
  GET_INSTITUTE_FILTERS,
  GET_PENDING_LIMITS,
  UPDATE_INSTITUTE_LIMIT,
  UPDATE_INSTITUTE_LIMIT_ADMIN,
} from "../../graphql";

let FormRef;
let UpdateSubscription;
const DeleteModalContainer = styled.div`
  background: ${(props) => (props.transparent ? "#fff" : "#eaf2fc")};
  padding: 10px;
  border-radius: 5px;
  .title {
    font-size: 17px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
  .timer {
    font-size: 13px;
    font-weight: 600;
    color: #343434;
    line-height: 16px;
    opacity: 0.43;
  }
  .close {
    font-size: 13px;
    font-weight: 600;
    color: #dd455d;
    line-height: 16px;
  }
`;
const AlertContainer = styled.div`
  background: #fff;
  padding: 10px 20px;
  border-radius: 11px;
  & .heading {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 19px;
    color: #343434;
  }
  & .counter {
    border-radius: 50%;
    background: #dd455d;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    color: #fff;
    display: inline;
    padding: 0px 5px;
  }
`;
export class SubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instituteDetails: [],
      schoolsCSV: [],
      filterData: [],
      currFilter: [],
      selectedIndex: null,
      refetch: true,
      searchTerm: "",
      sort: "ascending",
      sortCol: "name",
      sortStr: "ASC",
      forDownload: false,
      isAlertClick: false,
      getPendingLimitRequests: [],
      isPendingLimit: true,
      selectedSchool: {
        name: "",
        logo: "",
      },
    };
  }
  selectSchool = (index) => {
    const instituteDetails = this.state.instituteDetails;
    instituteDetails.map((data, dataIndex) => {
      data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = true;
      }
      return data;
    });
    this.setState({
      selectedSchool: instituteDetails[index],
      instituteDetails,
      selectedIndex: index + 1,
    });
  };

  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };

  toggleDeleteModal = () => {
    this.setState({
      isDeleteOpen: !this.state.isDeleteOpen,
    });
  };
  onSortChange = () => {};

  onDownloadClick = () => {
    const CSV = [];
    CSV.push([
      "School Name",
      "Principal Name",
      "Principal Contact Number",
      "Address",
      "Email",
      "Number of Students",
      "Cost Per Student",
      "Plan",
      "Valid From",
      "Valid To",
      "Website",
    ]);
    this.state.downloadInstituteDetails.map((data) => {
      CSV.push([
        data.name,
        data.principalName,
        data.principalContact,
        `"${data.address}"`,
        data.emailId,
        data.noOfStudents,
        data.costPerStudent,
        data.plan,
        moment(data.validFrom).format("DD-MM-YYYY"),
        moment(data.validUpto).format("DD-MM-YYYY"),
        `"${data.website}"`,
      ]);
    });
    this.setState({ schoolsCSV: CSV, forDownload: false }, () => {
      DownloadCSV("Schools", CSV);
    });
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;

            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  sliderChange = (dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[dataIndex].minValue = value[0];
    filterData[dataIndex].maxValue = value[1];

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  resetFilter = () => {
    const currFilter = [...this.state.filterData];
    // filterData[dataIndex].minValue = value[0];
    // filterData[dataIndex].maxValue = value[1];
    const filterData = currFilter.map((data) => {
      if ((data.fieldValues || []).length) {
        data.fieldValues = data.fieldValues.map((field) => {
          field.isChecked = false;
          return field;
        });
      } else {
        data.minValue = data.defaultMinValue;
        data.maxValue = data.defaultMaxValue;
      }
      return data;
    });

    this.setState({ filterData, currFilter: [] });
  };
  render() {
    const columns = [
      {
        title: "School name",
        dataIndex: "name",
        key: "name",
        width: 250,
        fixed: "left",
      },

      {
        title: "Plan",
        dataIndex: "plan",
        key: "plan",
        width: 300,
      },
      {
        title: "Student Limit",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
      },
      {
        title: "No of students",
        dataIndex: "studentLimit",
        key: "studentLimit",
        width: 160,
        render: (text, data, index) => {
          return (
            <div className="d-flex justify-content-between">
              <div>{data.totalStudents}</div>
              {/*<div
                className="color-blue"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ noOfStudents: data.noOfStudents });
                  this.selectSchool(index);
                  setTimeout(() => {
                    this.toggleDeleteModal();
                  }, 10);
                }}
              >
                Edit Limit
              </div>*/}
            </div>
          );
        },
      },
      {
        title: "Pricing",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className="word-break-all">
              {data.costPerStudent * data.noOfStudents}
            </div>
          );
        },
      },
      {
        title: "Validity Expires",
        dataIndex: "validUpto",
        key: "validUpto",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className>{moment(data.validUpto).format("YYYY-DD-MM")}</div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="row m-0 mt-4">
          <div className="col-5  pt-3">
            <Search
              onReset={this.resetFilter}
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {data.defaultMinValue && data.defaultMinValue && (
                          <div className="pl-1 pr-1">
                            <SliderRange
                              min={Math.ceil(data.defaultMinValue)}
                              max={Math.ceil(data.defaultMaxValue)}
                              valueMin={Math.ceil(data.minValue)}
                              valueMax={Math.ceil(data.maxValue)}
                              onSliderChange={(e) => {
                                this.sliderChange(index, e);
                              }}
                            ></SliderRange>
                          </div>
                        )}
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "name",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "name",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    title="Student Limit"
                    subTitle="(High to Low)"
                    active={this.state.sort === "studentLimitLow"}
                    stateKey="studentLimitLow"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "noOfStudents",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    title="Student Limit"
                    subTitle="(Low to High)"
                    active={this.state.sort === "studentLimitHigh"}
                    stateKey="studentLimitHigh"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "noOfStudents",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            <div>
              <Button
                title="Create new school"
                primary
                rounded
                onClick={() => {
                  this.props.history.push("/dashboard/school/create");
                }}
              ></Button>
            </div>
          </div>
        </div>
        <Mutation
          mutation={UPDATE_INSTITUTE_LIMIT_ADMIN}
          onError={(error) => {
            if (!error || !error.message) return null;

            sendNotification({
              message: error.message.replace("GraphQL error: ", ""),
              type: "danger",
            });
            // this.showNotificationError(
            //   error.message.replace("GraphQL error: ", "")
            // );
          }}
          onCompleted={(data) => {
            if (data.updateInstituteLimitRequest) {
              this.setState({ isPendingLimit: false }, () => {
                this.setState({ isPendingLimit: true });
              });
              // this.showNotification(
              //   "👋 Welcome to our group!",
              //   ``
              // );
              // setTimeout(() => {
              //   // localStorage.setItem("token", data.adminSignIn.token);
              //   this.props.history.push(`/dashboard/admins`);
              //   // this.props.history.push("/events/me");
              // }, 500);
              // message.success("Contact Created!!");
            }
          }}
        >
          {(UpdateAdmin, { data, loading, error }) => {
            UpdateSubscription = UpdateAdmin;
            return (
              <React.Fragment>
                <Loading is_loading={loading}></Loading>
              </React.Fragment>
            );
          }}
        </Mutation>
        {(this.state.getPendingLimitRequests || []).length !== 0 && (
          <div className="col-10 mt-3">
            <AlertContainer>
              <div className="d-flex justify-content-between align-items-center">
                <div className="heading d-flex justify-content-center align-items-center">
                  Alert
                  <div className="ml-2">
                    <div className="counter">
                      {(this.state.getPendingLimitRequests || []).length}
                    </div>
                  </div>
                </div>
                <Image src={Images.arrowIcon}></Image>
              </div>
              <div className="row mt-4">
                {(this.state.getPendingLimitRequests || []).map((data) => {
                  return (
                    <div className="col-6 mb-3">
                      <DeleteModalContainer>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="timer">
                            {moment(data.createdAt).fromNow()}
                          </div>
                          <div
                            className="close pointer-cursor"
                            onClick={() => {
                              UpdateSubscription({
                                variables: {
                                  requestId: data.id,
                                  status: "REJECTED",
                                  isNotified: false,
                                },
                              });
                            }}
                          >
                            Close
                          </div>
                        </div>
                        <div className="d-flex  align-items-center">
                          <div className="col-2 p-0">
                            <Image src={(data.institute || {}).coverImage} />
                          </div>
                          <div className="ml-2 desc">
                            <div>{(data.institute || {}).name}</div>
                            <div className="semi-bold">
                              Extra limit requested: {data.limit}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-4 justify-content-end">
                          <Button
                            rounded
                            primary
                            title={"Edit Limit"}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  selectedSchool: {
                                    name: "",
                                    requestId: data.id,
                                    id: localStorage.getItem("institueId"),
                                  },
                                  noOfStudents: data.limit,
                                  isAlertClick: true,
                                },
                                () => {
                                  this.toggleDeleteModal();
                                }
                              );
                            }}
                          ></Button>
                        </div>
                      </DeleteModalContainer>
                    </div>
                  );
                })}
              </div>
            </AlertContainer>
          </div>
        )}
        {this.state.isPendingLimit && (
          <Query
            query={GET_PENDING_LIMITS}
            onCompleted={(data) => {
              if (data.getPendingLimitRequests) {
                this.setState({
                  getPendingLimitRequests: data.getPendingLimitRequests,
                });
              }
            }}
            fetchPolicy="network-only"
            variables={{
              instituteId: localStorage.getItem("institueId"),
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <Query
          query={GET_INSTITUTE_FILTERS}
          onCompleted={(data) => {
            if (data.createUniqueFiltersForInstitute) {
              let filterData = data.createUniqueFiltersForInstitute.data.map(
                (data) => {
                  if (data.minValue && data.maxValue) {
                    data.defaultMinValue = data.minValue;
                    data.defaultMaxValue = data.maxValue;
                  }
                  if ((data.fieldValues || []).length) {
                    data.fieldValues = data.fieldValues.map((field) => {
                      return {
                        label: field,
                        isChecked: false,
                      };
                    });
                  }
                  return data;
                }
              );
              //console.log(filterData);
              this.setState({
                filterData: filterData, //data.createUniqueFiltersForInstitute.data,
              });
            }
          }}
        >
          {({ data, loading, error }) => {
            return <Loading is_loading={loading} />;
          }}
        </Query>
        {this.state.forDownload && (
          <Query
            variables={{
              searchTerm: this.state.searchTerm,
              sortCol: this.state.sortCol,
              sort: this.state.sortStr,
              filter: this.state.currFilter,
            }}
            query={DOWNLOAD_SUBSCRIPTION_DETAILS}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (this.state.forDownload) {
                if (data.getInstituteDetailsList) {
                  let downloadInstituteDetails = [];

                  downloadInstituteDetails = data.getInstituteDetailsList.map(
                    (data) => {
                      data.isSelected = false;
                      return data;
                    }
                  );

                  this.setState({ downloadInstituteDetails }, () => {
                    this.onDownloadClick();
                  });
                }
              } else {
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        {this.state.forDownload && (
          <Query
            variables={{
              searchTerm: this.state.searchTerm,
              sortCol: this.state.sortCol,
              sort: this.state.sortStr,
              filter: this.state.currFilter,
            }}
            query={DOWNLOAD_SUBSCRIPTION_DETAILS}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (this.state.forDownload) {
                if (data.getInstituteDetailsList) {
                  let downloadInstituteDetails = [];

                  downloadInstituteDetails = data.getInstituteDetailsList.map(
                    (data) => {
                      data.isSelected = false;
                      return data;
                    }
                  );

                  this.setState({ downloadInstituteDetails }, () => {
                    this.onDownloadClick();
                  });
                }
              } else {
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        {this.state.refetch && (
          <Query
            variables={{
              searchTerm: this.state.searchTerm,
              sortCol: this.state.sortCol,
              sort: this.state.sortStr,
              filter: this.state.currFilter,
            }}
            query={GET_INSTITUTE_DETAILS}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (this.state.forDownload) {
                console.log(data);
              } else {
                if (data.getInstituteDetailsList) {
                  let instituteDetails = [];

                  instituteDetails = data.getInstituteDetailsList.map(
                    (data) => {
                      data.isSelected = false;
                      return data;
                    }
                  );
                  this.setState({ instituteDetails });
                }
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}

        <div className="col-12 mt-4">
          <Table
            useFixedHeader
            rowClassName={(record, i) => `pointer-cursor`}
            columns={columns}
            data={this.state.instituteDetails}
            // scroll={{
            //   y: 350,
            // }}
            onRow={(data, index) => ({
              onClick: () => {
                this.setState({
                  noOfStudents: data.noOfStudents,
                  totalStudents: data.totalStudents,
                });
                this.selectSchool(index);
                setTimeout(() => {
                  this.toggleDeleteModal();
                }, 10);
              },
            })}
            emptyText="No Institute Found!!"
            //title={setTableTitle}
          />
        </div>
        <ModalMsg
          image={Images.ModalSuccess}
          isOpen={this.state.successModal}
          toggleModal={this.onSuccessModal}
          message={`${this.state.selectedSchool.name.toUpperCase()} STUDENT LIMIT HAS BEEN UPDATED IN THE PLATFORM`}
        ></ModalMsg>
        <Modal
          isOpen={this.state.isDeleteOpen}
          toggle={() => {
            this.setState({ isAlertClick: false });

            this.toggleDeleteModal();
          }}
          centered
          size="md"
        >
          {this.state.selectedSchool !== "" && (
            <ModalBody>
              <DeleteModalContainer transparent>
                <div className="title">Edit Subscription Limit</div>
                <div className="desc mt-2 mb-3">
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-4 pl-0">Number of students</div>
                    <div className="col-8">
                      <Form
                        className={`col-12 p-0 ${
                          this.state.formSubmit ? "invalid-form" : ""
                        }`}
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                      >
                        <ReactInput
                          placeholder=""
                          white
                          required
                          type="number"
                          value={this.state.noOfStudents}
                          className={"w-100"}
                          onChange={(value) => {
                            this.onChange("noOfStudents", value);
                          }}
                        ></ReactInput>
                      </Form>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-8">
                      {(parseInt(this.state.noOfStudents) || 0) <=
                        (parseInt(this.state.totalStudents) || 0) && (
                        <span className="text-tiny error color-red">
                          Total Students is greater than Student Limit
                        </span>
                      )}
                    </div>
                  </InputContainer>
                  <div className="row m-0">
                    <div className="col-3"></div>
                    <div className="col-9">
                      <div className="d-flex justify-content-end mt-3">
                        <Button
                          primaryOutline
                          primary
                          rounded
                          title={"+1"}
                          onClick={() => {
                            this.onChange(
                              "noOfStudents",
                              (parseInt(this.state.noOfStudents) || 0) + 1
                            );
                          }}
                        ></Button>
                        <div className="ml-2">
                          <Button
                            primaryOutline
                            rounded
                            title={"+10"}
                            onClick={() => {
                              this.onChange(
                                "noOfStudents",
                                (parseInt(this.state.noOfStudents) || 0) + 10
                              );
                            }}
                          ></Button>
                        </div>
                        <div className="ml-2">
                          <Button
                            primaryOutline
                            rounded
                            title={"+100"}
                            onClick={() => {
                              this.onChange(
                                "noOfStudents",
                                (parseInt(this.state.noOfStudents) || 0) + 100
                              );
                            }}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  align-items-center"></div>
                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    primaryTransparent
                    title={"Cancel"}
                    onClick={() => {
                      this.setState({ isAlertClick: false });

                      this.toggleDeleteModal();
                    }}
                  ></Button>
                  <Mutation
                    variables={{
                      // id: this.props.query.id
                      instituteId: this.state.selectedSchool.id,
                      limit: parseInt(this.state.noOfStudents),
                    }}
                    refetchQueries={[
                      {
                        query: GET_INSTITUTE_DETAILS,
                      },
                    ]}
                    mutation={UPDATE_INSTITUTE_LIMIT}
                    onError={(error) => {
                      if (!error || !error.message) return null;

                      sendNotification({
                        message: error.message.replace("GraphQL error: ", ""),
                        type: "danger",
                      });
                      // this.showNotificationError(
                      //   error.message.replace("GraphQL error: ", "")
                      // );
                    }}
                    onCompleted={(data) => {
                      if (data.updateInstituteLimit) {
                        // this.toggle
                        this.toggleDeleteModal();
                        if (this.state.isAlertClick) {
                          this.setState({ isAlertClick: false });
                          UpdateSubscription({
                            variables: {
                              requestId: this.state.selectedSchool.requestId,
                              status: "ACCEPTED",
                              isNotified: false,
                            },
                          });
                        } else {
                          this.onSuccessModal();
                        }
                        this.setState({ refetch: false }, () => {
                          this.setState({ refetch: true });
                        });
                      }
                    }}
                  >
                    {(DeleteAdmin, { data, loading, error }) => {
                      return (
                        <Button
                          rounded
                          primary
                          title={"Edit Limit"}
                          onClick={() => {
                            this.setState({ formSubmit: true }, () => {
                              FormRef.validateAll();
                              setTimeout(() => {
                                if (
                                  document.querySelectorAll(".form-error")
                                    .length === 0
                                ) {
                                  if (
                                    document.querySelectorAll(".inputRequired")
                                      .length === 0
                                  ) {
                                    DeleteAdmin();
                                  }
                                }
                              }, 1);
                            });
                          }}
                        ></Button>
                      );
                    }}
                  </Mutation>
                </div>
              </DeleteModalContainer>
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}

export default SubscriptionList;
