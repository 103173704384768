import Table from "rc-table";
import React, { Component } from "react";
import { Query } from "react-apollo";
import ReactAvatar from "react-avatar";
import LinesEllipsis from "react-lines-ellipsis";
import { Modal } from "reactstrap";
import DeleteModal from "../../../Component/DeleteModal";
// import styled from "styled-components";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import Loading from "../../../Component/Loading";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import Images from "../../../Themes/Images";
import { sendNotification } from "../../../utils";
import { DELETE_STOP, GET_STOP_DETAILS, GET_STOP_FILTER } from "../../graphql";

export class StopList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stopDetails: [],
      selectedIndex: null,
      isOpen: false,
      selectedId: [],
      refetch: true,
      selectedStop: {},
      instituteId: "",
      sort: "ascending",
      sortCol: "stopId",
      sortStr: "ASC",
      isDeleteOpen: false,
      filterData: [],
      currFilter: [],
      modalData: {
        students: [],
      },
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    this.setState({ role: role, instituteId });
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  selectStop = (index) => {
    const stopDetails = this.state.stopDetails;
    stopDetails.map((data, dataIndex) => {
      // data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = !data.isSelected;
      }
      return data;
    });
    let selectedIndex = null;
    let selectedId = stopDetails
      .filter((data, index) => {
        if (data.isSelected) {
          selectedIndex = index + 1;
          return data;
        }
      })
      .map((data) => data.id);
    this.setState({
      stopDetails,
      selectedIndex: selectedIndex,
      selectedStop: this.state.stopDetails[index],
      //this.state.stopDetails[index].id,
      selectedId: selectedId,
    });
  };
  editStop = () => {
    // alert(this.state.selectedIndex);
    if (this.state.selectedIndex) {
      this.props.history.push(
        `/school/stop/edit/${
          this.state.stopDetails[this.state.selectedIndex - 1].id
        }`
      );
    } else {
      sendNotification({ message: "Please select a stop", type: "danger" });
    }
  };
  deleteStop = (func) => {
    if (this.state.selectedIndex) {
      const stopDetails = [...this.state.stopDetails];
      stopDetails.splice(this.state.selectedIndex - 1, 1);
      // console.log(this.state.driverDetails);
      this.setState({ stopDetails });
      func();
    } else {
      sendNotification({ message: "Please select a stop", type: "danger" });
    }
  };
  toggleDeleteModal = () => {
    if (this.state.selectedIndex) {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    } else {
      sendNotification({ message: "Please select a stop", type: "danger" });
    }
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;
            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  onViewStopDetails = (index) => {
    let modalData = {};
    let StopDetails = this.state.stopDetails[index];
    modalData.stopId = StopDetails.stopId;
    modalData.stopName = StopDetails.stopName;
    modalData.students = StopDetails.students;
    this.setState({ modalData }, () => {
      this.toggleModal();
    });
  };

  render() {
    const columns = [
      {
        title: "Stop ID",
        dataIndex: "stopId",
        key: "stopId",
        width: 200,
        fixed: "left",
      },

      {
        title: "Stop name",
        dataIndex: "stopName",
        key: "stopName",
        width: 180,
      },
      // {
      //   title: "Radius/polygon",
      //   dataIndex: "radius",
      //   key: "radius",
      //   width: 200,
      // },
      {
        title: "Driver name",
        dataIndex: "driverName",
        key: "driverName",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              <div className="d-none">
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={
                    data.trips
                      ? data.trips[0].driver
                        ? data.trips[0].driver.firstName +
                          " " +
                          data.trips[0].driver.lastName
                        : ""
                      : ""
                  }
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
              <div>
                {(data.routes || []).map((route, routeIndex) => {
                  return (
                    <div key={`route-${index}-${routeIndex}`}>
                      {route.trips.map((trip, tripIndex) => {
                        return (
                          <div
                            className="mb-1"
                            key={`Vehicle-route-${tripIndex}-${routeIndex}`}
                          >
                            <div>
                              <LinesEllipsis
                                style={{ whiteSpace: "pre-wrap" }}
                                text={
                                  trip.driver
                                    ? trip.driver.firstName +
                                      " " +
                                      trip.driver.lastName
                                    : ""
                                }
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </div>
                            <div>
                              <LinesEllipsis
                                style={{ whiteSpace: "pre-wrap" }}
                                text={
                                  trip.driver ? trip.driver.mobileNumber : ""
                                }
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
      },
      {
        title: "No of students",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className="p-0">
                <div>{data.students.length || "0"}</div>
              </div>
              <div className="p-0">
                <span
                  className="ml-3 color-sky-blue pointer-cursor"
                  onClick={() => {
                    this.onViewStopDetails(index);
                  }}
                >
                  View
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Bus details",
        dataIndex: "busDetails",
        key: "busDetails",
        width: 200,
        render: (text, data, index) => {
          return (data.routes || []).map((route, routeIndex) => {
            return (
              <div key={`route-${index}-${routeIndex}`}>
                {route.trips.map((trip, tripIndex) => {
                  return (
                    <div
                      className="mb-1 tableMinHeight"
                      key={`Vehicle-route-${tripIndex}-${routeIndex}`}
                    >
                      <LinesEllipsis
                        style={{ whiteSpace: "pre-wrap" }}
                        text={trip.vehicle ? trip.vehicle.regNumber : ""}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </div>
                  );
                })}
              </div>
            );
          });
        },
      },
      {
        title: "Route ID",
        dataIndex: "routeId",
        key: "routeId",
        width: 200,
        render: (text, data, index) => {
          return (data.routes || []).map((route, routeIndex) => {
            return (
              <div
                className="tableMinHeight mb-1"
                key={`route-${index}-${routeIndex}`}
              >
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={route.routeId}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            );
          });
        },
      },
      {
        title: "Location",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className="d-flex justify-content-between mb-2">
              {data.location}
              <div className="p-0">
                <span className="mt-2 color-sky-blue">Track</span>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.setState({ forDownload: true });
              // this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 180,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className="col-6">
                <img
                  src={
                    !data.isSelected
                      ? Images.circleIcon
                      : Images.circleIconSelected
                  }
                  className="img-fluid "
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectStop(index);
                  }}
                />
              </div>
              <div className="col-4 p-0">
                <img src={Images.nextIcon} className="img-fluid " alt="" />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="row m-0 mt-4">
          <div className="col-5  pt-3">
            <Search
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              isShowFilter={this.state.filterData.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "stopName",
                      });
                    }}
                  ></SortContainer>

                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "stopName",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>{" "}
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            <div>
              <Button
                title="Create new stop"
                primary
                rounded
                onClick={() => {
                  this.props.history.push("/school/stop/create");
                }}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                title="Edit"
                white
                rounded
                onClick={this.editStop}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                onClick={() => {
                  // this.deleteStop(deleteStop);
                  this.toggleDeleteModal();
                }}
                title="Delete"
                white
                rounded
              ></Button>
            </div>
          </div>
        </div>
        {this.state.instituteId !== "" && (
          <React.Fragment>
            <Query
              query={GET_STOP_FILTER}
              variables={{
                instituteId: this.state.instituteId,
              }}
              onCompleted={(data) => {
                if (data.createUniqueFiltersForStop) {
                  let filterData = data.createUniqueFiltersForStop.data
                    .map((data) => {
                      if (data.label) {
                        if (data.minValue && data.maxValue) {
                          data.defaultMinValue = data.minValue;
                          data.defaultMaxValue = data.maxValue;
                        }
                        if ((data.fieldValues || []).length) {
                          data.fieldValues = data.fieldValues.map((field) => {
                            return {
                              label: field,
                              isChecked: false,
                            };
                          });
                        }
                        return data;
                      }
                    })
                    .filter((data) => data);
                  //console.log(filterData);
                  this.setState({
                    filterData: filterData, //data.createUniqueFiltersForInstitute.data,
                  });
                }
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>
            {this.state.refetch && (
              <Query
                query={GET_STOP_DETAILS}
                fetchPolicy={"network-only"}
                variables={{
                  instituteId: this.state.instituteId,
                  searchTerm: this.state.searchTerm,
                  sortCol: this.state.sortCol,
                  sort: this.state.sortStr,
                  filter: this.state.currFilter,
                }}
                onCompleted={(data) => {
                  if (data.getStopDetails) {
                    let stopDetails = [];

                    stopDetails = data.getStopDetails.map((data) => {
                      data.isSelected = false;
                      return data;
                    });
                    this.setState({ stopDetails });
                  }
                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ hasErr: true });

                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                }}
              >
                {({ data, loading, error }) => {
                  return <Loading is_loading={loading} />;
                }}
              </Query>
            )}
          </React.Fragment>
        )}
        <div className="col-12 mt-5">
          <Table
            useFixedHeader
            columns={columns}
            data={this.state.stopDetails}
            scroll={{
              x: 1200,
              // y: 350,
              y: "calc(100vh - 140px - 75px - 64px )",
            }}
            emptyText="No Stops Found"
            //title={setTableTitle}
          />

          <DeleteModal
            isDeleteOpen={this.state.isDeleteOpen}
            modelTitle="Delete Stop"
            modelDesc={`Are you sure you want to remove ${
              this.state.selectedId.length <= 1
                ? this.state.selectedStop.stopName
                : `${this.state.selectedId.length} stops`
            } from the Scabbr
        platform?`}
            modelImage={""}
            heading={`Stop Name: ${this.state.selectedStop.stopName} `}
            subHeading={`Stop ID: ${this.state.selectedStop.stopId}`}
            deleteMutation={DELETE_STOP}
            mutationVariables={{ stopIds: this.state.selectedId }}
            selectedIds={this.state.selectedId}
            onMutationCompleted={() => {
              this.setState({ refetch: false }, () => {
                this.setState({ refetch: true });
              });
            }}
            mutationKey="deleteStop"
            toggleDeleteModal={this.toggleDeleteModal}
            modelMsg={`${
              this.state.selectedId.length <= 1
                ? (this.state.selectedStop.stopName || "").toUpperCase()
                : `${this.state.selectedId.length} STOPS`
            } HAS BEEN REMOVED FROM THE PLATFORM`}
            buttonText={"Remove Stop"}
          ></DeleteModal>
          <Modal
            isOpen={this.state.isOpen}
            toggle={this.toggleModal}
            centered
            size="md"
          >
            <React.Fragment>
              <div className="border-bottom pb-3 mb-3 pl-4 pr-4 pt-4">
                <div className="d-flex">
                  <div className="title col-3 p-0">Stop name</div>
                  <div className="semiTitle">{this.state.modalData.stopId}</div>
                </div>
                <div className="d-flex">
                  <div className="title col-3 p-0">Stop ID</div>
                  <div className="semiTitle">
                    {this.state.modalData.stopName}
                  </div>
                </div>
              </div>
              <div className="pl-4 pr-4">
                <div className="d-flex justify-content-between">
                  <div className="listTitle">List of students</div>
                  <div className="semiTitle title small">
                    {`${
                      this.state.modalData.students.length === 0
                        ? this.state.modalData.students.length +
                          "students selected"
                        : ""
                    }`}
                  </div>
                </div>
              </div>
              <div className="pl-4 pr-4">
                {this.state.modalData.students.map((data, index) => {
                  return (
                    <div
                      key={`Students-${index}`}
                      className="d-flex justify-content-between align-items-center mt-2"
                    >
                      <div className="d-flex">
                        <div style={{ width: 60, height: 60 }}>
                          <ReactAvatar
                            name={data.firstName + " " + data.lastName}
                            size="60px"
                            round="8px"
                            src={data.displayPicture}
                          ></ReactAvatar>
                        </div>
                        <div className="pl-3">
                          <div>{`${data.firstName} ${data.lastName}`}</div>
                          <div>
                            {data.class} {data.section}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-end mt-3 pb-2">
                <Button
                  title="Cancel"
                  primaryTransparent
                  rounded
                  onClick={this.toggleModal}
                ></Button>
              </div>
            </React.Fragment>
          </Modal>
        </div>
      </div>
    );
  }
}

export default StopList;
