import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../../Themes/Color";
import Images from "../../Themes/Images";
import { DownloadCSV } from "../../utils";
import Button from "./button";
const FileContainer = styled.div`
  input {
    display: none;
  }
  .uploadContainer {
    width: 100%;
    background: #cfdbfd;
    border-radius: 7px;
    padding: 10px;
    cursor: pointer;
  }
  .uploadContainerBorder {
    border: 1px solid;
    border-color: ${colors.colorBlue};
    border-style: dashed;
    border-radius: 5px;
    padding: 10px;
  }
  .textTitle {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 17px;
    color: ${colors.colorBlue};
  }
`;
export class FileUpload extends Component {
  onChange = (e) => {
    // console.log(e.target);
    const latestFile = this.inputElement.files[
      this.inputElement.files.length - 1
    ];
    if (typeof FileReader != "undefined") {
      var reader = new FileReader();
      reader.onload = (e) => {
        var rows = e.target.result.split("\n");
        this.props.onFileUpload(rows);
      };
      reader.readAsText(latestFile);
    } else {
      alert("This browser does not support HTML5.");
    }
  };
  render() {
    return (
      <FileContainer>
        <input
          className=""
          type="file"
          accept=".csv"
          onChange={this.onChange}
          ref={(input) => (this.inputElement = input)}
        ></input>
        <div
          className="uploadContainer"
          onClick={() => {
            this.inputElement.click();
          }}
        >
          <div className="uploadContainerBorder d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <img src={Images.AddIcon} alt="icon" />
              <div className="ml-2 textTitle">Upload file</div>
            </div>
            <div className="textTitle mr-3">Excel/Csv</div>
          </div>
        </div>
        <div className="mt-2 text-description d-flex align-items-center justify-content-between">
          <span>
            {this.props.count
              ? `${this.props.count} ${this.props.data} found`
              : ""}
          </span>
          <span>
            {(this.props.errData || []).length !== 0 && (
              <div
                className="color-red pointer-cursor"
                onClick={() => {
                  DownloadCSV(this.props.data + "-err-log", this.props.errData);
                }}
              >
                Download error log
              </div>
            )}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <Button
            onClick={this.props.onHandleSubmit}
            title="Create signup"
            primary
            rounded
          ></Button>
        </div>
      </FileContainer>
    );
  }
}

export default FileUpload;
