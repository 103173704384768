import moment from "moment";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import DateSelector from "../../../Component/DateSelector";
import Button from "../../../Component/Form/button";
import ReactFile from "../../../Component/Form/file";
import ReactInput from "../../../Component/Form/input";
import Loading from "../../../Component/Loading";
import { generateRandomString, sendNotification } from "../../../utils";
import { isMobileNumber } from "../../../validator";
import {
  CREATE_DRIVER,
  GET_DRIVER_DETAILS_ID,
  UPDATE_DRIVER,
} from "../../graphql";

let FormRef, FormRef1;

const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export class CreateDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CreateDriver: {
        profilePicture: "",
        fname: "",
        lname: "",
        dob: moment().format("YYYY/MM/DD"),
        mobileNo: "",
        bGrp: "",
        doj: moment().format("YYYY/MM/DD"),
        validUpto: moment().format("YYYY/MM/DD"),
        drivingLicense: "",
        licenseNo: "",
        aadharCard: "",
        aadharCardNo: "",
      },
      dataVariables: {},
      activeTab: this.props.history.location.search.split("=")[1]
        ? this.props.history.location.search.split("=")[1]
        : "1",
      fileInfo: {
        profilePicture: "",
        drivingLicense: "",
        aadharCard: "",
      },

      formSubmit: false,
      handleForm1: false,
      handleForm2: false,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    // refer
    this.setState({ gpwd: generateRandomString(7), instituteId });
  }
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const CreateDriver = { ...this.state.CreateDriver };
    CreateDriver[key] = value;

    this.setState({ CreateDriver });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  // onNextButton = () => {
  //   this.setActiveTab(parseInt(this.state.activeTab) + 1);
  // };
  onNextButton = () => {
    // this.setActiveTab(parseInt(this.state.activeTab) + 1);
    if (this.state.activeTab === "1") {
      this.setState({ handleForm1: true }, () => {
        this.onNextButtonValidate(FormRef);
      });
    }
    if (this.state.activeTab === "2") {
      this.setState({ handleForm2: true }, () => {
        this.onNextButtonValidate(FormRef1);
      });
    }
  };
  onNextButtonValidate = (Form) => {
    Form.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll(".form-error").length === 0) {
        if (document.querySelectorAll(".inputRequired").length === 0) {
          // ContactOrganizer();
          // func();
          // this.onSetVariables(func);
          this.setActiveTab(parseInt(this.state.activeTab) + 1);
          // this.props.changePage();
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      } else {
        // this.setActiveTab(1);
        sendNotification({
          message: "Please fill all the fields",
          type: "danger",
        });
      }
    }, 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/school/drivers");
    }
  };
  onChange = (key, value) => {
    const CreateDriver = { ...this.state.CreateDriver };
    CreateDriver[key] = value;
    this.setState({ CreateDriver });
  };
  handleSubmit = (func) => {
    this.setState({ handleForm2: true }, () => {
      FormRef1.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            this.setDataVariables(func);
            // this.props.changePage();
          } else {
            // this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  setDataVariables = (func) => {
    const dataVariables = {
      // id: this.props.query.id,
      firstName: this.state.CreateDriver.fname,
      lastName: this.state.CreateDriver.lname,
      displayPicture: this.state.CreateDriver.profilePicture,
      dob: this.state.CreateDriver.dob,
      // this.state.CreateDriver.yearOfBirth +
      // "/" +
      // this.state.CreateDriver.monthOfBirth +
      // "/" +
      // this.state.CreateDriver.dateOfBirth,
      mobileNumber: this.state.CreateDriver.mobileNo,
      bloodGroup: this.state.CreateDriver.bGrp,
      doj: this.state.CreateDriver.doj,

      drivingLicense: this.state.CreateDriver.drivingLicense,
      licenseNo: this.state.CreateDriver.licenseNo,
      validUpto: this.state.CreateDriver.validUpto,
      aadharCard: this.state.CreateDriver.aadharCard,
      aadharNo: this.state.CreateDriver.aadharCardNo,
      userToken: this.state.CreateDriver.userToken,
    };
    if (this.props.match.params.id) {
      dataVariables.id = this.props.match.params.id;
    } else {
      dataVariables.instituteId = localStorage.getItem("institueId");
    }

    this.setState({ dataVariables }, () => {
      func();
    });
  };
  fileInfo = (key, value) => {
    const fileInfo = { ...this.state.fileInfo };
    fileInfo[key] = value;
    this.setState({ fileInfo });
  };
  render() {
    return (
      <SchoolContainer className="pl-3">
        {this.props.match.params.id && (
          <Query
            variables={{ id: this.props.match.params.id }}
            query={GET_DRIVER_DETAILS_ID}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (data.getDriverById) {
                let CreateDriver = {
                  profilePicture: data.getDriverById.displayPicture,
                  fname: data.getDriverById.firstName,
                  lname: data.getDriverById.lastName,
                  dob: data.getDriverById.dob,
                  imageURL: data.getDriverById.displayPicture,
                  drivingLicense: data.getDriverById.drivingLicense,
                  // dateOfBirth: new Date(data.getDriverById.dob).getDate(),
                  // monthOfBirth: new Date(data.getDriverById.dob).getMonth() + 1,
                  // yearOfBirth: new Date(data.getDriverById.dob).getFullYear(),
                  mobileNo: data.getDriverById.mobileNumber,
                  bGrp: data.getDriverById.bloodGroup,
                  doj: data.getDriverById.doj,
                  validUpto: data.getDriverById.validUpto,

                  licenseNo: data.getDriverById.licenseNo,
                  aadharCard: data.getDriverById.aadharLink,
                  aadharCardNo: data.getDriverById.aadharNo,
                  activeTab: "1",
                };
                // let fileInfo = data.getDriverById.displayPicture
                //   ? { profilePicture: { name: "dp.png" } }
                //   : {};
                let fileInfo = {
                  profilePicture: {
                    name: "dp.png",
                    img: data.getDriverById.displayPicture,
                  },
                  aadharCard: {
                    name: "aadhar.png",
                    img: data.getDriverById.aadharCard,
                  },
                  drivingLicense: {
                    name: "license.png",
                    img: data.getDriverById.drivingLicense,
                  },
                };

                this.setState({ CreateDriver, fileInfo }, () => {});

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <div className="menu mt-4 pt-3">
          <div
            className={`menuItem ${
              this.state.activeTab === "1" ? "active" : ""
            }`}
          >
            Personal details
          </div>
          <div
            className={`menuItem ${
              this.state.activeTab === "2" ? "active" : ""
            }`}
          >
            Documents
          </div>
        </div>
        <div className="col-9 pl-4 pr-4 pt-4">
          <Mutation
            variables={{
              data: this.state.dataVariables,
            }}
            mutation={
              this.props.match.params.id ? UPDATE_DRIVER : CREATE_DRIVER
            }
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });

              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.createDriver) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Driver created successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/drivers");
                }, 500);
                // message.success("Contact Created!!");
              }
              if (data.updateDriver) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Driver updated successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/drivers");
                }, 500);
                // message.success("Contact Created!!");
              }
            }}
          >
            {(CreateDriver, { data, loading, error }) => {
              return (
                <div>
                  <Loading is_loading={loading} />
                  {this.state.activeTab && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "1" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                        className={` ${
                          this.state.handleForm1 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Driver image</div>
                          <div className="col-9">
                            <ReactFile
                              placeholder=""
                              white
                              header="Display picture"
                              multiple={false}
                              required
                              uploadFor={"Drivers"}
                              value={this.state.fileInfo.profilePicture}
                              imageURL={this.state.CreateDriver.profilePicture}
                              token={{
                                userToken: this.state.CreateDriver.userToken,
                                insToken: this.state.instituteId,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("userToken", userToken);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("profilePicture", value);
                              }}
                              onDelete={() => {
                                this.onChange("profilePicture", "");
                                this.fileInfo("profilePicture", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "profilePicture",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">First name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateDriver.fname}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("fname", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Last name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateDriver.lname}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("lname", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Date of birth </div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={this.state.CreateDriver.dob}
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "dob",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                              dateObj={{
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: "select",
                              }}
                            ></DateSelector>
                            {/**

                           <div className="col-2 pl-0">
                              <NumericalInput
                                value={this.state.CreateDriver.dateOfBirth}
                                max="31"
                                min="1"
                                onChange={value => {
                                  this.onChange("dateOfBirth", value);
                                }}
                                padString="00"
                              ></NumericalInput>
                            </div>
                            <div className="col-2 pl-0">
                              <NumericalInput
                                value={this.state.CreateDriver.monthOfBirth}
                                max="12"
                                min="1"
                                padString="00"
                                onChange={value => {
                                  this.onChange("monthOfBirth", value);
                                }}
                              ></NumericalInput>
                            </div>
                            <div className="col-3 pl-0">
                              <NumericalInput
                                max={new Date().getFullYear()}
                                min="1950"
                                value={this.state.CreateDriver.yearOfBirth}
                                padString="0000"
                                onChange={value => {
                                  this.onChange("yearOfBirth", value);
                                }}
                              ></NumericalInput>
                            </div>
                              */}
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Mobile number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              validation={[isMobileNumber]}
                              value={this.state.CreateDriver.mobileNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("mobileNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Blood group</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateDriver.bGrp}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("bGrp", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "2" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "2" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef1 = ref;
                        }}
                        className={` ${
                          this.state.handleForm2 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Date of joining </div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={this.state.CreateDriver.doj}
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "doj",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                              dateObj={{
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: "select",
                              }}
                            ></DateSelector>
                            {/*
                            <div className="col-2 pl-0">
                              <NumericalInput
                                max="31"
                                min="1"
                                value={this.state.CreateDriver.dateOfJoining}
                                padString="00"
                                onChange={(value) => {
                                  this.onChange("dateOfJoining", value);
                                }}
                              ></NumericalInput>
                            </div>
                            <div className="col-2 pl-0">
                              <NumericalInput
                                max="12"
                                min="1"
                                value={this.state.CreateDriver.monthOfJoining}
                                padString="00"
                                onChange={(value) => {
                                  this.onChange("monthOfJoining", value);
                                }}
                              ></NumericalInput>
                            </div>
                            <div className="col-3 pl-0">
                              <NumericalInput
                                max={new Date().getFullYear()}
                                min="1950"
                                value={this.state.CreateDriver.yearOfJoining}
                                padString="0000"
                                onChange={(value) => {
                                  this.onChange("yearOfJoining", value);
                                }}
                              ></NumericalInput>
                              </div>*/}
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Driving license</div>
                          <div className="col-9">
                            <ReactFile
                              placeholder=""
                              white
                              aspect={16 / 9}
                              header="Driving License"
                              multiple={false}
                              required
                              uploadFor={"Drivers"}
                              value={this.state.fileInfo.drivingLicense}
                              imageURL={this.state.CreateDriver.drivingLicense}
                              token={{
                                userToken: this.state.CreateDriver.userToken,
                                insToken: this.state.instituteId,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("userToken", userToken);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("drivingLicense", value);
                              }}
                              onDelete={() => {
                                this.onChange("drivingLicense", "");
                                this.fileInfo("drivingLicense", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "drivingLicense",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                            {/* <ReactFile
                              placeholder=""
                              white
                              // required
                              value={this.state.CreateDriver.drivingLicense}
                              className={"w-100"}
                              onChange={value => {
                                this.onChange("drivingLicense", value);
                              }}
                            ></ReactFile> */}
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">License number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateDriver.licenseNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("licenseNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Valid upto</div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={this.state.CreateDriver.validUpto}
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "validUpto",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                              dateObj={{
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: "select",
                              }}
                            ></DateSelector>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Aadhar card</div>
                          <div className="col-9">
                            <ReactFile
                              placeholder=""
                              white
                              header="Aadhar card"
                              aspect={16 / 9}
                              multiple={false}
                              required
                              uploadFor={"Drivers"}
                              value={this.state.fileInfo.aadharCard}
                              imageURL={this.state.CreateDriver.aadharCard}
                              token={{
                                userToken: this.state.CreateDriver.userToken,
                                insToken: this.state.instituteId,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("userToken", userToken);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("aadharCard", value);
                              }}
                              onDelete={() => {
                                this.onChange("aadharCard", "");
                                this.fileInfo("aadharCard", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "aadharCard",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                            {/* <ReactFile
                              placeholder=""
                              white
                              // required
                              value={this.state.CreateDriver.aadharCardImage}
                              className={"w-100"}
                              onChange={value => {
                                this.onChange("aadharCardImage", value);
                              }}
                            ></ReactFile> */}
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Aadhar number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              // validation={[isMobileNumber]}
                              value={this.state.CreateDriver.aadharCardNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("aadharCardNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}

                  <div className="mt-2 mb-5 pt-4 d-flex align-items-center justify-content-end">
                    <Button
                      primaryTransparent
                      buttonClass="mr-4"
                      title="Back"
                      disabled={this.state.activeTab === "1"}
                      onClick={this.onBackButton}
                    ></Button>
                    <div className="d-inline">
                      <Button
                        title={`${
                          this.state.activeTab === "2"
                            ? this.props.match.params.id
                              ? "Update driver"
                              : "Create new driver"
                            : "Next"
                        }`}
                        primary
                        rounded
                        onClick={() => {
                          if (this.state.activeTab === "2") {
                            this.handleSubmit(CreateDriver);
                          } else {
                            this.onNextButton();
                          }
                          // this.handleSubmit(CreateDriver);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Mutation>
        </div>
      </SchoolContainer>
    );
  }
}

export default CreateDriver;
