import React from "react";
import { Query } from "react-apollo";
import { Redirect, Route } from "react-router-dom";
import styled from "styled-components";
import { ADMIN_LOGIN_CHECK } from "../Application/graphql";
import School from "../Component/Dashboard/school";
import Loading from "../Component/Loading";
import Nav from "../Component/Nav";
import { colors } from "../Themes/Color";
// import ReactGA from 'react-ga';
import { getToken } from "../utils";

const ComponentContainer = styled.div`
  height: calc(100vh - 65px);
  overflow: scroll;
  background: ${colors.defaultBackgroundColor};
  position: relative;
`;
const RouteContainer = styled.div`
  & > .menuBar {
    width: 180px;
    height: 100vh;
    overflow: scroll;
  }
  & > .componentArea {
    width: calc(100% - 180px);
  }
`;
// class GAComponent extends React.Component{

//   componentDidUpdate() {
//     this.props.history.listen(location => {
//       ReactGA.set({ page: location.pathname });
//       ReactGA.pageview(location.pathname);
//     });
//   }

//   render() {
//     const { Component } = this.props;
//     return (
//       <Component {...this.props} />
//     );
//   }
// }

const SchoolAuth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() ? (
        <RouteContainer className="row m-0">
          <Query
            query={ADMIN_LOGIN_CHECK}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              // setUserProfile(data.adminLoginCheck);
              if (!data.adminLoginCheck) {
                localStorage.clear();
                props.history.push(`/`);
              }
            }}
            onError={() => {
              // localStorage.clear();
              props.history.push(`/`);
            }}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading is_loading={loading}></Loading>;
              }
              if (error) {
                return <div></div>;
              }
              return (
                <React.Fragment>
                  <div className="p-0 menuBar">
                    <School
                      {...props}
                      user={(data || {}).adminLoginCheck}
                    ></School>
                  </div>
                  <div className=" p-0 componentArea">
                    <Nav
                      {...props}
                      hideAdmin
                      title={rest.title}
                      user={(data || {}).adminLoginCheck}
                      userLoading={loading}
                    />
                    <ComponentContainer>
                      <Component
                        {...props}
                        user={(data || {}).adminLoginCheck}
                        userLoading={loading}
                      />
                    </ComponentContainer>
                  </div>
                </React.Fragment>
              );
            }}
          </Query>

          {/* <GAComponent Component={Component} {...props} /> */}
        </RouteContainer>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default SchoolAuth;
