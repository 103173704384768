import gql from "graphql-tag";

export const USER_LOGIN = gql`
  mutation adminSignIn($email: String!, $password: String!) {
    adminSignIn(emailId: $email, password: $password) {
      token
      user {
        role
        rolePriority
        institute {
          id
        }
      }
    }
  }
`;
export const ADMIN_LOGIN_CHECK = gql`
  query adminLoginCheck {
    adminLoginCheck {
      id
      firstName
      lastName
      displayPicture
      emailId
      mobileNumber
      rolePriority
      role
      institute {
        id
        logo
        name
        totalStudents
        totalBus
        totalDrivers
      }
    }
  }
`;
export const GET_TASK_DETAILS = gql`
  query getTaskDetails {
    getAdminDetails {
      id
      firstName
      lastName
    }
    getInstituteDetailsList {
      id
      name
      address
    }
  }
`;
export const ADMIN_DASHBOARD = gql`
  query AdminDashboard {
    getDashboardData {
      noOfUsers
      noOfSchools
      noOfDrivers
      noOfVehicles
      tasks {
        id
        institute {
          name
        }
        assignTo {
          id
          firstName
        }
        insContactNo
        insContactPerson
        status
        description
        insName
        insAddress
        dueDate
      }
      subscriptions {
        name
        subscription
        validUpto
        principalName
        contactNo
      }
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation sendResetPasswordLink($email: String!) {
    sendResetPasswordLink(emailId: $email)
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetAdminPassword($token: String!, $newPassword: String!) {
    resetAdminPassword(token: $token, newPassword: $newPassword) {
      id
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation createAdminUser($data: AdminCreateInput!) {
    createAdminUser(data: $data) {
      id
    }
  }
`;
export const UPDATE_ADMIN = gql`
  mutation updateAdminUser($data: AdminUpdateInput!) {
    updateAdminUser(data: $data) {
      id
    }
  }
`;
export const DELETE_ADMIN_USER = gql`
  mutation deleteAdminUser($userId: String!) {
    deleteAdminUser(userId: $userId)
  }
`;
export const GET_ADMIN_DETAILS = gql`
  query getAdminDetails {
    getAdminDetails {
      id
      firstName
      lastName
      displayPicture
      mobileNumber
      userName
      status
      location
      rolePriority
      emailId
      state
      city
      isCurrentUser
    }
  }
`;
export const GET_ADMIN_DETAILS_ID = gql`
  query getAdminById($id: String!) {
    getAdminById(id: $id) {
      id
      firstName
      lastName
      displayPicture
      mobileNumber
      userName
      status
      location
      rolePriority
      emailId
      GPlaceID
      userName
      state
      city
      isCurrentUser
      userToken
    }
  }
`;

export const CREATE_SCHOOL = gql`
  mutation createInstitute($data: createInstituteInput!) {
    createInstitute(data: $data) {
      id
    }
  }
`;
export const DELETE_SCHOOL = gql`
  mutation deleteInstitute($instituteId: String!) {
    deleteInstitute(instituteId: $instituteId)
  }
`;
export const UPDATE_SCHOOL = gql`
  mutation updateInstitute($data: InstituteUpdateInput!) {
    updateInstitute(data: $data) {
      id
    }
  }
`;
export const GET_INSTITUTE_FILTERS = gql`
  query InstituteFilters {
    createUniqueFiltersForInstitute {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const DOWNLOAD_INSTITUTE_DETAILS = gql`
  query getInstituteDetailsList(
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getInstituteDetailsList(
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      id
      name
      principalName
      principalContact
      address
      website
      emailId
      totalStudents
      totalDrivers
      totalBus
      noOfStudents
    }
  }
`;
export const DOWNLOAD_SUBSCRIPTION_DETAILS = gql`
  query getInstituteDetailsList(
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getInstituteDetailsList(
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      id
      name
      principalName
      principalContact
      address
      website
      emailId
      noOfStudents
      costPerStudent
      validFrom
      validUpto
      plan
    }
  }
`;
export const GET_PENDING_LIMITS = gql`
  query getPendingLimitRequests($instituteId: String!) {
    getPendingLimitRequests(instituteId: $instituteId) {
      id
      limit
      isNotified
      status
      createdAt
      institute {
        id
        name
        coverImage
      }
    }
  }
`;
export const UPDATE_INSTITUTE_LIMIT = gql`
  mutation updateInstituteLimit($instituteId: String!, $limit: Int) {
    updateInstituteLimit(instituteId: $instituteId, limit: $limit)
  }
`;
export const GET_INSTITUTE_DETAILS = gql`
  query getInstituteDetailsList(
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getInstituteDetailsList(
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      id
      name
      plan
      contact
      emailId
      contactNo
      costPerStudent
      totalStudents
      address
      validUpto
      validity
      noOfStudents
    }
  }
`;
export const GET_SIGNED_URL = gql`
  mutation getSignedUrl(
    $data: [fileInput]!
    $userToken: String
    $insToken: String
    $uploadFor: S3UploadFor!
  ) {
    getSignedUrl(
      data: $data
      userToken: $userToken
      insToken: $insToken
      uploadFor: $uploadFor
    ) {
      signedUrl
      insToken
      userToken
    }
  }
`;
export const GET_INSTITUTE_DETAILS_ID = gql`
  query getInstituteById($id: String!) {
    getInstituteById(id: $id) {
      id
      name
      coverImage
      logo
      userName
      syllabus
      billingName
      subscription
      validFrom
      validUpto
      contactNo
      website
      address
      principalName
      transportOfficer
      principalContact
      emailId
      description
      noOfStudents
      costPerStudent
      totalStudents
      totalBus
      insToken
      totalDrivers
      alternatePhone
      alternateEmail
      isActive
    }
  }
`;
export const CREATE_DRIVER = gql`
  mutation createDriver($data: DriverCreateInput) {
    createDriver(data: $data) {
      id
    }
  }
`;
export const UPDATE_DRIVER = gql`
  mutation updateDriver($data: DriverUpdateInput) {
    updateDriver(data: $data) {
      id
    }
  }
`;
export const DELETE_DRIVER = gql`
  mutation deleteDriver($driverIds: [String]!, $instituteId: String!) {
    deleteDriver(driverIds: $driverIds, instituteId: $instituteId)
  }
`;
export const GET_DRIVER_FILTER = gql`
  query DriverFilters($instituteId: String!) {
    createUniqueFiltersForDriver(instituteId: $instituteId) {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;
export const GET_DRIVER_DETAILS = gql`
  query getDriverDetails(
    $instituteId: String!
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getDriverDetails(
      instituteId: $instituteId
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      firstName
      lastName
      id
      mobileNumber
      aadharLink
      drivingLicense
      displayPicture
      licenseNo
      aadharNo
      # route
      # busNumber
      #coordinate
      license
      # trips {
      #   route {
      #     routeName
      #   }
      #   vehicle {
      #     regNumber
      #     location
      #   }
      # }
      schedule {
        # if schedule for the day available
        route {
          routeId
          routeName
        }
        vehicle {
          vehicleId
          regNumber
        }
        isActive
      }
      trips {
        ## default mapping
        route {
          routeId
          routeName
          schedule {
            isActive
            delayedTimeInMins
          }
        }
        vehicle {
          regNumber
        }
      }
    }
  }
`;
export const GET_DRIVER_BY_ID = gql`
  query getDriverById($id: String!) {
    getDriverById(id: $id) {
      id
      firstName
      lastName
      displayPicture
      dob
      mobileNumber
      bloodGroup
      doj
      drivingLicense
      licenseNo

      validUpto
      aadharLink
      aadharNo
      addressProof
      isActive
      trips {
        tripName
        tripId
        routeId
        vehicleId
        route {
          type
          routeId
        }
      }
      schedule {
        tripType
        vehicle {
          vehicleId
        }
        route {
          routeId
        }
      }
    }
  }
`;
export const GET_DRIVER_DETAILS_ID = gql`
  query getDriverById($id: String!) {
    getDriverById(id: $id) {
      id
      firstName
      lastName
      displayPicture
      dob
      mobileNumber
      bloodGroup
      doj
      drivingLicense
      licenseNo
      validUpto
      aadharLink
      aadharNo
      isActive
    }
  }
`;
export const CREATE_STUDENT = gql`
  mutation createStudent($data: StudentInput) {
    createStudent(data: $data) {
      id
    }
  }
`;
export const UPDATE_STUDENT = gql`
  mutation updateStudent($data: StudentInput) {
    updateStudent(data: $data) {
      id
    }
  }
`;
export const DELETE_STUDENT = gql`
  mutation deleteStudent($studentIds: [String]!, $instituteId: String!) {
    deleteStudent(studentIds: $studentIds, instituteId: $instituteId)
  }
`;
export const CREATE_INSTITUTE_LIMIT_REQUEST = gql`
  mutation createInstituteLimitRequest($instituteId: String!, $limit: Int) {
    createInstituteLimitRequest(instituteId: $instituteId, limit: $limit)
  }
`;
export const GET_STUDENT_FILTER = gql`
  query StudentFilters($instituteId: String!) {
    createUniqueFiltersForStudent(instituteId: $instituteId) {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const GET_STUDENT_DETAILS = gql`
  query getStudentDetails(
    $instituteId: String!
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getStudentDetails(
      instituteId: $instituteId
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      firstName
      lastName
      class
      QRkey
      section
      id
      studentId
      displayPicture
      dob
      bloodGroup
      address
      institute {
        id
        noOfStudents
        totalStudents
      }
      fatherName
      fatherMobileNo
      fatherOccupation
      motherName
      motherMobileNo
      motherOccupation

      # father {
      #   name
      #   mobileNumber
      # }
      # mother {
      #   name
      #   mobileNumber
      # }

      stop {
        stopName
      }

      trip {
        status
      }

      routes {
        timings
        routeId
        routeName
        trips {
          vehicle {
            id
            vehicleId
          }
        }

        schedule {
          id
          currentLocation
          isActive
        }
      }
    }
  }
`;
// export const DOWNLOAD_STUDENT_DETAILS = gql`

// `;
export const GET_STUDENT_BY_ID = gql`
  query getStudentById($id: String!) {
    getStudentById(id: $id) {
      studentId
      displayPicture
      firstName
      lastName
      class
      section
      dob
      bloodGroup
      address
      fatherName
      fatherOccupation
      fatherMobileNo
      pickUpStop
      QRkey
      institute {
        id
        validUpto
        validity
        plan
        subscription
      }
      drop {
        stopName
        stopId
      }
      stop {
        stopName
        stopId
      }
      motherName
      motherOccupation
      motherMobileNo
    }
  }
`;
export const GET_STUDENT_DETAILS_BY_ID = gql`
  query getStudentById($id: String!) {
    getStudentById(id: $id) {
      studentId
      displayPicture
      firstName
      QRkey
      lastName
      class
      section
      dob
      bloodGroup
      address
      fatherName
      fatherOccupation
      fatherMobileNo

      motherName
      motherOccupation
      motherMobileNo
    }
  }
`;

export const CREATE_VEHICLE = gql`
  mutation createVehicle($data: VehicleCreateInput) {
    createVehicle(data: $data) {
      id
    }
  }
`;
export const UPDATE_VEHICLE = gql`
  mutation updateVehicle($data: VehicleUpdateInput) {
    updateVehicle(data: $data) {
      id
    }
  }
`;
export const DELETE_VEHICLE = gql`
  mutation deleteVehicle($vehicleIds: [String]!, $instituteId: String!) {
    deleteVehicle(vehicleIds: $vehicleIds, instituteId: $instituteId)
  }
`;
export const GET_VEHICLE_FILTERS = gql`
  query VehicleFilters($instituteId: String!) {
    createUniqueFiltersForDriver(instituteId: $instituteId) {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const GET_VEHICLE_DETAILS_BY_ID = gql`
  query getVehicleById($id: String!) {
    getVehicleById(id: $id) {
      vehicleId
      type
      manufacture
      regNumber
      capacity
      rcbook
      rcValidUpto
      regDate
      insurance
      insValidUpto
      QRkey
      location
    }
  }
`;
export const GET_PLACES = gql`
  mutation myPlaces($data: SearchPlaces) {
    getPlaces(data: $data) {
      placename
      subtitle
      placeid
    }
  }
`;
export const GET_LAT_LONG = gql`
  mutation placeLat($data: LatLongInput) {
    getLatLong(data: $data) {
      lat
      lng
      phone
      name
      address
    }
  }
`;
export const GET_VEHICLE_DETAILS = gql`
  query getVehicleDetails(
    $instituteId: String!
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getVehicleDetails(
      instituteId: $instituteId
      searchTerm: $searchTerm
      sortCol: $sortCol
      filter: $filter
      sort: $sort
    ) {
      id
      vehicleId
      regNumber
      capacity
      QRkey
      trips {
        routeId
        driver {
          firstName
          lastName
          mobileNumber
        }
        route {
          timings
          routeName
          routeId
          stops {
            id
            stopName
            stopId
          }
          students {
            id
            firstName
            lastName
            class
            section
          }
        }
      }
      schedule {
        driver {
          firstName
          lastName
          mobileNumber
        }
        route {
          timings
          routeName
          routeId
          stops {
            id
            stopName
            stopId
          }
          students {
            id
            firstName
            lastName
            class
            section
          }
        }
      }
      rcbook
      insurance
      location
    }
  }
`;
export const GET_STUDENTS_FOR_SHOP = gql`
  query getStudentsForStop(
    $instituteId: String!
    $stopId: String
    $routeId: String
  ) {
    getStudentsForStop(
      instituteId: $instituteId
      stopId: $stopId
      routeId: $routeId
    ) {
      studentId
      id
      QRkey
      firstName
      lastName
    }
  }
`;
export const CREATE_STOP = gql`
  mutation createStop($data: StopCreateInput) {
    createStop(data: $data) {
      id
    }
  }
`;
export const UPDATE_STOP = gql`
  mutation updateStop($data: StopUpdateInput) {
    updateStop(data: $data) {
      id
    }
  }
`;
export const DELETE_STOP = gql`
  mutation deleteStop($stopIds: [String]!) {
    deleteStop(stopIds: $stopIds)
  }
`;
export const GET_STOP_FILTER = gql`
  query StopFilters($instituteId: String!) {
    createUniqueFiltersForStop(instituteId: $instituteId) {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const GET_STOP_DETAILS = gql`
  query getStopDetails(
    $instituteId: String!
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getStopDetails(
      instituteId: $instituteId
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      stopId
      id
      stopName
      radius
      routes {
        routeId
        trips {
          vehicle {
            regNumber
            location
          }
          driver {
            firstName
            lastName
            mobileNumber
          }
        }
      }
      students {
        id
        firstName
        lastName
        class
        section
        displayPicture
      }
    }
  }
`;
export const GET_STOP_BY_ID = gql`
  query getStopById($id: String!) {
    getStopById(id: $id) {
      id
      stopId
      stopName
      radius
      coordinates
      students {
        id
        studentId
        firstName
        lastName
      }
    }
  }
`;
export const CREATE_ROUTE = gql`
  mutation createRoute($data: RouteCreateInput) {
    createRoute(data: $data) {
      id
    }
  }
`;
export const UPDATE_ROUTE = gql`
  mutation updateRoute($data: RouteUpdateInput) {
    updateRoute(data: $data) {
      id
    }
  }
`;
export const DELETE_ROUTE = gql`
  mutation deleteRoute($routeIds: [String]!) {
    deleteRoute(routeIds: $routeIds)
  }
`;
export const GET_ROUTE_FILTER = gql`
  query RouteFilters($instituteId: String!) {
    createUniqueFiltersForRoute(instituteId: $instituteId) {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const GET_ROUTE_DETAILS = gql`
  query getRouteDetails(
    $instituteId: String!
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getRouteDetails(
      instituteId: $instituteId
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      id
      routeId
      routeName
      type
      timings
      stops {
        id
        stopName
        stopId
      }
      students {
        id
        firstName
        lastName
        class
        section
      }
      trips {
        vehicle {
          regNumber
          location
        }
        driver {
          firstName
          lastName
          mobileNumber
        }
      }
    }
  }
`;
export const GET_ROUTE_BY_ID = gql`
  query getRouteById($id: String!) {
    getRouteById(id: $id) {
      timings
      routeName
      routeId
      type
      stops {
        id
        stopId
        stopName
        students {
          id

          firstName
        }
      }
      students {
        firstName
        lastName
        section
        class
        id
        pickUpStop
        stop {
          id
        }
      }
    }
  }
`;
export const GET_STOPS_FOR_ROUTE = gql`
  query getStopsForRoute($instituteId: String) {
    getStopsForRoute(instituteId: $instituteId) {
      stopName
      stopId
      id
      students {
        id
        firstName
        lastName
        class
        displayPicture
        section
      }
    }
  }
`;
export const GET_STUDENT_FOR_STOP = gql`
  query getStudentsForStop($instituteId: String!) {
    getStudentsForStop(instituteId: $instituteId) {
      id
      studentId
      firstName
      lastName
      class
      section
      stop {
        stopName
        stopId
      }
    }
  }
`;
export const CREATE_TRIP = gql`
  mutation createTrip($data: TripCreateInput) {
    createTrip(data: $data) {
      id
    }
  }
`;
export const UPDATE_TRIP = gql`
  mutation updateTrip($data: TripUpdateInput) {
    updateTrip(data: $data) {
      id
    }
  }
`;
export const DELETE_TRIP = gql`
  mutation deleteTrip($tripIds: [String]!) {
    deleteTrip(tripIds: $tripIds)
  }
`;
export const GET_TRIP_DETAILS = gql`
  query getTripDetails(
    $instituteId: String!
    $searchTerm: String
    $sort: String
    $sortCol: String
    $filter: [FilterInput]
  ) {
    getTripDetails(
      instituteId: $instituteId
      searchTerm: $searchTerm
      sort: $sort
      sortCol: $sortCol
      filter: $filter
    ) {
      tripId
      id
      route {
        routeName
        routeId
        type
        stops {
          id
          stopName
          stopId
        }
        students {
          id
          firstName
          lastName
          class
          section
        }
      }
      vehicle {
        regNumber
        location
      }
      driver {
        firstName
        lastName
        mobileNumber
      }
    }
  }
`;
export const GET_TRIP_BY_ID = gql`
  query getTripById($id: String!) {
    getTripById(id: $id) {
      tripName
      tripId
      routeId
      vehicleId
      driverId
    }
  }
`;
export const GET_TRIP_FILTER = gql`
  query TripFilters($instituteId: String!) {
    createUniqueFiltersForTrip(instituteId: $instituteId) {
      data {
        fieldName
        label
        fieldValues
        minValue
        maxValue
      }
    }
  }
`;
export const GET_TRIP_DATA = gql`
  query getTripData($instituteId: String!) {
    getVehiclesForInstitute(instituteId: $instituteId) {
      vehicleId
      id
      regNumber
    }
    getDriversForInstitute(instituteId: $instituteId) {
      firstName
      lastName
      id
    }
    getRoutesForInstitute(instituteId: $instituteId) {
      routeName
      id
    }
  }
`;
export const DRIVER_BULK_SIGNUP = gql`
  mutation driverBulkImport($data: [DriverCreateInput]!) {
    driverBulkImport(data: $data)
  }
`;
export const VEHICLE_BULK_SIGNUP = gql`
  mutation vehicleBulkImport($data: [VehicleCreateInput]!) {
    vehicleBulkImport(data: $data)
  }
`;
export const STUDENT_BULK_SIGNUP = gql`
  mutation studentBulkImport($data: [StudentInput]!, $instituteId: String!) {
    studentBulkImport(data: $data, instituteId: $instituteId)
  }
`;
export const CREATE_TASK = gql`
  mutation createTask($data: TaskCreateInput) {
    createTask(data: $data) {
      id
    }
  }
`;
export const UPDATE_TASK = gql`
  mutation updateTask($data: TaskUpdateInput) {
    updateTask(data: $data) {
      id
    }
  }
`;
export const DELETE_TASK = gql`
  mutation deleteTask($taskId: String!) {
    deleteTask(taskId: $taskId) {
      id
    }
  }
`;
export const GET_BIRDS_VIEW = gql`
  query BirdsView($instituteId: String!) {
    getBirdsView(instituteId: $instituteId) {
      firstName
      lastName
      mobileNumber
      schedule {
        id
        currentLocation
        scheduledTime
        route {
          routeId
          stops {
            id
            stopId
            stopName
          }
        }
        trip {
          id
          tripId
        }
        path {
          id
          reachTime
          stop {
            stopName
          }
        }
        vehicle {
          vehicleId
          regNumber
        }
      }
    }
  }
`;
export const GET_INSTITUTE_DASHBOARD_DATA = gql`
  query getInstituteDashboardData($instituteId: String!, $limit: Int) {
    getInstituteDashboardData(instituteId: $instituteId, limit: $limit) {
      institute {
        name
        subscription
        totalStudents
        noOfStudents
        validUpto
        coverImage
        isActive
      }
      upcoming {
        id
        route {
          routeName
          routeId
        }
        driver {
          firstName
          lastName
        }
        vehicle {
          vehicleId
        }
        startedTime
        scheduledTime
      }
      ongoing {
        id
        currentLocation
      }
      isLimitReached
      limitRequest {
        id
        isNotified
        limit
        isNotified
        status
        updatedAt
        createdAt
      }
      notifications {
        userId
        role
        message
        id
      }
      studentsAbsent {
        status
        tripLogId
        pickupTime
        dropTime
        stop {
          id
          stopId
        }
        schedule {
          route {
            routeId
          }
          vehicle {
            vehicleId
          }
        }
        student {
          firstName
          lastName
          class
          section
          fatherName
          fatherMobileNo
          motherName
          motherMobileNo
          routes {
            routeName
          }
        }
      }
    }
  }
`;

export const UPDATE_INSTITUTE_LIMIT_ADMIN = gql`
  mutation updateInstituteLimitRequest(
    $requestId: String!
    $status: String
    $isNotified: Boolean
  ) {
    updateInstituteLimitRequest(
      requestId: $requestId
      status: $status
      isNotified: $isNotified
    )
  }
`;
