import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { colors } from "../Themes/Color";
import Images from "../Themes/Images";
const ModalContainer = styled.div`
  &.backdropStyle {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    transition: 0.5s all ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .modalStyle {
    background-color: #fff;
    border-radius: 5px;
    min-width: 500px;
    max-width: 500px;
    /* min-height: 300px; */
    margin: 0 auto;
    padding: 15px;
    overflow: scroll;
  }
  & .header {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 19px;
    color: ${colors.colorBlue};
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  &.show {
    z-index: 9999;
    opacity: 1;
  }
`;
class Modal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    // if (!this.props.show) {
    //   return null;
    // }

    return (
      <ModalContainer
        className={`backdropStyle ${this.props.show ? "show" : "hide"}`}
        onClick={(e) => {
          e.preventDefault();
          // alert();
          this.props.onClose();
        }}
      >
        <div
          className="modalStyle position-relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {this.props.heading && (
            <div className="header text-center mb-4">{this.props.heading}</div>
          )}
          <div className="close" onClick={this.props.onClose}>
            <img src={Images.CloseIcon} alt="close" />
          </div>
          {this.props.children}

          {/* <div className="footer">
            <button onClick={this.props.onClose}>Close</button>
          </div> */}
        </div>
      </ModalContainer>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default Modal;
