import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import { Modal } from "reactstrap";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import Loading from "../../../Component/Loading";
import ModalStyle from "../../../Component/Styles/ModalStyle";
import { colors } from "../../../Themes/Color";
import Images from "../../../Themes/Images";
import { generateRandomString, sendNotification } from "../../../utils";
import {
  CREATE_ROUTE,
  GET_ROUTE_BY_ID,
  GET_STOPS_FOR_ROUTE,
  UPDATE_ROUTE,
} from "../../graphql";

let FormRef, FormRef1;

const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
  .title {
    color: #919191;
    font-size: 17px;
    font-weight: 600;
    font-family: "Muli", sans-serif;
  }
  .semiTitle {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    font-size: 17px;
  }
  .listTitle {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 17px;
    color: ${colors.colorBlue};
  }
  .small {
    font-size: 15px;
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StopStudentCount = styled.div`
  background: #cfdbfd;
  padding: 15px 25px;
  border-radius: 10px;
  .title {
    font-size: 17px;
    font-weight: 600;
    font-family: "Muli", sans-serif;
  }
  .edit {
    font-size: 13px;
    font-weight: 600;
    color: #3f3fd5;
    font-family: "Muli", sans-serif;
  }
`;
export class CreateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        students: [],
        allStudents: [],
      },
      CreateRoute: {
        routeName: "",
        routeId: "",
        routeType: "PICKUP",
        routeTiming: "MORNING",
        selectedStops: [],
        stops: [{ id: "", students: [] }],
        stopsList: [],
      },
      isOpen: false,
      dataVariables: {},
      activeTab: "1",
      formSubmit: false,
      selectedStop: "",
      modalSelected: "",
      handleForm1: false,
      handleForm2: false,
      instituteId: "",
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    setTimeout(() => {
      this.setState({ role: role, instituteId }); // refer
    }, 100);
    this.setState({ gpwd: generateRandomString(7) });
  }
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const CreateRoute = { ...this.state.CreateRoute };
    CreateRoute[key] = value;

    this.setState({ CreateRoute });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  // onNextButton = () => {
  //   this.setActiveTab(parseInt(this.state.activeTab) + 1);
  // };
  onNextButton = () => {
    // this.setActiveTab(parseInt(this.state.activeTab) + 1);
    if (this.state.activeTab === "1") {
      this.setState({ handleForm1: true }, () => {
        this.onNextButtonValidate(FormRef);
      });
    }
    if (this.state.activeTab === "2") {
      this.setState({ handleForm2: true }, () => {
        this.onNextButtonValidate(FormRef1);
      });
    }
  };
  onNextButtonValidate = (Form) => {
    Form.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll(".form-error").length === 0) {
        if (document.querySelectorAll(".inputRequired").length === 0) {
          // ContactOrganizer();
          // func();
          // this.onSetVariables(func);
          this.setActiveTab(parseInt(this.state.activeTab) + 1);
          // this.props.changePage();
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      } else {
        // this.setActiveTab(1);
        sendNotification({
          message: "Please fill all the fields",
          type: "danger",
        });
      }
    }, 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/school/routes");
    }
  };
  onChange = (key, value) => {
    const CreateRoute = { ...this.state.CreateRoute };
    CreateRoute[key] = value;
    this.setState({ CreateRoute });
  };
  addStop = () => {
    const CreateRoute = { ...this.state.CreateRoute };

    CreateRoute.stops.push({ id: "", students: [] });
    this.setState({ CreateRoute });
  };
  onSelectStop = (index, value) => {
    const CreateRoute = JSON.parse(JSON.stringify(this.state.CreateRoute));
    // console.log(CreateRoute.selectedStops);
    if (value !== "") {
      // console.log(CreateRoute.stops[index], value);
      // if (CreateRoute.selectedStops.includes(value + "")) {
      if (CreateRoute.stops[index].id !== "") {
        // console.log("splice");
        let selectedIndex = CreateRoute.selectedStops.indexOf(value);
        CreateRoute.selectedStops.splice(selectedIndex, 1);
      }
      CreateRoute.selectedStops.push(value + "");

      const selectedStudent = CreateRoute.stopsList.filter((studentData) => {
        if (value == studentData.id)
          if (CreateRoute.selectedStops.includes(studentData.id)) {
            return studentData;
          }
      }); //Entire Student Array
      console.log(selectedStudent);
      let allStudents = [...((selectedStudent[0] || {}).students || [])].map(
        (student) => {
          // console.log(CreateRoute.stops[index].students);
          if (this.props.match.params.id) {
            let rouStu = CreateRoute.stops[index].students.filter((data) => {
              // console.log(data.id, data.id);
              if (data.id === student.id) {
                return data;
              }
            });
            // console.log(rouStu);
            if (
              rouStu.length ||
              CreateRoute.stops[index].students.length === 0
            ) {
              student.isChecked = true;
            } else {
              student.isChecked = false;
            }
          } else {
            student.isChecked = true;
          }
          return student;
        }
      );
      // if (!this.props.match.params.id) {
      CreateRoute.stops[index].students = selectedStudent.length
        ? allStudents.filter((data) => data.isChecked)
        : [];
      // }s

      CreateRoute.stops[index].stopName = selectedStudent.length
        ? selectedStudent[0].stopName
        : "";
      CreateRoute.stops[index].stopId = selectedStudent.length
        ? selectedStudent[0].stopId
        : "";

      CreateRoute.stops[index].allStudents = allStudents;
    } else {
      let popElement = CreateRoute.stops[index].id;
      let EleIndex = CreateRoute.selectedStops.indexOf(popElement);
      CreateRoute.selectedStops.splice(EleIndex, 1);
      CreateRoute.stops[index].students = [];
    }
    CreateRoute.stops[index].id = value;

    // console.log(CreateRoute.selectedStops);
    this.setState({ CreateRoute });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSubmit = (func) => {
    this.setState({ handleForm2: true }, () => {
      FormRef1.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            this.setDataVariables(func);
            // this.props.changePage();
          } else {
            // this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  setDataVariables = (func) => {
    let students = [];
    // let shops = this.state.CreateRoute.stops.map((data) => {
    //   data.students.map((studentData) => {
    //     students.push(studentData.id);
    //   });
    //   return data.id;
    // });
    let shops = this.state.CreateRoute.stops.map((data) => {
      const students = data.students.map((studentData) => {
        return studentData.id;
        // students.push(studentData.id);
      });
      return {
        id: data.id,
        students,
      };
    });
    const dataVariables = {
      // id: this.props.query.id,
      routeName: this.state.CreateRoute.routeName,
      routeId: this.state.CreateRoute.routeId,
      type: this.state.CreateRoute.routeType,
      timings: this.state.CreateRoute.routeTiming,
      stops: shops,
      students: students,
    };
    if (this.props.match.params.id) {
      dataVariables.id = this.props.match.params.id;
    } else {
      dataVariables.instituteId = localStorage.getItem("institueId");
    }

    this.setState({ dataVariables }, () => {
      func();
    });
  };
  studentStops = (index) => {
    // console.log(this.state.CreateRoute.stopsList[index]);
    let modalData = {};
    // alert(index);
    let stopDetails = JSON.parse(
      JSON.stringify(this.state.CreateRoute.stops[index])
    ); //{ ...this.state.CreateRoute.stops[index] };
    modalData.stopName = stopDetails.stopName;
    modalData.stopId = stopDetails.stopId;
    modalData.allStudents = [...stopDetails.allStudents] || [];
    // modalData.students = this.state.CreateRoute.stopsList[index].students.map;
    this.setState(
      {
        modalData,
        selectedStop: stopDetails.id,
        modalSelected: index,
      },
      () => {
        this.toggleModal();
      }
    );
  };
  selectModalStudents = (index) => {
    let modalData = { ...this.state.modalData };
    // modalData.stopId="";
    // modalData.stopName="";
    modalData.allStudents[index].isChecked = !modalData.allStudents[index]
      .isChecked;
    this.setState({ modalData });
  };
  confirmStudent = () => {
    const CreateRoute = { ...this.state.CreateRoute };
    CreateRoute.stops = CreateRoute.stops.map((data) => {
      if (data.id === this.state.selectedStop) {
        data.students = this.state.modalData.allStudents.filter(
          (data) => data.isChecked
        );
        data.allStudents = [...this.state.modalData.allStudents];
      }
      return data;
    });
    this.setState({ CreateRoute }, () => {
      this.toggleModal();
    });
  };
  onDeleteStop = (index) => {
    const CreateRoute = this.state.CreateRoute;
    let popElement = CreateRoute.stops.splice(index, 1);
    let EleIndex = CreateRoute.selectedStops.indexOf(popElement.id);
    CreateRoute.selectedStops.splice(EleIndex, 1);
    this.setState({ CreateRoute });
  };
  onRouteDataUpdate = () => {
    const CreateRoute = JSON.parse(JSON.stringify(this.state.CreateRoute));
    let stops = CreateRoute.stops.map((stop) => {
      const selectedStudent = CreateRoute.stopsList.filter((studentData) => {
        if (stop.id === studentData.id)
          if (CreateRoute.selectedStops.includes(studentData.id)) {
            return studentData;
          }
      });
      console.log(selectedStudent);
      let studentsArr = (stop.selectedStudents || []).map((student) => {
        return student.id;
        // console.log(student);
      });
      let allStudents = ((selectedStudent[0] || {}).students || []).map(
        (student) => {
          student.isChecked = studentsArr.includes(student.id);
          return student;
        }
      );
      console.log(allStudents);
      stop.students = stop.selectedStudents || [];
      stop.allStudents = allStudents;
      return stop;
    });
    CreateRoute.stops = stops;
    this.setState({ CreateRoute });
  };
  render() {
    return (
      <SchoolContainer className="pl-3">
        {this.state.instituteId !== "" && (
          <Query
            variables={{ instituteId: this.state.instituteId }}
            query={GET_STOPS_FOR_ROUTE}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              // console.log(data);
              if (data)
                if (data.getStopsForRoute) {
                  // let CreateRoute = {
                  //   activeTab: "1",
                  //   stopsList: data.getStopsForRoute,
                  //   selectedStops: [],
                  //   routeName: "",
                  //   routeId: "",
                  //   routeType: "PICKUP",
                  //   routeTiming: "MORNING",
                  //   stops: [{ id: "", students: [] }]
                  // };
                  let CreateRoute = JSON.parse(
                    JSON.stringify(this.state.CreateRoute)
                  );
                  console.log(data);
                  CreateRoute.stopsList = data.getStopsForRoute;
                  this.setState({ CreateRoute }, () => {
                    this.onRouteDataUpdate();

                    // this.state.CreateRoute.stops.map((data, index) => {
                    // this.onSelectStop(index, data.id);
                    // });
                  });

                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        {this.props.match.params.id && this.state.instituteId !== "" && (
          <Query
            variables={{
              id: this.props.match.params.id,
            }}
            query={GET_ROUTE_BY_ID}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (data.getRouteById) {
                let CreateRoute = JSON.parse(
                  JSON.stringify(this.state.CreateRoute)
                );

                CreateRoute.routeName = data.getRouteById.routeName;
                CreateRoute.routeId = data.getRouteById.routeId;
                CreateRoute.routeType = data.getRouteById.type;
                CreateRoute.routeTiming = data.getRouteById.timings;

                // stops: [{ id: "", students: [] }]
                var stopArr = [],
                  selectedStop = [];
                data.getRouteById.stops.map((stop) => {
                  var studentArr = stop.students.map((student) => {
                    student.isChecked = true;
                    return student;
                  });
                  // console.log(stop);

                  stopArr.push({
                    id: stop.id,
                    students: [],
                    selectedStudents: studentArr,
                    stopId: stop.stopId,
                    stopName: stop.stopName,
                  });
                  selectedStop.push(stop.id);
                });
                CreateRoute.selectedStops = stopArr.map((data) => data.id);
                CreateRoute.stops = stopArr;
                // console.log(CreateRoute);
                // CreateRoute.selectedStops = selectedStop;
                this.setState({ CreateRoute }, () => {
                  this.onRouteDataUpdate();
                  // stopArr.map((stop, index) => {
                  //   setTimeout(() => {
                  //     this.onSelectStop(index, stop.id);
                  //   }, 10);
                  // });
                  // console.log(this.state.CreateRoute);
                });
                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}

        <div className="menu mt-4 pt-3">
          <div
            className={`menuItem ${
              this.state.activeTab === "1" ? "active" : ""
            }`}
          >
            Route details
          </div>
          <div
            className={`menuItem ${
              this.state.activeTab === "2" ? "active" : ""
            }`}
          >
            Stop details
          </div>
        </div>
        <div
          className={`${
            this.state.activeTab === "2" ? "col-11" : "col-9"
          } pl-4 pr-4 pt-4`}
        >
          <Mutation
            variables={{
              data: this.state.dataVariables,
            }}
            mutation={this.props.match.params.id ? UPDATE_ROUTE : CREATE_ROUTE}
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });

              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.createRoute) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Student created successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/routes");
                }, 500);
                // message.success("Contact Created!!");
              }
              if (data.updateRoute) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Student updated successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/routes");
                }, 500);
                // message.success("Contact Created!!");
              }
            }}
          >
            {(CreateRoute, { data, loading, error }) => {
              return (
                <div>
                  <Loading is_loading={loading} />
                  {this.state.activeTab === "1" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "1" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                        className={` ${
                          this.state.handleForm1 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Route name </div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateRoute.routeName}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("routeName", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Route ID</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateRoute.routeId}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("routeId", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Route type</div>
                          <div className="col-9">
                            <ReactSelect
                              placeholder=""
                              white
                              required
                              value={this.state.CreateRoute.routeType}
                              className={"w-100"}
                              options={[
                                { value: "PICKUP", name: "Pickup" },
                                { value: "DROP", name: "Drop" },
                              ]}
                              onChange={(value) => {
                                // console.log(value);
                                this.onChange("routeType", value);
                              }}
                            ></ReactSelect>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Route timings</div>
                          <div className="col-9">
                            <div className="col-12 p-0">
                              <ReactSelect
                                placeholder=""
                                white
                                required
                                value={this.state.CreateRoute.routeTiming}
                                className={"w-100"}
                                options={[
                                  { value: "MORNING", name: "Morning" },
                                  { value: "AFTERNOON", name: "Afternoon" },
                                  { value: "EVENING", name: "Evening" },
                                ]}
                                onChange={(value) => {
                                  // console.log(value);
                                  this.onChange("routeTiming", value);
                                }}
                              ></ReactSelect>
                            </div>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "2" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "2" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef1 = ref;
                        }}
                        className={` ${
                          this.state.handleForm2 ? "invalid-form" : ""
                        }`}
                      >
                        {this.state.CreateRoute.stops.map((data, index) => {
                          // console.log(data);
                          return (
                            <InputContainer
                              className="row m-0 mt-3"
                              key={`Route-${index}`}
                            >
                              <div className="col-3 pl-0">
                                {`Stop ${index + 1}`}{" "}
                              </div>
                              <div className="col-9 d-flex pr-0">
                                <div className="col">
                                  <ReactSelect
                                    placeholder=""
                                    white
                                    required
                                    value={data.id}
                                    className={"w-100"}
                                    options={[
                                      { value: "", name: "Select a stop" },
                                      ...this.state.CreateRoute.stopsList
                                        .map((studentData) => {
                                          if (
                                            !this.state.CreateRoute.selectedStops.includes(
                                              studentData.id
                                            )
                                          ) {
                                            return {
                                              name: studentData.stopName,
                                              value: studentData.id,
                                            };
                                          } else {
                                            if (data.id === studentData.id) {
                                              return {
                                                name: studentData.stopName,
                                                value: studentData.id,
                                              };
                                            }
                                          }
                                        })
                                        .filter((data) => data),
                                    ]}
                                    onChange={(value) => {
                                      // console.log(value);
                                      this.onSelectStop(index, value);
                                      // this.onChange("subsType", value);
                                    }}
                                  ></ReactSelect>
                                </div>
                                <div className="p-0 col">
                                  {data.students.length !== 0 && (
                                    <StopStudentCount className="d-flex justify-content-between align-items-center">
                                      <div className="title">
                                        {data.students.length} students
                                      </div>
                                      <div
                                        className="edit pointer-cursor"
                                        onClick={() => {
                                          this.studentStops(index);
                                        }}
                                      >
                                        Edit
                                      </div>
                                    </StopStudentCount>
                                  )}
                                </div>
                                <div className="col-3 pr-0 d-flex">
                                  {data.id !== "" && (
                                    <div className="deleteContainer">
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.onDeleteStop(index);
                                        }}
                                        danger
                                        rounded
                                        title={"Delete"}
                                        primary
                                      ></Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </InputContainer>
                          );
                        })}
                      </Form>
                      <div className="d-flex text-center justify-content-end mt-3">
                        <Button
                          title="Add stop"
                          primary
                          rounded
                          onClick={this.addStop}
                        ></Button>
                      </div>
                    </div>
                  )}

                  <div className="mt-2 mb-5 pt-4 d-flex align-items-center justify-content-end">
                    <Button
                      primaryTransparent
                      buttonClass="mr-4"
                      title="Back"
                      disabled={this.state.activeTab === "1"}
                      onClick={this.onBackButton}
                    ></Button>
                    <div className="d-inline">
                      <Button
                        title={`${
                          this.state.activeTab === "2"
                            ? this.props.match.params.id
                              ? "Update route"
                              : "Create route"
                            : "Next"
                        }`}
                        primary
                        rounded
                        onClick={() => {
                          if (this.state.activeTab === "2") {
                            this.handleSubmit(CreateRoute);
                          } else {
                            this.onNextButton();
                          }
                          // this.handleSubmit(CreateRoute);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Mutation>
        </div>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          centered
          size="md"
        >
          <SchoolContainer>
            <ModalStyle>
              <React.Fragment>
                <div className="border-bottom pb-3 mb-3">
                  <div className="d-flex">
                    <div className="title col-3 p-0">Stop name</div>
                    <div className="semiTitle">
                      {this.state.modalData.stopId}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="title col-3 p-0">Stop ID</div>
                    <div className="semiTitle">
                      {this.state.modalData.stopName}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="listTitle">List of students</div>
                    <div className="semiTitle title small">
                      {`${
                        this.state.modalData.allStudents.filter(
                          (data) => data.isChecked
                        ).length !== 0
                          ? this.state.modalData.allStudents.filter(
                              (data) => data.isChecked
                            ).length + " students selected"
                          : ""
                      }`}
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.modalData.allStudents.map((data, index) => {
                    return (
                      <div
                        key={`Students-${index}`}
                        className="d-flex justify-content-between align-items-center mt-2 pointer-cursor"
                        onClick={() => {
                          this.selectModalStudents(index);
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <div style={{ width: 60 }}>
                            <img
                              className="img-fluid"
                              src={data.displayPicture}
                              alt="dp"
                            />
                          </div>
                          <div className="ml-3">
                            <div>{data.firstName + " " + data.lastName}</div>
                            <div>{data.class}</div>
                          </div>
                        </div>
                        <div>
                          <img
                            src={
                              !data.isChecked
                                ? Images.circleIcon
                                : Images.circleIconChecked
                            }
                            className="img-fluid "
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      title="Cancel"
                      primaryTransparent
                      rounded
                      onClick={this.toggleModal}
                    ></Button>
                    <div className="mr-2">
                      <Button
                        title="Confirm"
                        primary
                        rounded
                        onClick={this.confirmStudent}
                      ></Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </ModalStyle>
          </SchoolContainer>
        </Modal>
      </SchoolContainer>
    );
  }
}

export default CreateRoute;
