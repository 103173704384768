import React, { Component } from "react";
import Images from "../../Themes/Images";
import Image from "../Image";
import { DashboardContainer } from "./style/dashboard";

export class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instituteId: "",
      roleSet: "",
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    const roleSet = (this.props.user || {}).role === "SUPERADMIN" ? "1" : "0";

    // alert(instituteId);
    this.setState({ instituteId, role, roleSet });
  }
  render() {
    return (
      <DashboardContainer className="d-flex justify-content-between flex-column">
        <div>
          <div
            className="logo pointer-cursor"
            onClick={() => {
              if (this.state.role === "PRIMARY") {
                this.props.history.push("/dashboard");
              } else {
                if (this.state.roleSet === "1") {
                  this.props.history.push("/dashboard");
                } else {
                  this.props.history.push("/school/dashboard");
                }
              }
            }}
          >
            <Image src={Images.DashboardLogo}></Image>
          </div>
          <div className="listContainer">
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/dashboard"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/dashboard");
              }}
            >
              Dashboard
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/trips" ? "active" : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/trips");
              }}
            >
              Trips
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/routes"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/routes");
              }}
            >
              Routes
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/stops" ? "active" : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/stops");
              }}
            >
              Stops
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/vehicles"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/vehicles");
              }}
            >
              Buses
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/students"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/students");
              }}
            >
              Students
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/drivers"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/drivers");
              }}
            >
              Drivers
            </div>
            <div
              className={`pointer-cursor ${
                this.props.location.pathname === "/school/birdsview"
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                this.props.history.push("/school/birdsview");
              }}
            >
              Birds Eye view
            </div>
          </div>
        </div>
        <div className="listContainer pt-0 pb-2">
          <div
            className={`pointer-cursor ${
              this.props.location.pathname.includes("/dashboard/school/edit/")
                ? "active"
                : ""
            }`}
            onClick={() => {
              if (this.state.role === "PRIMARY") {
                this.props.history.push(
                  `/dashboard/school/edit/${this.state.instituteId}`
                );
              } else {
                this.props.history.push("/aboutschool");
              }
            }}
          >
            {this.state.role === "PRIMARY" ? "Edit School" : "About School"}
          </div>
        </div>
      </DashboardContainer>
    );
  }
}

export default School;
