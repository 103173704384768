import moment from "moment";
import Table from "rc-table";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import DateSelector from "../../Component/DateSelector";
import Button from "../../Component/Form/button";
import ReactInput from "../../Component/Form/input";
import ReactSelect from "../../Component/Form/select";
import ReactTextarea from "../../Component/Form/textarea";
import Loading from "../../Component/Loading";
import TableStyle from "../../Component/Styles/Table";
import TitleContainer from "../../Component/TitleContainer";
import { colors } from "../../Themes/Color";
import Images from "../../Themes/Images";
import { sendNotification } from "../../utils";
import {
  ADMIN_DASHBOARD,
  CREATE_TASK,
  DELETE_TASK,
  GET_TASK_DETAILS,
  UPDATE_TASK,
} from "../graphql";

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .changePwdFloat {
    color: #004584;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    right: 30px;
  }
`;
const Tag = styled.div`
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  max-width: 80px;
  font-family: "Muli", sans-serif;
  background: ${(props) => (props.isPending ? "#FFD248" : "#2AD792")};
`;
let FormRef;

const CountContainer = styled.div`
  border-radius: 10px;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  background: ${colors.colorWhite};
  margin-left: 60px;
  & > div {
    display: flex;
    align-items: center;
  }
  /* justify-content: center; */
  & > div > div {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-family: "Muli", sans-serif;
  }
  .title {
    font-weight: 17px;
  }
  .count {
    font-size: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* line-height: 44px; */
    color: ${colors.colorWhite};
    background: ${colors.gradientColor};
  }
`;
export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTaskOpen: false,
      adminDetails: [],
      task: [],
      subscriptions: [],
      formSubmit: false,
      refetch: true,
      taskVariables: {},
      dashboardDetails: {
        schoolsCount: 0,
        usersCount: 0,
        busesCount: 0,
        driversCount: 0,
      },

      insDetails: [],
      createTask: {
        insName: "",
        description: "",
        id: "",
      },
    };
  }
  toggleTaskModal = () => {
    this.setState({ isTaskOpen: !this.state.isTaskOpen });
  };
  onChange = (key, value) => {
    const createTask = { ...this.state.createTask };
    createTask[key] = value;

    this.setState({ createTask });
  };
  handleSubmit = (func) => {
    this.setState({ formSubmit: true }, () => {
      FormRef.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            const variables = {
              assignTo: this.state.createTask.assignTo,
              description: this.state.createTask.description,
              insName: this.state.createTask.insName,
              insAddress: this.state.createTask.insAddress,
              insContactPerson: this.state.createTask.insContactPerson,
              insContactNo: this.state.createTask.insContactNo,
              dueDate: this.state.createTask.dueDate,
            };
            if (this.state.createTask.id !== "") {
              variables.id = this.state.createTask.id;
            }
            this.setState({ taskVariables: variables }, () => {
              func();
            });
            // this.props.changePage();
          } else {
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  updateTask = (index) => {
    let currentTask = this.state.task[index];
    let createTask = {
      assignTo: currentTask.assignTo.id,
      description: currentTask.description,
      insName: currentTask.insName,
      insAddress: currentTask.insAddress,
      insContactPerson: currentTask.insContactPerson,
      insContactNo: currentTask.insContactNo,
      dueDate: currentTask.dueDate,
      id: currentTask.id,
    };
    this.setState({ createTask }, () => {
      this.toggleTaskModal();
    });
  };
  render() {
    const columns = [
      {
        title: "No",
        dataIndex: "sno",
        key: "sno",
        width: 100,
        render: (text, data, index) => {
          return <div>{("00" + (index + 1)).slice(-2)}</div>;
        },
      },

      {
        title: "Assigned To",
        dataIndex: "assignedTo",
        key: "assignedTo",
        width: 180,
        render: (text, data, index) => {
          return <div>{data.assignTo ? data.assignTo.firstName : ""}</div>;
        },
      },
      {
        title: "School",
        dataIndex: "schoolName",
        key: "schoolName",
        width: 300,
        render: (text, data, index) => {
          return <div>{data.insName || ""}</div>;
        },
      },
      {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              <div>{data.insContactPerson || ""}</div>
              <div>{data.insContactNo || ""}</div>
            </div>
          );
        },
      },
      {
        title: "Review",
        dataIndex: "review",
        key: "review",
        width: 400,
        render: (text, data, index) => {
          return <div>{data.description || ""}</div>;
        },
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 200,
        render: (text, data, index) => {
          return <Tag isPending={data.status === "PENDING"}>{data.status}</Tag>;
        },
      },
      {
        title: "",
        dataIndex: "",
        width: 160,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          //console.log(data, index);
          return (
            <div className="d-flex">
              <div
                className="pr-2 pointer-cursor color-blue"
                onClick={() => {
                  this.updateTask(index);
                }}
              >
                Edit
              </div>
              <Mutation
                mutation={DELETE_TASK}
                variables={{
                  taskId: data.id,
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ isError: true });
                  // sendNotification({
                  //   message: error.message.replace("GraphQL error: ", ""),
                  //   type: "danger"
                  // });
                  // this.showNotificationError(
                  //   error.message.replace("GraphQL error: ", "")
                  // );
                }}
                onCompleted={(data) => {
                  this.setState({ refetch: false }, () => {
                    this.setState({ refetch: true });
                  });
                }}
              >
                {(DeleteTask, { data, loading, error }) => {
                  if (loading) {
                    return <Loading is_loading={loading}> </Loading>;
                  }
                  return (
                    <div
                      className="ml-4 pointer-cursor color-blue"
                      onClick={() => {
                        DeleteTask();
                      }}
                    >
                      Delete
                      {/* <ReactAvatar
                <img
                style={{ width: "25px" }}
                    className="img-fluid"
                    src={Images.LogoutImage}
                  ></img>
        // colors={["#D8D8D8"]}
        name="Admin"
        size="34px"
        round
      ></ReactAvatar> */}
                    </div>
                  );
                }}
              </Mutation>
            </div>
          );
        },
      },
    ];
    return (
      <div className="row m-0 mt-4 pt-3">
        <div className="col-12">
          <div className="col-12 text-left">
            <React.Fragment>
              {this.state.refetch && (
                <Query
                  query={ADMIN_DASHBOARD}
                  fetchPolicy="network-only"
                  onCompleted={(data) => {
                    console.log(data);
                    let dashboardDetails = {
                      schoolsCount: data.getDashboardData
                        ? data.getDashboardData.noOfSchools
                        : 0,
                      usersCount: data.getDashboardData
                        ? data.getDashboardData.noOfUsers
                        : 0,
                      busesCount: data.getDashboardData
                        ? data.getDashboardData.noOfVehicles
                        : 0,
                      driversCount: data.getDashboardData
                        ? data.getDashboardData.noOfDrivers
                        : 0,
                    };

                    this.setState({
                      dashboardDetails,
                      subscriptions: data.getDashboardData.subscriptions,
                      task: data.getDashboardData.tasks || [],
                    });
                  }}
                >
                  {({ data, loading, error }) => {
                    return <Loading is_loading={loading} />;
                  }}
                </Query>
              )}
            </React.Fragment>
            <div className="d-flex justify-content-between">
              <CountContainer className="ml-0">
                <div>
                  <div className="title">No of Schools</div>
                  <div className="count">
                    {this.state.dashboardDetails.schoolsCount || "0"}
                  </div>
                </div>
              </CountContainer>
              <CountContainer>
                <div>
                  <div className="title">No of Users</div>
                  <div className="count">
                    {this.state.dashboardDetails.usersCount || "0"}
                  </div>
                </div>
              </CountContainer>
              <CountContainer>
                <div>
                  <div className="title">No of Buses</div>
                  <div className="count">
                    {this.state.dashboardDetails.busesCount || "0"}
                  </div>
                </div>
              </CountContainer>
              <CountContainer>
                <div>
                  <div className="title">No of Drivers</div>
                  <div className="count">
                    {this.state.dashboardDetails.driversCount || "0"}
                  </div>
                </div>
              </CountContainer>
            </div>
            <div>
              <TitleContainer
                className="mt-4 mb-3"
                title="Tasks"
              ></TitleContainer>
              <div className="row m-0">
                <div className="col-12 pl-0">
                  <TableStyle>
                    <Table
                      useFixedHeader
                      columns={columns}
                      data={this.state.task}
                      scroll={{
                        y: 350,
                      }}
                      emptyText="No tasks found!!"
                      //title={setTableTitle}
                    />
                    <div className="text-right mt-2 mb-2">
                      <img
                        alt=""
                        className="img-fluid mr-3 text-right pointer-cursor"
                        src={Images.AddTask}
                        onClick={() => {
                          let createTask = { ...this.state.createTask };
                          createTask.id = "";
                          this.setState({ createTask }, () => {
                            this.toggleTaskModal();
                          });
                        }}
                      ></img>
                    </div>
                  </TableStyle>
                </div>
                <div className="col-5 d-none">
                  <TableStyle>
                    <div className="tableHeader row m-0">
                      <div className="col-12">App usage/version</div>
                    </div>
                    <div className="tableBody row m-0 alternate pt-3">
                      <div className="col-12">App usage/version</div>
                      <div className="col-12">
                        <div className="flex-center progressContainer mt-3">
                          <div>1.4</div>
                          <div className="progress position-relative">
                            <div
                              className="position-absolute"
                              style={{ width: "50%" }}
                            ></div>
                          </div>
                          <div className="small">25,000</div>
                        </div>
                        <div className="flex-center progressContainer mt-3">
                          <div>1.3</div>
                          <div className="progress position-relative">
                            <div
                              className="position-absolute"
                              style={{ width: "25%" }}
                            ></div>
                          </div>
                          <div className="small">12,000</div>
                        </div>
                        <div className="flex-center progressContainer mt-3">
                          <div>1.2</div>
                          <div className="progress position-relative">
                            <div
                              className="position-absolute"
                              style={{ width: "12.5%" }}
                            ></div>
                          </div>
                          <div className="small">6,000</div>
                        </div>
                        <div className="flex-center progressContainer mt-3">
                          <div>1.1</div>
                          <div className="progress position-relative">
                            <div
                              className="position-absolute"
                              style={{ width: "2%" }}
                            ></div>
                          </div>
                          <div className="small">2,000</div>
                        </div>
                      </div>
                    </div>
                  </TableStyle>
                </div>
              </div>
            </div>
            <div>
              <TitleContainer
                className="mt-4 mb-3"
                title="Subscription"
              ></TitleContainer>
              <div className="col-7 pl-0 mb-4">
                <TableStyle height="306px">
                  <div className="tableHeader row m-0">
                    <div className="col-3">School name</div>
                    <div className="col-4">Subscription type</div>
                    <div className="col-2">Due date</div>
                    <div className="col-3">Contact</div>
                  </div>
                  {this.state.subscriptions.map((subscription, index) => {
                    return (
                      <div
                        className={`tableBody row m-0 ${
                          index % 2 ? "alternate" : ""
                        }`}
                      >
                        <div className="col-3">{subscription.name}</div>
                        <div className="col-4">{subscription.subscription}</div>
                        <div className="col-2">
                          <div className="date color-red">
                            {moment(subscription.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                        </div>
                        <div className="col-3">
                          <div>{subscription.principalName}</div>
                          <div>{subscription.contactNo}</div>
                        </div>
                      </div>
                    );
                  })}
                </TableStyle>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.isTaskOpen}
          toggle={this.toggleTaskModal}
          centered
          size="md"
        >
          <Query
            query={GET_TASK_DETAILS}
            onCompleted={(data) => {
              console.log(data);
              if (!this.state.createTask.assignTo) {
                this.onChange(
                  "assignTo",
                  data.getAdminDetails.length ? data.getAdminDetails[0].id : ""
                );
              }
              this.setState({
                adminDetails: data.getAdminDetails,
                insDetails: data.getInstituteDetailsList,
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
          {this.state.selectedAdmin !== "" && (
            <ModalBody>
              <TitleContainer
                className="color-blue"
                title="Task creation"
              ></TitleContainer>
              <Form
                className={`col-12 ${
                  this.state.formSubmit ? "invalid-form" : ""
                }`}
                ref={(ref) => {
                  FormRef = ref;
                }}
              >
                <div>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-4 pl-0">Assign to</div>

                    <div className="col-8">
                      <ReactSelect
                        placeholder=""
                        required
                        background
                        small
                        value={this.state.createTask.assignTo}
                        className={"w-100"}
                        // options={[
                        //   { value: "Namakkal", name: "Namakkal" },
                        //   { value: "Salem", name: "Van" }
                        // ]}
                        options={this.state.adminDetails.map((data) => {
                          return { value: data.id, name: data.firstName };
                        })}
                        onChange={(value) => {
                          // console.log(value);
                          this.onChange("assignTo", value);
                        }}
                      ></ReactSelect>
                    </div>
                  </InputContainer>
                </div>
                <div>
                  <InputContainer className="row m-0 mt-3 d-flex align-items-start ">
                    <div className="col-4 pl-0">Description</div>

                    <div className="col-8">
                      <ReactTextarea
                        required
                        placeholder=""
                        rows={6}
                        value={this.state.createTask.description}
                        className={"w-100 p-1"}
                        onChange={(value) => {
                          this.onChange("description", value);
                        }}
                      ></ReactTextarea>
                    </div>
                  </InputContainer>
                </div>
                <div>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-4 pl-0">School name</div>

                    <div className="col-8">
                      <ReactInput
                        placeholder=""
                        small
                        value={this.state.createTask.insName}
                        className={"w-100 p-1"}
                        onChange={(value) => {
                          this.onChange("insName", value);
                        }}
                      ></ReactInput>
                    </div>
                  </InputContainer>
                </div>
                <div>
                  <InputContainer className="row m-0 mt-3 d-flex align-items-start ">
                    <div className="col-4 pl-0">School address</div>

                    <div className="col-8">
                      <ReactTextarea
                        placeholder=""
                        rows={6}
                        value={this.state.createTask.insAddress}
                        className={"w-100 p-1"}
                        onChange={(value) => {
                          this.onChange("insAddress", value);
                        }}
                      ></ReactTextarea>
                    </div>
                  </InputContainer>
                </div>
                <div>
                  <InputContainer className="row m-0 mt-3 d-flex align-items-start ">
                    <div className="col-4 pl-0">School Contact</div>

                    <div className="col-8">
                      <ReactInput
                        placeholder=""
                        small
                        value={this.state.createTask.insContactPerson}
                        className={"w-100 p-1"}
                        onChange={(value) => {
                          this.onChange("insContactPerson", value);
                        }}
                      ></ReactInput>
                      <ReactInput
                        className="mt-2 w-100 p-1"
                        placeholder=""
                        small
                        type="number"
                        value={this.state.createTask.insContactNo}
                        onChange={(value) => {
                          this.onChange("insContactNo", value);
                        }}
                      ></ReactInput>
                    </div>
                  </InputContainer>
                </div>
                <div>
                  <InputContainer className="row m-0 mt-3 d-flex align-items-start ">
                    <div className="col-4 pl-0">Due Date</div>

                    <div className="col-8">
                      <DateSelector
                        small
                        startDate={this.state.createTask.dueDate}
                        setStartDate={(e) => {
                          // console.log(e);
                          this.onChange(
                            "dueDate",
                            moment(e).format("YYYY/MM/DD")
                          );
                        }}
                      ></DateSelector>
                    </div>
                  </InputContainer>
                </div>
              </Form>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  primaryTransparent
                  small
                  title={"Cancel"}
                  onClick={() => {
                    this.toggleTaskModal();
                  }}
                ></Button>
                <Mutation
                  variables={{
                    data: this.state.taskVariables,
                    // id: this.props.query.id
                    //userId: this.state.selectedAdmin.id,
                  }}
                  // refetchQueries={[
                  //   {
                  //     query: GET_ADMIN_DETAILS,
                  //   },
                  // ]}
                  mutation={
                    this.state.createTask.id !== "" ? UPDATE_TASK : CREATE_TASK
                  }
                  onError={(error) => {
                    if (!error || !error.message) return null;

                    sendNotification({
                      message: error.message.replace("GraphQL error: ", ""),
                      type: "danger",
                    });
                    // this.showNotificationError(
                    //   error.message.replace("GraphQL error: ", "")
                    // );
                  }}
                  onCompleted={(data) => {
                    if (data.createTask || data.updateTask) {
                      // this.toggle
                      this.toggleTaskModal();
                      this.setState({ refetch: false }, () => {
                        this.setState({ refetch: true });
                      });
                      // this.onSuccessModal();
                    }
                  }}
                >
                  {(CreateTask, { data, loading, error }) => {
                    if (loading) {
                      return <Loading is_loading={loading} />;
                    }
                    return (
                      <Button
                        rounded
                        primary
                        small
                        title={`${
                          this.state.createTask.id !== "" ? "Update" : "Create"
                        } Task`}
                        onClick={() => {
                          this.handleSubmit(CreateTask);
                          // CreateTask();
                        }}
                      ></Button>
                    );
                  }}
                </Mutation>
              </div>
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}

export default Dashboard;
