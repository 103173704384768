import { GoogleApiWrapper, Marker } from "google-maps-react";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import GetPlaces from "../../../Component/GetPlaces";
import Loading from "../../../Component/Loading";
import CurrentLocation from "../../../Component/MapContainer";
import { colors } from "../../../Themes/Color";
import Resources from "../../../Themes/Resources";
import { generateRandomString, sendNotification } from "../../../utils";
import {
  CREATE_STOP,
  GET_LAT_LONG,
  GET_STOP_BY_ID,
  GET_STUDENTS_FOR_SHOP,
  UPDATE_STOP,
} from "../../graphql";

let FormRef, FormRef1;
const MassUploadComponent = styled.div`
  padding: 12px 20px;
  background: #b9cbff;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  .shareTemplate:hover {
    text-decoration: none;
  }
`;
const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
  .shareTemplate:hover {
    text-decoration: none;
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StopContainer = styled.div`
  background: #cfdbfd;
  text-align: center;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;
const SchoolDetailsContainer = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  background: ${colors.gradientColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .schoolTextLarge {
    font-family: "Muli", sans-serif;
    font-size: 21;
    color: ${colors.colorWhite};
    margin-bottom: 10px;
  }
  .semiBold {
    font-weight: 600;
  }

  & .schoolTextSmall {
    font-family: "Muli", sans-serif;
    font-size: 19;

    color: ${colors.colorWhite};
  }
  .regular {
    font-weight: 500;
  }
`;
export class CreateStop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [],
      CreateStop: {
        stopID: "",
        stopName: "",
        coordinates: "",
        radius: "5",
        students: [""],
      },
      mapZoom: 17,
      dataVariables: {},
      activeTab: "1",
      dropdownVisible: false,
      formSubmit: false,
      studentsList: [],
      handleForm1: false,
      handleForm2: false,
      resetFile: true,
    };
    console.log(props);
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    // refer
    this.setState({ gpwd: generateRandomString(7) });
  }
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const CreateStop = { ...this.state.CreateStop };
    CreateStop[key] = value;

    this.setState({ CreateStop });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  // onNextButton = () => {
  //   this.setActiveTab(parseInt(this.state.activeTab) + 1);
  // };
  onNextButton = () => {
    // this.setActiveTab(parseInt(this.state.activeTab) + 1);
    if (this.state.activeTab === "1") {
      this.setState({ handleForm1: true }, () => {
        this.onNextButtonValidate(FormRef);
      });
    }
    if (this.state.activeTab === "2") {
      this.setState({ handleForm2: true }, () => {
        this.onNextButtonValidate(FormRef1);
      });
    }
  };
  onNextButtonValidate = (Form) => {
    Form.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll(".form-error").length === 0) {
        if (document.querySelectorAll(".inputRequired").length === 0) {
          // ContactOrganizer();
          // func();
          // this.onSetVariables(func);
          this.setActiveTab(parseInt(this.state.activeTab) + 1);
          // this.props.changePage();
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      } else {
        // this.setActiveTab(1);
        sendNotification({
          message: "Please fill all the fields",
          type: "danger",
        });
      }
    }, 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/school/stops");
    }
  };
  onChange = (key, value) => {
    const CreateStop = { ...this.state.CreateStop };
    CreateStop[key] = value;
    this.setState({ CreateStop });
  };
  handleSubmit = (func) => {
    this.setState({ handleForm2: true }, () => {
      FormRef1.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            if (this.state.position.length) {
              this.setDataVariables(func);
            } else {
              this.setActiveTab(1);

              sendNotification({
                message: "Please position the stop coordinates",
                type: "danger",
              });
            }
            // this.props.changePage();
          } else {
            this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  addMarker = (lat, lng) => {
    const position = [
      {
        lat,
        lng,
      },
    ];
    const createStop = { ...this.state.createStop };
    createStop.locationLat = lat;
    createStop.locationLng = lng;
    this.setState({ position, createStop }, () => {
      console.log(this.state.position);
    });
  };
  setDataVariables = (func) => {
    const dataVariables = {
      // id: this.props.query.id,
      stopId: this.state.CreateStop.stopID,
      stopName: this.state.CreateStop.stopName,
      radius: this.state.radius, //parseFloat(""),
      coordinates: this.state.position.length
        ? this.state.position[0].lat + "," + this.state.position[0].lng
        : "",
      studentList: this.state.CreateStop.students.filter((data) => data !== ""),
      instituteId: localStorage.getItem("institueId"),
    };
    if (this.props.match.params.id) {
      dataVariables.id = this.props.match.params.id;
    }

    this.setState({ dataVariables }, () => {
      func();
    });
  };

  setMulti = (value, func) => {
    // console.log(value);
    this.onChange("city", value.city);
    this.setState(value, () => {
      func();
    });
  };
  onDropDownVisible = (value) => {
    this.setState({ dropdownVisible: value });
  };
  addStudent = () => {
    const CreateStop = { ...this.state.CreateStop };
    CreateStop.students.push("");
    this.setState({ CreateStop });
  };
  assignStudents = (index, value) => {
    const CreateStop = { ...this.state.CreateStop };
    CreateStop.students[index] = value;
    this.setState({ CreateStop });
  };
  fileUpload = (e) => {
    // console.log(e.target);
    this.setState({ resetFile: false }, () => {
      this.setState({ resetFile: true });
    });
    const latestFile = this.inputElement.files[
      this.inputElement.files.length - 1
    ];
    if (typeof FileReader != "undefined") {
      var reader = new FileReader();
      reader.onload = (e) => {
        var rows = e.target.result.split("\n");
        rows.splice(0, 1);
        // console.log(rows);
        const CreateStop = { ...this.state.CreateStop };
        let students = rows
          .map((data) => {
            let studentList = this.state.studentsList.filter((studentData) => {
              if (studentData.QRkey === data) {
                return studentData;
              }
            });

            return (studentList[0] || {}).id; //.split(",");
          })
          .filter((data) => data);

        console.log(students);
        CreateStop.students = [...students, ...CreateStop.students];
        this.setState({ CreateStop });
      };
      reader.readAsText(latestFile);
    } else {
      alert("This browser does not support HTML5.");
    }
  };
  onDeleteStudent = (index) => {
    const CreateStop = this.state.CreateStop;
    CreateStop.students.splice(index, 1);
    this.setState({ CreateStop });
  };
  render() {
    return (
      <div>
        <SchoolContainer className="pl-3 ">
          {this.props.match.params.id && (
            <React.Fragment>
              <Query
                variables={{ id: this.props.match.params.id }}
                fetchPolicy={"network-only"}
                query={GET_STOP_BY_ID}
                onCompleted={(data) => {
                  if (data.getStopById) {
                    let CreateStop = {
                      stopID: data.getStopById.stopId,
                      stopName: data.getStopById.stopName,
                      coordinates: data.getStopById.coordinates,
                      radius: data.getStopById.radius,
                      students: data.getStopById.students.map(
                        (data) => data.id
                      ),
                      activeTab: "1",
                    };
                    this.addMarker(
                      data.getStopById.coordinates.split(",")[0],
                      data.getStopById.coordinates.split(",")[1]
                    );

                    this.setState({ CreateStop }, () => {});

                    // if (data.getOnboardingEmailId) {
                    //   this.setState({ email: data.getOnboardingEmailId.email });
                    // }
                  }
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ hasErr: true });
                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                }}
              >
                {({ data, loading, error }) => {
                  return <Loading is_loading={loading} />;
                }}
              </Query>
            </React.Fragment>
          )}
          <Query
            fetchPolicy={"network-only"}
            variables={{ instituteId: localStorage.getItem("institueId") }}
            query={GET_STUDENTS_FOR_SHOP}
            onCompleted={(data) => {
              if (data.getStudentsForStop) {
                // let studentsList = {
                //   id:
                // };

                this.setState(
                  { studentsList: data.getStudentsForStop },
                  () => {}
                );

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
          <div className="d-flex  mt-4 pt-3 justify-content-between pr-3">
            <div className="menu">
              <div
                className={`menuItem ${
                  this.state.activeTab === "1" ? "active" : ""
                }`}
              >
                Stop details
              </div>
              <div
                className={`menuItem ${
                  this.state.activeTab === "2" ? "active" : ""
                }`}
              >
                Student details
              </div>
            </div>
            {this.state.activeTab === "2" && (
              <a
                className="shareTemplate"
                download
                href={Resources.StopResource}
              >
                <Button title="Get template" white rounded></Button>
              </a>
            )}
          </div>
          <div className="col-12 pl-4 pr-4 pt-4">
            <Mutation
              variables={{
                data: this.state.dataVariables,
              }}
              mutation={this.props.match.params.id ? UPDATE_STOP : CREATE_STOP}
              onError={(error) => {
                if (!error || !error.message) return null;
                sendNotification({
                  message: error.message.replace("GraphQL error: ", ""),
                  type: "danger",
                });

                // this.showNotificationError(
                //   error.message.replace("GraphQL error: ", "")
                // );
              }}
              onCompleted={(data) => {
                if (data.createStop) {
                  // this.showNotification(
                  //   "👋 Welcome to our group!",
                  //   ``
                  // );
                  sendNotification({
                    message: "Stop created successfully",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.props.history.push("/school/stops");
                  }, 500);
                  // message.success("Contact Created!!");
                }
                if (data.updateStop) {
                  // this.showNotification(
                  //   "👋 Welcome to our group!",
                  //   ``
                  // );
                  sendNotification({
                    message: "Stop updated successfully",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.props.history.push("/school/stops");
                  }, 500);
                  // message.success("Contact Created!!");
                }
              }}
            >
              {(CreateStop, { data, loading, error }) => {
                return (
                  <div>
                    <Loading is_loading={loading} />
                    {this.state.activeTab === "1" && (
                      <div
                        className={`col-11 ${
                          this.state.activeTab === "1" ? "d-block" : "d-none"
                        }`}
                      >
                        <Form
                          ref={(ref) => {
                            FormRef = ref;
                          }}
                          className={` ${
                            this.state.handleForm1 ? "invalid-form" : ""
                          }`}
                        >
                          <div className="col-7">
                            <InputContainer className="row m-0 mt-3">
                              <div className="col-3 pl-0">Stop name</div>
                              <div className="col-9">
                                <ReactInput
                                  placeholder=""
                                  white
                                  required
                                  value={this.state.CreateStop.stopName}
                                  className={"w-100"}
                                  onChange={(value) => {
                                    this.onChange("stopName", value);
                                  }}
                                ></ReactInput>
                              </div>
                            </InputContainer>
                            <InputContainer className="row m-0 mt-3">
                              <div className="col-3 pl-0">Stop ID</div>
                              <div className="col-9">
                                <ReactInput
                                  placeholder=""
                                  white
                                  required
                                  value={this.state.CreateStop.stopID}
                                  className={"w-100"}
                                  onChange={(value) => {
                                    this.onChange("stopID", value);
                                  }}
                                ></ReactInput>
                              </div>
                            </InputContainer>
                          </div>
                          <div className="row m-0 mt-4">
                            <div
                              className="col-8 p-0"
                              style={{
                                height: 400,
                                borderRadius: 10,
                                overflow: "hidden",
                              }}
                            >
                              <div className="position-absolute mapSearchContainer col-5 p-0">
                                <Mutation
                                  variables={{
                                    data: { place: this.state.placeId },
                                  }}
                                  mutation={GET_LAT_LONG}
                                  onCompleted={(data) => {
                                    let CreateStop = {
                                      ...this.state.CreateStop,
                                    };
                                    if (data.getLatLong) {
                                      CreateStop.name = data.getLatLong.name;
                                      CreateStop.address =
                                        data.getLatLong.address;

                                      CreateStop.locationLat =
                                        data.getLatLong.lat;
                                      CreateStop.locationLng =
                                        data.getLatLong.lng;
                                      CreateStop.contactNo =
                                        data.getLatLong.phone;
                                      this.addMarker(
                                        data.getLatLong.lat,
                                        data.getLatLong.lng
                                      );
                                    }
                                    this.setState({ CreateStop });
                                    // onChange("locationLat", data.getLatLong.lat);
                                    // setTimeout(() => {
                                    //   onChange("locationLong", data.getLatLong.lng);
                                    // this.props.handleManyChange({
                                    //   locationLat: data.getLatLong.lat,
                                    //   locationLong: data.getLatLong.lng,
                                    // });
                                    // }, 100);
                                  }}
                                >
                                  {(GetLatLong, { data, loading, error }) => (
                                    <GetPlaces
                                      placeholder="Search"
                                      required
                                      type="establishment"
                                      setMulti={(stateData) => {
                                        this.setState({
                                          mapZoom:
                                            this.state.mapZoom === 17 ? 18 : 17,
                                        });
                                        this.setMulti(stateData, GetLatLong);
                                      }}
                                      dropdownVisible={
                                        this.state.dropdownVisible
                                      }
                                      onChange={(key, value) => {
                                        this.onChange("city", value);
                                      }}
                                      city={this.state.CreateStop.city}
                                      FormSubmit={this.state.FormSubmit}
                                      placeId={this.state.placeid}
                                      onDropDownVisible={this.onDropDownVisible}
                                    ></GetPlaces>
                                  )}
                                </Mutation>
                              </div>
                              <CurrentLocation
                                google={this.props.google}
                                addMarker={this.addMarker}
                                zoom={this.state.mapZoom}
                                currentLocation={
                                  !this.state.position.length
                                    ? { lat: 12.972442, lng: 77.580643 }
                                    : {
                                        lat: this.state.position[0].lat,
                                        lng: this.state.position[0].lng,
                                      }
                                }
                                initialCenter={
                                  !this.state.position.length
                                    ? { lat: 12.972442, lng: 77.580643 }
                                    : {
                                        lat: this.state.position[0].lat,
                                        lng: this.state.position[0].lng,
                                      }
                                }
                              >
                                {this.state.position.map((data, index) => {
                                  return (
                                    <Marker
                                      key={`marker-${index}`}
                                      onClick={this.onMarkerClick}
                                      name={"current location"}
                                      position={{
                                        lat: data.lat,
                                        lng: data.lng,
                                      }}
                                    />
                                  );
                                })}
                              </CurrentLocation>
                            </div>
                            <div className="col-4 d-flex justify-content-end flex-column">
                              {this.state.position.length !== 0 && (
                                <React.Fragment>
                                  <div>
                                    Radius/polygon:{" "}
                                    {this.state.CreateStop.radius}
                                  </div>
                                  <div>
                                    GPS Coordinates:{" "}
                                    {this.state.position.length
                                      ? `${this.state.position[0].lat},${this.state.position[0].lng}`
                                      : ""}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                    {this.state.activeTab === "2" && (
                      <div
                        className={`col-11 ${
                          this.state.activeTab === "2" ? "d-block" : "d-none"
                        }`}
                      >
                        <Form
                          ref={(ref) => {
                            FormRef1 = ref;
                          }}
                          className={` ${
                            this.state.handleForm2 ? "invalid-form" : ""
                          }`}
                        >
                          {this.state.resetFile && (
                            <input
                              className="d-none"
                              type="file"
                              ref={(input) => (this.inputElement = input)}
                              accept=".csv"
                              onChange={(e) => {
                                this.fileUpload(e);
                              }}
                            />
                          )}
                          <div className="text-center d-flex justify-content-center align-items-center flex-column">
                            <Button
                              onClick={() => {
                                this.inputElement.click();
                              }}
                              title="Upload"
                              primary
                              rounded
                            ></Button>
                            <div className="mt-2 text-description">
                              .Csv / .xlsx
                            </div>
                          </div>
                          <div className="text-center mt-4 mb-4">(Or)</div>
                          <div className="mt-4">
                            <div className="text-description ">
                              Student list
                            </div>
                            <div>
                              {this.state.CreateStop.students.map(
                                (data, index) => {
                                  return (
                                    <div
                                      className="row m-0 col-9 mt-4"
                                      key={`Stop-${index}`}
                                    >
                                      <StopContainer className="col-2">
                                        {this.state.CreateStop.stopID !== ""
                                          ? this.state.CreateStop.stopID
                                          : "Stop ID"}
                                      </StopContainer>
                                      <div className="col-7">
                                        <ReactSelect
                                          placeholder=""
                                          white
                                          required={
                                            this.state.CreateStop.length - 1 ===
                                            index
                                          }
                                          value={data}
                                          className={"w-100"}
                                          options={[
                                            {
                                              value: "",
                                              name: "Select Student",
                                            },
                                            ...this.state.studentsList
                                              .map((studentData) => {
                                                if (
                                                  !this.state.CreateStop.students.includes(
                                                    studentData.id
                                                  )
                                                ) {
                                                  return {
                                                    name:
                                                      studentData.firstName +
                                                      " " +
                                                      studentData.lastName,
                                                    value: studentData.id,
                                                  };
                                                } else {
                                                  if (data === studentData.id) {
                                                    return {
                                                      name:
                                                        studentData.firstName +
                                                        " " +
                                                        studentData.lastName,
                                                      value: studentData.id,
                                                    };
                                                  }
                                                }
                                              })
                                              .filter((data) => data),
                                          ]}
                                          onChange={(value) => {
                                            // console.log(value);
                                            this.assignStudents(index, value);
                                            // this.onChange("subsType", value);
                                          }}
                                        ></ReactSelect>
                                      </div>
                                      {data !== "" && (
                                        <div className="col-3">
                                          <div className="deleteContainer">
                                            <Button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.onDeleteStudent(index);
                                              }}
                                              danger
                                              rounded
                                              title={"Delete"}
                                              primary
                                            ></Button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                              <div className="text-center d-flex justify-content-center align-items-center mt-4 flex-column">
                                <Button
                                  onClick={() => {
                                    // this.inputElement.click();
                                    this.addStudent();
                                  }}
                                  title="Add Another"
                                  primary
                                  rounded
                                ></Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}

                    <div className="mt-2 col-11 mb-5 pt-4 d-flex align-items-center justify-content-end">
                      <Button
                        primaryTransparent
                        buttonClass="mr-4"
                        title="Back"
                        disabled={this.state.activeTab === "1"}
                        onClick={this.onBackButton}
                      ></Button>
                      <div className="d-inline">
                        <Button
                          title={`${
                            this.state.activeTab === "2"
                              ? this.props.match.params.id
                                ? "Update stop"
                                : "Create new stop"
                              : "Next"
                          }`}
                          primary
                          rounded
                          onClick={() => {
                            if (this.state.activeTab === "2") {
                              this.handleSubmit(CreateStop);
                            } else {
                              this.onNextButton();
                            }
                            // this.handleSubmit(CreateStop);
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Mutation>
          </div>
        </SchoolContainer>
      </div>
    );
  }
}

// export default CreateStop;
export default GoogleApiWrapper({
  apiKey: "AIzaSyBuR4HOfDQUy2xqT4dhwkoRcVy9nvbYstE",
})(CreateStop);
