import styled from "styled-components";
import { colors } from "../../Themes/Color";

const TableStyle = styled.div`
  font-family: "Muli", sans-serif;
  border-radius: 10px;
  overflow: hidden;
  background: ${colors.colorWhite};
  height: 100%;
  position: relative;
  min-height: ${(props) => (props.height ? props.height : "0")};
  .tableBody,
  .tableHeader {
    padding: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .tableHeader {
    background: ${colors.tableHeader};
    font-size: 17px;
    font-weight: 600;
    color: ${colors.colorDefaultBlack};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tableBody {
    background: ${colors.tableRowColor};
    font-weight: 400;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tag {
    font-size: 13px;
    text-align: center;
    border-radius: 5px;
    color: ${colors.colorDefaultBlack};
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .tag.warning {
    background: ${colors.warningColor};
  }
  .tag.success {
    background: ${colors.successColor};
  }
  .date {
    font-weight: 400;

    color: #343434;
  }
  .tableBody.alternate {
    background: ${colors.tableRowColorAlternate};
  }
  .small {
    font-size: 13px;
  }
  .vsmall {
    font-size: 12px;
  }
  .text-underline {
    text-decoration: underline;
  }
  .addTask {
    /* position: absolute; */
    /* right: 10px; */
    /* bottom: 10px; */
  }
  .color-red {
    color: red;
  }
  .color-blue {
    color: #3f3fd5;
  }
  .color-sky-blue {
    color: #0091ff;
  }
  .font-regular {
    font-weight: 400;
  }
  .plr-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .plr-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .width-130 {
    width: 130px;
  }
  .width-100 {
    width: 100px;
  }
  .width-80 {
    width: 80px;
  }
  .width-90 {
    width: 90px;
  }
  .width-95 {
    width: 95px;
  }
  .width-110 {
    width: 110px;
  }
  .width-150 {
    width: 150px;
  }
  .width-160 {
    width: 160px;
  }
  .width-140 {
    width: 140px;
  }
  .width-120 {
    width: 120px;
  }
  .width-170 {
    width: 170px;
  }
  .width-180 {
    width: 180px;
  }
  .width-200 {
    width: 200px;
  }
  .width-210 {
    width: 210px;
  }
  .width-220 {
    width: 220px;
  }
  .width-250 {
    width: 250px;
  }
  .progressContainer {
    .progress {
      width: 100%;
      border-radius: 4px;

      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 15px;
      margin-right: 15px;
      & .position-absolute {
        border-radius: 5px;
        background: ${colors.gradientColor};
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
`;

export default TableStyle;
