import styled from "styled-components";
import { colors } from "../../../Themes/Color";

export const DashboardContainer = styled.div`
  min-height: 100vh;
  overflow: scroll;
  background: ${colors.secondaryColor};
  & .logo {
    width: 140px;
  }
  & .listContainer {
    padding-top: 25px;
    text-align: left;
    & > div {
      font-family: "Muli", sans-serif;
      font-weight: 400;
      color: ${colors.colorWhite};
      opacity: 0.7;
      padding: 5px 15px;
      text-transform: capitalize;
      &.active {
        opacity: 1;
        font-weight: 600;
      }
    }
  }
`;
