import Table from "rc-table";
import React, { Component } from "react";
import { Query } from "react-apollo";
import { Modal } from "reactstrap";
import DeleteModal from "../../../Component/DeleteModal";
// import styled from "styled-components";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import Loading from "../../../Component/Loading";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import ModalStyle from "../../../Component/Styles/ModalStyle";
import Images from "../../../Themes/Images";
import { sendNotification } from "../../../utils";
import { DELETE_TRIP, GET_TRIP_DETAILS, GET_TRIP_FILTER } from "../../graphql";

export class TripList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripDetails: [],
      selectedIndex: null,
      isOpen: false,
      selectedId: "",
      refetch: true,
      selectedTrip: {},
      modalData: {
        allStudents: [],
      },
      instituteId: "",
      sort: "ascending",
      sortCol: "tripId",
      sortStr: "ASC",
      isDeleteOpen: false,
      filterData: [],
      currFilter: [],
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    this.setState({ role: role, instituteId });
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  selectTrip = (index) => {
    const tripDetails = this.state.tripDetails;
    tripDetails.map((data, dataIndex) => {
      // data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = true;
      }
      return data;
    });
    let selectedIndex = null;
    let selectedId = tripDetails
      .filter((data, index) => {
        if (data.isSelected) {
          selectedIndex = index + 1;
          return data;
        }
      })
      .map((data) => data.id);
    this.setState({
      tripDetails,
      selectedTrip: this.state.tripDetails[index],
      selectedIndex: selectedIndex,
      selectedId: selectedId, //this.state.tripDetails[index].id,
    });
  };
  editTrip = () => {
    // alert(this.state.selectedIndex);
    if (this.state.selectedIndex) {
      this.props.history.push(
        `/school/trip/edit/${
          this.state.tripDetails[this.state.selectedIndex - 1].id
        }`
      );
    } else {
      sendNotification({ message: "Please select a trip", type: "danger" });
    }
  };
  deleteTrip = () => {
    if (this.state.selectedIndex) {
      const tripDetails = [...this.state.tripDetails];
      // tripDetails.splice(this.state.selectedIndex - 1, 1);
      // console.log(this.state.driverDetails);
      this.setState(
        {
          tripDetails,
          selectedTrip: this.state.tripDetails[this.state.selectedIndex - 1],
        },
        () => {
          this.toggleDeleteModal();
        }
      );
      // func();
    } else {
      sendNotification({ message: "Please select a trip", type: "danger" });
    }
  };
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;
            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  onOpenModal = (index, key, type) => {
    const modalData = { ...this.state.modalData };
    modalData.tripId = this.state.tripDetails[index].tripId;
    modalData.tripType = this.state.tripDetails[index].route
      ? this.state.tripDetails[index].route.type
      : "";
    modalData.type = type === "stop" ? "Stops" : "Students";
    if (this.state.tripDetails[index].route) {
      modalData.allStudents = (
        this.state.tripDetails[index].route[key] || []
      ).map((data) => {
        return {
          name:
            type === "stop"
              ? data.stopName
              : data.firstName + " " + data.lastName,
          desc: type === "stop" ? data.stopId : `${data.class} ${data.section}`,
        };
      });
    } else {
      modalData.allStudents = [];
    }

    this.setState({ modalData }, () => {
      this.toggleModal();
    });
  };
  selectSchool = (index) => {
    const tripDetails = [...this.state.tripDetails];
    tripDetails.map((data, dataIndex) => {
      data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = true;
      }
      return data;
    });
    this.setState({
      selectedTrip: tripDetails[index],
      tripDetails,
      selectedIndex: index + 1,
    });
  };
  render() {
    const columns = [
      {
        title: "Trip ID",
        dataIndex: "tripId",
        key: "tripId",
        width: 150,
        fixed: "left",
      },

      {
        title: "Route type",
        dataIndex: "type",
        key: "type",
        width: 180,
        render: (text, data, index) => {
          return <div>{data.route ? data.route.type : ""}</div>;
        },
      },
      {
        title: "Route ID",
        dataIndex: "routeId",
        key: "routeId",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              <div>{data.route ? data.route.routeId : ""}</div>
              <div>{data.route ? data.route.routeName : ""}</div>
            </div>
          );
        },
      },
      {
        title: "Bus details",
        dataIndex: "busDetails",
        key: "busDetails",
        width: 200,
        render: (text, data, index) => {
          return <div> {data.vehicle ? data.vehicle.regNumber : ""}</div>;
        },
      },
      {
        title: "Driver name",
        dataIndex: "driverName",
        key: "driverName",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              <div>
                {data.driver
                  ? data.driver.firstName + " " + data.driver.lastName
                  : ""}
              </div>
              <div>{data.driver ? data.driver.mobileNumber : ""}</div>
            </div>
          );
        },
      },
      {
        title: "No of stops",
        dataIndex: "noOfStops",
        key: "noOfStops",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className=" p-0">
                <div>{data.route ? data.route.stops.length : "0"}</div>
              </div>
              <div className="p-0">
                <span
                  className="ml-3 color-sky-blue pointer-cursor"
                  onClick={() => {
                    this.onOpenModal(index, "stops", "stop");
                  }}
                >
                  View
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: "No of students",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className="p-0">
                <div>{data.route ? data.route.students.length : "0"}</div>
              </div>
              <div className="p-0">
                <span
                  className="ml-3 color-sky-blue pointer-cursor"
                  onClick={() => {
                    this.onOpenModal(index, "students", "student");
                  }}
                >
                  View
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Map",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              {data.location}
              <div className="p-0">
                <span className="mt-2 color-sky-blue">Track</span>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.setState({ forDownload: true });
              // this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 180,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          console.log(data, index);
          return (
            <div className="row m-0">
              <div className="col-6">
                <img
                  src={
                    !data.isSelected
                      ? Images.circleIcon
                      : Images.circleIconSelected
                  }
                  className="img-fluid "
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectTrip(index);
                  }}
                />
              </div>
              <div className="col-4 p-0">
                <img src={Images.nextIcon} className="img-fluid " alt="" />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="row m-0 mt-4">
          <div className="col-5  pt-3">
            <Search
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              isShowFilter={this.state.filterData.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "tripId",
                      });
                    }}
                  ></SortContainer>

                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "tripId",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            <div>
              <Button
                title="Create new trip"
                primary
                rounded
                onClick={() => {
                  this.props.history.push("/school/trip/create");
                }}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                title="Edit"
                white
                rounded
                onClick={this.editTrip}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                onClick={() => {
                  this.deleteTrip();
                }}
                title="Delete"
                white
                rounded
              ></Button>
            </div>
          </div>
        </div>
        {this.state.instituteId !== "" && (
          <React.Fragment>
            <Query
              query={GET_TRIP_FILTER}
              variables={{
                instituteId: this.state.instituteId,
              }}
              onCompleted={(data) => {
                if (data.createUniqueFiltersForStudent) {
                  let filterData = data.createUniqueFiltersForStudent.data
                    .map((data) => {
                      if (data.label) {
                        if (data.minValue && data.maxValue) {
                          data.defaultMinValue = data.minValue;
                          data.defaultMaxValue = data.maxValue;
                        }
                        if ((data.fieldValues || []).length) {
                          data.fieldValues = data.fieldValues.map((field) => {
                            return {
                              label: field,
                              isChecked: false,
                            };
                          });
                        }
                        return data;
                      }
                    })
                    .filter((data) => data);
                  //console.log(filterData);
                  this.setState({
                    filterData: filterData, //data.createUniqueFiltersForInstitute.data,
                  });
                }
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>
            {this.state.refetch && (
              <Query
                query={GET_TRIP_DETAILS}
                fetchPolicy={"network-only"}
                variables={{
                  instituteId: this.state.instituteId,
                  searchTerm: this.state.searchTerm,
                  sortCol: this.state.sortCol,
                  sort: this.state.sortStr,
                  filter: this.state.currFilter,
                }}
                onCompleted={(data) => {
                  if (data.getTripDetails) {
                    let tripDetails = [];

                    tripDetails = data.getTripDetails.map((data) => {
                      data.isSelected = false;
                      return data;
                    });
                    this.setState({ tripDetails });
                  }
                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ hasErr: true });

                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                }}
              >
                {({ data, loading, error }) => {
                  return <Loading is_loading={loading} />;
                }}
              </Query>
            )}
          </React.Fragment>
        )}
        <div className="col-12 mt-5">
          <Table
            useFixedHeader
            columns={columns}
            data={this.state.tripDetails}
            scroll={{
              x: 1200,
              // y: 350,
              y: "calc(100vh - 140px - 75px - 64px )",
            }}
            emptyText="No Trips Found"
            //title={setTableTitle}
          />
        </div>

        <DeleteModal
          isDeleteOpen={this.state.isDeleteOpen}
          modelTitle="Delete Trip"
          modelDesc={`Are you sure you want to remove ${this.state.selectedTrip.tripId} from the Scabbr
        platform?`}
          modelImage={""}
          heading={`Trip: ${this.state.selectedTrip.tripId}`}
          subHeading={`Timing: ${
            this.state.selectedTrip.route
              ? this.state.selectedTrip.route.type
              : ""
          }`}
          deleteMutation={DELETE_TRIP}
          mutationVariables={{ tripIds: this.state.selectedId }}
          onMutationCompleted={() => {
            this.setState({ refetch: false }, () => {
              this.setState({ refetch: true });
            });
          }}
          mutationKey="deleteTrip"
          toggleDeleteModal={this.toggleDeleteModal}
          modelMsg={`${(
            this.state.selectedTrip.tripId || ""
          ).toUpperCase()} HAS BEEN REMOVED FROM THE PLATFORM`}
          buttonText={"Remove Trip"}
        ></DeleteModal>

        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          centered
          size="md"
        >
          <ModalStyle>
            <React.Fragment>
              <div className="border-bottom pb-3 mb-3">
                <div className="d-flex">
                  <div className="title col-3 p-0">Trip name</div>
                  <div className="semiTitle">{this.state.modalData.tripId}</div>
                </div>
                <div className="d-flex">
                  <div className="title col-3 p-0">Trip Timing</div>
                  <div className="semiTitle">
                    {this.state.modalData.tripType}
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="listTitle">
                    List of {this.state.modalData.type}
                  </div>
                </div>
              </div>
              <div>
                {this.state.modalData.allStudents.map((data, index) => {
                  return (
                    <div
                      key={`Students-${index}`}
                      className="d-flex justify-content-between align-items-center mt-2 pointer-cursor"
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="">
                          <div>{data.name}</div>
                          <div>{data.desc}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="d-flex justify-content-end mt-3">
                  <Button
                    title="Cancel"
                    primaryTransparent
                    rounded
                    onClick={this.toggleModal}
                  ></Button>
                </div>
              </div>
            </React.Fragment>
          </ModalStyle>
        </Modal>
      </div>
    );
  }
}

export default TripList;
