import Table from "rc-table";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import LinesEllipsis from "react-lines-ellipsis";
import { Modal } from "reactstrap";
import styled from "styled-components";
import DeleteModal from "../../../Component/DeleteModal";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import FileUpload from "../../../Component/Form/fileUpload";
import Loading from "../../../Component/Loading";
import PictureModel from "../../../Component/PictureModel";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import ModalStyle from "../../../Component/Styles/ModalStyle";
import Images from "../../../Themes/Images";
import Resources from "../../../Themes/Resources";
import { DownloadCSV, DownloadQR, sendNotification } from "../../../utils";
import {
  DELETE_VEHICLE,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_FILTERS,
  VEHICLE_BULK_SIGNUP,
} from "../../graphql";

const MassUploadComponent = styled.div`
  padding: 12px 20px;
  background: #b9cbff;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  .shareTemplate:hover {
    text-decoration: none;
  }
`;
export class BusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busDetails: [],
      selectedIndex: null,
      isOpen: false,
      selectedId: [],
      bulkVehicles: [],
      refetch: true,
      isDeleteOpen: false,
      isModalOpen: false,
      selectedBus: {},
      role: "",
      selectedDocument: "",
      sort: "ascending",
      sortCol: "regNumber",
      sortStr: "ASC",
      modalData: {
        allData: [],
      },
      filterData: [],
      currFilter: [],
      DataErr: [],
      instituteId: "",
      pictureModelVisible: false,
      resetFile: true,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    this.setState({ role: role, instituteId });
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  toggleViewModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };
  selectBus = (index) => {
    const busDetails = this.state.busDetails;
    busDetails.map((data, dataIndex) => {
      // data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = !data.isSelected; //true;
      }
      return data;
    });
    let selectedIndex = null;
    let selectedId = busDetails
      .filter((data, index) => {
        if (data.isSelected) {
          selectedIndex = index + 1;
          return data;
        }
      })
      .map((data) => data.id);

    this.setState({
      busDetails,
      selectedIndex: selectedIndex,
      //this.state.busDetails[index].id,
      selectedId: selectedId,
      selectedBus: this.state.busDetails[index],
    });
  };
  editBus = () => {
    // alert(this.state.selectedIndex);
    if (this.state.selectedIndex) {
      this.props.history.push(
        `/school/vehicle/edit/${
          this.state.busDetails[this.state.selectedIndex - 1].id
        }`
      );
    } else {
      sendNotification({ message: "Please select a vehicle", type: "danger" });
    }
  };
  deleteBus = (func) => {
    if (this.state.selectedIndex) {
      this.toggleDeleteModal();
    } else {
      sendNotification({ message: "Please select a vehicle", type: "danger" });
    }
  };
  onFileUpload = (rows) => {
    this.setState({ resetFile: false }, () => {
      this.setState({ resetFile: true });
    });
    let DataErr = [];
    const bulkVehicles = rows
      .map((data, index) => {
        let splitRow = data.split(",");
        if (splitRow.length === 11) {
          if (index !== 0) {
            return {
              vehicleId: splitRow[0],
              brand: splitRow[1],
              regNumber: splitRow[2],
              type: splitRow[3],
              location: splitRow[4],
              capacity: splitRow[5],
              rcBook: splitRow[6],
              regDate: splitRow[7],
              validUpto: splitRow[8],
              insurance: splitRow[9],
              insValidUpto: splitRow[10],
              instituteId: this.state.instituteId,
              // isActive: true
            };
          }
        } else if (splitRow.length === 0) {
        } else {
          DataErr.push(data.split(","));
          //return "error";
        }
      })
      .filter((data) => data);

    // if (bulkVehicles.includes("error")) {
    //   sendNotification({ message: "Invalid CSV file", type: "danger" });
    // } else {
    // }
    let unique = bulkVehicles.filter(
      ((set) => (f) => !set.has(f.vehicleId) && set.add(f.vehicleId))(new Set())
    );

    this.setState({ bulkVehicles: unique, DataErr });
  };
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;
            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  viewPictureModal = (index, title, desc, image) => {
    this.setState({
      pictureModelVisible: true,
      selectedBus: this.state.busDetails[index],
      selectedTitle: title,
      selectedDesc: desc,
      selectedImage: image,
      selectedDocument: title.replace(/[^a-zA-Z]/gi, "-").toLowerCase(),
    });
  };
  onDownloadQR = () => {
    if (this.state.selectedIndex) {
      const selectedBus = this.state.busDetails[this.state.selectedIndex - 1];
      DownloadQR(
        selectedBus.vehicleNo,
        `https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${selectedBus.QRkey}&choe=UTF-8`,
        "downloadQR"
      );

      // func();
    } else {
      this.state.busDetails.map((data) => {
        DownloadQR(
          data.vehicleNo,
          `https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${data.QRkey}&choe=UTF-8`,
          "downloadQR"
        );
      });
    }
  };
  onDownloadClick = () => {
    const CSV = [];
    CSV.push(["Vehicle no", "Reg number"]);

    this.state.busDetails.map((data) => {
      CSV.push([data.vehicleNo, data.regNumber]);
    });
    this.setState({ schoolsCSV: CSV, forDownload: false }, () => {
      DownloadCSV("Vehicle", CSV);
    });
  };
  onBusDetailsView = (index, tripIndex, key, type) => {
    const modalData = { ...this.state.modalData };

    let tripDetails = this.state.busDetails[tripIndex].tripDetails[index];
    if (!tripDetails) {
      return;
    }
    modalData.busId = this.state.busDetails[tripIndex].vehicleId;
    modalData.routeId = tripDetails.routeId;
    modalData.routeName = tripDetails.routeName;
    modalData.tripType = tripDetails.timing;
    modalData.type = type === "stop" ? "Stops" : "Students";
    modalData.allData = (tripDetails[key] || []).map((data) => {
      return {
        name:
          type === "stop"
            ? data.stopName
            : data.firstName + " " + data.lastName,
        desc: type === "stop" ? data.stopId : `${data.class} ${data.section}`,
      };
    });

    this.setState({ modalData }, () => {
      this.toggleViewModal();
    });
  };
  render() {
    const columns = [
      {
        title: "Bus details",
        dataIndex: "regNumber",
        key: "regNumber",
        width: 150,
        fixed: "left",
        render: (text, data, index) => {
          return (
            <React.Fragment>
              <div> {data.regNumber}</div>
              <div
                className="color-sky-blue vsmall text-underline text-right pointer-cursor"
                onClick={() => {
                  this.viewPictureModal(
                    index,
                    "QR Code",
                    `Bus number: ${data.vehicleNo}`,
                    `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${data.QRkey}&choe=UTF-8`
                  );
                }}
              >
                View QR
              </div>
            </React.Fragment>
          );
        },
      },

      {
        title: "Documents",
        dataIndex: "contact",
        key: "contact",
        width: 180,
      },
      {
        title: "Route ID",
        dataIndex: "routeId",
        key: "routeId",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div className="mb-2" key={`route-${index}-${tripIndex}`}>
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.routeName}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />

                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.routeId}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            );
          });
        },
      },
      {
        title: "Timings",
        dataIndex: "timings",
        key: "timings",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="mb-2 tableMinHeight"
                key={`timing-${index}-${tripIndex}`}
              >
                <div>{data.timing}</div>
              </div>
            );
          });
        },
      },
      {
        title: "Driver name",
        dataIndex: "driverName",
        key: "driverName",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="mb-2 tableMinHeight"
                key={`dname-${index}-${tripIndex}`}
              >
                <div>{data.driverName}</div>
              </div>
            );
          });
        },
      },
      {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="mb-2 tableMinHeight"
                key={`mobile-${index}-${tripIndex}`}
              >
                <div className="word-break-all">{data.mobileNumber}</div>
              </div>
            );
          });
        },
      },
      {
        title: "No of stops",
        dataIndex: "noOfStops",
        key: "noOfStops",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="mb-2 d-flex justify-content-between tableMinHeight"
                key={`trip-${index}-${tripIndex}`}
              >
                <div className="word-break-all">{data.stops.length}</div>
                <span
                  className="ml-3 color-sky-blue pointer-cursor"
                  onClick={() => {
                    this.onBusDetailsView(tripIndex, index, "stops", "stop");
                    // this.toggleViewModal(index, "stops", "stop");
                  }}
                >
                  View
                </span>
              </div>
            );
          });
        },
      },
      {
        title: "No of student",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="mb-2 d-flex justify-content-between tableMinHeight"
                key={`student-${index}-${tripIndex}`}
              >
                <div className="word-break-all">{data.students.length}</div>
                <span
                  className="ml-3 color-sky-blue pointer-cursor"
                  onClick={() => {
                    // this.toggleViewModal(index, "stops", "stop");
                    this.onBusDetailsView(
                      tripIndex,
                      index,
                      "students",
                      "student"
                    );
                  }}
                >
                  View
                </span>
              </div>
            );
          });
        },
      },
      {
        title: "Location",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div className="d-flex justify-content-between mb-2">
              {data.location}
              <div className="p-0">
                <span className="mt-2 color-sky-blue">View/edit</span>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.onDownloadClick();

              this.setState({ forDownload: true });
              // this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 160,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          console.log(data, index);
          return (
            <div className="row m-0">
              <div className="col-6">
                <img
                  src={
                    !data.isSelected
                      ? Images.circleIcon
                      : Images.circleIconSelected
                  }
                  className="img-fluid "
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectBus(index);
                  }}
                />
              </div>
              <div className="col-4 p-0">
                <img src={Images.nextIcon} className="img-fluid " alt="" />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div
        className={
          this.state.role === "PRIMARY" || this.state.busDetails.length === 0
            ? "massUploadLayout"
            : ""
        }
      >
        <div className="row m-0 mt-4">
          <div className="col-5  pt-3">
            <Search
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              isShowFilter={this.state.filterData.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "regNumber",
                      });
                    }}
                  ></SortContainer>

                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "regNumber",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            <div>
              <Button
                title="Create new vehicle"
                primary
                rounded
                onClick={() => {
                  this.props.history.push("/school/vehicle/create");
                }}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                title="Edit"
                white
                rounded
                onClick={this.editBus}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                onClick={() => {
                  // this.toggleDeleteModal();
                  this.deleteBus();
                }}
                title="Delete"
                white
                rounded
              ></Button>
            </div>
          </div>
        </div>
        {this.state.instituteId !== "" && (
          <React.Fragment>
            <Query
              query={GET_VEHICLE_FILTERS}
              variables={{
                instituteId: this.state.instituteId,
              }}
              fetchPolicy={"network-only"}
              onCompleted={(data) => {
                if (data.createUniqueFiltersForVehicle) {
                  let filterData = data.createUniqueFiltersForVehicle.data
                    .map((data) => {
                      if (data.label) {
                        if (data.minValue && data.maxValue) {
                          data.defaultMinValue = data.minValue;
                          data.defaultMaxValue = data.maxValue;
                        }
                        if ((data.fieldValues || []).length) {
                          data.fieldValues = data.fieldValues.map((field) => {
                            return {
                              label: field,
                              isChecked: false,
                            };
                          });
                        }
                        return data;
                      }
                    })
                    .filter((data) => data);
                  //console.log(filterData);
                  this.setState({
                    filterData: filterData, //data.createUniqueFiltersForInstitute.data,
                  });
                }
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>
            {this.state.refetch && (
              <Query
                query={GET_VEHICLE_DETAILS}
                fetchPolicy={"network-only"}
                variables={{
                  instituteId: this.state.instituteId,
                  searchTerm: this.state.searchTerm,
                  sortCol: this.state.sortCol,
                  sort: this.state.sortStr,
                  filter: this.state.currFilter,
                }}
                onCompleted={(data) => {
                  if (data.getVehicleDetails) {
                    let busDetails = [];

                    busDetails = data.getVehicleDetails.map((data) => {
                      data.vehicleNo = data.vehicleId;
                      data.isSelected = false;
                      if ((data.schedule || []).length) {
                        data.tripDetails = data.schedule.map((schedule) => {
                          return {
                            routeName: schedule.route
                              ? schedule.route.routeName
                              : "",
                            routeId: schedule.route
                              ? schedule.route.routeId
                              : "",
                            regNumber: schedule.vehicle
                              ? schedule.vehicle.regNumber
                              : "",
                            timing: schedule.route
                              ? schedule.route.timings
                              : "",
                            driverName: schedule.driver
                              ? schedule.driver.firstName +
                                " " +
                                schedule.driver.lastName
                              : "",
                            mobileNumber: schedule.driver
                              ? schedule.driver.mobileNumber
                              : "",
                            stops: schedule.route
                              ? schedule.route.stops
                                ? schedule.route.stops
                                : []
                              : [],
                            students: schedule.route
                              ? schedule.route.students
                                ? schedule.route.students
                                : []
                              : [],
                            status: true,
                          };
                        });
                      } else {
                        data.tripDetails = data.trips.map((trip) => {
                          return {
                            routeName: trip.route ? trip.route.routeName : "",
                            routeId: trip.route ? trip.route.routeId : "",
                            regNumber: trip.vehicle
                              ? trip.vehicle.regNumber
                              : "",
                            timing: trip.route ? trip.route.timings : "",
                            driverName: trip.driver
                              ? trip.driver.firstName +
                                " " +
                                trip.driver.lastName
                              : "",
                            mobileNumber: trip.driver
                              ? trip.driver.mobileNumber
                              : "",
                            stops: trip.route
                              ? trip.route.stops
                                ? trip.route.stops
                                : []
                              : [],
                            students: trip.route
                              ? trip.route.students
                                ? trip.route.students
                                : []
                              : [],
                            status: false,
                          };
                        });
                      }
                      return data;
                    });
                    this.setState({ busDetails });
                  }
                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ hasErr: true });

                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                }}
              >
                {({ data, loading, error }) => {
                  return <Loading is_loading={loading} />;
                }}
              </Query>
            )}
          </React.Fragment>
        )}
        <div className="col-12 mt-5">
          <Table
            useFixedHeader
            columns={columns}
            data={this.state.busDetails}
            scroll={{
              x: 1200,
              y:
                this.state.role === "PRIMARY" ||
                this.state.busDetails.length === 0
                  ? "calc(100vh - 140px - 75px - 64px - 86px )"
                  : "calc(100vh - 140px - 75px - 64px )",
            }}
            emptyText="No Vehicles Found!!"
            //title={setTableTitle}
          />
        </div>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          centered
          size="md"
        >
          <ModalStyle className="p-3">
            {/* <FileUpload></FileUpload> */}
            <div className="header text-center mb-4">
              Mass signup - Vehicles
            </div>
            <div className="close" onClick={this.toggleModal}>
              <img src={Images.CloseIcon} alt="close" />
            </div>
            <Mutation
              variables={{
                data: this.state.bulkVehicles,
              }}
              mutation={VEHICLE_BULK_SIGNUP}
              onError={(error) => {
                if (!error || !error.message) return null;
                sendNotification({
                  message: error.message.replace("GraphQL error: ", ""),
                  type: "danger",
                });
                // this.showNotificationError(
                //   error.message.replace("GraphQL error: ", "")
                // );
              }}
              onCompleted={(data) => {
                if (data.vehicleBulkImport) {
                  this.setState({ refetch: false }, () => {
                    this.setState({ refetch: true });
                    this.toggleModal();
                  });
                  this.props.history.push("/school/vehicles");
                  sendNotification({
                    message: "Vehicles created successfully",
                    type: "success",
                  });
                }
              }}
            >
              {(BulkBus, { data, loading, error }) => {
                return (
                  <React.Fragment>
                    <Loading is_loading={loading} />
                    {this.state.resetFile && (
                      <FileUpload
                        errData={this.state.DataErr}
                        count={this.state.bulkVehicles.length}
                        onFileUpload={this.onFileUpload}
                        onHandleSubmit={() => {
                          if (this.state.bulkVehicles.length) {
                            BulkBus();
                          } else {
                            sendNotification({
                              message: "No Vehicles available",
                              type: "danger",
                            });
                          }
                        }}
                        data="vehicles"
                      ></FileUpload>
                    )}
                  </React.Fragment>
                );
              }}
            </Mutation>
          </ModalStyle>
        </Modal>

        <PictureModel
          fileInfo={`${this.state.selectedBus.vehicleNo}-${this.state.selectedDocument}`}
          image={this.state.selectedImage}
          isOpen={this.state.pictureModelVisible}
          title={this.state.selectedTitle}
          desc={this.state.selectedDesc}
          toggleModal={() => {
            this.setState({ pictureModelVisible: false });
          }}
        ></PictureModel>
        <DeleteModal
          isDeleteOpen={this.state.isDeleteOpen}
          modelTitle="Delete Vehicle"
          modelDesc={`Are you sure you want to remove ${
            this.state.selectedId.length <= 1
              ? this.state.selectedBus.vehicleNo
              : `${this.state.selectedId.length} vehicles`
          }  from the Scabbr
        platform?`}
          modelImage={this.state.selectedBus.rcbook}
          heading={`Vehicle ID :${this.state.selectedBus.vehicleNo} `}
          subHeading={`Reg No: ${this.state.selectedBus.regNumber}`}
          deleteMutation={DELETE_VEHICLE}
          mutationVariables={{
            vehicleIds: this.state.selectedId,
            instituteId: this.state.instituteId,
          }}
          selectedIds={this.state.selectedId}
          onMutationCompleted={() => {
            this.setState({ refetch: false }, () => {
              this.setState({ refetch: true });
            });
          }}
          mutationKey="deleteVehicle"
          toggleDeleteModal={this.toggleDeleteModal}
          modelMsg={`${
            this.state.selectedId.length <= 1
              ? (this.state.selectedBus.vehicleNo || "").toUpperCase()
              : `${this.state.selectedId.length} VEHICLES`
          } HAS BEEN REMOVED FROM THE PLATFORM`}
          buttonText={"Remove Vehicle"}
        ></DeleteModal>
        {(this.state.role === "PRIMARY" ||
          this.state.busDetails.length === 0) && (
          <MassUploadComponent className="d-flex justify-content-between">
            <div>
              <Button
                onClick={() => {
                  this.onDownloadQR();
                }}
                title="Download QR"
                primary
                rounded
              ></Button>
            </div>
            <div className="d-flex">
              <div className="mr-3">
                <Button
                  onClick={this.toggleModal}
                  title="Mass Signup"
                  primary
                  rounded
                ></Button>
              </div>
              <a
                className="shareTemplate"
                download
                href={Resources.VehicleResource}
              >
                <Button title="Get template" white rounded></Button>
              </a>
            </div>
            {/* <Button title="Share template" white rounded></Button> */}
          </MassUploadComponent>
        )}

        <Modal
          isOpen={this.state.isModalOpen}
          toggle={this.toggleViewModal}
          centered
          size="md"
        >
          <ModalStyle>
            <React.Fragment>
              <div className="border-bottom pb-3 mb-3">
                <div className="d-flex">
                  <div className="title col-3 p-0">Bus Details</div>
                  <div className="semiTitle">{this.state.modalData.busId}</div>
                </div>
                <div className="d-flex">
                  <div className="title col-3 p-0">Route ID</div>
                  <div className="semiTitle">
                    {this.state.modalData.routeId}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="title col-3 p-0">Route Name</div>
                  <div className="semiTitle">
                    {this.state.modalData.routeName}
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <div className="listTitle">
                    List of {this.state.modalData.type}
                  </div>
                </div>
              </div>
              <div>
                {this.state.modalData.allData.map((data, index) => {
                  return (
                    <div
                      key={`Students-${index}`}
                      className="d-flex justify-content-between align-items-center mt-2 pointer-cursor"
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="">
                          <div>{data.name}</div>
                          <div>{data.desc}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="d-flex justify-content-end mt-3">
                  <Button
                    title="Cancel"
                    primaryTransparent
                    rounded
                    onClick={this.toggleViewModal}
                  ></Button>
                </div>
              </div>
            </React.Fragment>
          </ModalStyle>
        </Modal>
      </div>
    );
  }
}

export default BusList;
