import React from "react";
import styled, { keyframes } from "styled-components";
const LoadingCircle = keyframes`
  100% {
    transform: rotate(360deg);
}
`;
const LoadingContainer = styled.div`
  .icon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .spin {
    display: inline-block;

    animation: ${LoadingCircle} 1s infinite linear;
  }
  &.opacity-black-5 {
    background: rgba(0, 0, 0, 0.6);
  }
  &.z-index-999 {
    z-index: 9999;
  }
`;
const Loading = props => {
  return (
    <span>
      {props.is_loading && (
        <LoadingContainer
          className={`${
            props.absolute
              ? "position-absolute absolute-loading opacity-white-5"
              : "vh-100 position-fixed opacity-black-5"
          } ${
            props.transparent ? "opacity-transparent" : ""
          } z-index-999 align-items-center col-12 fixed-top d-flex justify-content-center`}
        >
          <svg
            viewBox="0 0 1024 1024"
            focusable="false"
            className="spin"
            data-icon="loading"
            width="3em"
            height="3em"
            fill="#fff"
            aria-hidden="true"
          >
            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
          </svg>
        </LoadingContainer>
      )}
    </span>
  );
};

export default Loading;
