import PropTypes from "prop-types";
import React, { Component } from "react";
import InputStyle from "./style/input";
export class ReactSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      showPwd: false,
      error: false,
    };
  }
  onChange = (event) => {
    // if (this.props.maxLength) {
    //   if (this.props.maxLength > event.target.value.length) {
    //     this.props.onChange(event.target.value);
    //   }
    // } else {
    this.props.onChange(event.target.value);
    // }
  };
  onFocused = () => {
    this.setState({ error: false });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };
  onTouched = () => {
    this.setState({ touched: true }, () => {
      if (this.props.validation)
        if (this.props.validation.length) {
          const errorList = this.props.validation
            .map((validate) => {
              if (validate(this.props.value)) {
                return validate;
              }
              return undefined;
            })
            .filter((data) => data);

          if (errorList.length) {
            this.setState({ error: true });
          } else {
            this.setState({ error: false });
          }
        }
    });
    // this.form.validateAll();
  };

  render() {
    return (
      <InputStyle
        password={this.props.type === "password"}
        white={this.props.white}
        small={this.props.small}
        className={`position-relative  ${
          this.props.required && !this.state.touched
            ? this.props.value === ""
              ? "inputRequired"
              : ""
            : ""
        } ${
          this.state.touched
            ? `touched ${
                this.props.required && this.props.value === ""
                  ? "invalid form-error"
                  : ""
              }`
            : ""
        } ${this.state.error ? "invalid form-error" : ""} ${
          this.props.relativeClass ? this.props.relativeClass : ""
        }`}
      >
        <div className="mt-0">
          {this.props.description && (
            <p className="text--small mb-2">{this.props.description}</p>
          )}
        </div>
        {/* <div className="lineBorder" /> */}
        <select
          value={this.props.value}
          validations={this.props.validations ? this.props.validations : []}
          onChange={this.onChange}
        >
          {this.props.options.map((option, index) => {
            return (
              <option key={`${this.props.name}-${index}`} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
        {/* <Select
          disabled={this.props.disabled}
          //onBlur={this.onTouched}
          min={this.props.min || ""}
          type={
            this.props.type === "password"
              ? this.state.showPwd
                ? "text"
                : "password"
              : this.props.type
          }
          // defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          value={this.props.value}
          validations={this.props.validations ? this.props.validations : []}
          required={this.props.required}
          //onFocus={this.onFocused}
          className={`${this.props.className ? this.props.className : ""} ${
            this.props.disabled ? "disabled" : ""
          }`}
          // {...this.props}
          //onChange={this.onChange}
        >
          <option>1</option>
        </Select> */}
        {/* {this.props.type === "password" && (
          <div
            className="passwordIcon pointer-cursor"
            onClick={() => {
              this.setState({ showPwd: !this.state.showPwd });
            }}
          >
            {this.state.showPwd ? (
              <Image src={Images.PasswordStrikeEye}></Image>
            ) : (
              <Image src={Images.PasswordEye}></Image>
            )}
          </div>
        )} */}
        {this.props.icon && (
          <div
            className="passwordIcon pointer-cursor"
            onClick={() => {
              this.setState({ showPwd: !this.state.showPwd });
            }}
          >
            {this.props.iconSource}
          </div>
        )}

        {this.props.maxLength && this.props.value !== undefined && (
          <p className="text--tiny">
            {this.props.maxLength - this.props.value.length}
          </p>
        )}
        {this.props.required &&
          this.props.value === "" &&
          this.state.touched && <span className="text-tiny error"></span>}
      </InputStyle>
    );
  }
}
ReactSelect.defaultProps = {
  options: [],
};
ReactSelect.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,

  maxLength: PropTypes.number,
  validation: PropTypes.array,
  required: PropTypes.bool,
  className: PropTypes.string,
  white: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  min: PropTypes.number,
  showPwd: PropTypes.bool,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.object,
  background: PropTypes.string,
};
export default ReactSelect;
