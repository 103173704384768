import PropTypes from "prop-types";
import React from "react";
import Images from "../Themes/Images";

const SortContainer = (props) => {
  return (
    <div
      className="w-100 d-flex justify-content-between pointer-cursor align-items-start"
      onClick={() => {
        props.onClick(props.stateKey);
      }}
    >
      <div className={`sortText ${props.active ? "active" : ""}`}>
        {props.title}
        {props.subTitle && (
          <div className="sortType small text-right">{props.subTitle}</div>
        )}
      </div>
      {props.active && <img className={"mr-2 mt-2"} src={Images.TickIcon} />}
    </div>
  );
};
SortContainer.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  key: PropTypes.string,
};

export default SortContainer;
