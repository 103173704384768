import { GoogleApiWrapper, Marker } from "google-maps-react";
import moment from "moment";
import React, { Component } from "react";
import { Query } from "react-apollo";
import styled from "styled-components";
import ReactAvatar from "../../../Component/Avatar";
import Button from "../../../Component/Form/button";
import Loading from "../../../Component/Loading";
import CurrentLocation from "../../../Component/MapContainer";
import Images from "../../../Themes/Images";
import { sendNotification } from "../../../utils";
import { GET_BIRDS_VIEW } from "../../graphql";
const BirdViewContainer = styled.div`
  .menu div {
    font-size: 16px;
    color: #008f56;
    padding: 15px;
    line-height: 18px;
    font-weight: 600;
    opacity: 0.59;
    &:nth-child(2) {
      color: #dd455d;
    }
    &.active {
      opacity: 1;
    }
  }
`;
const DriverStyle = styled.div`
  padding: 10px;
  padding-top: 12px;
  background: transparent;
  &.active {
    background: #b9cbff;
  }
  .time,
  .title,
  .desc {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    color: #343434;
  }
  .time {
    opacity: 0.6;
    line-height: 14px;
    font-size: 11px;
  }
  .title,
  .desc {
    line-height: 18px;
    opacity: 0.6;
    font-size: 14px;
  }
  .title {
    opacity: 1;
    font-weight: 600;
    color: #000000;
  }
`;
const DriverSideBar = styled.div`
  .title,
  .desc {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    color: #343434;
    line-height: 18px;
    font-size: 14px;
  }
  .semiBold {
    font-weight: 600;
  }
  .color-blue {
    color: #004584;
  }
  .regular {
    font-weight: 400;
    opacity: 0.6;
  }

  .padding {
    padding: 12px;
    padding-left: 46px;
  }
`;

const DriverContainer = (props) => {
  return (
    <DriverStyle className={`${props.active ? "active" : ""} pointer-cursor`}>
      <div className="d-flex justify-content-between">
        <div></div>
        <div className="time d-none">Updated: {moment().fromNow()}</div>
      </div>
      <div className="mt-2 d-flex">
        <ReactAvatar
          name={props.name}
          size="50px"
          round="8px"
          src={props.displayPicture}
        ></ReactAvatar>
        <div className="ml-3">
          <div className="title mb-2">{props.name}</div>
          <div className="desc">{props.mobileNumber}</div>
        </div>
      </div>
      <div className="desc mt-2">{props.vehicleNo}</div>
    </DriverStyle>
  );
};
export class BirdView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "active",
      stops: [{}, {}],
      instituteId: "",
      position: [],

      birdViews: [],
      activeBus: "",
      viewAll: true,
      activeIndex: 0,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    this.setState({ instituteId });
  }

  render() {
    return (
      <BirdViewContainer className="row m-0 mt-3">
        {this.state.instituteId !== "" && (
          <Query
            variables={{ instituteId: this.state.instituteId }}
            query={GET_BIRDS_VIEW}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (data.getBirdsView) {
                let position = data.getBirdsView
                  .map((data) => {
                    if (data.schedule.length) {
                      if (data.schedule[0].currentLocation) {
                        let [lat, lng] = data.schedule[0].currentLocation.split(
                          ","
                        );
                        return {
                          lat,
                          lng,
                        };
                      }
                    }
                  })
                  .filter((data) => data);
                this.setState({ birdViews: data.getBirdsView, position });
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <div className="col-2 p-0">
          <div className="d-flex justify-content-between menu">
            <div
              className={`pointer-cursor ${
                this.state.activeItem === "active" ? "active" : ""
              }`}
              onClick={() => {
                this.setState({ activeItem: "active" });
              }}
            >
              Active
            </div>
            <div
              className={`pointer-cursor ${
                this.state.activeItem === "inactive" ? "active" : ""
              }`}
              onClick={() => {
                this.setState({ activeItem: "inactive" });
              }}
            >
              Inactive
            </div>
          </div>
          <div className="vh-100 overflow-scroll">
            {this.state.birdViews.map((view, index) => {
              if (
                (view.schedule.length === 0 &&
                  this.state.activeItem === "inactive") ||
                (view.schedule.length !== 0 &&
                  this.state.activeItem === "active")
              ) {
                return (
                  <div
                    key={`BirdViews-${index}`}
                    onClick={() => {
                      this.setState({ viewAll: false, activeIndex: index });

                      this.setState({ activeBus: view });
                    }}
                  >
                    <DriverContainer
                      name={view.firstName || "" + view.lastName}
                      mobileNumber={view.mobileNumber || ""}
                      vehicleNo={
                        (view.schedule || []).length
                          ? (view.schedule[0].vehicle || {}).regNumber
                          : ""
                      }
                    ></DriverContainer>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div
          className={`${
            this.state.activeBus !== "" && !this.state.viewAll
              ? "col-7"
              : "col-10"
          }  p-0`}
        >
          <div
            className="position-relative"
            style={{
              height: 500,
              borderRadius: 10,
              overflow: "hidden",
              // height: "100vh",
              width: "100%",
            }}
          >
            <div
              className="position-absolute col-12 p-0 h-100"
              style={{ zIndex: 99 }}
            >
              <CurrentLocation
                google={this.props.google}
                addMarker={() => {}}
                currentLocation={
                  !this.state.position.length
                    ? { lat: 12.972442, lng: 77.580643 }
                    : {
                        lat: this.state.position[0].lat,
                        lng: this.state.position[0].lng,
                      }
                }
                initialCenter={
                  !this.state.position.length
                    ? { lat: 12.972442, lng: 77.580643 }
                    : {
                        lat: this.state.position[0].lat,
                        lng: this.state.position[0].lng,
                      }
                }
              >
                {this.state.position.map((data, index) => {
                  if (!this.state.viewAll || this.state.activeIndex === index) {
                    return (
                      <Marker
                        key={`marker-${index}`}
                        onClick={() => {}}
                        name={"current location"}
                        position={{
                          lat: data.lat,
                          lng: data.lng,
                        }}
                      />
                    );
                  }
                })}
              </CurrentLocation>
            </div>
          </div>
          <div className="mt-3 text-center">
            <div className="d-inline-block">
              <Button
                rounded
                primary
                title={"View all"}
                onClick={() => {
                  this.setState({ viewAll: !this.state.viewAll });
                  // this.state.disableService();
                  // DeleteAdmin();
                }}
              ></Button>
            </div>
          </div>
        </div>
        {!this.state.viewAll && this.state.activeBus !== "" && (
          <div className="col-3 p-0">
            <DriverContainer
              name={
                this.state.activeBus.firstName ||
                "" + this.state.activeBus.lastName
              }
              mobileNumber={this.state.activeBus.mobileNumber || ""}
              vehicleNo={
                (this.state.activeBus.schedule || []).length
                  ? (this.state.activeBus.schedule[0].vehicle || {}).regNumber
                  : ""
              }
            ></DriverContainer>
            {this.state.activeBus.schedule.length !== 0 && (
              <DriverSideBar>
                <div className="bg-white padding">
                  <div className="d-flex">
                    <div className="title color-blue semiBold">Bus no:</div>
                    <div className="desc regular ml-2">
                      {
                        (this.state.activeBus.schedule[0].vehicle || {})
                          .regNumber
                      }
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="title color-blue semiBold">
                      No of stops:
                    </div>
                    <div className="desc regular ml-2">
                      {
                        (this.state.activeBus.schedule[0].route || {}).stops
                          .length
                      }
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="title color-blue semiBold">Route ID:</div>
                    <div className="desc regular ml-2">
                      {(this.state.activeBus.schedule[0].route || {}).routeId}
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="title color-blue semiBold">Trip ID:</div>
                    <div className="desc regular ml-2">
                      {" "}
                      {(this.state.activeBus.schedule[0].trip || {}).tripId}
                    </div>
                  </div>
                </div>
                <div className="padding pl-3">
                  <div className="title semiBold">Current status</div>
                  <div className="desc regular mt-2 mb-3">
                    {" "}
                    {
                      this.state.activeBus.schedule[0].path.filter((path) => {
                        if (path.reachTime) {
                          return path;
                        }
                      }).length
                    }
                    /
                    {
                      (this.state.activeBus.schedule[0].route || {}).stops
                        .length
                    }{" "}
                    stop completed
                  </div>
                  {(this.state.activeBus.schedule[0].route || {}).stops.map(
                    (data, index) => {
                      return (
                        <div className="d-flex" key={`Stops-${index}`}>
                          <img
                            src={
                              index + 1 ===
                              (
                                (this.state.activeBus.schedule[0].route || {})
                                  .stops || []
                              ).length
                                ? Images.StopIcon
                                : Images.StopPinIcon
                            }
                            width={
                              index + 1 ===
                              (
                                (this.state.activeBus.schedule[0].route || {})
                                  .stops || []
                              ).length
                                ? 24
                                : 24
                            }
                            height={
                              index + 1 ===
                              (
                                (this.state.activeBus.schedule[0].route || {})
                                  .stops || []
                              ).length
                                ? 24
                                : 86
                            }
                          />
                          <div className="w-100 ml-3">
                            <div className="semiBold title d-flex justify-content-between">
                              <div>{data.stopId}</div>
                              <div>
                                {!data.stopName && (
                                  <div
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 14,
                                    }}
                                    className="desc regular"
                                  >
                                    12.05
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="regular desc"
                              style={{ marginBottom: 50 }}
                            >
                              {data.stopName}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </DriverSideBar>
            )}
          </div>
        )}
      </BirdViewContainer>
    );
  }
}

// export default BirdView
export default GoogleApiWrapper({
  apiKey: "AIzaSyBuR4HOfDQUy2xqT4dhwkoRcVy9nvbYstE",
})(BirdView);
