import { Map } from "google-maps-react";
import React from "react";
import ReactDOM from "react-dom";

const mapStyles = {
  map: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
};
export class CurrentLocation extends React.Component {
  constructor(props) {
    super(props);

    const { lat, lng } = this.props.initialCenter;
    this.state = {
      currentLocation: {
        lat: lat,
        lng: lng,
      },
    };
  }
  componentDidMount() {
    if (this.props.centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const coords = pos.coords;
          this.setState({
            currentLocation: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          });
        });
      }
    }
    this.loadMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }
    if (prevState.currentLocation !== this.state.currentLocation) {
      this.recenterMap();
    }
  }

  loadMap() {
    // setTimeout(() => {
    //   this.props.setReference(this.refs.map);
    // }, 5000);

    return;
    if (this.props && this.props.google) {
      // checks if google is available
      const { google } = this.props;
      const maps = google.maps;

      const mapRef = this.refs.map;

      // reference to the actual DOM element
      const node = ReactDOM.findDOMNode(mapRef);

      let { zoom } = this.props;
      const { lat, lng } = this.state.currentLocation;
      const center = new maps.LatLng(lat, lng);
      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
          onClick: (t, map, c) => {
            console.log(t, map, c);
            //this.addMarker(c.latLng, map)
          },
        }
      );
      // maps.Map() is constructor that instantiates the map
      this.map = new maps.Map(node, mapConfig);
    }
  }

  recenterMap() {
    const map = this.map;
    const current = this.state.currentLocation;

    const google = this.props.google;
    const maps = google.maps;

    if (map) {
      let center = new maps.LatLng(current.lat, current.lng);
      map.panTo(center);
    }
  }

  renderChildren() {
    const { children } = this.props;

    if (!children) return;

    return React.Children.map(children, (c) => {
      if (!c) return;
      return React.cloneElement(c, {
        map: this.map,
        google: this.props.google,
        mapCenter: this.state.currentLocation,
      });
    });
  }
  addMarker = (location, map) => {
    // this.setState(prev => ({
    //   fields: {
    //     ...prev.fields,
    //     location
    //   }
    // }));
    this.props.addMarker(location.lat(), location.lng());
    map.panTo(location);
  };
  render() {
    const style = Object.assign({}, mapStyles.map);

    return (
      <div className="position-relative w-100 h-100">
        {/* <div style={style} ref="map">
          Loading map...
        </div> */}
        <Map
          google={this.props.google}
          style={{
            width: "100%",
            height: "100%",
          }}
          initialCenter={this.props.currentLocation}
          center={this.props.currentLocation}
          zoom={this.props.zoom}
          onClick={(t, map, c) => this.addMarker(c.latLng, map)}
        >
          {this.renderChildren()}
        </Map>
      </div>
    );
  }
}
export default CurrentLocation;

CurrentLocation.defaultProps = {
  zoom: 14,
  initialCenter: {
    lat: 12.972442,
    lng: 77.580643,
  },
  centerAroundCurrentLocation: false,
  visible: true,
};
