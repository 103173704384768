import moment from "moment";
import PropTypes from "prop-types";
import { store } from "react-notifications-component";
var FileSaver = require("file-saver");

export const getToken = () => {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
};
export const generateRandomString = (length) => {
  var text = "";
  var possibleChars = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++)
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length)
    );
  return text;
};
export const sendNotification = (data) => {
  store.addNotification({
    title: data.title || data.type === "danger" ? "Something Went Wrong" : "",
    message: data.message || "",
    type: data.type || "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: false,
    },
    width: 300,
  });
};
sendNotification.propTypes = {
  title: PropTypes.string,
};

export async function getS3UploadUrl(fileName, contentType, uploadFor) {
  // const query = `{"operationName":null,"variables":{},"query":"{\\n  getS3SignedUrl(key: \\"${fileName}\\", contentType: \\"${contentType}\\") {\\n    signedUrl\\n  }\\n}\\n"}`;
  // const { data: uploadLink } = await post(
  //   `${process.env.REACT_APP_API}`,
  //   query,
  //   {
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   }
  // );
  let query = `mutation {\\n  getSignedUrl(uploadFor: ${uploadFor}, userToken: \\"\\", insToken: \\"\\", data: [{contentType: \\"${contentType}\\", filename: \\"${fileName}\\", oldFilePath: \\"e.png\\"}]) {\\n    signedUrl\\n  }\\n}\\n`;
  const { data: uploadLink } = await fetch(`https://api.console.scabbr.com`, {
    // credentials: "omit",
    headers: {
      "content-type": "application/json",
    },
    // referrerPolicy: "no-referrer-when-downgrade",
    body: '{"operationName":null,"variables":{},"query":' + query + "}",
    method: "POST",
    // mode: "cors"
  });
  return uploadLink.data.getS3SignedUrl.signedUrl;
}

export function uploadFiles(urlList, fileList) {
  console.log(fileList, urlList);
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map(
      (file, index) =>
        new Promise((resolveUpload, rejectUpload) => {
          const xhr = new XMLHttpRequest();
          xhr.file = file;
          xhr.onreadystatechange = function (e) {
            if (this.readyState === 4 && this.status === 200) {
              resolveUpload(urlList[index]);
            } else if (this.readyState === 4 && this.status !== 200) {
              rejectUpload(e);
            }
          };
          xhr.open("put", urlList[index], true);
          if (
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "application/pdf"
          ) {
            xhr.setRequestHeader("Content-Type", file.type);
          } else if (file.type === "audio/wav" || file.type === "audio/webm") {
            xhr.setRequestHeader("Content-Type", file.type);
          } else {
            xhr.setRequestHeader("Content-Type", "binary/octet-stream");
          }
          xhr.send(file);
        })
    );
    console.log(formUploadPromiseArray);

    Promise.all(formUploadPromiseArray)
      .then((urls) => {
        console.log(urls);
        resolve(
          urls.map((url) => {
            console.log(url);
            return url.split("?")[0];
          })
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function DownloadCSV(fileInfo, data) {
  const csvContent =
    "data:text/csv;charset=utf-8," + data.map((e) => e.join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${fileInfo}-${moment().format("DD-MM-YY")}.csv`
  );
  document.body.appendChild(link); // Required for FF

  link.click();
  link.remove();
}

const toDataURL = (url) => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};
export async function DownloadImage(fileInfo, data) {
  var link = document.createElement("a");
  link.href = await toDataURL(data);
  var re = /(?:\.([^.]+))?$/;

  var ext = re.exec(data)[1];
  if (re.exec(data)[0].includes(`.com`)) {
    ext = null;
  }
  link.download = `${fileInfo}-${moment().format("DD-MM-YY")}.${
    ext ? ext : "png"
  }`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return;
}
export function DownloadQR(fileInfo, data, id) {
  FileSaver.saveAs(data, `${fileInfo}-${moment().format("DD-MM-YY")}.png`);
  return;
}
