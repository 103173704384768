import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
const SliderRange = (props) => {
  return (
    <Range
      allowCross={false}
      min={props.min}
      max={props.max}
      value={[props.valueMin, props.valueMax]}
      onChange={(e) => {
        // console.log(e);
        props.onSliderChange(e);
      }}
      defaultValue={[props.min, props.max]}
      trackStyle={[{ backgroundColor: "#004A87", height: "2px" }]}
      handleStyle={[
        {
          backgroundColor: "#fff",
          borderColor: "#fff",
          marginTop: "-6px",
          boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.13)",
        },
        {
          backgroundColor: "#fff",
          borderColor: "#fff",
          marginTop: "-6px",
          boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.13)",
        },
      ]}
      railStyle={{ backgroundColor: "#ccc", height: "2px" }}
    />
  );
};

export default SliderRange;
