export const colors = {
  primaryColor: "#3F3FD5",
  gradientColor:
    "linear-gradient(45deg, rgba(5,147,219,1) 35%, rgba(80,38,210,1) 100%)",
  inactiveGradientColor:
    "linear-gradient(to right, rgb(228, 77, 38), rgb(241, 101, 41))",
  secondaryColor: "#08366A",
  defaultBorderColor: "#979797",
  defaultBackgroundColor: "#EFF1F7",
  inputBackground: "#EDEEF3",
  headingColor: "#333",
  subheadingColor: "#999",
  errorColor: "#DD455D",
  placeholderColor: "#343434",
  colorWhite: "#fff",
  colorBlack: "#212121",
  colorDefaultBlack: "#000",
  colorBlue: "#004584",
  tableHeader: "#CFDBFD",
  tableRowColor: "#EAF2FC",
  tableRowColorAlternate: "#fff",
  warningColor: "#FFD248",
  successColor: "#2AD792",
  grayColor: "#919191",
};
