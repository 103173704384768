import moment from "moment";
import Table from "rc-table";
import React, { Component } from "react";
import { Query } from "react-apollo";
import ReactAvatar from "react-avatar";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import Loading from "../../../Component/Loading";
import PictureModel from "../../../Component/PictureModel";
import Images from "../../../Themes/Images";
import { DownloadImage, sendNotification } from "../../../utils";
import { GET_STUDENT_BY_ID } from "../../graphql";
const SubscriptionContainer = styled.div`
  width: 100%;
  padding: 25px;
  padding-left: 15px;
  padding-right: 15px;

  background: #cfdbfd;
`;
const ViewStudentStyle = styled.div`
  .dp {
    width: 106px;
    height: 106px;
    border-radius: 5px;
  }
  .title {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 19px;
    font-size: 15px;
    color: #000;
  }
  .desc {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    line-height: 19px;
    font-size: 15px;
    color: #000;
  }
  .titleContent {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 21px;
    font-size: 17px;
    color: #08366a;
  }
  .b-left {
    border-left: 1px solid;
    border-color: #004584;
  }
`;
const ViewStopContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  width: 75%;
  .subDesc {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    line-height: 16px;
    font-size: 13px;
    color: #3f3fd5;
  }
`;
export class ViewStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StudentsView: {},
      pictureModelVisible: false,
      tripDetails: [],
    };
  }
  viewPictureModal = (title, desc, image) => {
    this.setState({
      pictureModelVisible: true,
      selectedTitle: title,
      selectedDesc: desc,
      selectedImage: image,
      selectedDocument: title.replace(/[^a-zA-Z]/gi, "-").toLowerCase(),
    });
  };
  render() {
    const columns = [
      {
        title: "Date",
        dataIndex: "tripDate",
        key: "tripDate",
        width: 100,
        fixed: "left",
      },

      {
        title: "Trip type",
        dataIndex: "tripType",
        key: "tripType",
        width: 100,
      },
      {
        title: "Stop name",
        dataIndex: "stopName",
        key: "stopName",
        width: 150,
      },
      {
        title: "Bus number",
        dataIndex: "busNumber",
        key: "busNumber",
        width: 150,
      },
      {
        title: "Driver name",
        dataIndex: "driverName",
        key: "driverName",
        width: 150,
      },

      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.setState({ forDownload: true });
              this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 160,
        key: "operations",
        fixed: "right",
      },
    ];
    return (
      <ViewStudentStyle>
        {this.props.match.params.id && (
          <Query
            variables={{ id: this.props.match.params.id }}
            fetchPolicy="network-only"
            query={GET_STUDENT_BY_ID}
            onCompleted={(data) => {
              if (data.getStudentById) {
                let StudentsView = {
                  id: this.props.match.params.id,
                  QRkey: data.getStudentById.QRkey,
                  profilePicture: data.getStudentById.displayPicture,
                  name:
                    data.getStudentById.firstName +
                    " " +
                    data.getStudentById.lastName,
                  studentId: data.getStudentById.studentId,
                  dob: data.getStudentById.dob,
                  // dateOfBirth: new Date(data.getStudentById.dob).getDate(),
                  // monthOfBirth:
                  //   new Date(data.getStudentById.dob).getMonth() + 1,
                  //   yearOfBirth: new Date(data.getStudentById.dob).getFullYear(),
                  class: data.getStudentById.class || "",
                  section: data.getStudentById.section || "",
                  bloodGroup: data.getStudentById.bloodGroup,
                  address: data.getStudentById.address,
                  instituteUpto: moment(
                    (data.getStudentById.institute || {}).validUpto
                  ).format("DD/MM/YY"),
                  institutePlan:
                    (data.getStudentById.institute || {}).subscription || "",
                  fatherName: data.getStudentById.fatherName,
                  fatherMobileNo: data.getStudentById.fatherMobileNo,
                  fatherMobileNoOld: data.getStudentById.fatherMobileNo || "",
                  fatherOccupation: data.getStudentById.fatherOccupation,
                  motherName: data.getStudentById.motherName,
                  motherMobileNoOld: data.getStudentById.motherMobileNo || "",
                  motherMobileNo: data.getStudentById.motherMobileNo,
                  motherOccupation: data.getStudentById.motherOccupation,
                  roleNo: data.getStudentById.studentId,
                  activeTab: "1",
                  imageURL: data.getStudentById.displayPicture,
                  pickupStop: data.getStudentById.stop,
                  dropStop: data.getStudentById.drop,
                };
                let fileInfo = data.getStudentById.displayPicture
                  ? { profilePicture: { name: "dp.png" } }
                  : {};
                this.setState(
                  {
                    StudentsView,
                    fileInfo,
                  },
                  () => {}
                );

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}

        <div className="row m-0">
          <SubscriptionContainer className="mb-3 d-flex justify-content-between">
            <div className="d-flex align-items-center justify-content-center">
              <img src={Images.rssIcon} className="img-fluid " alt="" />
              <div className="title ml-2">{`Subscription valid till ${
                this.state.StudentsView.instituteUpto || ""
              } (${this.state.StudentsView.institutePlan || ""})`}</div>
            </div>
            <div className="d-flex justify-content-end">
              <div>
                <Button
                  title="Edit Profile"
                  small
                  primary
                  rounded
                  onClick={() => {
                    this.props.history.push(
                      `/school/student/edit/${this.props.match.params.id}`
                    );
                  }}
                ></Button>
              </div>
            </div>
          </SubscriptionContainer>
          <div className="col-4 row m-0">
            <div className="dp col-3 p-0">
              <ReactAvatar
                name={this.state.StudentsView.name}
                size="90px"
                round="8px"
                src={this.state.StudentsView.profilePicture}
              ></ReactAvatar>
            </div>
            <div className="col-9 p-0">
              <div className="row m-0 mb-2">
                <div className="title col-6">Name</div>
                <div className="desc">{`${
                  this.state.StudentsView.name || ""
                }`}</div>
              </div>
              <div className="row m-0 mb-2">
                <div className="title col-6">Student ID</div>
                <div className="desc">{this.state.StudentsView.studentId}</div>
              </div>
              <div className="row m-0 mb-2">
                <div className="title col-6">Class/sec</div>
                <div className="desc">{`${
                  this.state.StudentsView.class || ""
                } ${this.state.StudentsView.section || ""}`}</div>
              </div>
              <div className="row m-0 mb-2">
                <div className="title col-6">Blood Group</div>
                <div className="desc">
                  {this.state.StudentsView.bloodGroup || ""}
                </div>
              </div>
            </div>
          </div>
          {(this.state.StudentsView.pickupStop ||
            this.state.StudentsView.dropStop) && (
            <React.Fragment>
              <div className="col-5 b-left">
                {this.state.StudentsView.pickupStop && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="titleContent">Pick up</div>
                    <ViewStopContainer className="d-flex justify-content-between align-item-center">
                      <div className="desc ">{`${
                        (this.state.StudentsView.pickupStop || {}).stopName ||
                        ""
                      } ${
                        (this.state.StudentsView.pickupStop || {}).stopId
                      }`}</div>
                      <div className="d-flex">
                        <div className="subDesc mr-4">View</div>
                        <div className="subDesc">Edit</div>
                      </div>
                    </ViewStopContainer>
                  </div>
                )}
                {this.state.StudentsView.dropStop && (
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="titleContent">Drop</div>
                    <ViewStopContainer className="d-flex justify-content-between align-item-center">
                      <div className="desc ">
                        {`${
                          (this.state.StudentsView.dropStop || {}).stopName ||
                          ""
                        } ${(this.state.StudentsView.dropStop || {}).stopId}`}
                      </div>
                      <div className="d-flex">
                        <div className="subDesc mr-4">View</div>
                        <div className="subDesc">Edit</div>
                      </div>
                    </ViewStopContainer>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
          <div className="col-3 b-left d-flex align-items-center justify-content-center">
            <div>
              <img
                src={`https://chart.googleapis.com/chart?chs=110x110&cht=qr&chl=${this.state.StudentsView.QRkey}&choe=UTF-8`}
                alt="QR"
              />
            </div>
            <div className="d-flex flex-column ml-3">
              <Button
                primary
                rounded
                title={"View"}
                onClick={() => {
                  this.viewPictureModal(
                    "QR Code",
                    `Student ID: ${this.state.StudentsView.studentId}`,
                    `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${this.state.StudentsView.QRkey}&choe=UTF-8`
                  );
                }}
              ></Button>
              <div className="mt-2">
                <Button
                  white
                  rounded
                  title={"Download"}
                  onClick={() => {
                    DownloadImage(
                      `${this.state.StudentsView.studentId}-${
                        this.state.StudentsView.class || ""
                      } ${this.state.StudentsView.section || ""}`,
                      `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${this.state.StudentsView.QRkey}&choe=UTF-8`
                    );
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 mt-3">
          <div className="titleContent mb-3 col-12">Parent Details</div>
          {this.state.StudentsView.fatherName ? (
            <React.Fragment>
              <div className="col-4">
                <div className="col-12 p-0">
                  <div className="row mb-2">
                    <div className="title col-6">Name</div>
                    <div className="desc col-6 p-0">
                      {this.state.StudentsView.fatherName}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="title col-6">Contact</div>
                    <div className="desc col-6 p-0">
                      {this.state.StudentsView.fatherMobileNo}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="title col-6">Occupation</div>
                    <div className="desc col-6 p-0">
                      {this.state.StudentsView.fatherOccupation}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="title col-6">Address</div>
                    <div className="desc col-6 p-0">
                      {this.state.StudentsView.address}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 b-left">
                {this.state.StudentsView.motherName ? (
                  <div className="col-12 p-0">
                    <div className="row mb-2">
                      <div className="title col-6">Name</div>
                      <div className="desc col-6 p-0">
                        {this.state.StudentsView.motherName}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="title col-6">Contact</div>
                      <div className="desc col-6 p-0">
                        {this.state.StudentsView.motherMobileNo}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="title col-6">Occupation</div>
                      <div className="desc col-6 p-0">
                        {this.state.StudentsView.motherOccupation}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="title col-6">Address</div>
                      <div className="desc col-6 p-0">
                        {this.state.StudentsView.address}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-9 p-0">
                    <Button
                      primary
                      rounded
                      title={"Add another parent"}
                      onClick={() => {
                        this.props.history.push(
                          `/school/student/edit/${this.props.match.params.id}?q=2`
                        );
                      }}
                    ></Button>
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className="row m-0">
              <div className="col-12">
                <Button
                  primary
                  rounded
                  title={"Add Parent"}
                  onClick={() => {
                    this.props.history.push(
                      `/school/student/edit/${this.props.match.params.id}?q=2`
                    );
                  }}
                ></Button>
              </div>
            </div>
          )}
        </div>
        <div className="row m-0 mt-4">
          {/*<div className="col-5">
            <Search></Search>
          </div>*/}
          <div className="col-12 mb-3">
            <div className="titleContent mb-4 mt-4">All trips</div>

            <div className="col-9 p-0">
              <Table
                useFixedHeader
                columns={columns}
                data={this.state.tripDetails}
                scroll={{
                  x: 1200,
                  y: 350,
                }}
                emptyText="No Trips Found!!"
                //title={setTableTitle}
              />
            </div>
          </div>
        </div>

        <PictureModel
          fileInfo={`${this.state.StudentsView.studentId}-${
            this.state.StudentsView.class || ""
          } ${this.state.StudentsView.section || ""}`}
          image={this.state.selectedImage}
          isOpen={this.state.pictureModelVisible}
          title={this.state.selectedTitle}
          desc={this.state.selectedDesc}
          toggleModal={() => {
            this.setState({ pictureModelVisible: false });
          }}
        ></PictureModel>
      </ViewStudentStyle>
    );
  }
}

export default ViewStudent;
