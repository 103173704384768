import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import ReactFile from "../../../Component/Form/file";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import Image from "../../../Component/Image";
import Loading from "../../../Component/Loading";
import ModalMsg from "../../../Component/ModalMsg";
import TitleContainer from "../../../Component/TitleContainer";
import City from "../../../Data/cities.json";
import State from "../../../Data/state.json";
import Images from "../../../Themes/Images";
import { generateRandomString, sendNotification } from "../../../utils";
import { email, isMobileNumber } from "../../../validator";
import {
  CREATE_ADMIN,
  GET_ADMIN_DETAILS_ID,
  UPDATE_ADMIN,
} from "../../graphql";

let FormRef;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .changePwdFloat {
    color: #004584;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    right: 30px;
  }
`;
export class createAdmin extends Component {
  state = {
    createAdmin: {
      name: "",
      profilePicture: "",
      is_500: false,
      formSubmit: false,
      email: "",
      mobile: "",
      location: "",
      lname: "",
      state: State[0].name,
      city: City[State[0].name][0].name,
      password: "",
      cpassword: "",
      rolePriority: "",
      role: "SUPERADMIN",
      imageURL: "",
    },
    fileInfo: {
      profilePicture: "",
    },
    gpwd: "",
    successModal: false,
    variables: {},
  };
  componentDidMount() {
    // if(this.props.)
    if (
      !(
        this.props.match.params.id === "primary" ||
        this.props.match.params.id === "secondary"
      )
    ) {
      this.setState({ is_500: true });
    }
    // this.setState({
    //   rolePriority:
    //     this.props.match.params.id === "primary" ? "PRIMARY" : "SECONDARY"
    // });
    this.onChange(
      "rolePriority",
      this.props.match.params.id === "primary" ? "PRIMARY" : "SECONDARY"
    );
    this.setState({ gpwd: generateRandomString(7) });
  }
  onChange = (key, value) => {
    const createAdmin = { ...this.state.createAdmin };
    createAdmin[key] = value;

    this.setState({ createAdmin });
  };
  handleSubmit = (func) => {
    this.setState({ formSubmit: true }, () => {
      FormRef.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            const variables = {
              name: this.state.createAdmin.name,
              mobileNo: this.state.createAdmin.mobile,
              locationName: this.state.createAdmin.location,
              loginName: this.state.createAdmin.lname,
              rolePriority: this.state.createAdmin.rolePriority,
              role: this.state.createAdmin.role,
              displayPicture: this.state.createAdmin.imageURL,
              state: this.state.createAdmin.state,
              city: this.state.createAdmin.city,
              userToken: this.state.createAdmin.userToken,
              GPlaceID: "",
            };
            if (this.props.match.params.uid) {
              variables.id = this.props.match.params.uid;
            } else {
              variables.emailId = this.state.createAdmin.email;
              variables.isActive = true;
              variables.password = this.state.createAdmin.password;
            }
            this.setState({ variables }, () => {
              func();
            });
            // this.props.changePage();
          } else {
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  fileInfo = (key, value) => {
    const fileInfo = { ...this.state.fileInfo };
    fileInfo[key] = value;
    this.setState({ fileInfo });
  };
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };

  render() {
    return (
      <div>
        {this.props.match.params.uid && (
          <Query
            variables={{ id: this.props.match.params.uid }}
            fetchPolicy={"network-only"}
            query={GET_ADMIN_DETAILS_ID}
            onCompleted={(data) => {
              if (data.getAdminById) {
                let createAdmin = {
                  name: data.getAdminById.firstName,
                  email: data.getAdminById.emailId,
                  mobile: data.getAdminById.mobileNumber,
                  location: data.getAdminById.location,
                  // lname: data.getAdminById.location,
                  lname: data.getAdminById.userName,
                  city: data.getAdminById.city,
                  state: data.getAdminById.state,
                  displayPicture: data.getAdminById.displayPicture,
                  imageURL: data.getAdminById.displayPicture,
                  isCurrentUser: data.getAdminById.isCurrentUser,
                  userToken: data.getAdminById.userToken,
                };
                // let fileInfo = {
                //   profilePicture: data.getAdminById.displayPicture
                //     ? { name: "dp.png" }
                //     : {}
                // };
                let fileInfo = data.getAdminById.displayPicture
                  ? { profilePicture: { name: "dp.png" } }
                  : {};
                // this.fileInfo("profilePicture", { name: "" });
                this.setState({ createAdmin, fileInfo });
              }
              // if (data.getOnboardingEmailId) {
              //   this.setState({ email: data.getOnboardingEmailId.email });
              // }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <div className="col-11 pl-4 pr-4">
          <TitleContainer
            className="mt-5 pl-4 color-blue"
            title={`${
              this.props.match.params.id === "primary" ? "Primary" : "Secondary"
            } admin creation`}
          ></TitleContainer>
          <TitleContainer
            className="mt-4 pt-3 pl-4 small color-blue"
            title="Personal details"
          ></TitleContainer>
          <TitleContainer
            className="mt-1 pl-4 very-small color-gray semi-bold"
            title="(*All Fields are mandatory)"
          ></TitleContainer>
        </div>
        <div className="col-8 pl-4 pr-4">
          <Mutation
            variables={{
              // id: this.props.query.id
              data: { ...this.state.variables },
            }}
            mutation={this.props.match.params.uid ? UPDATE_ADMIN : CREATE_ADMIN}
            onError={(error) => {
              if (!error || !error.message) return null;

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.createAdminUser) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  title: "",
                  message: "Admin Created Successfully",
                });
                setTimeout(() => {
                  this.props.history.push(`/dashboard/admins`);
                  // this.props.history.push("/events/me");
                }, 500);
                // message.success("Contact Created!!");
              }
              if (data.updateAdminUser) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  title: "",
                  message: "Admin Updated Successfully",
                });
                setTimeout(() => {
                  this.props.history.push(`/dashboard/admins`);
                  // this.props.history.push("/events/me");
                }, 500);
                // message.success("Contact Created!!");
              }
            }}
          >
            {(CreateAdmin, { data, loading, error }) => {
              return (
                <Form
                  ref={(ref) => {
                    FormRef = ref;
                  }}
                  className={`pl-4 ${
                    this.state.formSubmit ? "invalid-form" : ""
                  }`}
                >
                  <Loading is_loading={loading}></Loading>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-3 pl-0">Profile picture</div>
                    <div className="col-9">
                      <ReactFile
                        placeholder=""
                        white
                        header="profile picture"
                        multiple={false}
                        // required
                        uploadFor={"SuperAdmins"}
                        value={this.state.fileInfo.profilePicture}
                        imageURL={this.state.createAdmin.imageURL}
                        className={"w-100"}
                        onSuccess={(value) => {
                          this.onChange("imageURL", value);
                        }}
                        onDelete={() => {
                          this.onChange("imageURL", "");
                          this.fileInfo("profilePicture", { name: "" });
                        }}
                        onChange={(value) => {
                          this.fileInfo(
                            "profilePicture",
                            value ? value[value.length - 1] : ""
                          );
                        }}
                        token={{
                          userToken: this.state.createAdmin.userToken,
                        }}
                        onTokenChange={(insToken, userToken) => {
                          this.onChange("userToken", userToken);
                        }}
                      ></ReactFile>
                    </div>
                  </InputContainer>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-3 pl-0">Name</div>
                    <div className="col-9">
                      <ReactInput
                        placeholder=""
                        white
                        required
                        value={this.state.createAdmin.name}
                        className={"w-100"}
                        isError={
                          this.state.formSubmit &&
                          this.state.createAdmin.name === ""
                        }
                        ErrorMessage="This field can't be blank"
                        onChange={(value) => {
                          this.onChange("name", value);
                        }}
                      ></ReactInput>
                    </div>
                  </InputContainer>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-3 pl-0">Email</div>
                    <div className="col-9">
                      <ReactInput
                        white
                        disabled={this.props.match.params.uid}
                        value={this.state.createAdmin.email}
                        className={"w-100"}
                        placeholder=""
                        required
                        validation={[email]}
                        isError={
                          this.state.formSubmit &&
                          this.state.createAdmin.email === ""
                        }
                        ErrorMessage="This field can't be blank"
                        onChange={(value) => {
                          this.onChange("email", value);
                        }}
                      ></ReactInput>
                    </div>
                  </InputContainer>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-3 pl-0">Mobile number</div>
                    <div className="col-9">
                      <ReactInput
                        placeholder=""
                        white
                        required
                        validation={[isMobileNumber]}
                        value={this.state.createAdmin.mobile}
                        className={"w-100"}
                        onChange={(value) => {
                          this.onChange("mobile", value);
                        }}
                        isError={
                          this.state.formSubmit &&
                          (this.state.createAdmin.mobile === "" ||
                            isNaN(this.state.createAdmin.mobile))
                        }
                        ErrorMessage={
                          this.state.mobile === ""
                            ? "This field can't be blank"
                            : "Number must consist of digits"
                        }
                      ></ReactInput>
                    </div>
                  </InputContainer>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-3 pl-0">State</div>

                    <div className="col-9">
                      <ReactSelect
                        placeholder=""
                        white
                        required
                        value={this.state.createAdmin.state}
                        className={"w-100"}
                        // options={[
                        //   { value: "Namakkal", name: "Namakkal" },
                        //   { value: "Salem", name: "Van" }
                        // ]}
                        options={State.map((data) => {
                          return { value: data.name, name: data.name };
                        })}
                        onChange={(value) => {
                          // console.log(value);
                          this.onChange("state", value);
                        }}
                      ></ReactSelect>
                    </div>
                  </InputContainer>
                  <InputContainer className="row m-0 mt-3">
                    <div className="col-3 pl-0">City</div>

                    <div className="col-9">
                      <ReactSelect
                        placeholder=""
                        white
                        required
                        value={this.state.createAdmin.city}
                        className={"w-100"}
                        // options={[
                        //   { value: "Namakkal", name: "Namakkal" },
                        //   { value: "Salem", name: "Van" }
                        // ]}
                        options={(City[this.state.createAdmin.state] || []).map(
                          (data) => {
                            return { value: data.name, name: data.name };
                          }
                        )}
                        onChange={(value) => {
                          // console.log(value);
                          this.onChange("city", value);
                        }}
                      ></ReactSelect>
                    </div>
                  </InputContainer>

                  <TitleContainer
                    className="mt-5 small color-blue"
                    title="Login Credentials"
                  ></TitleContainer>

                  {this.state.createAdmin.isCurrentUser && (
                    <React.Fragment>
                      {this.props.match.params.uid && (
                        <InputContainer className="row m-0 mt-3 position-relative">
                          <div className="col-3 pl-0 disabled">Password</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              disabled={this.props.match.params.uid}
                              type="password"
                              pwdIcon={false}
                              value={this.state.gpwd}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("password", value);
                              }}
                              isError={
                                this.state.formSubmit &&
                                this.state.createAdmin.password === ""
                              }
                              ErrorMessage="Password cannot be empty"
                            ></ReactInput>
                          </div>
                          <div
                            className="changePwdFloat pointer-cursor"
                            onClick={() => {
                              this.props.history.push(
                                this.props.match.url + "/changepassword"
                              );
                            }}
                          >
                            Change Password
                          </div>
                        </InputContainer>
                      )}
                    </React.Fragment>
                  )}
                  {!this.props.match.params.uid && (
                    <React.Fragment>
                      <InputContainer className="row m-0 mt-3">
                        <div className="col-3 pl-0">Password</div>
                        <div className="col-9">
                          <ReactInput
                            placeholder=""
                            white
                            required
                            type="password"
                            value={this.state.createAdmin.password}
                            className={"w-100"}
                            onChange={(value) => {
                              this.onChange("password", value);
                            }}
                            isError={
                              this.state.formSubmit &&
                              this.state.createAdmin.password === ""
                            }
                            ErrorMessage="Password cannot be empty"
                          ></ReactInput>
                        </div>
                      </InputContainer>
                      <InputContainer className="justify-content-end mt-3">
                        <div className="col-12">
                          <div className="flex-center justify-content-end">
                            <div
                              className="pointer-cursor"
                              onClick={this.generatePwd}
                            >
                              <Image src={Images.pwdRefresh}></Image>
                            </div>
                            <span className="mr-2 ml-2">
                              Suggested password:{" "}
                              <span
                                className="pointer-cursor"
                                onClick={() => {
                                  this.onChange("password", this.state.gpwd);
                                }}
                              >
                                {this.state.gpwd}
                              </span>
                            </span>
                            <div
                              className="pointer-cursor"
                              onClick={() => {
                                this.onChange("password", this.state.gpwd);
                              }}
                            >
                              <Image src={Images.copyIcon}></Image>
                            </div>
                          </div>
                        </div>
                      </InputContainer>
                      <InputContainer className="row m-0 mt-3">
                        <div className="col-3 pl-0">Confirm password</div>
                        <div className="col-9">
                          <ReactInput
                            placeholder=""
                            white
                            disabled={this.props.match.params.uid}
                            required
                            type="password"
                            value={this.state.createAdmin.cpassword}
                            className={"w-100"}
                            onChange={(value) => {
                              this.onChange("cpassword", value);
                            }}
                            isError={
                              this.state.formSubmit &&
                              this.state.createAdmin.cpassword === ""
                            }
                            ErrorMessage="This field can't be blank"
                          ></ReactInput>
                        </div>
                      </InputContainer>
                    </React.Fragment>
                  )}
                  <div className="mt-5 mb-5 pt-4 d-flex align-items-center justify-content-end">
                    <Button
                      primaryTransparent
                      buttonClass="mr-4"
                      onClick={() => {
                        this.props.history.push(`/dashboard/admins`);
                      }}
                      title="Cancel"
                    ></Button>
                    <div className="d-inline">
                      <Button
                        title={`${
                          this.props.match.params.uid ? "Update" : "Create"
                        } new admin`}
                        primary
                        rounded
                        onClick={() => {
                          if (!this.props.match.params.uid) {
                            if (
                              this.state.createAdmin.password !==
                              this.state.createAdmin.cpassword
                            ) {
                              sendNotification({
                                message: "Password mismatch",
                                type: "danger",
                              });
                              return;
                            }
                          }
                          this.handleSubmit(CreateAdmin);
                        }}
                      ></Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Mutation>
        </div>
        <ModalMsg
          image={Images.ModalSuccess}
          isOpen={this.state.successModal}
          toggleModal={this.onSuccessModal}
          message="THE PROFILE HAS BEEN SUCCESSFULLY CREATED"
        ></ModalMsg>
      </div>
    );
  }
}

export default createAdmin;
