import PropTypes from "prop-types";
import React, { useState } from "react";
import IdleTimer from "react-idle-timer";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { colors } from "../Themes/Color";
import ReactAvatar from "./Avatar";
import Button from "./Form/button";
const FixedOverlayContainer = styled.div`
  bottom: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
  & > div {
    background: rgba(207, 219, 253, 0.73);
    /* height: 100vh;
  width: 100vw; */
    top: 64px;
    /* display: none; */
    left: 180px;
    right: 0;
    bottom: 0;
    position: fixed;
  }
`;
const SchoolContainer = styled.div`
  background: #e3eaff;
  border-radius: 5px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  & .desc {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    line-height: 13px;
    font-size: 10px;
    color: #343434;
  }
  & .heading {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 31px;
    font-size: 25px;
    color: #343434;
    min-width: 35px;
    text-align: center;
  }
`;
const NavContainer = styled.div`
  padding-left: 30px;
  padding-top: 15px;
  border-left: 1px solid ${colors.defaultBackgroundColor};
  padding-bottom: 15px;
  padding-right: 15px;
  background: ${colors.secondaryColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .title {
    font-family: "Muli", sans-serif;
    font-size: 23px;
    color: ${colors.colorWhite};
  }
`;
const ProfileContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  min-width: 330px;

  & .dp {
    width: 63px;
    height: 63px;
  }
  .title {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 19px;
    font-size: 15px;
    color: #000;
  }
  .desc {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    line-height: 19px;
    font-size: 15px;
    color: #000;
  }
  .color-blue {
    color: #3f3fd5;
  }
  .small {
    font-weight: 12px;
  }
`;
var idleTimer = null;
const Nav = (props) => {
  const [visible, setVisible] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const _onAction = (e) => {
    //console.log("user did something", e);
  };

  const _onActive = (e) => {
    //console.log("user is active", e);
    //console.log("time remaining", idleTimer.getRemainingTime());
  };

  const _onIdle = (e) => {
    //console.log("user is idle", e);
    if (process.env.NODE_ENV !== "development") {
      // props.history.push(`/`);
      // localStorage.clear();
    }
    //console.log("last active", idleTimer.getLastActiveTime());
  };
  return (
    <NavContainer>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onActive={_onActive}
        onIdle={_onIdle}
        onAction={_onAction}
        debounce={250}
        timeout={1000 * 60 * 2}
      />
      {props.title && <div className="title"> {props.title} </div>}
      <div className="d-flex justify-content-center align-items-center">
        {!props.hideAdmin && (
          <Button
            onClick={() => {
              props.history.push(`/dashboard/admins`);
            }}
            white
            title="Admins"
            small
          ></Button>
        )}

        <div
          className="ml-4 pointer-cursor"
          onClick={() => {
            setVisible(true);
          }}
        >
          <ReactAvatar
            name={
              (props.user || {}).institute
                ? (props.user || {}).institute.name
                : (props.user || {}).firstName
            }
            size="34px"
            round
            src={
              (props.user || {}).institute
                ? (props.user || {}).institute.logo
                : (props.user || {}).displayPicture
            }
          ></ReactAvatar>
        </div>
      </div>
      <CSSTransition
        in={visible}
        timeout={5000}
        // transitionName="fade"

        className="TransitionContainer"
      >
        <React.Fragment>
          {visible && (
            <FixedOverlayContainer
              onClick={() => {
                setVisible(false);
              }}
            >
              <div>
                <ProfileContainer
                  className="d-flex"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="dp mr-3">
                    <ReactAvatar
                      name={
                        (props.user || {}).institute
                          ? (props.user || {}).institute.name
                          : (props.user || {}).firstName
                      }
                      size="63px"
                      round
                      src={
                        (props.user || {}).institute
                          ? (props.user || {}).institute.logo
                          : (props.user || {}).displayPicture
                      }
                    ></ReactAvatar>
                  </div>
                  <div className="w-100">
                    <div className="title">
                      {(props.user || {}).institute
                        ? (props.user || {}).institute.name
                        : ((props.user || {}).firstName || "") +
                          ((props.user || {}).lastName || "")}
                    </div>
                    {(props.user || {}).rolePriority.toLowerCase() ===
                      "primary" ||
                    (props.user || {}).rolePriority.toLowerCase() ===
                      "secondary" ? (
                      <React.Fragment>
                        <div className="desc mt-2">
                          {(props.user || {}).emailId}
                        </div>
                        <div className="desc mt-2">
                          {(props.user || {}).mobileNumber}
                        </div>
                        <div className="d-flex mt-3 justify-content-end">
                          {((props.user || {}).rolePriority.toLowerCase() ===
                            "primary" ||
                            (props.user || {}).rolePriority.toLowerCase() ===
                              "secondary") && (
                            <div
                              className="small color-blue pointer-cursor"
                              onClick={() => {
                                props.history.push(
                                  `/dashboard/admins/${(
                                    props.user || {}
                                  ).rolePriority.toLowerCase()}/edit/${
                                    (props.user || {}).id
                                  }/changepassword`
                                );
                                setVisible(false);
                              }}
                            >
                              Change password
                            </div>
                          )}
                          <div
                            className="small color-blue pointer-cursor ml-3"
                            onClick={() => {
                              localStorage.clear();
                              props.history.push(`/`);
                              setVisible(false);
                            }}
                          >
                            Logout
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div>
                        <div className="d-flex">
                          <SchoolContainer className="d-flex mt-2">
                            <div className="mr-2 d-flex justify-content-center align-items-center flex-column">
                              <div className="desc">Students</div>
                              <div className="heading">
                                {(props.user || {}).institute
                                  ? (props.user || {}).institute.totalStudents
                                  : 0}
                              </div>
                            </div>
                            <div className="mr-2 d-flex justify-content-center align-items-center flex-column">
                              <div className="desc">Bus</div>
                              <div className="heading">
                                {(props.user || {}).institute
                                  ? (props.user || {}).institute.totalBus
                                  : 0}
                              </div>
                            </div>
                            <div className="mr-2 d-flex justify-content-center align-items-center flex-column">
                              <div className="desc">Drivers</div>
                              <div className="heading">
                                {(props.user || {}).institute
                                  ? (props.user || {}).institute.totalDrivers
                                  : 0}
                              </div>
                            </div>
                          </SchoolContainer>
                        </div>
                        <div className="d-flex mt-2 justify-content-end">
                          <div
                            className="small color-blue pointer-cursor ml-3"
                            onClick={() => {
                              localStorage.clear();
                              props.history.push(`/`);
                              setVisible(false);
                            }}
                          >
                            Logout
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </ProfileContainer>
              </div>
            </FixedOverlayContainer>
          )}
        </React.Fragment>
      </CSSTransition>
    </NavContainer>
  );
};

Nav.protoTypes = {
  title: PropTypes.string,
};
export default Nav;
