import PropTypes from "prop-types";
import React from "react";
import Img from "react-image";
const Image = props => {
  return (
    <Img
      className={`img-fluid ${props.className ? props.className : ""}`}
      src={props.src}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string
};
export default Image;
