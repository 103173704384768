import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createClient from "./ApolloClient";
import App from "./App";
import ErrorBoundary from "./Component/ErrorBoundary";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "./Stores";

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={createClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div>
            <App />
          </div>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
