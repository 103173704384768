import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import Image from "../../../Component/Image";
import Loading from "../../../Component/Loading";
import ModalMsg from "../../../Component/ModalMsg";
import TableStyle from "../../../Component/Styles/Table";
import TitleContainer from "../../../Component/TitleContainer";
import Images from "../../../Themes/Images";
import { sendNotification } from "../../../utils";
import { DELETE_ADMIN_USER, GET_ADMIN_DETAILS } from "../../graphql";
const AdminContainer = styled.div``;
const DeleteModalContainer = styled.div`
  .title {
    font-size: 17px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
`;
export class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: [],
      secondary: [],
      isDeleteOpen: false,
      successModal: false,
      refetch: true,

      selectedAdmin: {
        firstName: "Balaji",
        displayPicture:
          "https://scabbr.s3.ap-south-1.amazonaws.com/Screen+Shot+2018-12-28+at+6.45.33+PM.png",
        emailId: "ibalajisankar@gmail.com",
      },
      role: "",
    };
  }
  componentDidMount() {
    const role = (this.props.user || {}).rolePriority;
    this.setState({ role: role });
  }
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };
  deleteAdmin = (admin) => {
    this.setState({ selectedAdmin: admin }, () => {
      this.toggleDeleteModal();
    });
  };
  render() {
    return (
      <AdminContainer>
        <div className="col-11 pl-4 pr-4">
          {this.state.refetch && (
            <Query
              query={GET_ADMIN_DETAILS}
              fetchPolicy="network-only"
              onCompleted={(data) => {
                if (data.getAdminDetails) {
                  let primary = [],
                    secondary = [];
                  data.getAdminDetails.map((data) => {
                    if (data.rolePriority === "PRIMARY") {
                      primary.push(data);
                    }
                    if (data.rolePriority === "SECONDARY") {
                      secondary.push(data);
                    }
                  });
                  this.setState({ primary, secondary });
                }
                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }}
              onError={(error) => {
                if (!error || !error.message) return null;
                this.setState({ hasErr: true });
                sendNotification({
                  message: error.message.replace("GraphQL error: ", ""),
                  type: "danger",
                });
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>
          )}
          <TitleContainer
            className="mt-5 pl-5 color-blue"
            title="Primary admin"
          ></TitleContainer>
          <TableStyle className="mt-3 pb-2">
            <div className="tableHeader row m-0">
              <div className="width-180 plr-10">Name</div>
              <div className="width-180 plr-10">Email</div>
              <div className="width-180 plr-10">Mobile Number</div>
              <div className="width-100 plr-10">Status</div>
              <div className="width-160 plr-10">Location</div>
              {this.state.role === "PRIMARY" && (
                <div className="width-210 color-blue font-regular text-right plr-10">
                  {/* <div
                  className="col-12 pointer-cursor"
                  onClick={() => {
                  }}
                >
                  Add new admin
                </div> */}
                  <Button
                    primary
                    rounded
                    title="Add new admin"
                    onClick={() => {
                      this.props.history.push(
                        `/dashboard/admins/primary/create`
                      );
                    }}
                  ></Button>
                </div>
              )}
            </div>
            {this.state.primary.map((data, index) => {
              return (
                <div
                  className={`tableBody row m-0 ${
                    index % 2 ? "" : "alternate"
                  }`}
                  key={`primary-${index}`}
                >
                  <div className="width-180 plr-10 word-break-all">
                    {data.firstName}
                  </div>
                  <div className="width-180 plr-10 word-break-all">
                    {data.emailId}
                  </div>
                  <div className="width-180 plr-10 word-break-all">
                    {data.mobileNumber}
                  </div>
                  <div className="width-100 plr-10 word-break-all">
                    {data.status}
                  </div>
                  <div className="width-160 plr-10">
                    <div className="d-flex">
                      <div className="col-10 p-0">
                        <div>{data.city}</div>
                        <div>{data.state}</div>
                      </div>
                      <img
                        alt=""
                        src={Images.Location}
                        className="img-fluid ml-2"
                      />
                    </div>
                  </div>
                  {this.state.role === "PRIMARY" && (
                    <div className="width-200 plr-10 color-blue font-regular text-right align-items-center d-flex">
                      {/* <div className="col-12">View</div> */}
                      {!data.isCurrentUser && (
                        <React.Fragment>
                          <div
                            className="color-blue col-6 pointer-cursor"
                            onClick={() => {
                              this.props.history.push(
                                `/dashboard/admins/primary/edit/${data.id}`
                              );
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className="color-red col-6 pointer-cursor"
                            onClick={() => {
                              this.deleteAdmin(data);
                            }}
                          >
                            Remove
                          </div>
                        </React.Fragment>
                      )}
                      {data.isCurrentUser && (
                        <div
                          className="color-blue col-6 pointer-cursor"
                          onClick={() => {
                            this.props.history.push(
                              `/dashboard/admins/primary/edit/${data.id}`
                            );
                          }}
                        >
                          View
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </TableStyle>
        </div>
        <div className="col-11 pl-4 pr-4 mb-5">
          <TitleContainer
            className="mt-5 pl-5 color-blue cursor-pointer"
            title="Secondary admin"
          ></TitleContainer>
          <TableStyle className="mt-3 pb-2">
            <div className="tableHeader row m-0">
              <div className="width-180 plr-10">Name</div>
              <div className="width-180 plr-10">Email</div>
              <div className="width-180 plr-10">Mobile Number</div>
              <div className="width-100 plr-10">Status</div>
              <div className="width-160 plr-10">Location</div>
              {this.state.role === "PRIMARY" && (
                <div className="width-210 plr-10 color-blue font-regular text-right pointer-cursor">
                  <Button
                    primary
                    rounded
                    title="Add new admin"
                    onClick={() => {
                      this.props.history.push(
                        `/dashboard/admins/secondary/create`
                      );
                    }}
                  ></Button>
                  {/* <div
                  className="col-12"
                  onClick={() => {
                    this.props.history.push(
                      `/dashboard/admins/secondary/create`
                    );
                  }}
                >
                  Add new admin
                </div> */}
                </div>
              )}
            </div>
            {this.state.secondary.length === 0 && (
              <div className="pt-4 pb-4 text-center">No Admin found!!</div>
            )}
            {this.state.secondary.map((data, index) => {
              return (
                <div
                  className={`tableBody row m-0 ${
                    index % 2 ? "" : "alternate"
                  }`}
                  key={`primary-${index}`}
                >
                  <div className="width-180 plr-10 word-break-all">
                    {data.firstName}
                  </div>
                  <div className="width-180 plr-10 word-break-all">
                    {data.emailId}
                  </div>
                  <div className="width-180 plr-10 word-break-all">
                    {data.mobileNumber}
                  </div>
                  <div className="width-100 plr-10">{data.status}</div>

                  <div className="width-160 plr-10 word-break-all">
                    <div className="d-flex">
                      <div className="col-10 p-0">
                        <div>{data.city}</div>
                        <div>{data.state}</div>
                      </div>
                      <img
                        alt=""
                        src={Images.Location}
                        className="img-fluid ml-2"
                      />
                    </div>
                  </div>
                  {this.state.role === "PRIMARY" && (
                    <div className="width-200 plr-10 color-blue font-regular text-right align-items-center d-flex">
                      {/* <div className="col-12">View</div> */}
                      {!data.isCurrentUser && (
                        <React.Fragment>
                          <div
                            className="color-blue col-6 pointer-cursor"
                            onClick={() => {
                              this.props.history.push(
                                `/dashboard/admins/secondary/edit/${data.id}`
                              );
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className="color-red col-6 pointer-cursor"
                            onClick={() => {
                              this.deleteAdmin(data);
                            }}
                          >
                            Remove
                          </div>
                        </React.Fragment>
                      )}
                      {data.isCurrentUser && (
                        <div
                          className="color-blue col-6 pointer-cursor"
                          onClick={() => {
                            this.props.history.push(
                              `/dashboard/admins/secondary/edit/${data.id}`
                            );
                          }}
                        >
                          View
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </TableStyle>
        </div>
        <ModalMsg
          image={Images.ModalFailure}
          isOpen={this.state.successModal}
          toggleModal={this.onSuccessModal}
          message={`${this.state.selectedAdmin.firstName.toUpperCase()} HAS BEEN REMOVED FROM THE PLATFORM`}
        ></ModalMsg>
        <Modal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          centered
          size="md"
        >
          {this.state.selectedAdmin !== "" && (
            <ModalBody>
              <DeleteModalContainer>
                <div className="title">Delete admin</div>
                <div className="desc mt-2 mb-3">
                  {`Are you sure you want to remove ${this.state.selectedAdmin.firstName} from the Scabbr
                platform?`}
                </div>
                <div className="d-flex  align-items-center">
                  <div className="col-2 p-0">
                    <Image src={this.state.selectedAdmin.displayPicture} />
                  </div>
                  <div className="ml-2 desc">
                    <div className="semi-bold">{`${
                      this.state.selectedAdmin.firstName
                    } ${this.state.selectedAdmin.lastName || ""}`}</div>
                    <div>{this.state.selectedAdmin.emailId}</div>
                  </div>
                </div>
                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    primaryTransparent
                    title={"Cancel"}
                    onClick={() => {
                      this.toggleDeleteModal();
                    }}
                  ></Button>
                  <Mutation
                    variables={{
                      // id: this.props.query.id
                      userId: this.state.selectedAdmin.id,
                    }}
                    refetchQueries={[
                      {
                        query: GET_ADMIN_DETAILS,
                      },
                    ]}
                    mutation={DELETE_ADMIN_USER}
                    onError={(error) => {
                      if (!error || !error.message) return null;

                      sendNotification({
                        message: error.message.replace("GraphQL error: ", ""),
                        type: "danger",
                      });
                      // this.showNotificationError(
                      //   error.message.replace("GraphQL error: ", "")
                      // );
                    }}
                    onCompleted={(data) => {
                      if (data.deleteAdminUser) {
                        // this.toggle
                        this.toggleDeleteModal();
                        this.setState({ refetch: false }, () => {
                          this.setState({ refetch: true });
                        });
                        this.onSuccessModal();
                      }
                    }}
                  >
                    {(DeleteAdmin, { data, loading, error }) => {
                      return (
                        <Button
                          rounded
                          danger
                          title={"Remove admin"}
                          onClick={() => {
                            DeleteAdmin();
                          }}
                        ></Button>
                      );
                    }}
                  </Mutation>
                </div>
              </DeleteModalContainer>
            </ModalBody>
          )}
        </Modal>
      </AdminContainer>
    );
  }
}

export default Admin;
