import React from "react";
import Images from "../Themes/Images";
import Image from "./Image";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="vmh-100 w-100 d-flex align-items-center justify-content-center pt-5  pb-5 flex-column">
          <Image src={Images.A404}></Image>
          <div className="msg-title mt-5">
            Something went wrong. Try after sometime.
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
