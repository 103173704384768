import React, { Component } from "react";
import Image from "../Component/Image";
import Images from "../Themes/Images";

export class GenericNotFound extends Component {
  render() {
    return (
      <div className="vmh-100 w-100 d-flex align-items-center justify-content-center pt-5  pb-5 flex-column">
        <Image src={Images.A404}></Image>
        <div className="msg-title mt-5">
          Sorry, the page you visited does not exist.
        </div>
        <div className="msg-title pb-2">Please contact admin</div>
      </div>
    );
  }
}

export default GenericNotFound;
