import React from "react";
import { Query } from "react-apollo";
import { Redirect, Route } from "react-router-dom";
import styled from "styled-components";
import { ADMIN_LOGIN_CHECK } from "../Application/graphql";
import Admin from "../Component/Dashboard/admin";
import Button from "../Component/Form/button";
import Image from "../Component/Image";
import Loading from "../Component/Loading";
import Nav from "../Component/Nav";
import { colors } from "../Themes/Color";
import Images from "../Themes/Images";
// import ReactGA from 'react-ga';
import { getToken } from "../utils";

const ComponentContainer = styled.div`
  height: calc(100vh - 65px);
  overflow: scroll;
  background: ${colors.defaultBackgroundColor};
  position: relative;
`;
const RouteContainer = styled.div`
  & > .menuBar {
    width: 180px;
    height: 100vh;
    overflow: scroll;
  }
  & > .componentArea {
    width: calc(100% - 180px);
  }
`;
// class GAComponent extends React.Component{

//   componentDidUpdate() {
//     this.props.history.listen(location => {
//       ReactGA.set({ page: location.pathname });
//       ReactGA.pageview(location.pathname);
//     });
//   }

//   render() {
//     const { Component } = this.props;
//     return (
//       <Component {...this.props} />
//     );
//   }
// }

const AdminAuth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() ? (
        <RouteContainer className="row m-0">
          <Query
            query={ADMIN_LOGIN_CHECK}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              // setUserProfile(data.adminLoginCheck);
              if (!data.adminLoginCheck) {
                localStorage.clear();
                props.history.push(`/`);
              }
            }}
            onError={(data) => {
              // localStorage.clear();
              props.history.push(`/`);
            }}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading is_loading={loading}></Loading>;
              }
              if (error) {
                return <div></div>;
              }

              return (
                <React.Fragment>
                  {(data || {}).adminLoginCheck.role !== "SUPERADMIN" && (
                    <div className="vmh-100 w-100 d-flex align-items-center justify-content-center pt-5  pb-5 flex-column">
                      <Image src={Images.A403}></Image>
                      <div className="msg-title mt-5">
                        {" "}
                        Sorry, you don't have access to this page
                      </div>
                      <div className="msg-title pb-2">Please contact admin</div>
                      <Button
                        primaryTransparent
                        small
                        title={"Back Home"}
                        onClick={() => {
                          if (
                            (data || {}).adminLoginCheck.rolePriority ===
                              "PRIMARY" ||
                            (data || {}).adminLoginCheck.rolePriority ===
                              "SECONDARY"
                          ) {
                            props.history.push(`/dashboard`);
                          } else {
                            props.history.push(`/school/dashboard`);
                          }
                          // this.toggleTaskModal();
                        }}
                      ></Button>
                    </div>
                  )}
                  {(data || {}).adminLoginCheck.role === "SUPERADMIN" && (
                    <React.Fragment>
                      <div className="menuBar p-0">
                        <Admin
                          {...props}
                          user={(data || {}).adminLoginCheck}
                        ></Admin>
                      </div>
                      <div className=" p-0 componentArea">
                        <Nav
                          {...props}
                          title={rest.title}
                          user={(data || {}).adminLoginCheck}
                          userLoading={loading}
                        />
                        <ComponentContainer>
                          <Component
                            {...props}
                            user={(data || {}).adminLoginCheck}
                            userLoading={loading}
                          />
                        </ComponentContainer>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            }}
          </Query>

          {/* <GAComponent Component={Component} {...props} /> */}
        </RouteContainer>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default AdminAuth;
