import styled from "styled-components";

const DeleteModalContainer = styled.div`
  .title {
    font-size: 17px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
`;
export default DeleteModalContainer;
