import PropTypes from "prop-types";
import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { Modal, ModalBody } from "reactstrap";
import Images from "../Themes/Images";
import { sendNotification } from "../utils";
import Button from "./Form/button";
import DeleteModalContainer from "./Form/style/deleteContainer";
import Image from "./Image";
import Loading from "./Loading";
import ModalMsg from "./ModalMsg";

export class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successModal: false,
    };
  }
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };
  render() {
    return (
      <div>
        <ModalMsg
          image={Images.ModalFailure}
          isOpen={this.state.successModal}
          toggleModal={this.onSuccessModal}
          message={this.props.modelMsg}
        ></ModalMsg>

        <Modal
          isOpen={this.props.isDeleteOpen}
          toggle={this.props.toggleDeleteModal}
          centered
          size="md"
        >
          {this.state.selectedSchool !== "" && (
            <ModalBody>
              <DeleteModalContainer>
                <div className="title">{this.props.modelTitle}</div>
                <div className="desc mt-2 mb-3">{this.props.modelDesc}</div>
                {(this.props.selectedIds || []).length <= 1 && (
                  <div className="d-flex  align-items-center">
                    {this.props.modelImage && this.props.modelImage !== "" && (
                      <div className="col-2 p-0">
                        <Image src={this.props.modelImage} />
                      </div>
                    )}
                    <div className="ml-2 desc">
                      <div className="semi-bold">{this.props.heading}</div>
                      <div>{this.props.subHeading}</div>
                    </div>
                  </div>
                )}
                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    primaryTransparent
                    title={"Cancel"}
                    onClick={() => {
                      this.props.toggleDeleteModal();
                    }}
                  ></Button>

                  <Mutation
                    variables={{
                      // id: this.props.query.id
                      // instituteId: this.state.selectedSchool.id,
                      ...this.props.mutationVariables,
                    }}
                    mutation={this.props.deleteMutation}
                    onError={(error) => {
                      if (!error || !error.message) return null;

                      sendNotification({
                        message: error.message.replace("GraphQL error: ", ""),
                        type: "danger",
                      });
                      // this.showNotificationError(
                      //   error.message.replace("GraphQL error: ", "")
                      // );
                    }}
                    onCompleted={(data) => {
                      if (data[this.props.mutationKey]) {
                        // this.toggle
                        this.props.toggleDeleteModal();
                        this.props.onMutationCompleted();
                        this.onSuccessModal();
                      }
                    }}
                  >
                    {(DeleteAdmin, { data, loading, error }) => {
                      return (
                        <div>
                          <Loading is_loading={loading} />
                          <Button
                            rounded
                            danger
                            title={this.props.buttonText}
                            onClick={() => {
                              DeleteAdmin();
                            }}
                          ></Button>
                        </div>
                      );
                    }}
                  </Mutation>
                </div>
              </DeleteModalContainer>
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}
DeleteModal.propTypes = {
  isDeleteOpen: PropTypes.bool,
  modelImage: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  deleteMutation: PropTypes.object,
  mutationVariables: PropTypes.object,
  onMutationCompleted: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
  modelMsg: PropTypes.string,
  buttonText: PropTypes.string,
  mutationKey: PropTypes.string,
  selectedIds: PropTypes.array,
};

export default DeleteModal;
