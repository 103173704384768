import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { colors } from "../Themes/Color";
const DateContainer = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  & input {
    display: block;
    font-family: inherit;
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    padding: 16px;
    /* padding-top: 28px;
    padding-bottom: 5px; */
    padding-right: ${(props) => (props.password ? "40px" : "16px")};
    padding-top:20px;
    padding-bottom:20px;
    line-height: 1.45;
    height: ${(props) => (props.small ? "36px" : "60px")};
    font-weight: 400;
    margin: 0 0 4px;
    max-width: 100%;
    border: none;
    /* border-color: ${(props) => props.theme.defaultBorderColor}; */
    background-color: ${colors.inputBackground};
    border-radius: 8px;
    color: ${colors.colorBlack};
    width: 100%;
    box-sizing: border-box;
    border:1px solid ${colors.inputBackground};
    &.disabled {
      background: #f1f1f1;
    }
    &:focus {
      outline: none;
      /* border-left-width: 6px; */
      /* border-color: #585858; */
      border-color: ${(props) => props.theme.defaultBorderColor};
    }
    &:hover {
      outline: none;
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
  ${(props) =>
    props.white &&
    css`
      & input {
        background-color: ${colors.colorWhite};
      }
    `}
`;
const DateSelector = (props) => {
  return (
    <DateContainer white={props.white} small={props.small}>
      <div>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={props.startDate ? new Date(props.startDate) : new Date()}
          onChange={(date) => props.setStartDate(date)}
          {...props.dateObj}
        />
      </div>
    </DateContainer>
  );
};
DateSelector.defaultProps = {
  dateObj: {},
};
DateSelector.propTypes = {
  selected: PropTypes.string,
  setStartDate: PropTypes.func,
  dateObj: PropTypes.object,
};

export default DateSelector;
