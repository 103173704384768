// import { InMemoryCache } from "apollo-cache-inmemory";
// import { ApolloClient } from "apollo-client";
// import { ApolloLink } from "apollo-link";
// import { onError } from "apollo-link-error";
// import { createHttpLink } from "apollo-link-http";
// // import { devEndpoint, endpoint, prodEndpoint } from "../config";
// function createClient({ headers }) {
//   // return new ApolloClient({
//   //   uri:
//   //     process.env.NODE_ENV === "development"
//   //       ? endpoint
//   //       : process.env.NODE_ENV === "production"
//   //       ? prodEndpoint
//   //       : devEndpoint,
//   //   request: operation => {
//   //     operation.setContext({
//   //       fetchOptions: {
//   //         credentials: "include"
//   //       },
//   //       headers
//   //     });
//   //   }
//   // });
//   const uri = process.env.API_END_POINT;
//   // process.env.NODE_ENV === "development"
//   //   ? endpoint
//   //   : process.env.NODE_ENV === "production"
//   //   ? prodEndpoint
//   //   : devEndpoint;
//   console.log(uri);

//   const errorLink = onError(({ networkError, graphQLErrors }) => {
//     // if (graphQLErrors) {
//     //   graphQLErrors.map(({ message, locations, path }) =>
//     //     console.log(
//     //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//     //     )
//     //   );
//     // }
//     if (networkError) {
//       // return <div>Error</div>;
//     }
//     // console.log(`[Network error]: ${networkError}`);
//   });

//   const httpLink = createHttpLink({
//     uri: uri,
//     credentials: "include",
//     headers: headers
//   });

//   const link = ApolloLink.from([errorLink, httpLink]);
//   return new ApolloClient({
//     // ssrMode: false,
//     // Remember that this is the interface the SSR server will use to connect to the
//     // API server, so we need to ensure it isn't firewalled, etc
//     link,
//     cache: new InMemoryCache()
//   });
// }
// export default createClient;

import ApolloClient from "apollo-boost";

const client = new ApolloClient({
  // uri: process.env.API_END_POINT,
  uri: "https://api.console.scabbr.com/",
  onError: ({ graphQLErrors, networkError }) => {
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // Router.push("/500");
    }
  },
  request: (operation) => {
    operation.setContext({
      fetchOptions: {
        credentials: "include",
      },
      // headers,
    });
  },
});
export default client;
