export default {
  A403: require("../Static/images/A403.svg"),
  A404: require("../Static/images/A404.svg"),

  DashboardLogo: require("../Static/images/dashboardLogo.svg"),
  Info: require("../Static/images/info.svg"),
  InfoGray: require("../Static/images/info-gray.svg"),
  AddTask: require("../Static/images/addTask.svg"),
  Location: require("../Static/images/location.svg"),
  LocationOutline: require("../Static/images/location-outline.svg"),
  pwdRefresh: require("../Static/images/pwdRefresh.svg"),
  copyIcon: require("../Static/images/icons/copy.svg"),
  arrowIcon: require("../Static/images/icons/arrow.svg"),
  rssIcon: require("../Static/images/icons/rss.svg"),

  searchIcon: require("../Static/images/search/searchIcon.png"),
  sortIcon: require("../Static/images/search/sortIcon.png"),
  filterIcon: require("../Static/images/search/filterIcon.png"),
  circleIcon: require("../Static/images/icons/selectCircle.png"),
  circleIconChecked: require("../Static/images/icons/checkedCircleSelected.png"),
  circleIconSelected: require("../Static/images/icons/selectCircleSelected.png"),
  nextIcon: require("../Static/images/icons/next.png"),
  CloseIcon: require("../Static/images/icons/close.png"),
  SchoolIcon: require("../Static/images/dummy/StJohns-Crest-Small.png"),
  AddIcon: require("../Static/images/icons/add.png"),
  PasswordEye: require("../Static/images/icons/pwd-icon.png"),
  TickIcon: require("../Static/images/icons/tick.svg"),

  PasswordStrikeEye: require("../Static/images/icons/pwd-icon.png"),
  ModalSuccess: require("../Static/images/modal/success.svg"),
  ModalFailure: require("../Static/images/modal/error.svg"),
  StopIcon: require("../Static/images/icons/StopPin.png"),
  StopPinIcon: require("../Static/images/icons/StopPinLine.png"),
};
