import Table from "rc-table";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import LinesEllipsis from "react-lines-ellipsis";
import styled from "styled-components";
import DeleteModal from "../../../Component/DeleteModal";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import FileUpload from "../../../Component/Form/fileUpload";
import Loading from "../../../Component/Loading";
import Modal from "../../../Component/Modal";
import PictureModel from "../../../Component/PictureModel";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import Images from "../../../Themes/Images";
import Resources from "../../../Themes/Resources";
import { DownloadCSV, sendNotification } from "../../../utils";
import { DELETE_DRIVER, DRIVER_BULK_SIGNUP, GET_DRIVER_DETAILS, GET_DRIVER_FILTER } from "../../graphql";

const MassUploadComponent = styled.div`
  padding: 12px 20px;
  background: #b9cbff;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  .shareTemplate:hover {
    text-decoration: none;
  }
`;
export class DriversList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverDetails: [],
      selectedIndex: null,
      isOpen: false,
      selectedId: [],
      bulkDrivers: [],
      refetch: true,
      role: "",
      selectedDriver: {},
      pictureModelVisible: false,
      instituteId: "",
      sort: "ascending",
      sortCol: "firstName",
      sortStr: "ASC",
      isDeleteOpen: false,
      filterData: [],
      currFilter: [],
      resetFile: true,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    this.setState({ role: role, instituteId });
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  selectDriver = (index) => {
    const driverDetails = [...this.state.driverDetails];
    driverDetails.map((data, dataIndex) => {
      // data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = !data.isSelected;
      }
      return data;
    });
    let selectedIndex = null;
    let selectedId = driverDetails
      .filter((data, index) => {
        if (data.isSelected) {
          selectedIndex = index + 1;
          return data;
        }
      })
      .map((data) => data.id);
    this.setState({
      driverDetails,
      selectedDriver: this.state.driverDetails[index],
      selectedIndex: selectedIndex,
      // selectedId: this.state.driverDetails[index].id,
      selectedId: selectedId,
    });
  };
  editDrivers = () => {
    // alert(this.state.selectedIndex);
    if (this.state.selectedIndex) {
      if (this.state.selectedId.length !== 1) {
        sendNotification({
          message: "Please select only one driver",
          type: "danger",
        });
      } else {
        this.props.history.push(
          `/school/driver/edit/${
            this.state.driverDetails[this.state.selectedIndex - 1].id
          }`
        );
      }
    } else {
      sendNotification({ message: "Please select a driver", type: "danger" });
    }
  };
  deleteDrivers = (func) => {
    if (this.state.selectedIndex) {
      this.toggleDeleteModal();
    } else {
      sendNotification({ message: "Please select a driver", type: "danger" });
    }
  };
  // convertToJson
  onFileUpload = (rows) => {
    this.setState({ resetFile: false }, () => {
      this.setState({ resetFile: true });
    });
    let DataErr = [];
    // console.log(rows);
    const bulkDrivers = rows
      .map((data, index) => {
        let splitRow = data.split(",");
        if (splitRow.length === 11) {
          if (index !== 0) {
            return {
              firstName: splitRow[0],
              lastName: splitRow[1],
              mobileNumber: splitRow[2],
              //emailId: splitRow[3],
              isActive: splitRow[3] === "true",
              dob: splitRow[4],
              bloodGroup: splitRow[5],
              doj: splitRow[6],
              licenseNo: splitRow[7],
              validUpto: splitRow[8],
              aadharNo: splitRow[9],
              isPasswordReset: splitRow[10] === "true",
              instituteId: localStorage.getItem("institueId"),
              stopId: this.state.selectedStop,
              drivingLicense: "hello",
              // updatedBy: context.req.userId,
              // bulkImported: true
            };
          }
        } else if (splitRow.length === 0) {
        } else {
          DataErr.push(data.split(","));
          // return "error";
        }
      })
      .filter((data) => data);
    // if (bulkDrivers.includes("error")) {
    //   sendNotification({ message: "Invalid CSV file", type: "danger" });
    // } else {
    let unique = bulkDrivers.filter(
      ((set) => (f) => !set.has(f.mobileNumber) && set.add(f.mobileNumber))(
        new Set()
      )
    );

    this.setState({ bulkDrivers: unique, DataErr });
    // }
  };
  viewPictureModal = (index, title, desc, image) => {
    this.setState({
      pictureModelVisible: true,
      selectedDriver: this.state.driverDetails[index],
      selectedTitle: title,
      selectedDesc: desc,
      selectedImage: image,
      selectedDocument: title.replace(/[^a-zA-Z]/gi, "-").toLowerCase(),
    });
  };
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;
            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  onDownloadClick = () => {
    const CSV = [];
    CSV.push([
      "Driver Name",
      "DOB",
      "Mobile number",
      "Blood Group",
      "Date of joining",
      "License number",
      "Valid upto",
      "Aadhar number",
    ]);
    this.state.downloadDriverDetails.map((data) => {
      CSV.push([
        data.name,
        data.dob,
        data.mobileNumber,
        data.bloodGroup,
        data.emailId,
        data.doj,
        data.licenseNo || "",
        data.validUpto || "",
        data.aadharNo || "",
      ]);
    });
    this.setState({ schoolsCSV: CSV, forDownload: false }, () => {
      DownloadCSV("Drivers", CSV);
    });
  };
  render() {
    const columns = [
      {
        title: "Driver name",
        dataIndex: "driverName",
        key: "driverName",
        width: 200,
        fixed: "left",
      },

      {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
        width: 180,
      },
      {
        title: "Document",
        dataIndex: "document",
        key: "document",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              <div className="row m-0">
                <div className="col-8 p-0">
                  <div>License</div>
                </div>
                <div className="p-0">
                  <span
                    className="ml-2 color-sky-blue pointer-cursor"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.viewPictureModal(
                        index,
                        "Driving License",
                        `License no: ${data.licenseNo}`,
                        data.drivingLicense
                      );
                    }}
                  >
                    View
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-8 p-0">
                  <div>Address proof</div>
                </div>
                <div className="p-0">
                  <span
                    className="ml-2 color-sky-blue pointer-cursor"
                    onClick={(e) => {
                      e.stopPropagation();

                      this.viewPictureModal(
                        index,
                        "Address Proof",
                        `Aadhar no: ${data.aadharNo}`,
                        data.aadharLink
                      );
                    }}
                  >
                    View
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Route",
        dataIndex: "route",
        key: "route",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div className="mb-2" key={`trip-${index}-${tripIndex}`}>
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.routeName}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.routeId}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            );
          });
        },
      },
      {
        title: "Bus number",
        dataIndex: "busNo",
        key: "busNo",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="mb-2 tableMinHeight"
                key={`trip-${index}-${tripIndex}`}
              >
                <div>{data.regNumber}</div>
              </div>
            );
          });
        },
      },
      {
        title: "Route Status",
        dataIndex: "routeStatus",
        key: "routeStatus",
        width: 200,
        render: (text, data, index) => {
          return data.tripDetails.map((data, tripIndex) => {
            return (
              <div
                className="d-flex justify-content-between mb-2 tableMinHeight"
                key={`trip-${index}-${tripIndex}`}
              >
                <div>{data.status ? "Live" : "Inactive"}</div>
                <span
                  className="ml-2 color-sky-blue pointer-cursor"
                  onClick={() => {}}
                >
                  Track
                </span>
              </div>
            );
          });
        },
      },
      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.setState({ forDownload: true });
              this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 160,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className="col-6">
                <img
                  src={
                    !data.isSelected
                      ? Images.circleIcon
                      : Images.circleIconSelected
                  }
                  className="img-fluid "
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectDriver(index);
                  }}
                />
              </div>
              <div className="col-4 p-0">
                <img src={Images.nextIcon} className="img-fluid " alt="" />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div
        className={`${
          this.state.role === "PRIMARY" || this.state.driverDetails.length === 0
            ? "massUploadLayout"
            : ""
        }`}
      >
        <div className="row m-0 mt-4">

          <div className="col-5  pt-3">
            <Search
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              isShowFilter={this.state.filterData.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "firstName",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "firstName",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            <div>
              <Button
                title="Create new driver"
                primary
                rounded
                onClick={() => {
                  this.props.history.push("/school/driver/create");
                }}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                title="Edit"
                white
                rounded
                onClick={this.editDrivers}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                onClick={() => {
                  // this.toggleDeleteModal();
                  this.deleteDrivers();
                }}
                title="Delete"
                white
                rounded
              ></Button>
            </div>
          </div>
        </div>
        {this.state.instituteId !== "" && (
          <React.Fragment>
            <Query
              query={GET_DRIVER_FILTER}
              variables={{
                instituteId: this.state.instituteId,
              }}
              onCompleted={(data) => {
                if (data.createUniqueFiltersForDriver) {
                  let filterData = data.createUniqueFiltersForDriver.data
                    .map((data) => {
                      if (data.label) {
                        if (data.minValue && data.maxValue) {
                          data.defaultMinValue = data.minValue;
                          data.defaultMaxValue = data.maxValue;
                        }
                        if ((data.fieldValues || []).length) {
                          data.fieldValues = data.fieldValues.map((field) => {
                            return {
                              label: field,
                              isChecked: false,
                            };
                          });
                        }
                        return data;
                      }
                    })
                    .filter((data) => data);
                  //console.log(filterData);
                  this.setState({
                    filterData: filterData, //data.createUniqueFiltersForInstitute.data,
                  });
                }
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>

            {this.state.refetch && (
              <Query
                query={GET_DRIVER_DETAILS}
                fetchPolicy={"network-only"}
                variables={{
                  instituteId: this.state.instituteId,
                  searchTerm: this.state.searchTerm,
                  sortCol: this.state.sortCol,
                  sort: this.state.sortStr,
                  filter: this.state.currFilter,
                }}
                onCompleted={(data) => {
                  if (data.getDriverDetails) {
                    let driverDetails = [];

                    driverDetails = data.getDriverDetails.map((data) => {
                      data.isSelected = false;
                      data.driverName = data.firstName; // + " " + data.lastName;
                      data.contact = data.mobileNumber;
                      if ((data.schedule || []).length) {
                        data.tripDetails = data.schedule.map((schedule) => {
                          return {
                            routeName: schedule.route
                              ? schedule.route.routeName
                              : "",
                            routeId: schedule.route
                              ? schedule.route.routeId
                              : "",
                            regNumber: schedule.vehicle
                              ? schedule.vehicle.regNumber
                              : "",
                            status: true,
                          };
                        });
                      } else {
                        data.tripDetails = data.trips.map((trip) => {
                          return {
                            routeName: trip.route ? trip.route.routeName : "",
                            routeId: trip.route ? trip.route.routeId : "",
                            regNumber: trip.vehicle
                              ? trip.vehicle.regNumber
                              : "",
                            status: false,
                          };
                        });
                      }
                      return data;
                    });
                    this.setState({ driverDetails });
                  }
                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ hasErr: true });

                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                }}
              >
                {({ data, loading, error }) => {
                  return <Loading is_loading={loading} />;
                }}
              </Query>
            )}
          </React.Fragment>
        )}
        <div className="col-12 mt-5">
          <Table
            useFixedHeader
            onRow={(data, index) => ({
              onClick: () => {
                setTimeout(() => {
                  this.props.history.push(`/school/driver/view/${data.id}`);
                }, 1);
              },
            })}
            columns={columns}
            rowClassName={(record, i) => `pointer-cursor`}
            data={this.state.driverDetails}
            scroll={{
              x: 1200,
              // y: 350,
              y:
                this.state.role === "PRIMARY" ||
                this.state.driverDetails.length === 0
                  ? "calc(100vh - 140px - 75px - 64px - 86px)"
                  : "calc(100vh - 140px - 75px - 64px )",
            }}
            emptyText="No Driver Found!!"
            //title={setTableTitle}
          />
        </div>

        <Modal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          heading="Mass signup - Drivers"
        >
          <Mutation
            variables={{
              data: this.state.bulkDrivers,
            }}
            mutation={DRIVER_BULK_SIGNUP}
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.driverBulkImport) {
                this.setState({ refetch: false }, () => {
                  this.setState({ refetch: true });
                  this.toggleModal();
                });
                sendNotification({
                  message: "Drivers created successfully",
                  type: "success",
                });
              }
            }}
          >
            {(BulkDriver, { data, loading, error }) => {
              return (
                <React.Fragment>
                  <Loading is_loading={loading} />
                  {this.state.resetFile && (
                    <FileUpload
                      errData={this.state.DataErr}
                      count={this.state.bulkDrivers.length}
                      onFileUpload={this.onFileUpload}
                      onHandleSubmit={() => {
                        if (this.state.bulkDrivers.length) {
                          BulkDriver();
                        } else {
                          sendNotification({
                            message: "No Drivers available",
                            type: "danger",
                          });
                        }
                      }}
                      data="drivers"
                    ></FileUpload>
                  )}
                </React.Fragment>
              );
            }}
          </Mutation>
        </Modal>
        <PictureModel
          fileInfo={`${this.state.selectedDriver.firstName}-${this.state.selectedDocument}`}
          image={this.state.selectedImage}
          isOpen={this.state.pictureModelVisible}
          title={this.state.selectedTitle}
          desc={this.state.selectedDesc}
          toggleModal={() => {
            this.setState({ pictureModelVisible: false });
          }}
        ></PictureModel>

        <DeleteModal
          isDeleteOpen={this.state.isDeleteOpen}
          modelTitle="Delete Driver"
          modelDesc={`Are you sure you want to remove ${
            this.state.selectedId.length <= 1
              ? this.state.selectedDriver.firstName
              : `${this.state.selectedId.length} drivers`
          } from the Scabbr
        platform?`}
          modelImage={this.state.selectedDriver.displayPicture}
          heading={`${
            this.state.selectedDriver.firstName +
            " " +
            this.state.selectedDriver.lastName
          } `}
          subHeading={`Contact: ${this.state.selectedDriver.mobileNumber}`}
          deleteMutation={DELETE_DRIVER}
          mutationVariables={{
            driverIds: this.state.selectedId,
            instituteId: this.state.instituteId,
          }}
          onMutationCompleted={() => {
            this.setState({ refetch: false }, () => {
              this.setState({ refetch: true });
            });
          }}
          selectedIds={this.state.selectedId}
          mutationKey="deleteDriver"
          toggleDeleteModal={this.toggleDeleteModal}
          modelMsg={`${
            this.state.selectedId.length <= 1
              ? (this.state.selectedDriver.firstName || "").toUpperCase()
              : `${this.state.selectedId.length} DRIVERS`
          } HAS BEEN REMOVED FROM THE PLATFORM`}
          buttonText={"Remove Driver"}
        ></DeleteModal>
        {(this.state.role === "PRIMARY" ||
          this.state.driverDetails.length === 0) && (
          <MassUploadComponent className="d-flex">
            <div className="mr-3">
              <Button
                onClick={this.toggleModal}
                title="Mass Signup"
                primary
                rounded
              ></Button>
            </div>
            <a
              className="shareTemplate"
              download
              href={Resources.DriverResource}
            >
              <Button title="Get template" white rounded></Button>
            </a>
          </MassUploadComponent>
        )}
      </div>
    );
  }
}

export default DriversList;
