import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";

const TitleStyle = styled.div`
  font-size: 19px;
  font-family: "Muli", sans-serif;
  &.color-blue {
    color: #004584;
  }
  &.small {
    font-size: 17px;
  }
  &.very-small {
    font-size: 13px;
  }
  &.color-gray {
    color: #919191;
  }
  &.semi-bold {
    font-weight: 600;
  }
`;
export class TitleContainer extends Component {
  render() {
    return (
      <TitleStyle className={this.props.className ? this.props.className : ""}>
        {this.props.title}
      </TitleStyle>
    );
  }
}

TitleContainer.protoTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

export default TitleContainer;
