import moment from "moment";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import DateSelector from "../../../Component/DateSelector";
import Button from "../../../Component/Form/button";
import ReactFile from "../../../Component/Form/file";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import ReactTextarea from "../../../Component/Form/textarea";
import Loading from "../../../Component/Loading";
import { generateRandomString, sendNotification } from "../../../utils";
import { isMobileNumber } from "../../../validator";
import {
  CREATE_STUDENT,
  GET_STUDENT_DETAILS_BY_ID,
  UPDATE_STUDENT,
} from "../../graphql";

let FormRef, FormRef1;
const classOptions = [
  { value: "PreKG", name: "PreKG" },
  { value: "LKG", name: "LKG" },
  { value: "UKG", name: "UKG" },

  { value: "01", name: "01" },
  { value: "02", name: "02" },
  { value: "03", name: "03" },
  { value: "04", name: "04" },
  { value: "05", name: "05" },
  { value: "06", name: "06" },
  { value: "07", name: "07" },
  { value: "08", name: "08" },
  { value: "09", name: "09" },
  { value: "10", name: "10" },
  { value: "11", name: "11" },
  { value: "12", name: "12" },
];
const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export class CreateStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CreateStudents: {
        profilePicture: "",
        fname: "",
        lname: "",
        dob: moment().format("YYYY/MM/DD"),
        class: "PreKG",
        section: "",
        mobileNo: "",
        bGrp: "",
        address: "",

        fatherName: "",
        fatherMobileNo: "",
        fatherOccupation: "",
        motherName: "",
        motherMobileNo: "",
        motherOccupation: "",
        roleNo: "",
      },
      dataVariables: {},
      fileInfo: {
        profilePicture: "",
      },
      activeTab: this.props.history.location.search.split("=")[1]
        ? this.props.history.location.search.split("=")[1]
        : "1",
      formSubmit: false,
      handleForm1: false,
      handleForm2: false,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    // refer

    this.setState({ gpwd: generateRandomString(7), instituteId });
  }
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const CreateStudents = { ...this.state.CreateStudents };
    CreateStudents[key] = value;

    this.setState({ CreateStudents });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  onNextButton = () => {
    // this.setActiveTab(parseInt(this.state.activeTab) + 1);
    if (this.state.activeTab === "1") {
      this.setState({ handleForm1: true }, () => {
        this.onNextButtonValidate(FormRef);
      });
    }
    if (this.state.activeTab === "2") {
      this.setState({ handleForm2: true }, () => {
        this.onNextButtonValidate(FormRef1);
      });
    }
  };
  onNextButtonValidate = (Form) => {
    Form.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll(".form-error").length === 0) {
        if (document.querySelectorAll(".inputRequired").length === 0) {
          // ContactOrganizer();
          // func();
          // this.onSetVariables(func);
          this.setActiveTab(parseInt(this.state.activeTab) + 1);
          // this.props.changePage();
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      } else {
        // this.setActiveTab(1);
        sendNotification({
          message: "Please fill all the fields",
          type: "danger",
        });
      }
    }, 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/school/students");
    }
  };
  onChange = (key, value) => {
    const CreateStudents = { ...this.state.CreateStudents };
    CreateStudents[key] = value;
    this.setState({ CreateStudents });
  };
  handleSubmit = (func) => {
    this.setState({ handleForm2: true }, () => {
      FormRef1.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            this.setDataVariables(func);
            // this.props.changePage();
          } else {
            // this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  setDataVariables = (func) => {
    const dataVariables = {
      // id: this.props.query.id,
      instituteId: localStorage.getItem("institueId"),
      firstName: this.state.CreateStudents.fname,
      lastName: this.state.CreateStudents.lname,
      studentId: this.state.CreateStudents.roleNo,
      displayPicture: this.state.CreateStudents.imageURL,
      dob: this.state.CreateStudents.dob,
      // this.state.CreateStudents.yearOfBirth +
      // "/" +
      // this.state.CreateStudents.monthOfBirth +
      // "/" +
      // this.state.CreateStudents.dateOfBirth,
      class: this.state.CreateStudents.class,
      section: this.state.CreateStudents.section,
      // mobileNumber: this.state.CreateStudents.mobileNo,
      bloodGroup: this.state.CreateStudents.bGrp,
      address: this.state.CreateStudents.address,
      fatherName: this.state.CreateStudents.fatherName,
      fatherContact: this.state.CreateStudents.fatherMobileNo,
      fatherOccupation: this.state.CreateStudents.fatherOccupation,
      motherName: this.state.CreateStudents.motherName,
      motherContact: this.state.CreateStudents.motherMobileNo,
      motherOccupation: this.state.CreateStudents.motherOccupation,
      userToken: this.state.CreateStudents.userToken,
    };
    if (this.props.match.params.id) {
      dataVariables.id = this.props.match.params.id;
      dataVariables.fatherContactOld = this.state.CreateStudents.motherMobileNoOld;
      dataVariables.motherContactOld = this.state.CreateStudents.motherMobileNoOld;
    }

    this.setState({ dataVariables }, () => {
      func();
    });
  };
  fileInfo = (key, value) => {
    const fileInfo = { ...this.state.fileInfo };
    fileInfo[key] = value;
    this.setState({ fileInfo });
  };
  render() {
    return (
      <SchoolContainer className="pl-3">
        {this.props.match.params.id && (
          <Query
            variables={{ id: this.props.match.params.id }}
            fetchPolicy="network-only"
            query={GET_STUDENT_DETAILS_BY_ID}
            onCompleted={(data) => {
              if (data.getStudentById) {
                let CreateStudents = {
                  profilePicture: data.getStudentById.displayPicture,
                  fname: data.getStudentById.firstName,
                  lname: data.getStudentById.lastName,
                  dob: data.getStudentById.dob,
                  // dateOfBirth: new Date(data.getStudentById.dob).getDate(),
                  // monthOfBirth:
                  //   new Date(data.getStudentById.dob).getMonth() + 1,
                  //   yearOfBirth: new Date(data.getStudentById.dob).getFullYear(),
                  class: data.getStudentById.class,
                  section: data.getStudentById.section,
                  bGrp: data.getStudentById.bloodGroup,
                  address: data.getStudentById.address,

                  fatherName: data.getStudentById.fatherName,
                  fatherMobileNo: data.getStudentById.fatherMobileNo,
                  fatherMobileNoOld: data.getStudentById.fatherMobileNo || "",
                  fatherOccupation: data.getStudentById.fatherOccupation,
                  motherName: data.getStudentById.motherName,
                  motherMobileNoOld: data.getStudentById.motherMobileNo || "",
                  motherMobileNo: data.getStudentById.motherMobileNo,
                  motherOccupation: data.getStudentById.motherOccupation,
                  roleNo: data.getStudentById.studentId,
                  activeTab: "1",
                  imageURL: data.getStudentById.displayPicture,
                };
                let fileInfo = data.getStudentById.displayPicture
                  ? { profilePicture: { name: "dp.png" } }
                  : {};
                this.setState(
                  {
                    CreateStudents,
                    fileInfo,
                  },
                  () => {}
                );

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <div className="menu mt-4 pt-3">
          <div
            className={`menuItem ${
              this.state.activeTab === "1" ? "active" : ""
            }`}
          >
            Student details
          </div>
          <div
            className={`menuItem ${
              this.state.activeTab === "2" ? "active" : ""
            }`}
          >
            Parent details
          </div>
        </div>
        <div className="col-9 pl-4 pr-4 pt-4">
          <Mutation
            variables={{
              data: this.state.dataVariables,
            }}
            mutation={
              this.props.match.params.id ? UPDATE_STUDENT : CREATE_STUDENT
            }
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });

              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.createStudent) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Student created successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/students");
                }, 500);
                // message.success("Contact Created!!");
              }
              if (data.updateStudent) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Student updated successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/students");
                }, 500);
                // message.success("Contact Created!!");
              }
            }}
          >
            {(CreateStudents, { data, loading, error }) => {
              return (
                <div>
                  <Loading is_loading={loading} />
                  {this.state.activeTab === "1" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "1" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                        className={` ${
                          this.state.handleForm1 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Profile picture</div>
                          <div className="col-9">
                            <ReactFile
                              placeholder=""
                              white
                              header="student profile picture"
                              multiple={false}
                              required
                              uploadFor={"Students"}
                              value={this.state.fileInfo.profilePicture}
                              imageURL={this.state.CreateStudents.imageURL}
                              token={{
                                insToken: this.state.instituteId,
                                userToken: this.state.CreateStudents.userToken,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("insToken", insToken);
                                setTimeout(() => {
                                  this.onChange("userToken", insToken);
                                }, 10);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("imageURL", value);
                              }}
                              onDelete={() => {
                                this.onChange("imageURL", "");
                                this.fileInfo("profilePicture", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "profilePicture",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">First name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateStudents.fname}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("fname", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Last name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateStudents.lname}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("lname", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Class/Section</div>
                          <div className="col-9">
                            <div className="row col-0 p-0">
                              <div className="col-6">
                                <ReactSelect
                                  placeholder=""
                                  white
                                  required
                                  value={this.state.CreateStudents.class}
                                  className={"w-100"}
                                  // options={[
                                  //   { value: "Namakkal", name: "Namakkal" },
                                  //   { value: "Salem", name: "Van" }
                                  // ]}
                                  options={classOptions}
                                  onChange={(value) => {
                                    // console.log(value);
                                    this.onChange("class", value);
                                  }}
                                ></ReactSelect>
                              </div>
                              <div className="col-6">
                                <ReactInput
                                  placeholder="Section"
                                  white
                                  required
                                  value={this.state.CreateStudents.section}
                                  className={"w-100"}
                                  onChange={(value) => {
                                    this.onChange("section", value);
                                  }}
                                ></ReactInput>
                              </div>
                            </div>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Roll number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateStudents.roleNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("roleNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Date of birth </div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={this.state.CreateStudents.dob}
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "dob",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                              dateObj={{
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: "select",
                              }}
                            ></DateSelector>
                            {/*
                            <div className="col-2 pl-0">
                                <NumericalInput
                                value={this.state.CreateStudents.dateOfBirth}
                                max="31"
                                min="1"
                                onChange={(value) => {
                                  this.onChange("dateOfBirth", value);
                                }}
                                padString="00"
                              ></NumericalInput>
                              </div>
                              <div className="col-2 pl-0">
                              <NumericalInput
                              value={this.state.CreateStudents.monthOfBirth}
                              max="12"
                              min="1"
                              padString="00"
                              onChange={(value) => {
                                this.onChange("monthOfBirth", value);
                              }}
                              ></NumericalInput>
                              </div>
                              <div className="col-3 pl-0">
                              <NumericalInput
                              max={new Date().getFullYear()}
                              min="1950"
                              value={this.state.CreateStudents.yearOfBirth}
                              padString="0000"
                              onChange={(value) => {
                                this.onChange("yearOfBirth", value);
                              }}
                              ></NumericalInput>
                              </div>
                            */}
                          </div>
                        </InputContainer>

                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Blood group</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateStudents.bGrp}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("bGrp", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Address</div>
                          <div className="col-9">
                            <ReactTextarea
                              placeholder=""
                              white
                              rows={6}
                              required
                              value={this.state.CreateStudents.address}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("address", value);
                              }}
                            ></ReactTextarea>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "2" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "2" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef1 = ref;
                        }}
                        className={` ${
                          this.state.handleForm2 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Father name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateStudents.fatherName}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("fatherName", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Mobile number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              validation={[isMobileNumber]}
                              value={this.state.CreateStudents.fatherMobileNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("fatherMobileNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Occupation</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateStudents.fatherOccupation}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("fatherOccupation", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>

                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Mother name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              value={this.state.CreateStudents.motherName}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("motherName", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Mobile number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              validation={[isMobileNumber]}
                              value={this.state.CreateStudents.motherMobileNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("motherMobileNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Occupation</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              value={this.state.CreateStudents.motherOccupation}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("motherOccupation", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}

                  <div className="mt-2 mb-5 pt-4 d-flex align-items-center justify-content-end">
                    <Button
                      primaryTransparent
                      buttonClass="mr-4"
                      title="Back"
                      disabled={this.state.activeTab === "1"}
                      onClick={this.onBackButton}
                    ></Button>
                    <div className="d-inline">
                      <Button
                        title={`${
                          this.state.activeTab === "2"
                            ? this.props.match.params.id
                              ? "Update student profile"
                              : "Create student profile"
                            : "Next"
                        }`}
                        primary
                        rounded
                        onClick={() => {
                          if (this.state.activeTab === "2") {
                            this.handleSubmit(CreateStudents);
                          } else {
                            this.onNextButton();
                          }
                          // this.handleSubmit(CreateStudents);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Mutation>
        </div>
      </SchoolContainer>
    );
  }
}

export default CreateStudents;
