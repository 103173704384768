import moment from "moment";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import DateSelector from "../../../Component/DateSelector";
import Button from "../../../Component/Form/button";
import ReactFile from "../../../Component/Form/file";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import Loading from "../../../Component/Loading";
import { generateRandomString, sendNotification } from "../../../utils";
import {
  CREATE_VEHICLE,
  GET_VEHICLE_DETAILS_BY_ID,
  UPDATE_VEHICLE,
} from "../../graphql";

let FormRef, FormRef1;

const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export class CreateVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CreateVehicle: {
        vehicleID: "",
        vehicleType: "BUS",
        insuranceValid: moment().format("YYYY/MM/DD"),

        validUpto: moment().format("YYYY/MM/DD"),
        capacity: "",
        licenseNo: "",
        serialCode: "",
        districtCode: "",
        state: "",
        brand: "",
        rcBook: "",
        dateOfRegistration: "",
      },
      dataVariables: {},
      activeTab: "1",
      fileInfo: {
        rcBook: "",
        insurance: "",
      },
      formSubmit: false,
      handleForm1: false,
      handleForm2: false,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    // refer
    this.setState({ gpwd: generateRandomString(7), instituteId });
  }
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const CreateVehicle = { ...this.state.CreateVehicle };
    CreateVehicle[key] = value;

    this.setState({ CreateVehicle });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  // onNextButton = () => {
  //   this.setActiveTab(parseInt(this.state.activeTab) + 1);
  // };
  onNextButton = () => {
    // this.setActiveTab(parseInt(this.state.activeTab) + 1);
    if (this.state.activeTab === "1") {
      this.setState({ handleForm1: true }, () => {
        this.onNextButtonValidate(FormRef);
      });
    }
    if (this.state.activeTab === "2") {
      this.setState({ handleForm2: true }, () => {
        this.onNextButtonValidate(FormRef1);
      });
    }
  };
  onNextButtonValidate = (Form) => {
    Form.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll(".form-error").length === 0) {
        if (document.querySelectorAll(".inputRequired").length === 0) {
          // ContactOrganizer();
          // func();
          // this.onSetVariables(func);
          this.setActiveTab(parseInt(this.state.activeTab) + 1);
          // this.props.changePage();
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      } else {
        // this.setActiveTab(1);
        sendNotification({
          message: "Please fill all the fields",
          type: "danger",
        });
      }
    }, 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/school/vehicles");
    }
  };
  onChange = (key, value) => {
    const CreateVehicle = { ...this.state.CreateVehicle };
    CreateVehicle[key] = value;
    this.setState({ CreateVehicle });
  };
  handleSubmit = (func) => {
    this.setState({ handleForm2: true }, () => {
      FormRef1.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            this.setDataVariables(func);
            // this.props.changePage();
          } else {
            // this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  setDataVariables = (func) => {
    {
      /**
    if (
      isNaN(new Date(this.state.CreateVehicle.dateOfRegistration).getTime())
      ) {
        sendNotification({
          message: "Invalid format for date of registration",
          type: "danger",
        });
      } else {
      */
    }
    const dataVariables = {
      // id: this.props.query.id,
      vehicleId: this.state.CreateVehicle.vehicleID,
      type: this.state.CreateVehicle.vehicleType,
      brand: this.state.CreateVehicle.brand,
      regNumber: `${this.state.CreateVehicle.state} ${this.state.CreateVehicle.districtCode} ${this.state.CreateVehicle.serialCode} ${this.state.CreateVehicle.licenseNo}`,
      capacity: this.state.CreateVehicle.capacity + "",
      rcBook: this.state.CreateVehicle.rcBook,
      validUpto: this.state.CreateVehicle.validUpto,
      regDate: moment(this.state.CreateVehicle.dateOfRegistration).format(
        "YYYY/MM/DD"
      ),
      insurance: this.state.CreateVehicle.insurance,
      insValidUpto: this.state.CreateVehicle.insuranceValid,
      location: this.state.CreateVehicle.location,
    };
    if (this.props.match.params.id) {
      dataVariables.id = this.props.match.params.id;
    } else {
      dataVariables.instituteId = localStorage.getItem("institueId");
    }

    this.setState({ dataVariables }, () => {
      func();
    });
    {
      /**} */
    }
  };
  fileInfo = (key, value) => {
    const fileInfo = { ...this.state.fileInfo };
    fileInfo[key] = value;
    this.setState({ fileInfo });
  };
  render() {
    return (
      <SchoolContainer className="pl-3">
        {this.props.match.params.id && (
          <Query
            variables={{ id: this.props.match.params.id }}
            query={GET_VEHICLE_DETAILS_BY_ID}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (data.getVehicleById) {
                let CreateVehicle = {
                  vehicleID: data.getVehicleById.vehicleId,
                  vehicleType: data.getVehicleById.type,
                  dateOfInsuranceValid: moment(
                    data.getVehicleById.insValidUpto
                  ).format("YYYY/MM/DD"),
                  dateOfValid: data.getVehicleById.rcValidUpto,
                  capacity: data.getVehicleById.capacity,
                  licenseNo: data.getVehicleById.regNumber.split(" ")[3],
                  serialCode: data.getVehicleById.regNumber.split(" ")[2],
                  districtCode: data.getVehicleById.regNumber.split(" ")[1],
                  state: data.getVehicleById.regNumber.split(" ")[0],
                  brand: data.getVehicleById.manufacture,
                  rcBook: data.getVehicleById.rcbook,
                  dateOfRegistration: moment(
                    data.getVehicleById.regDate
                  ).format("YYYY/MM/DD"),
                  insurance: data.getVehicleById.insurance,
                  activeTab: "1",
                };
                let fileInfo = {
                  insurance: { name: "insurance.png" },
                  rcBook: {
                    name: "rcBook.png",
                  },
                };
                this.setState({ CreateVehicle, fileInfo }, () => {});

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}
        <div className="menu mt-4 pt-3">
          <div
            className={`menuItem ${
              this.state.activeTab === "1" ? "active" : ""
            }`}
          >
            Bus details
          </div>

          <div
            className={`menuItem ${
              this.state.activeTab === "2" ? "active" : ""
            }`}
          >
            Documents
          </div>
        </div>
        <div className="col-9 pl-4 pr-4 pt-4">
          <Mutation
            variables={{
              data: this.state.dataVariables,
            }}
            mutation={
              this.props.match.params.id ? UPDATE_VEHICLE : CREATE_VEHICLE
            }
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });

              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.createVehicle) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Vehicle created successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/vehicles");
                }, 500);
                // message.success("Contact Created!!");
              }
              if (data.updateVehicle) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Vehicle updated successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/school/vehicles");
                }, 500);
                // message.success("Contact Created!!");
              }
            }}
          >
            {(CreateVehicle, { data, loading, error }) => {
              return (
                <div>
                  <Loading is_loading={loading} />
                  {this.state.activeTab === "1" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "1" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                        className={` ${
                          this.state.handleForm1 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Vehicle type</div>
                          <div className="col-9">
                            <ReactSelect
                              placeholder=""
                              white
                              required
                              value={this.state.CreateVehicle.vehicleType}
                              className={"w-100"}
                              options={[
                                { value: "BUS", name: "Bus" },
                                { value: "VAN", name: "Van" },
                              ]}
                              onChange={(value) => {
                                // console.log(value);
                                this.onChange("vehicleType", value);
                              }}
                            ></ReactSelect>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Vehicle ID</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateVehicle.vehicleID}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("vehicleID", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Vehicle brand</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.CreateVehicle.brand}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("brand", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Vehicle Number</div>
                          <div className="col-9 d-flex">
                            <div className="col-2 pl-0">
                              <ReactInput
                                placeholder=""
                                white
                                required
                                value={this.state.CreateVehicle.state}
                                className={"w-100"}
                                onChange={(value) => {
                                  this.onChange("state", value);
                                }}
                              ></ReactInput>
                            </div>
                            <div className="col-2 pl-0">
                              <ReactInput
                                placeholder=""
                                white
                                required
                                value={this.state.CreateVehicle.districtCode}
                                className={"w-100"}
                                type="number"
                                onChange={(value) => {
                                  this.onChange("districtCode", value);
                                }}
                              ></ReactInput>
                            </div>
                            <div className="col-2 pl-0">
                              <ReactInput
                                placeholder=""
                                white
                                required
                                value={this.state.CreateVehicle.serialCode}
                                className={"w-100"}
                                onChange={(value) => {
                                  this.onChange("serialCode", value);
                                }}
                              ></ReactInput>
                            </div>
                            <div className="col-3 pl-0">
                              <ReactInput
                                placeholder=""
                                white
                                required
                                type="number"
                                value={this.state.CreateVehicle.licenseNo}
                                className={"w-100"}
                                onChange={(value) => {
                                  this.onChange("licenseNo", value);
                                }}
                              ></ReactInput>
                            </div>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Vehicle capacity </div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              type="number"
                              value={this.state.CreateVehicle.capacity}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("capacity", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "2" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "2" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef1 = ref;
                        }}
                        className={` ${
                          this.state.handleForm2 ? "invalid-form" : ""
                        }`}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">RC Book</div>
                          <div className="col-9">
                            {/* <ReactFile
                              placeholder=""
                              white
                              // required
                              value={this.state.CreateVehicle.rcBook}
                              className={"w-100"}
                              onChange={value => {
                                this.onChange("rcBook", value);
                              }}
                            ></ReactFile> */}
                            <ReactFile
                              placeholder=""
                              white
                              header="RC Book"
                              multiple={false}
                              required
                              uploadFor={"Vehicles"}
                              value={this.state.fileInfo.rcBook}
                              imageURL={this.state.CreateVehicle.rcBook}
                              token={{
                                userToken: this.state.CreateVehicle.userToken,
                                insToken: this.state.instituteId,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("userToken", userToken);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("rcBook", value);
                              }}
                              onDelete={() => {
                                this.onChange("rcBook", "");
                                this.fileInfo("rcBook", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "rcBook",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Date of registration</div>
                          <div className="col-9">
                            <DateSelector
                              white
                              startDate={
                                this.state.CreateVehicle.dateOfRegistration
                              }
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "dateOfRegistration",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                            ></DateSelector>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Valid upto</div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={this.state.CreateVehicle.validUpto}
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "validUpto",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                            ></DateSelector>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Insurance</div>
                          <div className="col-9">
                            {/* <ReactFile
                              placeholder=""
                              white
                              // required
                              value={this.state.CreateVehicle.insurance}
                              className={"w-100"}
                              onChange={value => {
                                this.onChange("insurance", value);
                              }}
                            ></ReactFile> */}
                            <ReactFile
                              placeholder=""
                              white
                              header="Insurance Picture"
                              multiple={false}
                              required
                              uploadFor={"Vehicles"}
                              value={this.state.fileInfo.insurance}
                              imageURL={this.state.CreateVehicle.insurance}
                              token={{
                                userToken: this.state.CreateVehicle.userToken,
                                insToken: this.state.instituteId,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("userToken", userToken);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("insurance", value);
                              }}
                              onDelete={() => {
                                this.onChange("insurance", "");
                                this.fileInfo("insurance", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "insurance",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Valid upto</div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={
                                this.state.CreateVehicle.insuranceValid
                              }
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "insuranceValid",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                            ></DateSelector>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}

                  <div className="mt-2 mb-5 pt-4 d-flex align-items-center justify-content-end">
                    <Button
                      primaryTransparent
                      buttonClass="mr-4"
                      title="Back"
                      disabled={this.state.activeTab === "1"}
                      onClick={this.onBackButton}
                    ></Button>
                    <div className="d-inline">
                      <Button
                        title={`${
                          this.state.activeTab === "2"
                            ? this.props.match.params.id
                              ? "Update vehicle"
                              : "Create vehicle"
                            : "Next"
                        }`}
                        primary
                        rounded
                        onClick={() => {
                          if (this.state.activeTab === "2") {
                            this.handleSubmit(CreateVehicle);
                          } else {
                            this.onNextButton();
                          }
                          // this.handleSubmit(CreateVehicle);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Mutation>
        </div>
      </SchoolContainer>
    );
  }
}

export default CreateVehicle;
