import PropTypes from "prop-types";
import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { GET_PLACES } from "../Application/graphql";
import ReactInput from "./Form/input";
const debounce = require("lodash.debounce");

let locationdebounced = null;
export class GetPlaces extends Component {
  componentWillUnmount() {
    locationdebounced = null;
  }
  // constructor(props){
  //   super(props);
  //   this.state={
  //     city:""
  //   }
  // }
  render() {
    return (
      <div>
        <Mutation
          variables={{
            data: {
              place: `${this.props.city}`,
              type: `${this.props.type}`
              // country: events.countryCode
            }
          }}
          mutation={GET_PLACES}
          onCompleted={data => {
            this.props.onDropDownVisible(true);
            // this.setState({ dropdownVisible: true });
            // if (data.getPlaces.id) {
            // alert("User Created!!");
            // this.props.history.push("/events/yourtickets");
            // message.success("Contact Created!!");
            // }
          }}
        >
          {(GetPlace, { data, loading, error }) => {
            if (locationdebounced === null) {
              locationdebounced = debounce(GetPlace, 500, {
                maxWait: "1000"
              });
            }
            if (error) {
              return <div />;
            }
            return (
              <React.Fragment>
                <ReactInput
                  placeholder={`${this.props.placeholder}`}
                  required={this.props.required}
                  relativeClass={`${
                    this.props.placeId === "" && this.props.FormSubmit
                      ? "form-error"
                      : ""
                  }`}
                  white
                  value={this.props.city}
                  className={"w-100"}
                  onChange={value => {
                    this.props.onChange("city", value);
                    locationdebounced();
                  }}
                  onFocus={value => {
                    // alert();
                    this.props.onDropDownVisible(true);
                    // this.setState({ dropdownVisible: true });
                  }}
                ></ReactInput>
                {this.props.dropdownVisible && (
                  <span>
                    {data && (
                      <React.Fragment>
                        <div className="position-absolute dropdownStyle col-12 pb-3">
                          {data && (
                            <React.Fragment>
                              {data.getPlaces.length === 0 && (
                                <div className="text-center pt-3">
                                  No places found
                                </div>
                              )}
                              {data.getPlaces.map((places, index) => (
                                <div
                                  className="pt-3"
                                  key={`places-${index}`}
                                  onClick={e => {
                                    this.props.setMulti({
                                      placeId: places.placeid,
                                      city: places.placename
                                    });

                                    // onChange('venueName', places.name);
                                    // setTimeout(() => {
                                    //   onChange("placeId", places.placeId);
                                    // }, 100);
                                    this.props.onDropDownVisible(false);
                                    // this.setState({
                                    //   dropdownVisible: false
                                    // });
                                    // console.log(places)
                                  }}
                                >
                                  <div className="placeName">
                                    {places.placename}
                                  </div>
                                  <div className="placeState">
                                    {places.subtitle}
                                  </div>
                                </div>
                              ))}
                            </React.Fragment>
                          )}
                          {loading && (
                            <div className="text-center pt-3">loading..</div>
                          )}
                          {/* {!data && !loading && (
                                <div className="text-center pt-3">
                                  No Options Found
                                </div>
                              )} */}
                        </div>
                        }
                      </React.Fragment>
                    )}
                  </span>
                )}
              </React.Fragment>
            );
          }}
        </Mutation>
      </div>
    );
  }
}

Image.propTypes = {
  placeId: PropTypes.string,
  dropdownVisible: PropTypes.bool,
  onDropDownVisible: PropTypes.func,
  onChange: PropTypes.func,
  setMulti: PropTypes.func,
  city: PropTypes.string,
  FormSubmit: PropTypes.bool,
  className: PropTypes.string
};

export default GetPlaces;
