import React, { Component } from "react";
import { Mutation } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import Button from "../../Component/Form/button";
import ReactInput from "../../Component/Form/input";
import Image from "../../Component/Image";
import Loading from "../../Component/Loading";
import Logo from "../../Static/logo.svg";
import { colors } from "../../Themes/Color";
import { sendNotification } from "../../utils";
// import { sendNotification } from "../../utils";
import { email } from "../../validator";
import { FORGOT_PASSWORD } from "../graphql";
let FormRef;

const LoginContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  background: ${colors.defaultBackgroundColor};
  .LogoCaption {
    font-family: "Muli", sans-serif;
    font-weight: 400;
    font-size: 17px;
  }
  .logo {
    width: 250px;
  }
  .titleText {
    font-size: 25px;
    font-weight: 500;
    color: ${colors.colorBlack};
  }
  .desc {
    font-size: 17px;
    color: ${colors.grayColor};
  }
`;
const CardContainer = styled.div`
  border-radius: 8px;

  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  min-width: 530px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background: ${colors.colorWhite};
  position: relative;
  transition: all 0.3s;
  & .body {
    padding: 24px;
    padding-bottom: 0;
    zoom: 1;
    margin-bottom: 25px;
  }
  & .forgotPwd {
    color: ${colors.primaryColor};
    font-size: 13px;
    font-weight: 400;
    font-family: "Muli", sans-serif;
  }
`;
export class ForgotPassword extends Component {
  state = {
    email: "",
    password: "",
    formSubmit: false,
    isError: false,
  };
  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  handleSubmit = (func) => {
    this.setState({ formSubmit: true }, () => {
      FormRef.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            func();
            // this.props.changePage();
          }
        }
      }, 1);
    });
  };
  render() {
    return (
      <LoginContainer>
        <div>
          <Image className="logo" src={Logo}></Image>
          <div className="LogoCaption mb-3 pb-2">
            Travel safe. Travel connected.{" "}
          </div>
          <div className="titleText">Did you forgot your password?</div>
          <div className="desc mt-1">
            Enter your email address you are using for you account
          </div>
          <div className="desc mb-3">
            below and we will send you a password reset link.
          </div>

          <CardContainer>
            <div className="body">
              <Mutation
                variables={{
                  // id: this.props.query.id
                  email: this.state.email,
                }}
                mutation={FORGOT_PASSWORD}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ isError: true });
                  // sendNotification({
                  //   message: error.message.replace("GraphQL error: ", ""),
                  //   type: "danger"
                  // });
                  // this.showNotificationError(
                  //   error.message.replace("GraphQL error: ", "")
                  // );
                }}
                onCompleted={(data) => {
                  if (data.sendResetPasswordLink) {
                    this.setState({ email: "", formSubmit: false }, () => {
                      sendNotification({
                        message: "Mail send successfully",
                        type: "success",
                      });
                    });
                  }
                }}
              >
                {(UserLogin, { data, loading, error }) => {
                  return (
                    <Form
                      ref={(ref) => {
                        FormRef = ref;
                      }}
                      className={` ${
                        this.state.formSubmit ? "invalid-form" : ""
                      }`}
                    >
                      <Loading is_loading={loading}></Loading>
                      <div className="mb-4">
                        <ReactInput
                          placeholder="Email address"
                          required
                          validation={[email]}
                          value={this.state.email}
                          className={"w-100"}
                          onChange={(value) => {
                            this.onChange("email", value);
                          }}
                          isError={
                            this.state.formSubmit && this.state.email === ""
                          }
                          ErrorMessage="Username is incorrect"
                        ></ReactInput>
                      </div>

                      <div className="mx-auto col-md-7">
                        <Button
                          title="Request reset link"
                          onClick={() => {
                            this.setState({ isError: false });

                            // localStorage.setItem("token", "token");
                            // UserLogin();
                            this.handleSubmit(UserLogin);
                            // this.props.history.push(`/dashboard`);
                          }}
                        ></Button>
                      </div>
                      <div
                        className="pb-2 forgotPwd mt-3 pointer-cursor"
                        onClick={() => {
                          this.props.history.push("/");
                        }}
                      >
                        Go back to sign in
                      </div>
                    </Form>
                  );
                }}
              </Mutation>
            </div>
          </CardContainer>
          {this.state.isError && (
            <div className="text-error mt-4 mb-4">
              <div>Your email does not exist.Please check the </div>
              <div>email you have entered.</div>
            </div>
          )}
        </div>
      </LoginContainer>
    );
  }
}

export default ForgotPassword;
