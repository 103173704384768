import moment from "moment";
import React, { Component } from "react";
import { Query } from "react-apollo";
import styled from "styled-components";
import ReactAvatar from "../../../Component/Avatar";
import Button from "../../../Component/Form/button";
import Loading from "../../../Component/Loading";
import PictureModel from "../../../Component/PictureModel";
import TableStyle from "../../../Component/Styles/Table";
import { sendNotification } from "../../../utils";
import { GET_DRIVER_BY_ID } from "../../graphql";

const ViewDriverStyle = styled.div`
  .dp {
    width: 106px;
    height: 106px;
    border-radius: 5px;
  }
  .title {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 19px;
    font-size: 15px;
    color: #000;
  }
  .desc {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    line-height: 19px;
    font-size: 15px;
    color: #000;
  }
  .titleContent {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 21px;
    font-size: 17px;
    color: #08366a;
  }
  .border-left-right {
    border-left: 1px solid;
    border-color: #004584;
  }
`;

export class ViewDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DriversView: {},
      pictureModelVisible: false,
      tripDetails: [],
    };
  }
  viewPictureModal = (title, desc, image) => {
    this.setState({
      pictureModelVisible: true,
      selectedTitle: title,
      selectedDesc: desc,
      selectedImage: image,
      selectedDocument: title.replace(/[^a-zA-Z]/gi, "-").toLowerCase(),
    });
  };
  render() {
    return (
      <ViewDriverStyle>
        {this.props.match.params.id && (
          <Query
            variables={{ id: this.props.match.params.id }}
            fetchPolicy="network-only"
            query={GET_DRIVER_BY_ID}
            onCompleted={(data) => {
              if (data.getDriverById) {
                let DriversView = {
                  id: this.props.match.params.id,
                  profilePicture: data.getDriverById.displayPicture,
                  name:
                    data.getDriverById.firstName +
                    " " +
                    data.getDriverById.lastName,
                  dob: moment(data.getDriverById.dob).format("DD/MM/YYYY"),
                  doj: moment(data.getDriverById.doj).format("DD/MM/YYYY"),
                  // dateOfBirth: new Date(data.getDriverById.dob).getDate(),
                  // monthOfBirth:
                  //   new Date(data.getDriverById.dob).getMonth() + 1,
                  //   yearOfBirth: new Date(data.getDriverById.dob).getFullYear(),

                  bloodGroup: data.getDriverById.bloodGroup,
                  address: data.getDriverById.address,
                  mobileNo: data.getDriverById.mobileNumber,
                  licenseNo: data.getDriverById.licenseNo,
                  drivingLicense: data.getDriverById.drivingLicense,
                  licenseUpto: moment(data.getDriverById.validUpto).format(
                    "DD/MM/YYYY"
                  ),
                  aadharNo: data.getDriverById.aadharNo,
                  aadharLink: data.getDriverById.aadharLink,

                  roleNo: data.getDriverById.studentId,
                  imageURL: data.getDriverById.displayPicture,
                };
                let fileInfo = data.getDriverById.displayPicture
                  ? { profilePicture: { name: "dp.png" } }
                  : {};
                this.setState(
                  {
                    DriversView,
                    fileInfo,
                  },
                  () => {}
                );

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });

              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}

        <div className="row m-0 mt-3">
          <div className="col-4 row m-0">
            <div className="dp col-3 p-0">
              <ReactAvatar
                name={this.state.DriversView.name}
                size="90px"
                round="8px"
                src={this.state.DriversView.profilePicture}
              ></ReactAvatar>
            </div>
            <div className="col-9 p-0">
              <div className="row m-0 mb-2">
                <div className="title col-6">Name</div>
                <div className="desc">{this.state.DriversView.name}</div>
              </div>
              <div className="row m-0 mb-2">
                <div className="title col-6">Contact</div>
                <div className="desc">{this.state.DriversView.mobileNo}</div>
              </div>
              <div className="row m-0 mb-2">
                <div className="title col-6">Date of birth</div>
                <div className="desc">{this.state.DriversView.dob}</div>
              </div>
            </div>
          </div>
          <div className="col-5 border-left-right">
            <div className="row m-0 mb-2">
              <div className="title col-6">Blood Group</div>
              <div className="desc">{this.state.DriversView.bloodGroup}</div>
            </div>
            <div className="row m-0 mb-2">
              <div className="title col-6">Date of joining</div>
              <div className="desc">{this.state.DriversView.doj}</div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <div className="col-8">
              <Button
                title="Edit Profile"
                small
                primary
                rounded
                onClick={() => {
                  this.props.history.push(
                    `/school/driver/edit/${this.props.match.params.id}`
                  );
                }}
              ></Button>
            </div>
          </div>
        </div>

        <div className="row m-0 mt-2">
          <div className="col-12 mb-3 mt-3">
            <div className="row m-0 ">
              <div className="col-7 pl-0">
                <div className="titleContent mt-3 mb-3">All trips</div>
                <TableStyle>
                  <div className="tableHeader row m-0">
                    <div className="col-1">Date</div>
                    <div className="col-2">Trip type</div>
                    <div className="col-3">Bus number</div>
                    <div className="col-2">Route ID</div>
                    <div className="plr-10 color-blue font-regular text-right width-140">
                      <div className="d-inline-block">
                        <Button title="Download" small white></Button>
                      </div>
                    </div>
                  </div>
                </TableStyle>
              </div>
              <div className="col-5 pr-0 ">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="titleContent">Documents submitted</div>
                  <Button
                    small
                    primary
                    rounded
                    title={"Add document"}
                    onClick={() => {
                      this.props.history.push(
                        `/school/driver/edit/${this.props.match.params.id}?q=2`
                      );
                    }}
                  ></Button>
                </div>

                <TableStyle style={{ height: "initial" }}>
                  <div className="tableHeader row m-0 pt-2 pb-2">
                    <div className="col-5">Document type</div>
                    <div className="col-5">Valid until</div>
                    <div className="col-2"></div>
                  </div>
                  <div className="tableBody row m-0 pt-2 pb-2">
                    <div className="col-5">License</div>
                    <div className="col-5">
                      {this.state.DriversView.licenseUpto}
                    </div>
                    <div className="col-2">
                      <div
                        className="color-sky-blue"
                        onClick={() => {
                          this.viewPictureModal(
                            "Driving License",
                            `License no: ${this.state.DriversView.licenseNo}`,
                            this.state.DriversView.drivingLicense
                          );
                        }}
                      >
                        View
                      </div>
                    </div>
                  </div>
                  <div className="tableBody alternate row m-0 pt-2 pb-2">
                    <div className="col-5">Address Proof</div>
                    <div className="col-5">N/A</div>
                    <div className="col-2">
                      <div
                        className="color-sky-blue"
                        onClick={() => {
                          this.viewPictureModal(
                            "Address Proof",
                            `Aadhar no: ${this.state.DriversView.aadharNo}`,
                            this.state.DriversView.aadharLink
                          );
                        }}
                      >
                        View
                      </div>
                    </div>
                  </div>
                </TableStyle>
              </div>
            </div>
          </div>
        </div>
        <PictureModel
          fileInfo={`${this.state.DriversView.name}-${this.state.selectedDocument}`}
          image={this.state.selectedImage}
          isOpen={this.state.pictureModelVisible}
          title={this.state.selectedTitle}
          desc={this.state.selectedDesc}
          toggleModal={() => {
            this.setState({ pictureModelVisible: false });
          }}
        ></PictureModel>
      </ViewDriverStyle>
    );
  }
}

export default ViewDriver;
