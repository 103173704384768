// import { Spin } from "antd";
// import "rc-table/assets/index.css";
import React, { Component } from "react";
import { Detector } from "react-detect-offline";
// import { hot } from 'react-hot-loader'
// import './less/index.css'
import { hot } from "react-hot-loader";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./App.css";
import Routes from "./Routes";
import "./table.css";

class App extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.idleTimer = null;
  }
  _onAction = (e) => {
    console.log("user did something", e);
  };

  _onActive = (e) => {
    console.log("user is active", e);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  };

  _onIdle = (e) => {
    console.log("user is idle", e);
    localStorage.clear();
    this.props.history.push(`/`);
    console.log("last active", this.idleTimer.getLastActiveTime());
  };
  render() {
    return (
      <div className="App Container">
        <ReactNotification />

        <Detector
          render={({ online }) => {
            //console.log(online);
            return (
              // <Spin
              //   className="noNetError"
              //   spinning={!online}
              //   tip="No Internet Connection. Reconnecting..."
              // >

              <Routes />
              // </Spin>
            );
          }}
        />
      </div>
    );
  }
}

// export default hot(module)(App)
export default hot(module)(App);
