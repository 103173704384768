import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { DownloadImage } from "../utils";
import Button from "./Form/button";
const PictureContainer = styled.div`
  .modal-header {
    border: 0;
  }
  .title {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    line-height: 24px;
    font-size: 19px;
    color: #004584;
  }
  .desc {
    font-weight: 500;
    font-family: "Muli", sans-serif;
    line-height: 21px;
    font-size: 17px;
    color: #004584;
    margin-top: 10px;
  }
  .modal-header .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`;
const PictureModel = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleModal} centered size="md">
      <PictureContainer>
        <ModalHeader
          className="text-center w-100 d-flex align-items-center justify-content-center"
          toggle={props.toggleModal}
        >
          <div className="title">{props.title}</div>
          <div className="desc">{props.desc}</div>
        </ModalHeader>
        <ModalBody className="text-center">
          {props.image && props.image !== "" && (
            <React.Fragment>
              <img alt="document" className="img-fluid" src={props.image}></img>
              <div className="d-flex mt-4 align-items-center justify-content-center mb-2">
                <Button
                  rounded
                  title={"Download"}
                  primary
                  onClick={() => {
                    DownloadImage(props.fileInfo, props.image);
                  }}
                ></Button>
              </div>
            </React.Fragment>
          )}
        </ModalBody>
      </PictureContainer>
    </Modal>
  );
};

PictureModel.protoTypes = {
  image: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
};
export default PictureModel;
