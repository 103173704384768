import styled, { css } from "styled-components";
import { colors } from "../../../Themes/Color";

const InputStyle = styled.div`
  & label {
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    left: 15px;
    top: 8px;
    z-index: 9;
    color: ${(props) => props.theme.subheadingColor};
  }
  &.fileContainer{
    input{
      border-top-right-radius:0;
      border-bottom-right-radius:0;
    }
  }
  /* & .lineBorder {
    width: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  } */
  .pointer-cursor {
    cursor: pointer;
  }
  .viewContainer{
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background:white;

    border: 1px solid #EDEEF3;
    border-left:0;
  }
  & input,textarea,select {
    display: block;
    font-family: inherit;
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    padding: 16px;
    /* padding-top: 28px;
    padding-bottom: 5px; */
    padding-right: ${(props) => (props.password ? "40px" : "16px")};
    padding-top:20px;
    padding-bottom:20px;
    line-height: 1.45;
    height: ${(props) => (props.small ? "36px" : "60px")};
    font-weight: 400;
    margin: 0 0 4px;
    max-width: 100%;
    border: none;
    /* border-color: ${(props) => props.theme.defaultBorderColor}; */
    background-color: ${colors.inputBackground};
    border-radius: 8px;
    color: ${colors.colorBlack};
    width: 100%;
    box-sizing: border-box;
    border:1px solid ${colors.inputBackground};
    &.disabled {
      background: #f1f1f1;
    }
    &:focus {
      outline: none;
      /* border-left-width: 6px; */
      /* border-color: #585858; */
      border-color: ${(props) => props.theme.defaultBorderColor};
    }
    &:hover {
      outline: none;
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
  & select{
    padding-top:16px;
    padding-bottom:16px;
  }
  & option{
    padding:20px;
  }
  & textarea{
    resize:none;
    height:initial;
  }

  ::-webkit-input-placeholder,
  ::-moz-placeholder ,
  :-ms-input-placeholder ,
  :-moz-placeholder {
    color: ${(props) => props.theme.placeholderColor};
}
  &.select {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    input {
      height: inherit;
    }
    div[class$="menu"] {
      z-index: 10;
    }
    .selectContainr > div.css-yk16xz-control,
    .selectContainr > div.css-1pahdxg-control {
      padding: 16px;
      padding-top: 28px;
      padding-bottom: 5px;
      padding-right: 5px;
      padding-left: 5px;
    }
  }
 .text-tiny {
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-size: 12px;
    line-height: 1.6;
    color:#DD455D;
  }
  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type="text"]:disabled {
    background: #eee;
  }
  .visibility--a11yHide {
    border: 0;
    clip: rect(0 0 0 0);
    position: absolute;
    overflow: hidden;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
  }
  .dropzone {
    border: none;
  }
  .dropzone__input {
    border: 1px solid #a2a2a2;
    border-radius: 4px;
  }
  p {
    color: #a2a2a2;
    font-size: 12px;
  }
  .passwordIcon {
    position: absolute;
    right: 15px;
    bottom: 22px;
  }
  .ReactCrop__image{
    width:100%;
  }

  &.touched {
    input,textarea {
      border-color: ${(props) => props.theme.primaryColor};
      /* border-left-width: 6px; */
    }
  }
  &.form-error {
    input,textarea {
      border-color: ${(props) => props.theme.errorColor};
      /* border-left-width: 6px; */
    }
    label {
      color: ${(props) => props.theme.errorColor};
    }
    .error {
      color: ${(props) => props.theme.errorColor};
    }
  }
  .deleteContainer{
    position:absolute;
    left:102%;
    top:6px;
  }
  ${(props) =>
    props.white &&
    css`
      & input,
      & textarea,
      select {
        background-color: ${colors.colorWhite};
      }
    `}
`;
export default InputStyle;
