import moment from "moment";
import Table from "rc-table";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import LinesEllipsis from "react-lines-ellipsis";
import Form from "react-validation/build/form";
import { Modal as ReactModal, ModalBody } from "reactstrap";
import styled from "styled-components";
import DeleteModal from "../../../Component/DeleteModal";
import Button from "../../../Component/Form/button";
import Checkbox from "../../../Component/Form/checkbox";
import FileUpload from "../../../Component/Form/fileUpload";
import ReactInput from "../../../Component/Form/input";
import DeleteModalContainer from "../../../Component/Form/style/deleteContainer";
import Loading from "../../../Component/Loading";
import Modal from "../../../Component/Modal";
import ModalMsg from "../../../Component/ModalMsg";
import Search from "../../../Component/Search";
import SortContainer from "../../../Component/SortContainer";
import Images from "../../../Themes/Images";
import Resources from "../../../Themes/Resources";
import { DownloadCSV, sendNotification } from "../../../utils";
import {
  CREATE_INSTITUTE_LIMIT_REQUEST,
  DELETE_STUDENT,
  GET_STUDENT_DETAILS,
  GET_STUDENT_FILTER,
  STUDENT_BULK_SIGNUP,
} from "../../graphql";

const MassUploadComponent = styled.div`
  padding: 12px 20px;
  background: #b9cbff;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  .shareTemplate:hover {
    text-decoration: none;
  }
`;
export class StudentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentDetails: [],
      selectedIndex: null,
      isOpen: false,
      selectedStudent: {},
      selectedId: [],
      bulkStudents: [],
      filterData: [],
      currFilter: [],
      DataErr: [],
      refetch: true,
      role: "",
      sort: "ascending",
      sortCol: "firstName",
      sortStr: "ASC",
      isDeleteOpen: false,
      isRequestOpen: false,
      instituteId: "",
      pictureModelVisible: false,
      successModal: false,
      institute: {},
      resetFile: true,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    const role = (this.props.user || {}).rolePriority;
    this.setState({ role: role, instituteId });
  }
  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  selectStudent = (index) => {
    const studentDetails = this.state.studentDetails;
    studentDetails.map((data, dataIndex) => {
      // data.isSelected = false;
      if (index === dataIndex) {
        data.isSelected = !data.isSelected;
      }
      return data;
    });
    let selectedIndex = null;
    let selectedId = studentDetails
      .filter((data, index) => {
        if (data.isSelected) {
          selectedIndex = index + 1;
          return data;
        }
      })
      .map((data) => data.id);

    this.setState({
      studentDetails,
      selectedIndex: selectedIndex,
      selectedStudent: this.state.studentDetails[index],
      selectedId: selectedId,
    });
  };
  editStudent = () => {
    // alert(this.state.selectedIndex);
    if (this.state.selectedIndex) {
      this.props.history.push(
        `/school/student/edit/${
          this.state.studentDetails[this.state.selectedIndex - 1].id
        }`
      );
    } else {
      sendNotification({ message: "Please select a student", type: "danger" });
    }
  };
  deleteStudents = (func) => {
    if (this.state.selectedIndex) {
      const studentDetails = [...this.state.studentDetails];

      // console.log(this.state.driverDetails);
      this.setState({ studentDetails }, () => {
        this.toggleDeleteModal();
      });
      // func();
    } else {
      sendNotification({ message: "Please select a student", type: "danger" });
    }
  };
  onFileUpload = (rows) => {
    this.setState({ resetFile: false }, () => {
      this.setState({ resetFile: true });
    });
    let DataErr = [];
    const bulkStudents = rows
      .map((data, index) => {
        let splitRow = data.split(",");
        console.log(splitRow.length);
        if (splitRow.length === 14) {
          if (index !== 0) {
            return {
              instituteId: this.state.instituteId,
              firstName: splitRow[0],
              lastName: splitRow[1],
              studentId: splitRow[2],
              displayPicture: "image",
              class: splitRow[3],
              section: splitRow[4],
              dob: splitRow[5] ? moment(splitRow[5]).format("YYYY/MM/DD") : "",
              // mobileNumber: splitRow[],
              bloodGroup: splitRow[6],
              address: splitRow[7],
              fatherName: splitRow[8],
              fatherContact: splitRow[9],
              fatherOccupation: splitRow[10],
              motherName: splitRow[11],
              motherContact: splitRow[12],
              motherOccupation: splitRow[13],
            };
          }
        } else if (splitRow.length === 1) {
        } else {
          // return "error";
          DataErr.push(data.split(","));
        }
      })
      .filter((data) => data);
    // if (bulkStudents.includes("error")) {
    //   sendNotification({ message: "Invalid CSV file", type: "danger" });
    // } else {
    let unique = bulkStudents.filter(
      ((set) => (f) => !set.has(f.studentId) && set.add(f.studentId))(new Set())
    );

    this.setState({ bulkStudents: unique, DataErr });
    // }
  };
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  onUpdateFilter = () => {
    let filterData = JSON.parse(JSON.stringify(this.state.filterData));
    let currFilter = filterData
      .map((data) => {
        if ((data.fieldValues || []).length) {
          data.fieldValues = data.fieldValues
            .map((data) => {
              if (data.isChecked) {
                return data.label;
              }
            })
            .filter((data) => data);
        }
        delete data.__typename;
        delete data.label;
        if (data.defaultMinValue) {
          if (
            data.minValue !== data.defaultMinValue &&
            data.maxValue !== data.defaultMaxValue
          ) {
            delete data.defaultMinValue;
            delete data.defaultMaxValue;
            data.minValue += "";
            data.maxValue += "";
            return data;
          }
        } else if ((data.fieldValues || []).length) {
          return data;
        }
      })
      .filter((data) => data);
    this.setState({ currFilter });
  };
  onChangeCheckbox = (filterIndex, dataIndex, value) => {
    const filterData = [...this.state.filterData];
    filterData[filterIndex].fieldValues[dataIndex].isChecked = value;

    this.setState({ filterData }, () => {
      this.onUpdateFilter();
    });
  };
  onDownloadQR = () => {
    const CSV = [];
    CSV.push([
      "Roll No",
      "Class",
      "Section",
      "First Name",
      "Last Name",
      "Father Name",
      "Father Contact Number",
      "DOB",
      "Blood Group",
      "Display Picture",
      "QR Code",
    ]);
    this.state.studentDetails.map((data) => {
      CSV.push([
        data.studentId,
        data.class,
        data.section,
        data.firstName,
        data.lastName,
        data.fatherName,
        data.fatherContact,

        moment(data.dob).format("DD-MM-YYYY"),
        data.bloodGroup,
        `"${data.displayPicture}"`,
        `"https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=${data.QRkey}&choe=UTF-8"`,
      ]);
    });
    this.setState({ schoolsCSV: CSV, forDownload: false }, () => {
      DownloadCSV("Students-ID", CSV);
    });
  };
  onDownloadClick = () => {
    const CSV = [];
    CSV.push([
      "Roll No",
      "Class",
      "Section",
      "First Name",
      "Last Name",
      "Father Name",
      "Father Contact Number",
      "Mother Name",
      "Mother Contact Number",
      "Address",
      "DOB",
      "Blood Group",
      "Pickup Stop",
    ]);
    this.state.studentDetails.map((data) => {
      CSV.push([
        data.studentId,
        data.class,
        data.section,
        data.firstName,
        data.lastName,
        data.fatherName,
        data.fatherContact,
        data.motherName,
        data.motherContact,
        `"${data.address}"`,
        moment(data.dob).format("DD-MM-YYYY"),
        data.bloodGroup,
        data.stopName || "",
      ]);
    });
    this.setState({ schoolsCSV: CSV, forDownload: false }, () => {
      DownloadCSV("Students", CSV);
    });
  };
  viewPictureModal = (title, desc, image) => {
    alert();
    this.setState({
      pictureModelVisible: true,
      selectedTitle: title,
      selectedDesc: desc,
      selectedImage: image,
      selectedDocument: title.replace(/[^a-zA-Z]/gi, "-").toLowerCase(),
    });
  };
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };
  toggleRequestModal = () => {
    this.setState({ isRequestOpen: !this.state.isRequestOpen });
  };
  render() {
    const columns = [
      {
        title: "Student name",
        dataIndex: "studentname",
        key: "studentName",
        width: 250,
        fixed: "left",
        render: (text, data, index) => {
          return (
            <React.Fragment>
              <LinesEllipsis
                style={{ whiteSpace: "pre-wrap" }}
                text={data.studentName}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
              <LinesEllipsis
                style={{ whiteSpace: "pre-wrap" }}
                text={data.studentId}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </React.Fragment>
          );
        },
      },

      {
        title: "Standard",
        dataIndex: "standard",
        key: "standard",
        width: 200,
      },
      {
        title: "Parent name",
        dataIndex: "parentName",
        key: "parentName",
        width: 200,
        render: (text, data, index) => {
          return (
            <React.Fragment>
              {data.fatherName && (
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.fatherName}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              )}
              {data.motherName && (
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.motherName}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              )}
            </React.Fragment>
          );
        },
      },
      {
        title: "Contact",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return (
            <div>
              {data.fatherContact && (
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.fatherContact}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              )}
              {data.motherContact && (
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.motherContact}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              )}
            </div>
          );
        },
      },
      {
        title: "Bus number",
        dataIndex: "busNumber",
        key: "busNumber",
        width: 200,
        render: (text, data, index) => {
          return data.routes.map((data, routeIndex) => {
            return data.tripDetails.map((trip, tripIndex) => {
              return (
                <div
                  className="mb-1"
                  key={`vehicle-${tripIndex}-${routeIndex}-${index}`}
                >
                  <LinesEllipsis
                    style={{ whiteSpace: "pre-wrap" }}
                    text={(trip.vehicle || {}).vehicleId}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </div>
              );
            });
          });
        },
      },
      {
        title: "Timings",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return data.routes.map((data, routeIndex) => {
            return (
              <div className="mb-1" key={`timing-${routeIndex}-${index}`}>
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.timings || ""}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            );
          });
        },
      },
      {
        title: "Stop",
        dataIndex: "stopName",
        key: "stopName",
        width: 150,
      },
      {
        title: "Route ID",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return data.routes.map((data, routeIndex) => {
            return (
              <div className="mb-1" key={`routeId-${routeIndex}-${index}`}>
                <LinesEllipsis
                  style={{ whiteSpace: "pre-wrap" }}
                  text={data.routeId || ""}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            );
          });
        },
      },
      {
        title: "Status",
        dataIndex: "noOfStudents",
        key: "noOfStudents",
        width: 200,
        render: (text, data, index) => {
          return data.routes.map((data, routeIndex) => {
            return (
              <div
                className=" d-flex justify-content-between mb-1"
                key={`status-${routeIndex}-${index}`}
              >
                {data.status ? "Live" : "Inactive"}
                <div className="p-0">
                  <span
                    className="ml-3 color-sky-blue pointer-cursor"
                    onClick={() => {}}
                  >
                    View
                  </span>
                </div>
              </div>
            );
          });
        },
      },
      {
        title: (
          <Button
            title="Download"
            small
            white
            onClick={() => {
              this.setState({ forDownload: true });
              this.onDownloadClick();
            }}
          ></Button>
        ),
        dataIndex: "",
        width: 160,
        key: "operations",
        fixed: "right",
        render: (text, data, index) => {
          return (
            <div className="row m-0">
              <div className="col-6">
                <img
                  src={
                    !data.isSelected
                      ? Images.circleIcon
                      : Images.circleIconSelected
                  }
                  className="img-fluid "
                  alt=""
                  onClick={(e) => {
                    e.stopPropagation();
                    this.selectStudent(index);
                  }}
                />
              </div>
              <div className="col-4 p-0">
                <img src={Images.nextIcon} className="img-fluid " alt="" />
              </div>
            </div>
          );
        },
      },
    ];
    return (
      <div
        className={
          this.state.role === "PRIMARY" ||
          this.state.studentDetails.length === 0
            ? "massUploadLayout"
            : ""
        }
      >
        <div className="row m-0 mt-4">
          <div className="col-5  pt-3">
            <Search
              searchTerm={this.state.searchTerm}
              isFilter={this.state.currFilter.length !== 0}
              isShowFilter={this.state.filterData.length !== 0}
              onSearchTerm={(searchTerm) => {
                this.setState({ searchTerm });
              }}
              filterContainer={
                <div className="bg-blue padding-10 ">
                  {this.state.filterData.map((data, index) => {
                    return (
                      <React.Fragment key={`filter-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className={` ${
                              (data.fieldValues || []).length
                                ? "sortType small"
                                : "sortText"
                            }`}
                          >
                            {data.label}
                          </div>
                          {data.defaultMinValue && data.defaultMinValue && (
                            <div className="sortType small">{`${data.defaultMinValue} - ${data.defaultMaxValue}`}</div>
                          )}
                        </div>
                        {(data.fieldValues || []).map(
                          (fieldValue, fieldIndex) => {
                            return (
                              <div
                                className="d-flex justify-content-between align-items-center pointer-cursor"
                                key={`FieldValues-${index}-${fieldIndex}`}
                                onClick={(value) => {
                                  this.onChangeCheckbox(
                                    index,
                                    fieldIndex,
                                    !fieldValue.isChecked
                                  );
                                }}
                              >
                                <div className="sortText">
                                  {fieldValue.label}
                                </div>
                                <div>
                                  <Checkbox
                                    value={fieldValue.isChecked}
                                    onChange={(value) => {
                                      // this.onChangeCheckbox(
                                      //   index,
                                      //   fieldIndex,
                                      //   value
                                      // );
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            );
                          }
                        )}
                        {index + 1 !== this.state.filterData.length && (
                          <div className="border-white mt-2 mb-2"></div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              }
              sortContainer={
                <div>
                  <SortContainer
                    active={this.state.sort === "ascending"}
                    title="Ascending"
                    stateKey="ascending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "firstName",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    active={this.state.sort === "descending"}
                    title="Descending"
                    stateKey="descending"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "firstName",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    title="Roll No"
                    subTitle="(High to Low)"
                    active={this.state.sort === "rollLow"}
                    stateKey="rollLow"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "DESC",
                        sortCol: "studentId",
                      });
                    }}
                  ></SortContainer>
                  <SortContainer
                    title="Roll No"
                    subTitle="(Low to High)"
                    active={this.state.sort === "rollHigh"}
                    stateKey="rollHigh"
                    onClick={(key) => {
                      this.setState({
                        sort: key,
                        sortStr: "ASC",
                        sortCol: "studentId",
                      });
                    }}
                  ></SortContainer>
                </div>
              }
            ></Search>
          </div>
          <div className="col-7 d-flex flex-row pt-3 justify-content-end">
            <div>
              <Button
                title="Create new student"
                primary
                rounded
                onClick={() => {
                  if (this.state.institute.id) {
                    if (
                      this.state.institute.noOfStudents !==
                      this.state.institute.totalStudents
                    ) {
                      this.props.history.push("/school/student/create");
                    } else {
                      this.toggleRequestModal();
                    }
                  } else {
                    this.props.history.push("/school/student/create");
                  }
                }}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                title="Edit"
                white
                rounded
                onClick={this.editStudent}
              ></Button>
            </div>
            <div className="ml-3">
              <Button
                onClick={() => {
                  this.deleteStudents();
                }}
                title="Delete"
                white
                rounded
              ></Button>
            </div>
          </div>
        </div>
        {this.state.instituteId !== "" && (
          <React.Fragment>
            <Query
              query={GET_STUDENT_FILTER}
              variables={{
                instituteId: this.state.instituteId,
              }}
              onCompleted={(data) => {
                if (data.createUniqueFiltersForStudent) {
                  let filterData = data.createUniqueFiltersForStudent.data
                    .map((data) => {
                      if (data.label) {
                        if (data.minValue && data.maxValue) {
                          data.defaultMinValue = data.minValue;
                          data.defaultMaxValue = data.maxValue;
                        }
                        if ((data.fieldValues || []).length) {
                          data.fieldValues = data.fieldValues.map((field) => {
                            return {
                              label: field,
                              isChecked: false,
                            };
                          });
                        }
                        return data;
                      }
                    })
                    .filter((data) => data);
                  //console.log(filterData);
                  this.setState({
                    filterData: filterData, //data.createUniqueFiltersForInstitute.data,
                  });
                }
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>
            {this.state.refetch && (
              <Query
                query={GET_STUDENT_DETAILS}
                fetchPolicy={"network-only"}
                variables={{
                  instituteId: this.state.instituteId,
                  searchTerm: this.state.searchTerm,
                  sortCol: this.state.sortCol,
                  sort: this.state.sortStr,
                  filter: this.state.currFilter,
                }}
                onCompleted={(data) => {
                  if (data.getStudentDetails) {
                    let studentDetails = [];
                    let institute = {};
                    studentDetails = data.getStudentDetails.map((data) => {
                      institute = data.institute;
                      data.studentName =
                        (data.firstName || "") + " " + (data.lastName || "");
                      data.standard =
                        (data.class || "") + " " + data.section || "";
                      data.isSelected = false;
                      data.fatherContact = data.fatherMobileNo;
                      data.motherContact = data.motherMobileNo;
                      data.stopName = (data.stop || {}).stopName || "";
                      data.status = (data.trip || {}).status || "";
                      data.timings = (data.routes || {}).timings;
                      data.busDetails =
                        (((data.routes || {}).trips || {}).vehicle || {})
                          .vehicleId || "";
                      data.routes = (data.routes || []).map((data) => {
                        data.tripDetails = (data.schedule || []).length
                          ? data.schedule
                          : data.trips;
                        data.isStatus = (data.schedule || []).length
                          ? true
                          : false;
                        return data;
                      });

                      return data;
                    });
                    this.setState({ studentDetails, institute });
                  }
                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }}
                onError={(error) => {
                  if (!error || !error.message) return null;
                  this.setState({ hasErr: true });

                  sendNotification({
                    message: error.message.replace("GraphQL error: ", ""),
                    type: "danger",
                  });
                }}
              >
                {({ data, loading, error }) => {
                  return <Loading is_loading={loading} />;
                }}
              </Query>
            )}
          </React.Fragment>
        )}
        <div className="col-12 mt-5">
          <Table
            useFixedHeader
            onRow={(data, index) => ({
              onClick: () => {
                this.props.history.push(`/school/student/view/${data.id}`);
              },
            })}
            columns={columns}
            data={this.state.studentDetails}
            scroll={{
              x: 1200,
              // y: 350,
              y:
                this.state.role === "PRIMARY" ||
                this.state.studentDetails.length === 0
                  ? "calc(100vh - 140px - 75px - 64px - 86px)"
                  : "calc(100vh - 140px - 75px - 64px )",
            }}
            emptyText="No Student Found!!"
            //title={setTableTitle}
          />
        </div>

        <Modal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          heading="Mass signup - Students"
        >
          {/* <FileUpload></FileUpload> */}
          <Mutation
            variables={{
              data: this.state.bulkStudents,
              instituteId: this.state.instituteId,
            }}
            mutation={STUDENT_BULK_SIGNUP}
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.studentBulkImport) {
                this.setState({ refetch: false }, () => {
                  this.setState({ refetch: true });
                  this.toggleModal();
                });
                this.props.history.push("/school/students");
                sendNotification({
                  message: "Students created successfully",
                  type: "success",
                });
              }
            }}
          >
            {(BulkStudent, { data, loading, error }) => {
              return (
                <React.Fragment>
                  <Loading is_loading={loading} />
                  {this.state.resetFile && (
                    <FileUpload
                      errData={this.state.DataErr}
                      count={this.state.bulkStudents.length}
                      onFileUpload={this.onFileUpload}
                      onHandleSubmit={() => {
                        if (this.state.bulkStudents.length) {
                          BulkStudent();
                        } else {
                          sendNotification({
                            message: "No Students available",
                            type: "danger",
                          });
                        }
                      }}
                      data="students"
                    ></FileUpload>
                  )}
                </React.Fragment>
              );
            }}
          </Mutation>
        </Modal>
        {(this.state.role === "PRIMARY" ||
          this.state.studentDetails.length === 0) && (
          <MassUploadComponent className="d-flex justify-content-between">
            <div>
              <Button
                onClick={() => {
                  this.onDownloadQR();
                }}
                title="Download QR"
                primary
                rounded
              ></Button>
            </div>
            <div className="d-flex">
              <div className="mr-3">
                <Button
                  onClick={() => {
                    this.toggleModal();
                  }}
                  title="Mass Signup"
                  primary
                  rounded
                ></Button>
              </div>
              <a
                className="shareTemplate"
                download
                href={Resources.StudentResource}
              >
                <Button title="Get template" white rounded></Button>
              </a>
            </div>
          </MassUploadComponent>
        )}
        <DeleteModal
          isDeleteOpen={this.state.isDeleteOpen}
          modelTitle="Delete Student"
          modelDesc={`Are you sure you want to remove ${
            this.state.selectedId.length <= 1
              ? this.state.selectedStudent.firstName
              : `${this.state.selectedId.length} students`
          } from the Scabbr
        platform?`}
          modelImage={this.state.selectedStudent.displayPicture}
          heading={`${
            this.state.selectedStudent.firstName +
            " " +
            this.state.selectedStudent.lastName
          } (${this.state.selectedStudent.studentId})`}
          subHeading={`Class: ${this.state.selectedStudent.class} Section: ${this.state.selectedStudent.section}`}
          deleteMutation={DELETE_STUDENT}
          mutationVariables={{
            studentIds: this.state.selectedId,
            instituteId: this.state.instituteId,
          }}
          selectedIds={this.state.selectedId}
          onMutationCompleted={() => {
            this.setState({ refetch: false }, () => {
              this.setState({ refetch: true });
            });
          }}
          mutationKey="deleteStudent"
          toggleDeleteModal={this.toggleDeleteModal}
          modelMsg={`${
            this.state.selectedId.length <= 1
              ? (this.state.selectedStudent.firstName || "").toUpperCase()
              : `${this.state.selectedId.length} STUDENTS`
          }  HAS BEEN REMOVED FROM THE PLATFORM`}
          buttonText={"Remove Student"}
        ></DeleteModal>

        <div id="downloadQR"></div>

        <ModalMsg
          image={Images.ModalSuccess}
          isOpen={this.state.successModal}
          toggleModal={this.onSuccessModal}
          message={`NEW REQUEST HAS BEEN RAISED. OUR TEAM WILL
        GET IN TOUCH WITH YOU SOON.`}
        ></ModalMsg>
        <ReactModal
          isOpen={this.state.isRequestOpen}
          toggle={this.toggleRequestModal}
          centered
          size="md"
        >
          <ModalBody>
            <DeleteModalContainer>
              <div className="title">Request student limit</div>
              <div className="desc mt-2 mb-3">
                {`You have reached the limit of student. Would you like to request for
                extra limit.`}
              </div>
              <div className="d-flex  align-items-center">
                <div className="col-12 p-0">
                  <Form>
                    <ReactInput
                      small
                      placeholder=""
                      required
                      type="number"
                      placeholder="Enter the extra limit you would like to request"
                      value={this.state.studentLimit}
                      className={"w-100"}
                      onChange={(value) => {
                        this.setState({ studentLimit: parseInt(value) });
                      }}
                    ></ReactInput>
                  </Form>
                </div>
              </div>
              <div className="d-flex mt-4 justify-content-end">
                <Button
                  primaryTransparent
                  title={"Cancel"}
                  onClick={() => {
                    this.toggleRequestModal();
                  }}
                ></Button>
                <Mutation
                  variables={{
                    // id: this.props.query.id
                    instituteId: this.state.instituteId,
                    limit: this.state.studentLimit,
                  }}
                  mutation={CREATE_INSTITUTE_LIMIT_REQUEST}
                  onError={(error) => {
                    if (!error || !error.message) return null;

                    sendNotification({
                      message: error.message.replace("GraphQL error: ", ""),
                      type: "danger",
                    });
                    // this.showNotificationError(
                    //   error.message.replace("GraphQL error: ", "")
                    // );
                  }}
                  onCompleted={(data) => {
                    if (data.createInstituteLimitRequest) {
                      // this.toggle
                      this.toggleRequestModal();
                      this.setState({ refetch: false }, () => {
                        this.setState({ refetch: true });
                      });
                      this.onSuccessModal();
                    }
                  }}
                >
                  {(DeleteAdmin, { data, loading, error }) => {
                    return (
                      <React.Fragment>
                        <Loading is_loading={loading}></Loading>
                        <Button
                          rounded
                          primary
                          title={"Request Limit"}
                          onClick={() => {
                            DeleteAdmin();
                          }}
                        ></Button>
                      </React.Fragment>
                    );
                  }}
                </Mutation>
              </div>
            </DeleteModalContainer>
          </ModalBody>
        </ReactModal>
      </div>
    );
  }
}

export default StudentsList;
