import moment from "moment";
import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import DateSelector from "../../../Component/DateSelector";
import Button from "../../../Component/Form/button";
import ReactFile from "../../../Component/Form/file";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import ReactTextarea from "../../../Component/Form/textarea";
import Image from "../../../Component/Image";
import Loading from "../../../Component/Loading";
import ModalMsg from "../../../Component/ModalMsg";
import TitleContainer from "../../../Component/TitleContainer";
import { colors } from "../../../Themes/Color";
import Images from "../../../Themes/Images";
import { generateRandomString, sendNotification } from "../../../utils";
import { email, isMobileNumber, isURL } from "../../../validator";
import {
  CREATE_SCHOOL,
  GET_INSTITUTE_DETAILS_ID,
  UPDATE_SCHOOL,
} from "../../graphql";

let FormRef, FormRef1, FormRef2, FormRef3;
// const StudentCount = styled.div`
//   .rounded{
//     border:1px solid ${colors.primaryColor};
//     borderRa
//   }
// `;
const SchoolDetailsContainer = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  background: ${colors.gradientColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .schoolTextLarge {
    font-family: "Muli", sans-serif;
    font-size: 21;
    color: ${colors.colorWhite};
    margin-bottom: 10px;
  }
  .semiBold {
    font-weight: 600;
  }

  & .schoolTextSmall {
    font-family: "Muli", sans-serif;
    font-size: 19;
    color: ${colors.colorWhite};
  }
  .regular {
    font-weight: 500;
  }
`;
const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .disableService {
    opacity: 0.6;
    pointer-events: none;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
const DeleteModalContainer = styled.div`
  .title {
    font-size: 17px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .semi-bold {
    font-weight: 600;
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export class CreateSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createSchool: {
        isActive: true,
        address: "",
        billingName: "",
        businessEmail: "",
        contactNo: "",
        lname: "",
        noOfStudents: "",
        costPerStudent: "",
        password: "",
        pname: "",
        schoolContactNo: "",
        sname: "",
        stream: "",
        subsType: "YEARLY",
        tName: "",
        website: "",
        dateOfValid: moment().format("YYYY/MM/DD"),

        imageURL: "",
      },
      fileInfo: { profilePicture: "" },
      selectedSchool: {},
      activeTab: "1",
      formSubmit: false,
      successModal: false,
      isDeleteOpen: false,
      isSetupOpen: false,
      setDataVariables: {},
      disableService: "",
      successModalImage: false,
      successMessage: "",
      handleForm1: false,
      handleForm2: false,
      handleForm3: false,
      handleForm4: false,
      roleSet: "1",
    };
  }
  componentDidMount() {
    const roleSet =
      (this.props.user || {}).rolePriority === "PRIMARY" ? "1" : "0";
    this.setState({ roleSet });
    this.setState({ gpwd: generateRandomString(7) });
  }
  onSetupModal = () => {
    this.setState({ isSetupOpen: !this.state.isSetupOpen });
  };
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const createSchool = { ...this.state.createSchool };
    createSchool[key] = value;

    this.setState({ createSchool });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  onNextButton = () => {
    if (this.state.activeTab === "1") {
      this.setState({ handleForm1: true }, () => {
        this.onNextButtonValidate(FormRef);
      });
    }
    if (this.state.activeTab === "2") {
      this.setState({ handleForm2: true }, () => {
        this.onNextButtonValidate(FormRef1);
      });
    }
    if (this.state.activeTab === "3") {
      this.setState({ handleForm3: true }, () => {
        this.onNextButtonValidate(FormRef2);
      });
    }
    if (this.state.activeTab === "4") {
      this.setState({ handleForm4: true }, () => {
        this.onNextButtonValidate(FormRef3);
      });
    }
  };
  onNextButtonValidate = (Form) => {
    Form.validateAll();
    setTimeout(() => {
      if (document.querySelectorAll(".form-error").length === 0) {
        if (document.querySelectorAll(".inputRequired").length === 0) {
          // ContactOrganizer();
          // func();
          // this.onSetVariables(func);
          console.log("Active tab");
          this.setActiveTab(
            this.state.roleSet !== "1" && this.state.activeTab === "2"
              ? parseInt("4")
              : parseInt(this.state.activeTab) + 1
          );
          // this.props.changePage();
        } else {
          // this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      } else {
        // this.setActiveTab(1);
        sendNotification({
          message: "Please fill all the fields",
          type: "danger",
        });
      }
    }, 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/dashboard/schools");
    }
    // this.setActiveTab(parseInt(this.state.activeTab) - 1);
  };
  onChange = (key, value) => {
    const createSchool = { ...this.state.createSchool };
    createSchool[key] = value;
    this.setState({ createSchool });
  };
  onSetVariables = (func) => {
    let setDataVariables = {
      isActive: true,
      name: this.state.createSchool.sname,
      syllabus: this.state.createSchool.stream,
      billingName: this.state.createSchool.billingName,
      contactNo: this.state.createSchool.contactNo,
      website: this.state.createSchool.website,
      address: this.state.createSchool.address,
      principalName: this.state.createSchool.pname,
      transportOfficerName: this.state.createSchool.tName,
      princiContact: this.state.createSchool.schoolContactNo,
      subscriptionType: this.state.createSchool.subsType,
      totalStudents: this.state.createSchool.noOfStudents + "",
      loginName: this.state.createSchool.businessEmail,
      logo: this.state.createSchool.imageURL,
      costPerStudent: parseFloat(this.state.createSchool.costPerStudent),
      //validFrom: "",
      description: "",
      alternatePhone: "",
      alternateEmail: "",
      validFrom: this.state.createSchool.dateOfValid,
    };
    if (this.props.match.params.id) {
      setDataVariables.id = this.props.match.params.id;
    } else {
      // password: this.state.createSchool.password,
      setDataVariables.businessEmail = this.state.createSchool.businessEmail;
      setDataVariables.password = this.state.createSchool.password;
    }
    this.setState({ setDataVariables }, () => {
      func();
    });
  };
  deleteAdmin = (admin) => {
    this.setState({ disableService: admin }, () => {
      this.toggleDeleteModal();
    });
  };

  handleSubmit = (func) => {
    this.setState({ handleForm4: true }, () => {
      FormRef3.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            this.onSetVariables(func);
            // this.props.changePage();
          } else {
            this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  fileInfo = (key, value) => {
    const fileInfo = { ...this.state.fileInfo };
    fileInfo[key] = value;
    this.setState({ fileInfo });
  };
  onSuccessModal = () => {
    this.setState({ successModal: !this.state.successModal });
  };
  toggleDeleteModal = () => {
    this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
  };
  deleteAdmin = (admin) => {
    this.setState({ disableService: admin }, () => {
      this.toggleDeleteModal();
    });
  };
  resetState = () => {
    this.setState({
      createSchool: {
        isActive: true,
        address: "",
        billingName: "",
        businessEmail: "",
        contactNo: "",
        lname: "",
        noOfStudents: "",
        costPerStudent: "",
        password: "",
        pname: "",
        schoolContactNo: "",
        sname: "",
        stream: "",
        subsType: "YEARLY",
        tName: "",
        website: "",
        dateOfValid: moment().format("YYYY/MM/DD"),

        imageURL: "",
      },
      fileInfo: { profilePicture: "" },
      selectedSchool: {},
      activeTab: "1",
      formSubmit: false,
      successModal: false,
      isDeleteOpen: false,
      setDataVariables: {},
      disableService: "",
      successModalImage: false,
      successMessage: "",
      handleForm1: false,
      handleForm2: false,
      handleForm3: false,
      handleForm4: false,
    });
  };
  render() {
    return (
      <SchoolContainer className="pl-3">
        {this.props.match.params.id && (
          <React.Fragment>
            <SchoolDetailsContainer className="mt-4 mr-3">
              <div className="d-flex align-items-center">
                <div style={{ width: "50px" }}>
                  <Image src={this.state.createSchool.imageURL}></Image>
                </div>
                <div className="ml-3">
                  <div className="schoolTextLarge semibold">
                    {this.state.createSchool.sname}
                  </div>
                  <div className="schoolTextSmall">
                    No of Students:
                    <span className="regular">
                      {" "}
                      {`${this.state.createSchool.noOfStudents} Student${
                        this.state.createSchool.noOfStudents === 1 ? "" : "s"
                      }`}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="schoolTextLarge semibold">
                  Subscription:{" "}
                  <span className="regular">
                    {this.state.createSchool.subsType}
                  </span>
                </div>
                <div className="schoolTextSmall">
                  Valid upto:
                  <span className="regular">
                    {" "}
                    {moment(this.state.createSchool.validUpto).format(
                      "DD/MM/YYYY"
                    )}
                  </span>
                </div>
              </div>
            </SchoolDetailsContainer>
            <div className="d-flex justify-content-between">
              <TitleContainer
                className="mt-4 pl-4 text-left color-blue "
                title="Edit School"
              ></TitleContainer>
              <div className="mt-3 pr-3">
                <Mutation
                  variables={{
                    // id: this.props.query.id
                    data: {
                      id: this.props.match.params.id,
                      isActive: !this.state.createSchool.isActive,
                    },
                  }}
                  mutation={UPDATE_SCHOOL}
                  onError={(error) => {
                    if (!error || !error.message) return null;

                    sendNotification({
                      message: error.message.replace("GraphQL error: ", ""),
                      type: "danger",
                    });
                    // this.showNotificationError(
                    //   error.message.replace("GraphQL error: ", "")
                    // );
                  }}
                  onCompleted={(data) => {
                    if (data.updateInstitute) {
                      // this.showNotification(
                      //   "👋 Welcome to our group!",
                      //   ``
                      // );
                      // sendNotification({
                      //   title: "",
                      //   message: "School Updated Successfully"
                      // });
                      let createSchool = { ...this.state.createSchool };
                      createSchool.isActive = !this.state.createSchool.isActive;
                      this.setState({ createSchool }, () => {
                        if (!this.state.createSchool.isActive) {
                          this.setState(
                            {
                              successMessage: `${this.state.createSchool.sname.toUpperCase()} HAS BEEN DISABLED`,
                              successModalImage: false,
                            },
                            () => {
                              this.toggleDeleteModal();
                              this.onSuccessModal();
                            }
                          );
                        } else {
                        }
                      });

                      // message.success("Contact Created!!");
                    }
                  }}
                >
                  {(DisableService, { data, loading, error }) => {
                    return (
                      <React.Fragment>
                        <Loading is_loading={loading} />
                        <Button
                          rounded
                          primary={!this.state.createSchool.isActive}
                          danger={this.state.createSchool.isActive}
                          title={
                            this.state.createSchool.isActive
                              ? "Disable service"
                              : "Enable service"
                          }
                          onClick={() => {
                            if (this.state.createSchool.isActive) {
                              this.deleteAdmin(DisableService);
                            } else {
                              DisableService();
                              this.setState(
                                {
                                  successMessage:
                                    "NEW SUBSCRIPTION PLAN HAS BEEN UPDATED",
                                  successModalImage: true,
                                },
                                () => {
                                  this.onSuccessModal();
                                }
                              );
                            }
                            // DisableService();
                          }}
                        ></Button>
                      </React.Fragment>
                    );
                  }}
                </Mutation>
              </div>
            </div>
            <Query
              fetchPolicy="network-only"
              variables={{ id: this.props.match.params.id }}
              query={GET_INSTITUTE_DETAILS_ID}
              onCompleted={(data) => {
                // console.log(data);
                if (data.getInstituteById) {
                  let createSchool = {
                    address: data.getInstituteById.address,
                    billingName: data.getInstituteById.billingName,
                    businessEmail: data.getInstituteById.emailId,

                    contactNo: data.getInstituteById.contactNo,
                    lname: data.getInstituteById.businessEmail,
                    noOfStudents: data.getInstituteById.noOfStudents,
                    totalStudents: data.getInstituteById.totalStudents,
                    costPerStudent: data.getInstituteById.costPerStudent,
                    pname: data.getInstituteById.principalName,
                    schoolContactNo: data.getInstituteById.principalContact,
                    sname: data.getInstituteById.name,
                    stream: data.getInstituteById.syllabus,
                    subsType: data.getInstituteById.subscription,
                    tName: data.getInstituteById.transportOfficer,
                    website: data.getInstituteById.website,
                    dateOfValid: moment(data.getInstituteById.validFrom).format(
                      "YYYY/MM/DD"
                    ),

                    insToken: data.getInstituteById.insToken,
                    imageURL: data.getInstituteById.logo,
                    validUpto: data.getInstituteById.validUpto,
                    isActive: data.getInstituteById.isActive,
                  };
                  let fileInfo = data.getInstituteById.logo
                    ? { profilePicture: { name: "logo.png" } }
                    : {};
                  this.setState({ createSchool, fileInfo });

                  // if (data.getOnboardingEmailId) {
                  //   this.setState({ email: data.getOnboardingEmailId.email });
                  // }
                }
              }}
              onError={(error) => {
                if (!error || !error.message) return null;
                this.setState({ hasErr: true });
                sendNotification({
                  message: error.message.replace("GraphQL error: ", ""),
                  type: "danger",
                });
              }}
            >
              {({ data, loading, error }) => {
                return <Loading is_loading={loading} />;
              }}
            </Query>
          </React.Fragment>
        )}
        <div
          className={`menu mt-2 pt-3 ${
            this.state.createSchool.isActive ? "" : "disableService"
          }`}
        >
          <div
            className={`menuItem ${
              this.state.activeTab === "1" ? "active" : ""
            }`}
          >
            School details
          </div>
          <div
            className={`menuItem ${
              this.state.activeTab === "2" ? "active" : ""
            }`}
          >
            Contact details
          </div>
          {this.state.roleSet === "1" && (
            <div
              className={`menuItem ${
                this.state.activeTab === "3" ? "active" : ""
              }`}
            >
              Billing details
            </div>
          )}
          <div
            className={`menuItem ${
              this.state.activeTab === "4" ? "active" : ""
            }`}
          >
            Login details
          </div>
        </div>
        <div
          className={`col-9 pl-4 pr-4 pt-4 ${
            this.state.createSchool.isActive ? "" : "disableService"
          }`}
        >
          <div className="col-12">
            <TitleContainer
              className="mt-1 col-11 pl-4 very-small text-right color-gray semi-bold"
              title="(*All Fields are mandatory)"
            ></TitleContainer>
          </div>
          <Mutation
            variables={{
              // id: this.props.query.id
              data: this.state.setDataVariables,
            }}
            mutation={
              this.props.match.params.id ? UPDATE_SCHOOL : CREATE_SCHOOL
            }
            onError={(error) => {
              if (!error || !error.message) return null;
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });

              // this.showNotificationError(
              //   error.message.replace("GraphQL error: ", "")
              // );
            }}
            onCompleted={(data) => {
              if (data.createInstitute) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                // sendNotification({
                //   message: "Institute created successfully",
                //   type: "success"
                // });
                this.resetState();
                this.setState(
                  {
                    successMessage:
                      "THE SCHOOL PROFILE HAS BEEN SUCCESSFULLY CREATED",
                    successModalImage: true,
                  },
                  () => {
                    this.onSuccessModal();
                  }
                );

                // message.success("Contact Created!!");
              }
              if (data.updateInstitute) {
                // this.showNotification(
                //   "👋 Welcome to our group!",
                //   ``
                // );
                sendNotification({
                  message: "Institute updated successfully",
                  type: "success",
                });
                setTimeout(() => {
                  this.props.history.push("/dashboard/schools");
                }, 500);
                // message.success("Contact Created!!");
              }
            }}
          >
            {(CreateSchool, { data, loading, error }) => {
              return (
                <div>
                  <Loading is_loading={loading} />
                  {this.state.activeTab === "1" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "1" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                        className={` ${
                          this.state.handleForm1 ? "invalid-form" : ""
                        } `}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">School logo</div>
                          <div className="col-9">
                            {/* <ReactFile

                          placeholder=""
                          white
                          required
                          value={this.state.createSchool.name}
                          className={"w-100"}
                          onChange={value => {
                            this.onChange("logo", value);
                          }}
                        ></ReactFile> */}
                            <ReactFile
                              placeholder=""
                              white
                              header="school logo"
                              required
                              multiple={false}
                              uploadFor={"Institutes"}
                              value={this.state.fileInfo.profilePicture}
                              imageURL={this.state.createSchool.imageURL}
                              token={{
                                insToken: this.state.createSchool.insToken,
                              }}
                              onTokenChange={(insToken, userToken) => {
                                this.onChange("insToken", insToken);
                              }}
                              className={"w-100"}
                              onSuccess={(value) => {
                                this.onChange("imageURL", value);
                              }}
                              onDelete={() => {
                                this.onChange("imageURL", "");
                                this.fileInfo("profilePicture", { name: "" });
                              }}
                              onChange={(value) => {
                                this.fileInfo(
                                  "profilePicture",
                                  value ? value[value.length - 1] : ""
                                );
                              }}
                            ></ReactFile>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">School name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.sname}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("sname", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Stream of syllabus</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.stream}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("stream", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        {/* <InputContainer className="row m-0 mt-3">
                        <div className="col-3 pl-0">Billing name</div>
                        <div className="col-9">
                          <ReactInput
                            placeholder=""
                            white
                            required
                            value={this.state.createSchool.billing}
                            className={"w-100"}
                            onChange={value => {
                              this.onChange("billing", value);
                            }}
                          ></ReactInput>
                        </div>
                      </InputContainer> */}
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Contact number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              validation={[isMobileNumber]}
                              value={this.state.createSchool.contactNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("contactNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Website</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              validation={[isURL]}
                              value={this.state.createSchool.website}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("website", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Address</div>
                          <div className="col-9">
                            <ReactTextarea
                              placeholder=""
                              white
                              rows={6}
                              required
                              value={this.state.createSchool.address}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("address", value);
                              }}
                            ></ReactTextarea>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "2" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "2" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef1 = ref;
                        }}
                        className={` ${
                          this.state.handleForm2 ? "invalid-form" : ""
                        } `}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Principal name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.pname}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("pname", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">
                            Transport officer name
                          </div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.tName}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("tName", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Contact number</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              validation={[isMobileNumber]}
                              value={this.state.createSchool.schoolContactNo}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("schoolContactNo", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Business email</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              disabled={this.props.match.params.id}
                              validation={[email]}
                              value={this.state.createSchool.businessEmail}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("businessEmail", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}

                  {this.state.activeTab === "3" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "3" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef2 = ref;
                        }}
                        className={` ${
                          this.state.handleForm3 ? "invalid-form" : ""
                        } `}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Billing name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.billingName}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("billingName", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Subscription type</div>
                          <div className="col-9">
                            <ReactSelect
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.subsType}
                              className={"w-100"}
                              options={[
                                { value: "YEARLY", name: "Yearly" },
                                { value: "MONTHLY", name: "Monthly" },
                                { value: "HALF_YEAR", name: "Yearly Twice" },
                                { value: "QUARTERLY", name: "Quarterly" },
                              ]}
                              onChange={(value) => {
                                // console.log(value);
                                this.onChange("subsType", value);
                              }}
                            ></ReactSelect>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Valid from</div>
                          <div className="col-9 d-flex">
                            <DateSelector
                              white
                              startDate={this.state.createSchool.dateOfValid}
                              setStartDate={(e) => {
                                // console.log(e);
                                this.onChange(
                                  "dateOfValid",
                                  moment(e).format("YYYY/MM/DD")
                                );
                              }}
                              dateObj={{
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: "select",
                              }}
                            ></DateSelector>
                          </div>
                        </InputContainer>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Number of students</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              type="number"
                              value={this.state.createSchool.noOfStudents}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("noOfStudents", value);
                              }}
                            ></ReactInput>
                          </div>
                          <div className="col-3"></div>
                          <div className="col-9">
                            {this.props.match.params.id && (
                              <React.Fragment>
                                {(parseInt(
                                  this.state.createSchool.noOfStudents
                                ) || 0) <=
                                  (parseInt(
                                    this.state.createSchool.totalStudents
                                  ) || 0) && (
                                  <span className="text-tiny error color-red">
                                    Total Students is greater than Student Limit
                                  </span>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </InputContainer>
                        <div className="row m-0">
                          <div className="col-3"></div>
                          <div className="col-9">
                            <div className="d-flex justify-content-end mt-3">
                              <Button
                                primaryOutline
                                primary
                                rounded
                                title={"+1"}
                                onClick={() => {
                                  this.onChange(
                                    "noOfStudents",
                                    (parseInt(
                                      this.state.createSchool.noOfStudents
                                    ) || 0) + 1
                                  );
                                }}
                              ></Button>
                              <div className="ml-2">
                                <Button
                                  primaryOutline
                                  rounded
                                  title={"+10"}
                                  onClick={() => {
                                    this.onChange(
                                      "noOfStudents",
                                      (parseInt(
                                        this.state.createSchool.noOfStudents
                                      ) || 0) + 10
                                    );
                                  }}
                                ></Button>
                              </div>
                              <div className="ml-2">
                                <Button
                                  primaryOutline
                                  rounded
                                  title={"+100"}
                                  onClick={() => {
                                    this.onChange(
                                      "noOfStudents",
                                      (parseInt(
                                        this.state.createSchool.noOfStudents
                                      ) || 0) + 100
                                    );
                                  }}
                                ></Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Price per students</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              required
                              value={this.state.createSchool.costPerStudent}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("costPerStudent", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                      </Form>
                    </div>
                  )}
                  {this.state.activeTab === "4" && (
                    <div
                      className={`col-11 ${
                        this.state.activeTab === "4" ? "d-block" : "d-none"
                      }`}
                    >
                      <Form
                        ref={(ref) => {
                          FormRef3 = ref;
                        }}
                        className={` ${
                          this.state.handleForm4 ? "invalid-form" : ""
                        } `}
                      >
                        <InputContainer className="row m-0 mt-3">
                          <div className="col-3 pl-0">Login name</div>
                          <div className="col-9">
                            <ReactInput
                              placeholder=""
                              white
                              disabled
                              required
                              value={this.state.createSchool.businessEmail}
                              className={"w-100"}
                              onChange={(value) => {
                                this.onChange("businessEmail", value);
                              }}
                            ></ReactInput>
                          </div>
                        </InputContainer>
                        {!this.props.match.params.id && (
                          <React.Fragment>
                            <InputContainer className="row m-0 mt-3">
                              <div className="col-3 pl-0">Password</div>
                              <div className="col-9">
                                <ReactInput
                                  placeholder=""
                                  white
                                  required
                                  type="password"
                                  value={this.state.createSchool.password}
                                  className={"w-100"}
                                  onChange={(value) => {
                                    this.onChange("password", value);
                                  }}
                                ></ReactInput>
                              </div>
                            </InputContainer>
                            <InputContainer className="justify-content-end mt-3">
                              <div className="col-12">
                                <div className="flex-center justify-content-end">
                                  <div
                                    className="pointer-cursor"
                                    onClick={() => {
                                      this.onChange(
                                        "password",
                                        this.state.gpwd
                                      );
                                    }}
                                  >
                                    <Image src={Images.copyIcon}></Image>
                                  </div>
                                  <span className="ml-3 mr-3">
                                    Suggested password:{" "}
                                    <span
                                    // className="pointer-cursor"
                                    >
                                      {this.state.gpwd}
                                    </span>
                                  </span>
                                  <div
                                    className="pointer-cursor"
                                    onClick={this.generatePwd}
                                  >
                                    <Image src={Images.pwdRefresh}></Image>
                                  </div>
                                </div>
                              </div>
                            </InputContainer>
                          </React.Fragment>
                        )}
                      </Form>
                    </div>
                  )}

                  <div className="mt-2 mb-5 pt-4 d-flex align-items-center justify-content-end">
                    <Button
                      primaryTransparent
                      buttonClass="mr-4"
                      title="Back"
                      disabled={this.state.activeTab === "1"}
                      onClick={this.onBackButton}
                    ></Button>
                    <div className="d-inline">
                      <Button
                        title={`${
                          this.state.activeTab === "4"
                            ? this.props.match.params.id
                              ? "Update school"
                              : "Add school"
                            : "Next"
                        }`}
                        primary
                        rounded
                        onClick={() => {
                          if (this.state.activeTab === "4") {
                            this.handleSubmit(CreateSchool);
                          } else {
                            this.onNextButton();
                          }
                          // this.handleSubmit(createSchool);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Mutation>
        </div>

        <ModalMsg
          image={
            this.state.successModalImage
              ? Images.ModalSuccess
              : Images.ModalFailure
          }
          isOpen={this.state.successModal}
          toggleModal={() => {
            this.onSuccessModal();
            this.props.history.push("/dashboard/schools");
          }}
          message={this.state.successMessage}
        ></ModalMsg>
        <Modal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          centered
          size="md"
        >
          {this.state.createSchool !== "" && (
            <ModalBody>
              <DeleteModalContainer>
                <div className="title">Disable subscription</div>
                <div className="desc mt-2 mb-3">
                  {`Are you sure you want to disable ${this.state.createSchool.sname}?`}
                </div>
                <div className="d-flex  align-items-center">
                  <div className="col-2 p-0">
                    <Image src={this.state.createSchool.imageURL} />
                  </div>
                  <div className="ml-2 desc">
                    <div className="semi-bold">{`${this.state.createSchool.sname}`}</div>
                    <div>
                      No of students:{" "}
                      {`${this.state.createSchool.noOfStudents} Student${
                        this.state.createSchool.noOfStudents === 1 ? "" : "s"
                      }`}
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    primaryTransparent
                    title={"Cancel"}
                    onClick={() => {
                      this.toggleDeleteModal();
                    }}
                  ></Button>

                  <Button
                    rounded
                    danger
                    title={"Disable"}
                    onClick={() => {
                      this.state.disableService();
                      // DeleteAdmin();
                    }}
                  ></Button>
                </div>
              </DeleteModalContainer>
            </ModalBody>
          )}
        </Modal>
        <Modal
          isOpen={this.state.isSetupOpen}
          toggle={this.onSetupModal}
          centered
          size="md"
        >
          {this.state.createSchool !== "" && (
            <ModalBody>
              <DeleteModalContainer>
                <div className="title">School data setup</div>
                <div className="desc mt-2 mb-3">
                  {`Do you want to set up your school data now?`}
                </div>

                <div className="d-flex mt-4 justify-content-end">
                  <Button
                    primaryTransparent
                    title={"Cancel"}
                    onClick={() => {
                      this.onSetupModal();
                      this.onSuccessModal();
                    }}
                  ></Button>

                  <Button
                    rounded
                    primary
                    title={"Setup"}
                    onClick={() => {
                      // this.state.disableService();
                      // DeleteAdmin();
                    }}
                  ></Button>
                </div>
              </DeleteModalContainer>
            </ModalBody>
          )}
        </Modal>
      </SchoolContainer>
    );
  }
}

export default CreateSchool;
