import PropTypes from "prop-types";
import React, { Component } from "react";
import Input from "react-validation/build/input";
import Image from "../../Component/Image";
import Images from "../../Themes/Images";
import InputStyle from "./style/input";

export class ReactInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      showPwd: false,
      error: false,
    };
  }
  onChange = (event) => {
    // if (this.props.maxLength) {
    //   if (this.props.maxLength > event.target.value.length) {
    //     this.props.onChange(event.target.value);
    //   }
    // } else {
    this.props.onChange(event.target.value);
    // }
  };
  onFocused = () => {
    this.setState({ error: false });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };
  handleKeyPress = (evt) => {
    // console.log(this.props.type);
    if (this.props.type === "number") {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    } else if (this.props.type === "decimal") {
      if (
        evt.which !== 46 &&
        evt.which > 31 &&
        (evt.which < 48 || evt.which > 57)
      ) {
        evt.preventDefault();
      }
    }
  };
  onTouched = () => {
    this.setState({ touched: true }, () => {
      if (this.props.validation)
        if (this.props.validation.length) {
          const errorList = this.props.validation
            .map((validate) => {
              if (validate(this.props.value)) {
                return validate;
              }
              return undefined;
            })
            .filter((data) => data);

          if (errorList.length) {
            this.setState({ error: true });
          } else {
            this.setState({ error: false });
          }
        }
    });
    // this.form.validateAll();
  };
  onKeyPress = (e) => {
    // alert();
    if (this.props.onKeyPress) {
      this.props.onKeyPress(e);
    }
  };

  render() {
    return (
      <InputStyle
        password={this.props.type === "password"}
        white={this.props.white}
        small={this.props.small}
        className={`position-relative  ${
          this.props.required && !this.state.touched
            ? this.props.value === ""
              ? "inputRequired"
              : ""
            : ""
        } ${
          this.state.touched
            ? `touched ${
                this.props.required && this.props.value === ""
                  ? "invalid form-error"
                  : ""
              }`
            : ""
        } ${this.state.error ? "invalid form-error" : ""} ${
          this.props.relativeClass ? this.props.relativeClass : ""
        }`}
      >
        <div className="mt-0">
          {this.props.description && (
            <p className="text--small mb-2">{this.props.description}</p>
          )}
        </div>

        {/* <div className="lineBorder" /> */}
        <Input
          disabled={this.props.disabled}
          onBlur={this.onTouched}
          min={this.props.min || ""}
          type={
            this.props.type === "password"
              ? this.state.showPwd
                ? "text"
                : "password"
              : this.props.type
          }
          // defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          value={this.props.value}
          validations={this.props.validations ? this.props.validations : []}
          required={this.props.required}
          onFocus={this.onFocused}
          className={`${this.props.className ? this.props.className : ""} ${
            this.props.disabled ? "disabled" : ""
          }`}
          onKeyPress={this.onKeyPress}
          // {...this.props}
          onChange={this.onChange}
          maxLength={this.props.maxLength}
          onKeyPress={this.handleKeyPress}
        />
        {this.props.pwdIcon && (
          <React.Fragment>
            {this.props.type === "password" && (
              <div
                className="passwordIcon pointer-cursor"
                onClick={() => {
                  this.setState({ showPwd: !this.state.showPwd });
                }}
              >
                {this.state.showPwd ? (
                  <Image src={Images.PasswordStrikeEye}></Image>
                ) : (
                  <Image src={Images.PasswordEye}></Image>
                )}
              </div>
            )}
          </React.Fragment>
        )}
        {this.props.icon && (
          <div
            className="passwordIcon pointer-cursor"
            onClick={() => {
              this.setState({ showPwd: !this.state.showPwd });
            }}
          >
            {this.props.iconSource}
          </div>
        )}

        {this.props.maxLength && this.props.value !== undefined && (
          <p className="text--tiny">
            {this.props.maxLength - this.props.value.length}
          </p>
        )}
        {/* {this.props.required &&
          this.props.value === "" &&
          this.state.touched && <span className="text-tiny error"></span>} */}
        {this.props.isError && (
          <span className="text-tiny error form-error">
            {this.props.ErrorMessage}
          </span>
        )}
      </InputStyle>
    );
  }
}
ReactInput.defaultProps = {
  pwdIcon: true,
};
ReactInput.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  isError: PropTypes.bool,
  ErrorMessage: PropTypes.string,

  maxLength: PropTypes.number,
  validation: PropTypes.array,
  required: PropTypes.bool,
  className: PropTypes.string,
  white: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  min: PropTypes.number,
  showPwd: PropTypes.bool,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  pwdIcon: PropTypes.bool,
};
export default ReactInput;
