import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import GenericNotFound from "../Application/A404";
import Dashboard from "../Application/Dashboard";
import Admin from "../Application/Dashboard/admins";
import ChangePassword from "../Application/Dashboard/admins/changePassword";
import createAdmin from "../Application/Dashboard/admins/create";
import BirdView from "../Application/Dashboard/birdView";
import BusList from "../Application/Dashboard/bus";
import CreateBus from "../Application/Dashboard/bus/create";
import DriversList from "../Application/Dashboard/drivers";
import CreateDriver from "../Application/Dashboard/drivers/create";
import ViewDriver from "../Application/Dashboard/drivers/view";
import RouteList from "../Application/Dashboard/routes";
import CreateRoute from "../Application/Dashboard/routes/create";
import SchoolList from "../Application/Dashboard/schools";
import AboutSchool from "../Application/Dashboard/schools/about";
import CreateSchool from "../Application/Dashboard/schools/create";
import StopList from "../Application/Dashboard/Stops";
import CreateStop from "../Application/Dashboard/Stops/create";
import StudentsList from "../Application/Dashboard/students";
import CreateStudents from "../Application/Dashboard/students/create";
import ViewStudent from "../Application/Dashboard/students/view";
import SubscriptionList from "../Application/Dashboard/subscription";
import TripList from "../Application/Dashboard/trips";
import CreateTrip from "../Application/Dashboard/trips/create";
import ForgotPassword from "../Application/ForgotPassworrd";
import Login from "../Application/Login";
import ResetPassword from "../Application/ResetPassword";
import SchoolDashboard from "../Application/Schools";
import VerifyEmail from "../Application/VerifyEmail";
import AdminAuth from "./AdminAuth";
import SchoolAuth from "./SchoolAuth";
export class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    const isOnline = window.navigator.onLine;
    if (isOnline) {
      console.log("online");
    } else {
      console.log("offline");
    }
  }
  render() {
    return (
      <main>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword/:id" component={ResetPassword} />
          <Route exact path="/verifyEmail/:id" component={VerifyEmail} />

          <AdminAuth
            title="Dashboard"
            exact
            path="/dashboard"
            component={Dashboard}
          ></AdminAuth>
          <AdminAuth
            title="Admins"
            exact
            path="/dashboard/admins"
            component={Admin}
          ></AdminAuth>
          <AdminAuth
            title="Admins"
            exact
            path="/dashboard/admins/:id/create"
            component={createAdmin}
          ></AdminAuth>
          <AdminAuth
            title="Admins"
            exact
            path="/dashboard/admins/:id/edit/:uid"
            component={createAdmin}
          ></AdminAuth>
          <AdminAuth
            title="Add new school"
            exact
            path="/dashboard/school/create"
            component={CreateSchool}
          ></AdminAuth>
          <AdminAuth
            title="Change Password"
            exact
            path="/dashboard/admins/primary/edit/:id/changepassword"
            component={ChangePassword}
          ></AdminAuth>
          <AdminAuth
            title="Change Password"
            exact
            path="/dashboard/admins/secondary/edit/:id/changepassword"
            component={ChangePassword}
          ></AdminAuth>
          <AdminAuth
            title="Edit School"
            exact
            path="/dashboard/school/edit/:id"
            component={CreateSchool}
          ></AdminAuth>
          <AdminAuth
            title="List of Schools"
            exact
            path="/dashboard/schools"
            component={SchoolList}
          ></AdminAuth>
          <AdminAuth
            title="Subscription"
            exact
            path="/subscription"
            component={SubscriptionList}
          ></AdminAuth>
          <SchoolAuth
            title="Dashboard"
            exact
            path="/school/dashboard"
            component={SchoolDashboard}
          ></SchoolAuth>
          <SchoolAuth
            title="Driver details"
            exact
            path="/school/drivers"
            component={DriversList}
          ></SchoolAuth>
          <SchoolAuth
            title="Create new bus driver"
            exact
            path="/school/driver/create"
            component={CreateDriver}
          ></SchoolAuth>
          <SchoolAuth
            title="Edit bus driver"
            exact
            path="/school/driver/edit/:id"
            component={CreateDriver}
          ></SchoolAuth>
          <SchoolAuth
            title="View bus driver"
            exact
            path="/school/driver/view/:id"
            component={ViewDriver}
          ></SchoolAuth>

          <SchoolAuth
            title="Create new student profile"
            exact
            path="/school/student/create"
            component={CreateStudents}
          ></SchoolAuth>
          <SchoolAuth
            title="Edit student profile"
            exact
            path="/school/student/edit/:id"
            component={CreateStudents}
          ></SchoolAuth>
          <SchoolAuth
            title="View student profile"
            exact
            path="/school/student/view/:id"
            component={ViewStudent}
          ></SchoolAuth>
          <SchoolAuth
            title="Student details"
            exact
            path="/school/students"
            component={StudentsList}
          ></SchoolAuth>
          <SchoolAuth
            title="Vehicle creation"
            exact
            path="/school/vehicle/create"
            component={CreateBus}
          ></SchoolAuth>
          <SchoolAuth
            title="Update Vehicle"
            exact
            path="/school/vehicle/edit/:id"
            component={CreateBus}
          ></SchoolAuth>
          <SchoolAuth
            title="Vehicle details"
            exact
            path="/school/vehicles"
            component={BusList}
          ></SchoolAuth>
          <SchoolAuth
            title="Stop details"
            exact
            path="/school/stops"
            component={StopList}
          ></SchoolAuth>
          <SchoolAuth
            title="Stop creation"
            exact
            path="/school/stop/create"
            component={CreateStop}
          ></SchoolAuth>
          <SchoolAuth
            title="Update Stop"
            exact
            path="/school/stop/edit/:id"
            component={CreateStop}
          ></SchoolAuth>
          <SchoolAuth
            title="Route creation"
            exact
            path="/school/route/create"
            component={CreateRoute}
          ></SchoolAuth>
          <SchoolAuth
            title="Edit Route"
            exact
            path="/school/route/edit/:id"
            component={CreateRoute}
          ></SchoolAuth>
          <SchoolAuth
            title="Trip creation"
            exact
            path="/school/trip/create"
            component={CreateTrip}
          ></SchoolAuth>
          <SchoolAuth
            title="Trips"
            exact
            path="/school/trips"
            component={TripList}
          ></SchoolAuth>
          <SchoolAuth
            title="Route Details"
            exact
            path="/school/routes"
            component={RouteList}
          ></SchoolAuth>
          <SchoolAuth
            title="Edit Trip"
            exact
            path="/school/trip/edit/:id"
            component={CreateTrip}
          ></SchoolAuth>
          <SchoolAuth
            title="Dashboard"
            exact
            path="/school/dashboard"
            component={SchoolDashboard}
          ></SchoolAuth>
          <SchoolAuth
            title="About School"
            exact
            path="/aboutschool"
            component={AboutSchool}
          ></SchoolAuth>
          <SchoolAuth
            title="Birds View"
            exact
            path="/school/birdsview"
            component={BirdView}
          ></SchoolAuth>
          <Route exact path="/404" component={GenericNotFound} />
          <Route exact path="*" component={GenericNotFound} />
        </Switch>
      </main>
    );
  }
}

export default Routes;
