import PropTypes from "prop-types";
import React, { Component } from "react";
import { Mutation } from "react-apollo";
import ReactImageCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Input from "react-validation/build/input";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { GET_SIGNED_URL } from "../../Application/graphql";
import { sendNotification, uploadFiles } from "../../utils";
import Button from "./button";
import InputStyle from "./style/input";
export class ReactFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      showPwd: false,
      error: false,
      crop: {
        // height: 100,
        // width: 100,
        x: 0,
        y: 0,
        aspect: props.aspect, //16 / 9,
        // unit: "px",
        unit: "%",
        width: 50,
        height: 50,
      },
      cropped: false,
      base64: "",
      isOpen: false,
      fileList: [],
      dataVariables: {},
    };
  }
  onChange = async (event) => {
    // if (this.props.maxLength) {
    //   if (this.props.maxLength > event.target.value.length) {
    //     this.props.onChange(event.target.value);
    //   }
    // } else {
    console.log(event.target.files);
    if (event.target.files.length) {
      this.setState({ fileList: event.target.files });
      const base64 = await this.toBase64(
        event.target.files ? event.target.files[0] : {}
      );
      this.setState({ base64 }, () => {
        this.makeClientCrop(this.state.crop);

        this.toggleModal();
      });
    }
    // this.props.
    // console.log(base64);
    // }
  };
  onFocused = () => {
    this.setState({ error: false });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };
  onTouched = () => {
    this.setState({ touched: true }, () => {
      if (this.props.validation)
        if (this.props.validation.length) {
          const errorList = this.props.validation
            .map((validate) => {
              if (validate(this.props.value)) {
                return validate;
              }
              return undefined;
            })
            .filter((data) => data);

          if (errorList.length) {
            this.setState({ error: true });
          } else {
            this.setState({ error: false });
          }
        }
    });
    // this.form.validateAll();
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop, percentCrop) => {
    if (crop.height !== 0 && crop.width !== 0) {
      this.setState({ crop });
    }
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };
  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageBlob = await this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageBlob });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    canvas.style.display = "none";
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  }
  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      this.setState({ name: "", selectedId: "" });
    });
  };
  toggleViewModal = () => {
    this.setState({ isViewOpen: !this.state.isViewOpen }, () => {
      this.setState({ name: "", selectedId: "" });
    });
  };
  handleCancel = () => {
    this.setState({
      modalVisible: false,
      loading: false,
      edit: false,
      src: null,
      croppedImageBlob: null,
      fileList: [],
      crop: {
        // height: 100,
        // width: 100,
        x: 0,
        y: 0,
        aspect: this.props.aspect, //16 / 9,
        // unit: "px",
        unit: "%",
        width: 50,
        height: 50,
      },
      base64: "",
      isOpen: false,
      isViewOpen: false,
    });
  };
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  getSignedUrl = async (func) => {
    const { fileList } = this.state;
    let file = fileList[0];
    let data = [
      {
        contentType: file.type,
        filename: file.name,
      },
    ];
    let dataVariables = {
      data: data,
      uploadFor: this.props.uploadFor,
      insToken: this.props.token.insToken,
      userToken: this.props.token.userToken,
    };
    this.setState({ dataVariables }, () => {
      func();
    });
    // const signedUploadUrl = await getS3UploadUrl(
    //   `${file.name}`,
    //   `${file.type}`
    // );
  };
  uploadImageToS3 = async (signedUploadUrl) => {
    this.setState({ loading: true });
    const { fileList, croppedImageBlob } = this.state;
    let file = fileList[0];

    const filesArray = [
      new File([croppedImageBlob], file.name, {
        type: file.type,
        lastModified: Date.now(),
      }),
    ];
    this.props.onChange(filesArray);
    // console.log(filesArray);

    const signedS3UrlArray = signedUploadUrl;
    const [uploadedResponse] = await uploadFiles(signedS3UrlArray, filesArray);
    setTimeout(() => {
      this.props.onSuccess(uploadedResponse);
    }, 500);
    this.handleCancel();
  };

  render() {
    return (
      <Mutation
        variables={{
          // id: this.props.query.id
          ...this.state.dataVariables,
        }}
        mutation={GET_SIGNED_URL}
        onError={(error) => {
          if (!error || !error.message) return null;

          sendNotification({
            message: error.message.replace("GraphQL error: ", ""),
            type: "danger",
          });
          // this.showNotificationError(
          //   error.message.replace("GraphQL error: ", "")
          // );
        }}
        onCompleted={(data) => {
          if (data.getSignedUrl) {
            const signedURL = data.getSignedUrl.map((data) => data.signedUrl);
            this.uploadImageToS3(signedURL);
            let signedUrl = data.getSignedUrl.length
              ? data.getSignedUrl[0]
              : {};
            this.props.onTokenChange(signedUrl.insToken, signedUrl.userToken);
            // this.showNotification(
            //   "👋 Welcome to our group!",
            //   ``
            // );
            // sendNotification({
            //   title: "",
            //   message: "Admin Created Successfully"
            // });
            // message.success("Contact Created!!");
          }
        }}
      >
        {(GetSignedURL, { data, loading, error }) => {
          return (
            <InputStyle
              password={this.props.type === "password"}
              white={this.props.white}
              className={`position-relative pointer-cursor fileContainer ${
                this.props.required && !this.state.touched
                  ? this.props.value === ""
                    ? "inputRequired"
                    : ""
                  : ""
              } ${
                this.state.touched
                  ? `touched ${
                      this.props.required && this.props.value === ""
                        ? "invalid form-error"
                        : ""
                    }`
                  : ""
              } ${this.state.error ? "invalid form-error" : ""} ${
                this.props.relativeClass ? this.props.relativeClass : ""
              }`}
              onClick={() => {
                if (!this.state.isOpen) this.inputElement.click();
              }}
            >
              <div className="mt-0">
                {this.props.description && (
                  <p className="text--small mb-2">{this.props.description}</p>
                )}
              </div>
              {/* <div className="lineBorder" /> */}
              <input
                className="d-none"
                type="file"
                ref={(input) => (this.inputElement = input)}
                onChange={this.onChange}
                multiple={this.props.multiple}
              />
              <div className="d-flex">
                <div className="w-100 d-flex">
                  <div className="w-100">
                    <Input
                      disabled={this.props.disabled}
                      onBlur={this.onTouched}
                      min={this.props.min || ""}
                      type="text"
                      rows={this.props.rows}
                      // defaultValue={this.props.defaultValue}
                      placeholder={this.props.placeholder}
                      value={this.props.value.name}
                      validations={
                        this.props.validations ? this.props.validations : []
                      }
                      required={this.props.required}
                      onFocus={this.onFocused}
                      className={`${
                        this.props.className ? this.props.className : ""
                      } pointer-none mb-0 ${
                        this.props.disabled ? "disabled" : ""
                      } border-right-0`}
                      // {...this.props}
                      maxLength={this.props.maxLength}
                    />
                  </div>
                  {this.props.value.name && (
                    <div
                      className="viewContainer"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleViewModal();
                      }}
                    >
                      View
                    </div>
                  )}
                </div>
                <Button
                  file
                  title={this.props.value.name ? "Change" : "Upload"}
                  primary
                ></Button>
                {this.props.value.name && (
                  <div className="deleteContainer">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.onDelete();
                      }}
                      danger
                      rounded
                      title={"Delete"}
                      primary
                    ></Button>
                  </div>
                )}
              </div>
              <Modal
                isOpen={this.state.isOpen}
                toggle={this.toggleModal}
                centered
                size="lg"
              >
                <ModalHeader toggle={this.toggleModal}>
                  Upload {`${this.props.header}`}
                  {/* {this.state.selectedId !== ""
                ? `Update ${this.state.message}`
                : `Create New ${this.state.message}`} */}
                </ModalHeader>
                <ModalBody>
                  {this.state.fileList.length && (
                    <ReactImageCrop
                      src={this.state.base64}
                      crop={this.state.crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      className="img-fluid"
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex">
                    <Button
                      primaryTransparent
                      rounded
                      title="Cancel"
                      onClick={() => {
                        this.handleCancel();
                      }}
                    ></Button>
                    <Button
                      primary
                      rounded
                      title="Confirm"
                      onClick={() => {
                        this.getSignedUrl(GetSignedURL);
                        // this.uploadImageToS3();
                      }}
                    ></Button>
                  </div>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={this.state.isViewOpen}
                toggle={this.toggleViewModal}
                centered
                size="lg"
              >
                <ModalHeader toggle={this.toggleViewModal}>
                  {`${this.props.header}`}
                  {/* {this.state.selectedId !== ""
                ? `Update ${this.state.message}`
                : `Create New ${this.state.message}`} */}
                </ModalHeader>
                <ModalBody className="text-center">
                  {this.props.imageURL && (
                    <img className="img-fluid" src={this.props.imageURL} />
                  )}
                </ModalBody>
                <ModalFooter>
                  <div className="d-flex">
                    <Button
                      primaryTransparent
                      rounded
                      title="Cancel"
                      onClick={() => {
                        this.handleCancel();
                      }}
                    ></Button>
                    <Button
                      primary
                      rounded
                      title="Edit"
                      onClick={() => {
                        this.handleCancel();
                        this.inputElement.click();

                        // this.inputElement
                        // this.getSignedUrl(GetSignedURL);
                        // this.uploadImageToS3();
                      }}
                    ></Button>
                  </div>
                </ModalFooter>
              </Modal>
              {/* {this.props.type === "password" && (
          <div
            className="passwordIcon pointer-cursor"
            onClick={() => {
              this.setState({ showPwd: !this.state.showPwd });
            }}
          >
            {this.state.showPwd ? (
              <Image src={Images.PasswordStrikeEye}></Image>
            ) : (
              <Image src={Images.PasswordEye}></Image>
            )}
          </div>
        )} */}

              {this.props.required &&
                this.props.value === "" &&
                this.state.touched && <span className="text-tiny error"></span>}
            </InputStyle>
          );
        }}
      </Mutation>
    );
  }
}
ReactFile.defaultProps = {
  multiple: false,
  aspect: 1,
  value: {},
};
ReactFile.propTypes = {
  aspect: PropTypes.number,
  value: PropTypes.object,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  validation: PropTypes.array,
  required: PropTypes.bool,
  className: PropTypes.string,
  white: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  min: PropTypes.number,
  showPwd: PropTypes.bool,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  onSuccess: PropTypes.func,
  uploadFor: PropTypes.string,
};
export default ReactFile;
