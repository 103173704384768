import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";
import Form from "react-validation/build/form";
import styled from "styled-components";
import Button from "../../../Component/Form/button";
import ReactInput from "../../../Component/Form/input";
import ReactSelect from "../../../Component/Form/select";
import Loading from "../../../Component/Loading";
import { generateRandomString, sendNotification } from "../../../utils";
import {
  CREATE_TRIP,
  GET_TRIP_BY_ID,
  GET_TRIP_DATA,
  UPDATE_TRIP,
} from "../../graphql";

let FormRef;

const SchoolContainer = styled.div`
  .menu {
    display: flex;
  }
  .menuItem {
    padding: 6px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 17px;
    color: #004584;
    opacity: 0.54;
    font-weight: 600;
    font-family: "Muli", sans-serif;
    border-right: 1px solid #004584;
    &.active {
      opacity: 1;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export class CreateTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CreateTrip: {
        tripName: "",
        tripId: "",
        routeId: "",
        vehicleId: "",
        driverId: "",
      },
      dataVariables: {},
      activeTab: "1",
      formSubmit: false,
    };
  }
  componentDidMount() {
    const instituteId = localStorage.getItem("institueId");
    // refer
    this.setState({ gpwd: generateRandomString(7) });
  }
  generatePwd = () => {
    this.setState({ gpwd: generateRandomString(7) });
  };
  onChange = (key, value) => {
    const CreateTrip = { ...this.state.CreateTrip };
    CreateTrip[key] = value;

    this.setState({ CreateTrip });
  };
  setActiveTab = (index) => {
    this.setState({ activeTab: index + "" });
  };
  onNextButton = () => {
    this.setActiveTab(parseInt(this.state.activeTab) + 1);
  };
  onBackButton = () => {
    if (this.state.activeTab !== "1") {
      this.setActiveTab(parseInt(this.state.activeTab) - 1);
    } else {
      this.props.history.push("/school/trips");
    }
  };
  onChange = (key, value) => {
    const CreateTrip = { ...this.state.CreateTrip };
    CreateTrip[key] = value;
    this.setState({ CreateTrip });
  };
  handleSubmit = (func) => {
    this.setState({ formSubmit: true }, () => {
      FormRef.validateAll();
      setTimeout(() => {
        if (document.querySelectorAll(".form-error").length === 0) {
          if (document.querySelectorAll(".inputRequired").length === 0) {
            // ContactOrganizer();
            // func();
            this.setDataVariables(func);
            // this.props.changePage();
          } else {
            this.setActiveTab(1);
            sendNotification({
              message: "Please fill all the fields",
              type: "danger",
            });
          }
        } else {
          this.setActiveTab(1);
          sendNotification({
            message: "Please fill all the fields",
            type: "danger",
          });
        }
      }, 1);
    });
  };
  setDataVariables = (func) => {
    const dataVariables = {
      // id: this.props.query.id,
      tripName: this.state.CreateTrip.tripName,
      tripId: this.state.CreateTrip.tripId,
      routeId: this.state.CreateTrip.routeId,
      vehicleId: this.state.CreateTrip.vehicleId,
      driverId: this.state.CreateTrip.driverId,
    };
    if (this.props.match.params.id) {
      dataVariables.id = this.props.match.params.id;
    } else {
      dataVariables.instituteId = localStorage.getItem("institueId");
    }

    this.setState({ dataVariables }, () => {
      func();
    });
  };
  render() {
    return (
      <SchoolContainer className="pl-3">
        {this.props.match.params.id && (
          <Query
            variables={{ id: this.props.match.params.id }}
            query={GET_TRIP_BY_ID}
            fetchPolicy={"network-only"}
            onCompleted={(data) => {
              if (data.getTripById) {
                let CreateTrip = {
                  tripName: data.getTripById.tripName,
                  tripId: data.getTripById.tripId,
                  routeId: data.getTripById.routeId,
                  vehicleId: data.getTripById.vehicleId,
                  driverId: data.getTripById.driverId,
                };

                this.setState({ CreateTrip }, () => {});

                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              return <Loading is_loading={loading} />;
            }}
          </Query>
        )}

        <div className="col-9 pl-4 pr-4 pt-4">
          <Query
            fetchPolicy={"network-only"}
            variables={{ instituteId: localStorage.getItem("institueId") }}
            query={GET_TRIP_DATA}
            onCompleted={(data) => {
              console.log(data);
              if (data.getStudentById) {
                // if (data.getOnboardingEmailId) {
                //   this.setState({ email: data.getOnboardingEmailId.email });
                // }
              }
            }}
            onError={(error) => {
              if (!error || !error.message) return null;
              this.setState({ hasErr: true });
              sendNotification({
                message: error.message.replace("GraphQL error: ", ""),
                type: "danger",
              });
            }}
          >
            {({ data, loading, error }) => {
              let queryLoading = loading;

              return (
                <Mutation
                  variables={{
                    data: this.state.dataVariables,
                  }}
                  mutation={
                    this.props.match.params.id ? UPDATE_TRIP : CREATE_TRIP
                  }
                  onError={(error) => {
                    if (!error || !error.message) return null;
                    sendNotification({
                      message: error.message.replace("GraphQL error: ", ""),
                      type: "danger",
                    });

                    // this.showNotificationError(
                    //   error.message.replace("GraphQL error: ", "")
                    // );
                  }}
                  onCompleted={(data) => {
                    if (data.createTrip) {
                      // this.showNotification(
                      //   "👋 Welcome to our group!",
                      //   ``
                      // );
                      sendNotification({
                        message: "Trips created successfully",
                        type: "success",
                      });
                      setTimeout(() => {
                        this.props.history.push("/school/trips");
                      }, 500);
                      // message.success("Contact Created!!");
                    }
                    if (data.updateTrip) {
                      // this.showNotification(
                      //   "👋 Welcome to our group!",
                      //   ``
                      // );
                      sendNotification({
                        message: "Trips updated successfully",
                        type: "success",
                      });
                      setTimeout(() => {
                        this.props.history.push("/school/trips");
                      }, 500);
                      // message.success("Contact Created!!");
                    }
                  }}
                >
                  {(CreateTrip, { loading, error }) => {
                    return (
                      <Form
                        ref={(ref) => {
                          FormRef = ref;
                        }}
                        className={` ${
                          this.state.formSubmit ? "invalid-form" : ""
                        }`}
                      >
                        <Loading is_loading={loading || queryLoading} />
                        <div
                          className={`col-11 ${
                            this.state.activeTab === "1" ? "d-block" : "d-none"
                          }`}
                        >
                          <InputContainer className="row m-0 mt-3">
                            <div className="col-3 pl-0">Trip name</div>
                            <div className="col-9">
                              <ReactInput
                                placeholder=""
                                white
                                required
                                value={this.state.CreateTrip.tripName}
                                className={"w-100"}
                                onChange={(value) => {
                                  this.onChange("tripName", value);
                                }}
                              ></ReactInput>
                            </div>
                          </InputContainer>
                          <InputContainer className="row m-0 mt-3">
                            <div className="col-3 pl-0">Trip ID</div>
                            <div className="col-9">
                              <ReactInput
                                placeholder=""
                                white
                                required
                                value={this.state.CreateTrip.tripId}
                                className={"w-100"}
                                onChange={(value) => {
                                  this.onChange("tripId", value);
                                }}
                              ></ReactInput>
                            </div>
                          </InputContainer>
                          <InputContainer className="row m-0 mt-3">
                            <div className="col-3 pl-0">Route ID</div>
                            <div className="col-9">
                              <ReactSelect
                                placeholder=""
                                white
                                required
                                value={this.state.CreateTrip.routeId}
                                className={"w-100"}
                                options={[
                                  { value: "", name: "Select a route" },
                                  ...(
                                    (data || {}).getRoutesForInstitute || []
                                  ).map((data) => {
                                    return {
                                      value: data.id,
                                      name: data.routeName,
                                    };
                                  }),
                                ]}
                                onChange={(value) => {
                                  // console.log(value);
                                  this.onChange("routeId", value);
                                }}
                              ></ReactSelect>
                            </div>
                          </InputContainer>
                          <InputContainer className="row m-0 mt-3">
                            <div className="col-3 pl-0">Vehicle ID</div>
                            <div className="col-9">
                              <ReactSelect
                                placeholder=""
                                white
                                required
                                value={this.state.CreateTrip.vehicleId}
                                className={"w-100"}
                                options={[
                                  { value: "", name: "Select a vehicle" },
                                  ...(
                                    (data || {}).getVehiclesForInstitute || []
                                  ).map((data) => {
                                    return {
                                      value: data.id,
                                      name: data.vehicleId,
                                    };
                                  }),
                                ]}
                                onChange={(value) => {
                                  // console.log(value);
                                  this.onChange("vehicleId", value);
                                }}
                              ></ReactSelect>
                            </div>
                          </InputContainer>
                          <InputContainer className="row m-0 mt-3">
                            <div className="col-3 pl-0">Driver name</div>
                            <div className="col-9">
                              <ReactSelect
                                placeholder=""
                                white
                                required
                                value={this.state.CreateTrip.driverId}
                                className={"w-100"}
                                options={[
                                  { value: "", name: "Select a driver" },
                                  ...(
                                    (data || {}).getDriversForInstitute || []
                                  ).map((data) => {
                                    return {
                                      value: data.id,
                                      name:
                                        data.firstName + " " + data.lastName,
                                    };
                                  }),
                                ]}
                                onChange={(value) => {
                                  // console.log(value);
                                  this.onChange("driverId", value);
                                }}
                              ></ReactSelect>
                            </div>
                          </InputContainer>
                        </div>

                        <div className="mt-2 mb-5 pt-4 d-flex align-items-center justify-content-end">
                          <Button
                            primaryTransparent
                            buttonClass="mr-4"
                            title="Back"
                            disabled={this.state.activeTab === "1"}
                            onClick={this.onBackButton}
                          ></Button>
                          <div className="d-inline">
                            <Button
                              title={`${
                                this.state.activeTab === "1"
                                  ? this.props.match.params.id
                                    ? "Update trip"
                                    : "Create new trip"
                                  : "Next"
                              }`}
                              primary
                              rounded
                              onClick={() => {
                                if (this.state.activeTab === "1") {
                                  this.handleSubmit(CreateTrip);
                                } else {
                                  this.onNextButton();
                                }
                                // this.handleSubmit(CreateTrip);
                              }}
                            ></Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </div>
      </SchoolContainer>
    );
  }
}

export default CreateTrip;
