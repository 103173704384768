import PropTypes from "prop-types";
import React, { Component } from "react";
import Textarea from "react-validation/build/textarea";
import InputStyle from "./style/input";
export class ReactTextarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      showPwd: false,
      error: false
    };
  }
  onChange = event => {
    // if (this.props.maxLength) {
    //   if (this.props.maxLength > event.target.value.length) {
    //     this.props.onChange(event.target.value);
    //   }
    // } else {
    this.props.onChange(event.target.value);
    // }
  };
  onFocused = () => {
    this.setState({ error: false });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };
  onTouched = () => {
    this.setState({ touched: true }, () => {
      if (this.props.validation)
        if (this.props.validation.length) {
          const errorList = this.props.validation
            .map(validate => {
              if (validate(this.props.value)) {
                return validate;
              }
              return undefined;
            })
            .filter(data => data);

          if (errorList.length) {
            this.setState({ error: true });
          } else {
            this.setState({ error: false });
          }
        }
    });
    // this.form.validateAll();
  };

  render() {
    return (
      <InputStyle
        password={this.props.type === "password"}
        white={this.props.white}
        className={`position-relative  ${
          this.props.required && !this.state.touched
            ? this.props.value === ""
              ? "inputRequired"
              : ""
            : ""
        } ${
          this.state.touched
            ? `touched ${
                this.props.required && this.props.value === ""
                  ? "invalid form-error"
                  : ""
              }`
            : ""
        } ${this.state.error ? "invalid form-error" : ""} ${
          this.props.relativeClass ? this.props.relativeClass : ""
        }`}
      >
        <div className="mt-0">
          {this.props.description && (
            <p className="text--small mb-2">{this.props.description}</p>
          )}
        </div>
        {/* <div className="lineBorder" /> */}
        <Textarea
          disabled={this.props.disabled}
          onBlur={this.onTouched}
          min={this.props.min || ""}
          type="text"
          rows={parseInt(this.props.rows)}
          // defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          value={this.props.value}
          validations={this.props.validations ? this.props.validations : []}
          required={this.props.required}
          onFocus={this.onFocused}
          className={`${this.props.className ? this.props.className : ""} ${
            this.props.disabled ? "disabled" : ""
          }`}
          // {...this.props}
          onChange={this.onChange}
          maxLength={this.props.maxLength}
        />
        {/* {this.props.type === "password" && (
          <div
            className="passwordIcon pointer-cursor"
            onClick={() => {
              this.setState({ showPwd: !this.state.showPwd });
            }}
          >
            {this.state.showPwd ? (
              <Image src={Images.PasswordStrikeEye}></Image>
            ) : (
              <Image src={Images.PasswordEye}></Image>
            )}
          </div>
        )} */}

        {this.props.maxLength && this.props.value !== undefined && (
          <p className="text--tiny">
            {this.props.maxLength - this.props.value.length}
          </p>
        )}
        {this.props.required &&
          this.props.value === "" &&
          this.state.touched && <span className="text-tiny error"></span>}
      </InputStyle>
    );
  }
}
ReactTextarea.defaultProps = {
  rows: 5
};
ReactTextarea.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  validation: PropTypes.array,
  required: PropTypes.bool,
  className: PropTypes.string,
  white: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  min: PropTypes.number,
  showPwd: PropTypes.bool,
  type: PropTypes.string,
  onFocus: PropTypes.func
};
export default ReactTextarea;
