import styled from "styled-components";
import { colors } from "../../Themes/Color";
const ModalStyle = styled.div`
  padding: 25px;

  &.backdropStyle {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    transition: 0.5s all ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .modalStyle {
    background-color: #fff;
    border-radius: 5px;
    min-width: 500px;
    max-width: 500px;
    /* min-height: 300px; */
    margin: 0 auto;
    padding: 15px;
    overflow: scroll;
  }
  & .header {
    font-weight: 600;
    font-family: "Muli", sans-serif;
    font-size: 19px;
    color: ${colors.colorBlue};
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  &.show {
    z-index: 9999;
    opacity: 1;
  }
`;
export default ModalStyle;
