import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import Images from "../Themes/Images";
const debounce = require("lodash.debounce");

const SearchContainer = styled.div`
  position: relative;
  input {
    padding: 10px 50px;
    padding-right: 80px;
    border-radius: 7px;
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .searchImage {
    position: absolute;
    /* padding-top: 10px; */
    left: 10px;
    top: 10px;
  }
  .searchIcon {
    left: 10px;
  }
  .imageContainer {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .sortType {
    font-family: "Muli", sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #919191;
    &.small {
      font-size: 10px;
      font-weight: 500;
      line-height: initial;
    }
  }
  .sortContainer {
    padding: 10px;
    margin: 10px;
    margin-right: 0;
    margin-top: 0;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    min-width: 200px;
    border-radius: 4px;
  }
  .sortOuterContainer {
    top: 150%;
    right: -9px;
    z-index: 9;
    &.filter {
      right: -15px;
      top: 170%;
    }
  }
  .arrowContainer {
    height: 10px;
  }
  .arrow-up {
    position: absolute;
    right: 20px;
    width: 0;
    height: 0;
    margin-top: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid #fff;
  }
  .sortText {
    font-family: "Muli", sans-serif;
    font-size: 15px;
    color: #000;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .sortText.active {
    color: #0064dc;
  }
  .danger {
    color: #dd455d;
  }
  .bg-blue {
    background: #f2f5fa;
  }
  .border-white {
    border: 1px solid #fff;
  }
  .padding-10 {
    padding: 10px;
  }
`;
let searchDebounce;
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      sortContainer: false,
      filterContainer: false,
    };
    searchDebounce = debounce(this.onSearchTerm, 1000);
  }
  onSearchTerm = () => {
    if (this.props.onSearchTerm) {
      this.props.onSearchTerm(this.state.searchTerm);
    }
  };
  render() {
    return (
      <SearchContainer
        onMouseLeave={() => {
          this.setState({ sortContainer: false, filterContainer: false });
        }}
      >
        <img
          alt=""
          src={Images.searchIcon}
          className="img-fluid searchIcon searchImage"
        />
        <input
          value={this.state.searchTerm}
          placeholder={this.props.placeholder}
          onChange={(e) => {
            this.setState({ searchTerm: e.target.value });

            searchDebounce();
          }}
        />
        <div className="imageContainer">
          {this.props.isShowFilter && (
            <div className="d-inline position-relative">
              <img
                alt=""
                src={Images.filterIcon}
                className="img-fluid filterIcon"
                onMouseEnter={() => {
                  this.setState({
                    filterContainer: true,
                    sortContainer: false,
                  });
                }}
              />
              {this.state.filterContainer && (
                <div
                  className="position-absolute sortOuterContainer filter"
                  onMouseLeave={() => {
                    this.setState({ filterContainer: false });
                  }}
                  onMouseEnter={() => {
                    this.setState({
                      filterContainer: true,
                      sortContainer: false,
                    });
                  }}
                >
                  <div className="arrowContainer">
                    <div className="arrow-up"></div>
                  </div>
                  <div className="sortContainer p-0 pb-2">
                    <div className="sortType padding-10 pb-1 d-flex justify-content-between">
                      <div>Filter</div>
                      {this.props.isFilter && (
                        <div
                          className="danger pointer-cursor"
                          onClick={this.props.onReset}
                        >
                          Reset all
                        </div>
                      )}
                    </div>
                    {this.props.filterContainer}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="d-inline">
            <img
              alt=""
              src={Images.sortIcon}
              className="img-fluid ml-3 sortIcon pointer-cursor"
              onMouseEnter={() => {
                this.setState({ sortContainer: true, filterContainer: false });
              }}
            />
            {this.state.sortContainer && (
              <div
                className="position-absolute sortOuterContainer"
                onMouseLeave={() => {
                  this.setState({
                    sortContainer: false,
                    filterContainer: false,
                  });
                }}
                onMouseEnter={() => {
                  this.setState({
                    sortContainer: true,
                    filterContainer: false,
                  });
                }}
              >
                <div className="arrowContainer">
                  <div className="arrow-up"></div>
                </div>
                <div className="sortContainer">
                  <div className="sortType">Sort</div>
                  {this.props.sortContainer}
                </div>
              </div>
            )}
          </div>
        </div>
      </SearchContainer>
    );
  }
}

// export class Search extends Component {
//   render() {
//     return (
//     );
//   }
// }
Search.propTypes = {
  placeholder: PropTypes.string,
  onSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
  sortContainer: PropTypes.element,
};
Search.defaultProps = {
  placeholder: "Search",
  isShowFilter: true,
};
export default Search;
