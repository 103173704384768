import validator from "validator";

export const email = value => {
  if (!validator.isEmail(value)) {
    return true;
  }
};

export const isMobileNumber = value => {
  if (!validator.isMobilePhone(value)) {
    return true;
  }
};
export const isURL = value => {
  if (!validator.isURL(value)) {
    return true;
  }
};
