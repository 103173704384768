import React from "react";
import Images from "../../Themes/Images";
import Image from "../Image";
import { DashboardContainer } from "./style/dashboard";

const Admin = (props) => {
  const role = (props.user || {}).rolePriority;
  return (
    <DashboardContainer>
      <div
        className="logo pointer-cursor"
        onClick={() => {
          props.history.push("/dashboard");
        }}
      >
        <Image src={Images.DashboardLogo}></Image>
      </div>
      <div className="listContainer">
        <div
          className={`pointer-cursor ${
            props.location.pathname === "/dashboard" ? "active" : ""
          }`}
          onClick={() => {
            props.history.push("/dashboard");
          }}
        >
          Dashboard
        </div>
        <div
          className={`pointer-cursor ${
            props.location.pathname === "/dashboard/schools" ? "active" : ""
          }`}
          onClick={() => {
            props.history.push("/dashboard/schools");
          }}
        >
          Schools
        </div>
        {role === "PRIMARY" && (
          <div
            className={`pointer-cursor ${
              props.location.pathname === "/subscription" ? "active" : ""
            }`}
            onClick={() => {
              props.history.push("/subscription");
            }}
          >
            Subscription
          </div>
        )}
        <div
          className={`pointer-cursor ${
            props.location.pathname === "/dashboard" ? "active" : ""
          }`}
          onClick={() => {
            props.history.push("/dashboard");
          }}
        >
          Plans/pricing
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Admin;
