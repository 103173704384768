import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../Themes/Color";

const ButtonContainer = styled.div`
  /* background: ${(props) =>
    props.transparent ? "transparent" : "#6c63ff"}; */
  /* background: rgb(5,147,219); */
  background: ${colors.gradientColor};

  padding: ${(props) => (props.small ? " 6px 20px" : " 10px 30px")};
  border-radius: ${(props) => (props.small ? "5px" : "8px")};
  cursor: pointer;
  & .ButtonText {
  font-family: 'Muli', sans-serif;
  font-weight:600;
    color: ${(props) => (props.transparent ? "#6c63ff" : colors.colorWhite)};
    font-size: ${(props) => (props.small ? "15px" : "17px")};
  }
  ${(props) =>
    props.white &&
    css`
      background: ${colors.colorWhite};
      & .ButtonText {
        color: ${colors.colorBlue};
      }
    `}
    ${(props) =>
      props.primary &&
      css`
        background: ${colors.colorBlue};
        & .ButtonText {
          color: ${colors.colorWhite};
        }
      `}
      ${(props) =>
        props.danger &&
        css`
          background: ${colors.errorColor};
          & .ButtonText {
            color: ${colors.colorWhite};
          }
        `}
    ${(props) =>
      props.rounded &&
      css`
        border-radius: 50px;
      `}
    ${(props) =>
      props.primaryTransparent &&
      css`
        background: initial;
        & .ButtonText {
          color: ${colors.colorBlue};
        }
      `}
      ${(props) =>
        props.primaryOutline &&
        css`
          background: white;
          border: 1px solid ${colors.colorBlue};
          & .ButtonText {
            color: ${colors.colorBlue};
          }
        `}
      ${(props) =>
        props.file &&
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          & .ButtonText {
            color: ${colors.colorWhite};
          }
        `}
`;
const Button = (props) => {
  return (
    <ButtonContainer
      className={`${props.buttonClass ? props.buttonClass : ""}`}
      transparent={props.transparent}
      small={props.small}
      type={props.type}
      white={props.white}
      danger={props.danger}
      rounded={props.rounded}
      primary={props.primary}
      file={props.file}
      primaryOutline={props.primaryOutline}
      primaryTransparent={props.primaryTransparent}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <div
        className={`text-center ButtonText FontAvenirBlack ${
          props.textClass ? props.textClass : ""
        }`}
      >
        {props.title}
      </div>
    </ButtonContainer>
  );
};
Button.defaultProps = {
  type: "button",
};

Button.propTypes = {
  buttonClass: PropTypes.string,
  textClass: PropTypes.string,
  danger: PropTypes.bool,
  title: PropTypes.string,
  rounded: PropTypes.bool,
  transparent: PropTypes.bool,
  small: PropTypes.bool,
  white: PropTypes.bool,
  primary: PropTypes.bool,
  primaryOutline: PropTypes.bool,
  file: PropTypes.bool,
  primaryTransparent: PropTypes.bool,
};
export default Button;
